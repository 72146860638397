import {RodapeLayout} from "./rodape-layout";

export class CampoRodapeLayout {
    codigo_campo_rodape_layout: number;
    codigo_rodape_layout: number;
    descricao: string;
    sigla: string;
    // schema: string;
    // tabela: string;
    // campo: string;
    sql: string;
    quantidade_caracteres: number;
    padrao: string;
    ordem: number;
    created_at: Date;
    deleted_at: Date;
    RodapeLayout : RodapeLayout = new RodapeLayout();
}
