<app-header></app-header>

<div class="row">
    <div class="col-sm-12">
      <div class="panel panel-default panel-table">
        <br />
        <div class="row" style="padding: 15px;">
            <div class="col-md-9">
                <span><b>DADOS DO ASSOCIADO</b></span>
                <br />
                <span class="ficha-financeira-span"><b>Associado:</b>&nbsp;&nbsp;{{associado_nome}}</span>
                <br />
                <span class="ficha-financeira-span"><b>Matrícula PF:</b>&nbsp;&nbsp;<span *ngIf="associado_matricula_dpf != 'null'">{{associado_matricula_dpf}}</span><span *ngIf="associado_matricula_dpf == 'null'">-</span></span>
                <br />
                <span class="ficha-financeira-span"><b>Tipo de Associado:</b>&nbsp;&nbsp;<span *ngIf="associado_tipo == 'AT'"> Ativo</span><span *ngIf="associado_tipo == 'AP'"> Aposentado</span><span *ngIf="associado_tipo == 'PE'"> Pensionista</span></span>
                <br />
                <span class="ficha-financeira-span"><b>Associado APCF:</b>&nbsp;&nbsp;<span *ngIf="associado_apcf == '1'"> Filiado</span><span *ngIf="associado_apcf == '0'"> Desfiliado</span></span>
                <br />
                <span class="ficha-financeira-span"><b>Associado APCF Sindical:</b>&nbsp;&nbsp;<span *ngIf="associado_apcf_sindical == '1'"> Filiado</span><span *ngIf="associado_apcf_sindical == '0'"> Desfiliado</span></span>
            </div>
            <div class="col-md-3">

            </div>
        </div>
        <br />
        <div class="panel-body" style="padding: 15px;">
            <button data-toggle="modal" data-target="#formPendencia" class="btn btn-space btn-success" tabindex="0" aria-controls="table3">Nova Pendência</button>
            <br />
            <br />
            <div class="row">
                <div class="tab-container">
                    <ul class="nav nav-tabs">
                        <li class="active"><a href="#aberta" data-toggle="tab">Ativas</a></li>
                        <li><a href="#excluida" data-toggle="tab">Inativas</a></li>
                    </ul>
                    <div class="tab-content">
                        <div id="aberta" class="tab-pane active cont">
                            <table id="table2" class="table table-striped table-hover table-fw-widget">
                                <thead>
                                    <tr>
                                        <th>Tipo da Pendência</th>
                                        <th>Descrição</th>
                                        <th>Data</th>
                                        <th>Situação</th>
                                        <th class="text-center">Ações</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="associadoPendencias && associadoPendencias.length > 0">
                                    <tr class="odd gradeX" *ngFor="let pendencia of associadoPendencias">
                                        <td class="col-md-2">{{pendencia.pendenciatipo.nome}}</td>
                                        <td class="col-md-6">{{pendencia.descricao}}</td>                       
                                        <td class="col-md-1">{{pendencia.created_at | date: 'dd/MM/yyyy'}}</td>                       
                                        <td class="col-md-1" *ngIf="pendencia.situacao == 'A'"><span class="label label-warning">Em aberto</span></td>                       
                                        <td class="col-md-1" *ngIf="pendencia.situacao == 'F'"><span class="label label-success">Finalizada</span></td>                       
                                        <td class="text-center" style="font-size: 22px;">
                                            <a data-toggle="modal" data-target="#md-footer-finalizar" *ngIf="pendencia.situacao != 'F'" (click)="definirCodigo(pendencia.codigo_associado_pendencia)"><span class="icon mdi mdi-check-circle"></span></a>
                                            &nbsp;
                                            <a data-toggle="modal" data-target="#formPendencia" *ngIf="pendencia.situacao != 'F'" (click)="carregar(pendencia.codigo_associado_pendencia)"><span class="mdi mdi-edit"></span></a>
                                            &nbsp;
                                            <a data-toggle="modal" data-target="#md-footer-reativar" *ngIf="pendencia.situacao == 'F'" (click)="definirCodigo(pendencia.codigo_associado_pendencia);"><span class="icon mdi mdi-undo"></span>&nbsp;</a>
                                            <a data-toggle="modal" data-target="#md-footer-delete" (click)="definirCodigo(pendencia.codigo_associado_pendencia)"><span class="icon mdi mdi-close-circle"></span></a>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="!associadoPendencias">
                                    <tr class="carregando">
                                        <td class="carregando" colspan="5">
                                            <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="associadoPendencias && associadoPendencias.length == 0">
                                    <tr>
                                        <td class="col-md-12" colspan="5">
                                            Nenhum registro encontrado.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div id="excluida" class="tab-pane cont">
                            <table id="table2" class="table table-striped table-hover table-fw-widget">
                                <thead>
                                    <tr>
                                        <th>Tipo da Pendência</th>
                                        <th>Descrição</th>
                                        <th>Data Exclusão</th>
                                        <th class="text-center">Ações</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="associadoPendenciasExcluidas && associadoPendenciasExcluidas.length > 0">
                                    <tr class="odd gradeX" *ngFor="let pendencia_excluida of associadoPendenciasExcluidas">
                                        <td class="col-md-2">{{pendencia_excluida.pendenciatipo.nome}}</td>
                                        <td class="col-md-6">{{pendencia_excluida.descricao}}</td>                       
                                        <td class="col-md-1">{{pendencia_excluida.deleted_at | date: 'dd/MM/yyyy'}}</td>                       
                                        <td class="text-center" style="font-size: 22px;">
                                            <a data-toggle="modal" data-target="#md-footer-restaurar" (click)="definirCodigo(pendencia_excluida.codigo_associado_pendencia);"><span class="badge badge-warning">Reativar</span></a>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="!associadoPendenciasExcluidas">
                                    <tr class="carregando">
                                        <td class="carregando" colspan="5">
                                            <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="associadoPendenciasExcluidas && associadoPendenciasExcluidas.length == 0">
                                    <tr>
                                        <td class="col-md-12" colspan="5">
                                            Nenhum registro encontrado.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</div>
<div class="row">
    <div class="col-sm-12">
        <a href="associado-listar" class="btn btn-space btn-default">Voltar</a>
    </div>
</div>

<!--Modal Pendencia-->
<div id="formPendencia" tabindex="-1" role="dialog" class="modal fade colored-header colored-header-primary">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close md-close"><span class="mdi mdi-close"></span></button>
                <h3 class="modal-title">Nova Pendencia</h3>
            </div>
            <div class="modal-body">
                <form #pendenciaForm="ngForm" [formGroup]="form" novalidate="" class="form-horizontal group-border-dashed">
                    <div class="form-group" [class.has-error]="(f.codigo_pendencia_tipo.touched || submitted) && f.codigo_pendencia_tipo.invalid == true">
                        <span class="col-sm-4" style="text-align: right;">
                            <label class="control-label">Tipo da Pendência <span class="obrigatorio">*</span></label>
                        </span>
                        <span class="col-sm-8">
                            <select class="select2 form-control" formControlName="codigo_pendencia_tipo">
                                <option *ngFor="let pendenciatipo of pendenciasTipo" value="{{pendenciatipo.codigo_pendencia_tipo}}">
                                    {{pendenciatipo.nome}}
                                </option>
                            </select>
                            <app-error-msg [control]="form.get('codigo_pendencia_tipo')" label="Tipo da Pendência"></app-error-msg>
                        </span>
                    </div>
                    <div class="form-group" [class.has-error]="(f.descricao.touched || submitted) && f.descricao.invalid == true">
                        <label for="inputNome" class="col-sm-4 control-label">Descrição <span class="obrigatorio">*</span></label>
                        <div class="col-sm-8">
                        <input id="inputNome" type="text" placeholder="Descrição" class="form-control" formControlName="descricao">
                        <app-error-msg [control]="form.get('descricao')" label="Descrição"></app-error-msg>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default md-close">Cancelar</button>
                <button type="button" data-dismiss="modal" class="btn btn-primary md-close" (click)="onSubmit()">Confirmar</button>
            </div>
        </div>
    </div>
</div>

<!--Modal Footer-->
<div id="md-footer-delete" tabindex="-1" role="dialog" style="" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <div class="text-warning"><span class="modal-main-icon mdi mdi-alert-triangle"></span></div>
                <h3>Tem certeza que deseja excluir este registro?</h3>
                <p>Esta operação não poderá ser desfeita.</p>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default">Cancelar</button>
                <button type="button" data-dismiss="modal" class="btn btn-warning" (click)="apagar(codigo_associado_pendencia)">Confirmar</button>
            </div>
        </div>
    </div>
</div>

<!--Modal Footer-->
<div id="md-footer-restaurar" tabindex="-1" role="dialog" style="" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <div class="text-warning"><span class="modal-main-icon mdi mdi-alert-triangle"></span></div>
                <h3>Tem certeza que deseja restaurar este registro?</h3>
                <p>Esta operação não poderá ser desfeita.</p>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default">Cancelar</button>
                <button type="button" data-dismiss="modal" class="btn btn-success" (click)="restaurar(codigo_associado_pendencia)">Confirmar</button>
            </div>
        </div>
    </div>
</div>

<!--Modal Footer-->
<div id="md-footer-reativar" tabindex="-1" role="dialog" style="" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <div class="text-warning"><span class="modal-main-icon mdi mdi-alert-triangle"></span></div>
                <h3>Tem certeza que deseja reativar este registro?</h3>
                <p>Esta operação não poderá ser desfeita.</p>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default">Cancelar</button>
                <button type="button" data-dismiss="modal" class="btn btn-success" (click)="reativar(codigo_associado_pendencia)">Confirmar</button>
            </div>
        </div>
    </div>
</div>

<!--Modal Footer-->
<div id="md-footer-finalizar" tabindex="-1" role="dialog" style="" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <div class="text-success"><span class="modal-main-icon mdi mdi-alert-triangle"></span></div>
                <h3>Tem certeza que deseja FINALIZAR esta pendência?</h3>
                <p>Esta operação não poderá ser desfeita.</p>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default">Cancelar</button>
                <button type="button" data-dismiss="modal" class="btn btn-success" (click)="finalizar(codigo_associado_pendencia)">Confirmar</button>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>