import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  user: any;
  me: any;
  nome_pessoa: string;
  codigo_pessoa: any;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private appComponent: AppComponent,
              private notifierService: NotifierService,
              private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.user = localStorage.getItem('currentUser');
    this.codigo_pessoa = localStorage.getItem('codigo_pessoa');
    this.nome_pessoa = localStorage.getItem('nome_pessoa');

    if(!this.user) {
      this.user = localStorage.getItem('currentUser');
    }
    if(!this.codigo_pessoa) {
      this.authenticationService.me().subscribe((data) => {
        this.me = data;
        localStorage.setItem('codigo_pessoa', this.me.codigo_pessoa);
        localStorage.setItem('nome_pessoa', this.me.nome);
        localStorage.setItem('nome_guerra', this.me.nome_guerra);
        localStorage.setItem('configuracao', this.me.configuracao);
        this.nome_pessoa = localStorage.getItem('nome_pessoa');
      }, error => console.log(error));
    }
  }

  logout() {
    this.authenticationService.logout();
    this.appComponent.setTitulo('');
    this.appComponent.setBreadcrumb(false);
    this.router.navigate(['login']);
  }

}
