import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppComponent } from 'src/app/app.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { AssociadoService } from 'src/app/core/services/associado.service';
import { NotifierService } from 'angular-notifier';
import { Associado } from 'src/app/shared/models/associado';
import { Uf } from 'src/app/shared/models/uf';
import { UfService } from 'src/app/core/services/uf.service';
import { FormValidation } from 'src/app/shared/validation/form-validation';
import { LotacaoService } from 'src/app/core/services/lotacao.service';
import { Lotacao } from 'src/app/shared/models/lotacao';
import { DatePipe } from '@angular/common'; //import do datePipe

@Component({
  selector: 'app-dados-administrativos',
  templateUrl: './dados-administrativos.component.html',
  styleUrls: ['./dados-administrativos.component.css'],
  providers: [DatePipe] //DatePipe como provider
})
export class DadosAdministrativosComponent implements OnInit {

  @ViewChild('associadoForm') formValues;
  @ViewChild('associadoFalecimentoForm') formFalecimentoValues;

  form: FormGroup;
  formFalecimento: FormGroup;
  breadCrumb;
  me;
  carregando: boolean;
  codigo_pessoa: number;
  tipo: string;
  Ufs: Uf;
  Lotacoes: Lotacao;
  carregando_salvar: boolean;
  avancar: boolean;
  data_falecimento: any;
  data_falecimento_form: any;
  nome_siape_instituidor: any;
  submited: boolean;
  tipo_associado: string;
  associado_tipo: string;

  constructor(private appComponent: AppComponent,
              private formBuilder: FormBuilder,
              private routerParam: ActivatedRoute,
              private router: Router,
              private datePipe: DatePipe,
              private authenticationService: AuthenticationService,
              private associadoService: AssociadoService,
              private ufService: UfService,
              private lotacaoService: LotacaoService,
              private notifierService: NotifierService) { 

              this.codigo_pessoa = this.routerParam.snapshot.params.id;

  }

  ngOnInit() {
    this.carregando = true;
    this.breadCrumb = [ 
      {nome: "Home", caminho: "/dashboard"},
      {nome: "Associado", caminho: "/associado-listar"}
    ];
    this.appComponent.setTitulo('Cadastro do Associado');
    this.appComponent.setBreadcrumb(this.breadCrumb);
    
    this.form = this.formBuilder.group({
      data_aposentadoria: [null, [FormValidation.dataValidator, FormValidation.dataAtualValidator]],
      data_falecimento: [null, [FormValidation.dataValidator, FormValidation.dataAtualValidator]],
      siape_instituidor: [null, FormValidation.siapeAtivoValidator],
      contrato_serpro: [null, FormValidation.contratoSerproValidator],
      codigo_upag: [null],
      sigla_uf_upag: [null],
      codigo_unidade_repasse: [null],
    });

    this.formFalecimento = this.formBuilder.group({
      data_falecimento_form: [null, [FormValidation.dataValidator, FormValidation.dataAtualValidator]],
    });

    this.obter();

  }

  obter(): void {
    this.carregando = true;
    this.associadoService.find(this.codigo_pessoa).subscribe((data: Associado) => {
      console.log(data);
      
      this.tipo = data.tipo;
      this.associado_tipo = data.tipo;
      this.data_falecimento = data.data_falecimento;
      this.nome_siape_instituidor = data.nome_siape_instituidor;
      this.form.patchValue({
        data_aposentadoria: this.datePipe.transform(data.data_aposentadoria, 'dd/MM/yyyy'),
        data_falecimento: this.datePipe.transform(data.data_falecimento, 'dd/MM/yyyy'),
        siape_instituidor: data.siape_instituidor,
        contrato_serpro: data.contrato_serpro,
        codigo_upag: data.codigo_upag,
        sigla_uf_upag: data.sigla_uf_upag,
        codigo_unidade_repasse: data.codigo_unidade_repasse,
      });

      const dataAposentadoriaControl = this.form.get('data_aposentadoria');
      const dataFalecimentoControl = this.form.get('data_falecimento');
      const siapeInstituidorControl = this.form.get('siape_instituidor');

      if (this.associado_tipo === 'AP') {
        dataAposentadoriaControl.setValidators([Validators.required, FormValidation.dataValidator]);
        dataFalecimentoControl.setValidators(null);
        siapeInstituidorControl.setValidators(null);
      }
      if (this.associado_tipo === 'AT' || this.associado_tipo === 'PE') {
        dataAposentadoriaControl.setValidators(null);
        dataFalecimentoControl.setValidators(null);
        siapeInstituidorControl.setValidators(null);
      }
      if (this.associado_tipo === 'PE') {       
        dataFalecimentoControl.setValidators(Validators.required);
        siapeInstituidorControl.setValidators(FormValidation.siapeAtivoValidator);
      }
      dataAposentadoriaControl.updateValueAndValidity();
      siapeInstituidorControl.updateValueAndValidity();
      dataFalecimentoControl.updateValueAndValidity();
      siapeInstituidorControl.updateValueAndValidity();

      if(!this.data_falecimento) {
        const dataFalecimentoControl = this.form.get('data_falecimento');
        dataFalecimentoControl.setValidators(null);
        dataFalecimentoControl.updateValueAndValidity();       
      }

      this.obterLotacao();
      this.obterUf();
      this.carregando = false;
    });
  }

  obterLotacao() {
    this.lotacaoService.list()
    .subscribe((data: Lotacao) => this.Lotacoes = data,
                error => console.log(error));
  }

  obterUf() {
    this.ufService.list()
    .subscribe((data: Uf) => this.Ufs = data,
                error => console.log(error));
                
  }

  definirAvancar() {
    this.avancar = true;
  }

  definirDataFalecimento() {
    this.data_falecimento_form = this.formFalecimento.value.data_falecimento_form;
    this.data_falecimento = this.data_falecimento_form;
    this.form.controls.data_falecimento.setValue(this.data_falecimento_form);
  }

  limparDataFalecimento() {
    this.data_falecimento_form = null;
    this.data_falecimento = null;
    this.form.controls.data_falecimento.setValue(null);
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit(avancar): void {
    this.submited = true;
    if(avancar) {
      this.avancar = avancar
    }
    this.carregando_salvar = true;
    let associado = this.form.value as Associado;
    associado.codigo_pessoa = this.codigo_pessoa;
    if(this.data_falecimento_form) {
      associado.data_falecimento = this.data_falecimento_form;
    }
    if(!associado.data_falecimento || associado.data_falecimento == null) {
      associado.data_falecimento = null;
    }
    if (this.form.valid) {
      this.associadoService.save(associado, this.codigo_pessoa).subscribe((data) => {
        this.notifierService.notify( 'success', 'Dados administrativos atualizados com sucesso.' );
        this.carregando_salvar = false;
        //this.obter();
        this.submited = false;
        if(this.data_falecimento) {
          this.router.navigate(['associado-listar/']);
        }
        if(this.avancar) {
          this.router.navigate(['dados-controle/' + this.codigo_pessoa]);
        }
      }, (error) => {
        this.carregando_salvar = false;
        this.notifierService.notify( 'error', error );
      });
    } else {
      //this.addMessage.controls['message'].value
      console.log(this.form.controls);
      this.carregando_salvar = false;
      this.notifierService.notify( 'error', 'Existem erros no preenchimento do formulário.');
    }
  }

}
