<app-header></app-header>

<div class="row wizard-row">
  <div class="col-md-12 fuelux">
    <div class="block-wizard panel panel-default">
      <div id="wizard1" class="wizard wizard-ux">
        <ul class="steps">
          <li data-step="1"><a href="dados-pessoais/{{codigo_pessoa}}">Dados Pessoais</a><span class="chevron"></span></li>
          <li data-step="2" class="active"><a href="dados-financeiros/{{codigo_pessoa}}">Dados Financeiros</a><span class="chevron"></span></li>
          <li data-step="3"><a href="dados-administrativos/{{codigo_pessoa}}">Dados Administrativos</a><span class="chevron"></span></li>
          <li data-step="4"><a href="dados-controle/{{codigo_pessoa}}">Dados de Controle</a><span class="chevron"></span></li>
          <li data-step="5"><a href="dados-complementares/{{codigo_pessoa}}">Dados Complementares</a><span class="chevron"></span></li>
        </ul>
        <div class="step-content">
          <div *ngIf="!carregando" style="text-align: center;"><img src="/assets/img/carregando_tabela.gif"></div>
          <div data-step="1" class="step-pane active" *ngIf="carregando">
            <form #associadoForm="ngForm" [formGroup]="form" data-parsley-namespace="data-parsley-" data-parsley-validate="" novalidate="" class="form-horizontal group-border-dashed">
              <div class="form-group">
                <label class="col-sm-3 control-label">Banco </label>
                <div class="col-sm-6">
                  <select class="select2 form-control" formControlName="codigo_banco">
                    <option *ngFor="let dado of banco" value="{{dado.codigo_banco}}">
                      {{dado.numero}} - {{dado.nome}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-3 control-label">Agência </label>
                <div class="col-sm-2">
                  <input type="text" placeholder="Agência" class="form-control" [dropSpecialCharacters]="true" mask="0000-0" formControlName="agencia">
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-3 control-label">Conta </label>
                <div class="col-sm-3">
                  <input type="text" placeholder="Conta" class="form-control" maxlength="20" formControlName="conta">
                </div>
                <div class="col-sm-2">
                  <input type="text" placeholder="DV" class="form-control" maxlength="3" formControlName="agencia_dv">
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-3 control-label">Operação</label>
                <div class="col-sm-1">
                  <input type="text" placeholder="Opr." class="form-control" maxlength="4" formControlName="operacao">
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-offset-3 col-sm-9">
                  <a href="dados-pessoais/{{codigo_pessoa}}" data-wizard="#wizard1" class="btn btn-primary btn-space wizard-next">Anterior</a>&nbsp;&nbsp;&nbsp;&nbsp;
                  <a href="dados-administrativos/{{codigo_pessoa}}" data-wizard="#wizard1" class="btn btn-primary btn-space wizard-next">Avançar</a>&nbsp;&nbsp;&nbsp;&nbsp;
                  <button data-wizard="#wizard1" class="btn btn-primary btn-space wizard-next" (click)="onSubmit()">Salvar</button>&nbsp;&nbsp;&nbsp;&nbsp;
                  <button data-wizard="#wizard1" class="btn btn-success btn-space wizard-next" (click)="onSubmit(true)">Salvar e Avançar</button>  
                  &nbsp; <img *ngIf="carregando_salvar" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>