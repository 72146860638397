import { Component, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { PessoaBanco } from 'src/app/shared/models/pessoa-banco';
import { PessoaBancoService } from 'src/app/core/services/pessoa-banco.service';
import { BancoService } from 'src/app/core/services/banco.service';
import { Banco } from 'src/app/shared/models/banco';

@Component({
  selector: 'app-dados-financeiros',
  templateUrl: './dados-financeiros.component.html',
  styleUrls: ['./dados-financeiros.component.css']
})
export class DadosFinanceirosComponent implements OnInit {

  @ViewChild('associadoForm') formValues;

  form: FormGroup;
  breadCrumb;
  me;
  carregando: boolean;
  codigo_pessoa: number;
  codigo_pessoa_banco: number;
  bancoPessoa: PessoaBanco;
  banco: Banco;
  carregando_salvar: boolean;

  constructor(private appComponent: AppComponent,
              private formBuilder: FormBuilder,
              private routerParam: ActivatedRoute,
              private router: Router,
              private authenticationService: AuthenticationService,
              private pessoaBancoService: PessoaBancoService,
              private bancoService: BancoService,
              private notifierService: NotifierService) { 

              this.codigo_pessoa = this.routerParam.snapshot.params.id;

  }

  ngOnInit() {
    this.breadCrumb = [ 
      {nome: "Home", caminho: "/dashboard"},
      {nome: "Associado", caminho: "/associado-listar"}
    ];
    this.appComponent.setTitulo('Cadastro do Associado');
    this.appComponent.setBreadcrumb(this.breadCrumb);
    
    this.form = this.formBuilder.group({
      codigo_banco: [null],
      agencia: [null],
      agencia_dv: [null],
      conta: [null],
      operacao: [null],
    });
    
    this.obter();
    this.obterBanco();
  }

  obter(): void {
    this.carregando = false;
    this.pessoaBancoService.find(this.codigo_pessoa).subscribe((data: PessoaBanco) => {
      this.codigo_pessoa_banco = data.codigo_pessoa_banco;
      this.form.patchValue({
        codigo_banco: data.codigo_banco,
        agencia: data.agencia,
        agencia_dv: data.agencia_dv,
        conta: data.conta,
        operacao: data.operacao,
      });
      this.carregando = true;
    });
  }

  obterBanco() {
    this.bancoService.list()
    .subscribe((data: Banco) => this.banco = data,
                error => console.log(error));
                
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit(avancar): void {   
    this.carregando_salvar = true;
    let pessoaBanco = this.form.value as PessoaBanco;
    pessoaBanco.codigo_pessoa = this.codigo_pessoa;
    this.pessoaBancoService.save(pessoaBanco, this.codigo_pessoa_banco).subscribe((data) => {
      this.notifierService.notify( 'success', 'Dados financeiros ATUALIZADOS com sucesso.' );
      this.carregando_salvar = false;
      if(avancar) {
        this.router.navigate(['dados-administrativos/' + this.codigo_pessoa]);
      }
    }, (error) => {
      this.carregando_salvar = false;
      this.notifierService.notify( 'error', 'Não foi possível CADASTRAR/ATUALIZAR o registro, ocorreu um erro. ' + error );
    });
  }

}
