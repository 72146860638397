import { Component, OnInit, ViewChild, DefaultIterableDiffer } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Uf } from 'src/app/shared/models/uf';
import { AppComponent } from 'src/app/app.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { AssociadoService } from 'src/app/core/services/associado.service';
import { UfService } from 'src/app/core/services/uf.service';
import { NotifierService } from 'angular-notifier';
import { Associado } from 'src/app/shared/models/associado';

@Component({
  selector: 'app-dados-controle',
  templateUrl: './dados-controle.component.html',
  styleUrls: ['./dados-controle.component.css']
})
export class DadosControleComponent implements OnInit {

  @ViewChild('associadoForm') formValues;

  form: FormGroup;
  breadCrumb;
  me;
  carregando: boolean;
  codigo_pessoa: number;
  tipo: string;
  Ufs: Uf;
  carregando_salvar: boolean;
  associado_apcf: string;
  associado_apcf_sindical: string;

  constructor(private appComponent: AppComponent,
              private formBuilder: FormBuilder,
              private routerParam: ActivatedRoute,
              private router: Router,
              private authenticationService: AuthenticationService,
              private associadoService: AssociadoService,
              private ufService: UfService,
              private notifierService: NotifierService) { 

              this.codigo_pessoa = this.routerParam.snapshot.params.id;

  }

  ngOnInit() {
    this.carregando = true;
    this.breadCrumb = [ 
      {nome: "Home", caminho: "/dashboard"},
      {nome: "Associado", caminho: "/associado-listar"}
    ];
    this.appComponent.setTitulo('Cadastro do Associado');
    this.appComponent.setBreadcrumb(this.breadCrumb);
    
    this.form = this.formBuilder.group({
      isento_mensalidade: [null, Validators.required],
      isento_mensalidade_apcf_sindical: [null, Validators.required],
      acesso_sistemas: [null, Validators.required],
      acesso_sistemas_apcf_sindical: [null, Validators.required],
      habilitado_votacao: [null, Validators.required],
      habilitado_votacao_sindical: [null, Validators.required],
      codigo_conselho_etica: [null, Validators.required],
      codigo_conselho_fiscal: [null, Validators.required],
      codigo_diretoria_executiva: [null, Validators.required],
      codigo_diretoria_regional: [null, Validators.required],
      codigo_secao_sub_regional: [null, Validators.required],
      sigla_uf_secao: [null, Validators.required],
      sigla_uf_regional: [null, Validators.required],
      inibir_envio_sms: [null, Validators.required],
      inibir_envio_email: [null, Validators.required],
    });
    
    this.obter();
    this.obterUf();

    this.carregando = false;
  }

  obter(): void {
    this.carregando = false;
    this.associadoService.find(this.codigo_pessoa).subscribe((data: Associado) => {
      this.tipo = data.tipo;
      this.associado_apcf_sindical = data.associado_apcf_sindical;
      this.associado_apcf = data.associado_apcf;
      (!data.isento_mensalidade) ? data.isento_mensalidade = 'N' : data.isento_mensalidade;
      (!data.isento_mensalidade_apcf_sindical) ? data.isento_mensalidade_apcf_sindical = 'N' : data.isento_mensalidade_apcf_sindical;
      (!data.acesso_sistemas) ? data.acesso_sistemas = 'S' : data.acesso_sistemas;
      (!data.acesso_sistemas_apcf_sindical) ? data.acesso_sistemas_apcf_sindical = 'S' : data.acesso_sistemas_apcf_sindical;
      (!data.habilitado_votacao) ? data.habilitado_votacao = 'S' : data.habilitado_votacao;
      (!data.habilitado_votacao_sindical) ? data.habilitado_votacao_sindical = 'S' : data.habilitado_votacao_sindical;
      (!data.inibir_envio_sms) ? data.inibir_envio_sms = 'N' : data.inibir_envio_sms;
      (!data.inibir_envio_email) ? data.inibir_envio_email = 'N' : data.inibir_envio_email;
      
      if(data.tipo == 'PE') {
        data.acesso_sistemas_apcf_sindical = 'N';
        data.habilitado_votacao = 'N';
        data.habilitado_votacao_sindical = 'N';
        data.isento_mensalidade_apcf_sindical = 'S';
      }

      this.form.patchValue({
        isento_mensalidade: data.isento_mensalidade,
        isento_mensalidade_apcf_sindical: data.isento_mensalidade_apcf_sindical,
        acesso_sistemas: data.acesso_sistemas,
        acesso_sistemas_apcf_sindical: data.acesso_sistemas_apcf_sindical,
        habilitado_votacao: data.habilitado_votacao,
        habilitado_votacao_sindical: data.habilitado_votacao_sindical,
        codigo_conselho_etica: data.codigo_conselho_etica,
        codigo_conselho_fiscal: data.codigo_conselho_fiscal,
        codigo_diretoria_executiva: data.codigo_diretoria_executiva,
        codigo_diretoria_regional: data.codigo_diretoria_regional,
        codigo_secao_sub_regional: data.codigo_secao_sub_regional,
        sigla_uf_secao: data.sigla_uf_secao,
        sigla_uf_regional: data.sigla_uf_regional,
        inibir_envio_sms: data.inibir_envio_sms,
        inibir_envio_email: data.inibir_envio_email
      });
      this.carregando = true;
    });
  }

  obterUf() {
    this.ufService.list()
    .subscribe((data: Uf) => this.Ufs = data,
                error => console.log(error));
                
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit(avancar): void {   
    this.carregando_salvar = true;
    let associado = this.form.value as Associado;
    associado.codigo_pessoa = this.codigo_pessoa;
    this.associadoService.save(associado, this.codigo_pessoa).subscribe((data) => {
      this.notifierService.notify( 'success', 'Dados de controle ATUALIZADOS com sucesso.' );
      this.carregando_salvar = false;
      if(avancar) {
        this.router.navigate(['dados-complementares/' + this.codigo_pessoa]);
      }
    }, (error) => {
      this.carregando_salvar = false;
      this.notifierService.notify( 'error', 'Não foi possível ATUALIZAR dados de controle, pois ocorreu um erro. ' + error );
    });
  }

}
