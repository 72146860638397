import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { FormValidation } from 'src/app/shared/validation/form-validation';

import { Documento } from 'src/app/shared/models/documento';
import { AppComponent } from 'src/app/app.component';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { DocumentoService } from 'src/app/core/services/documento.service';
import { DocumentoTipoService } from 'src/app/core/services/documento-tipo.service';
import { DocumentoAnexoService } from 'src/app/core/services/documento-anexo.service';
import { DocumentoTipo } from 'src/app/shared/models/documento-tipo';

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { filterResponse, uploadProgress } from 'src/app/shared/helpers/rxjs-operators';
import { UploadFileService } from 'src/app/core/services/upload.service';

@Component({
  selector: 'app-documento-editar',
  templateUrl: './documento-editar.component.html',
  styleUrls: ['./documento-editar.component.css'],
  providers: [DatePipe] //DatePipe como provider
})
export class DocumentoEditarComponent implements OnInit {

  @ViewChild('documentoForm') formValues;

  public config = {
    placeholder: 'Escreva um comentário',
    toolbar: [ 'bold', 'italic' ]
  }

  files: Set<File>;
  progress = 0;
  carregando_anexo: boolean;
  anexos: any;

  form: FormGroup;
  breadCrumb;
  carregando: boolean;
  submited: boolean;
  documento: Documento;
  codigo_documento: number;
  documentos_tipo: DocumentoTipo;
  ano: any;
  numero: any;
  codigo_documento_tipo: number;
  nome_documento_tipo: string;
  data_documento: string;

  public Editor = ClassicEditor;
  numero_documento_vinculado: any;
  anexo: string;
  extensao: string;

  constructor(private appComponent: AppComponent,
              private formBuilder: FormBuilder,
              private uploadFileService: UploadFileService,
              private routerParam: ActivatedRoute,
              private router: Router,
              private datePipe: DatePipe,
              private authenticationService: AuthenticationService,
              private documentoService: DocumentoService,
              private documentoAnexoService: DocumentoAnexoService,
              private documentoTipoService: DocumentoTipoService,
              private notifierService: NotifierService) {
              this.codigo_documento = this.routerParam.snapshot.params.id;
  }

  ngOnInit() {

    this.breadCrumb = [
      {nome: 'Documentos', caminho: '/documento-listar'},
      {nome: 'Editar', caminho: '/documento-editar'},
    ];
    this.appComponent.setTitulo('Cadastro de Documento');
    this.appComponent.setBreadcrumb(this.breadCrumb);

    this.form = this.formBuilder.group({
      numero_documento_vinculado: [null],
      assunto: [null, Validators.required],
      codigo_documento_tipo: [null, Validators.required],
      data_documento: [null, [Validators.required, FormValidation.dataValidator]],
      url: [null],
      observacao: [null],
    });

    if (this.codigo_documento) {
      this.carregando = true;
      this.obter();
    }
    this.obterDocumentoTipo();

  }

  obterDocumentoTipo() {
    this.documentoTipoService.list(false)
    .subscribe((data: DocumentoTipo) => this.documentos_tipo = data,
                error => console.log(error));
  }

  obter(): void {
    this.documentoService.find(this.codigo_documento).subscribe((data: Documento) => {
      this.codigo_documento_tipo = data.documentotipo.codigo_documento_tipo;
      this.nome_documento_tipo = data.documentotipo.nome;
      this.numero = data.numero;
      this.anexo = data.anexo;
      this.extensao = data.extensao;
      this.numero_documento_vinculado = data.numero_documento_vinculado;
      this.ano = data.ano;
      this.data_documento = this.datePipe.transform(data.data_documento, 'dd/MM/yyyy');
      this.form.patchValue({
        numero_documento_vinculado: data.numero_documento_vinculado,
        assunto: data.assunto,
        codigo_documento_tipo: data.codigo_documento_tipo,
        data_documento: this.datePipe.transform(data.data_documento, 'dd/MM/yyyy'),
        url: data.url,
        observacao: data.observacao
      });
      this.carregando = false;
    });
  }

  onSubmit(): void {
    this.submited = true;
    this.carregando = true;
    const documento = this.form.value as Documento;
    //if(this.codigo_documento) {
    //  documento.data_documento = this.data_documento;
    //}
    if (documento.data_documento && documento.assunto && documento.codigo_documento_tipo) {
      this.documentoService.save(documento, this.codigo_documento).subscribe((data) => {
        this.router.navigate(['documento-listar']);
        this.notifierService.notify( 'success', 'Documento cadastrado com sucesso.' );
        this.carregando = false;
      }, (error) => {
        this.notifierService.notify( 'error', 'Não foi cadastrar um novo documento. ' + error );
        this.carregando = false;
      });
    } else {
      console.log(this.form);
      this.notifierService.notify( 'error', 'Existem erros no preenchimento do formulário.');
      this.carregando = false;
    }
  }

  baixarDocumento( nome ): void {
    this.carregando_anexo = true;
    this.documentoAnexoService.downloadArquivo(this.codigo_documento, nome);
    this.carregando_anexo = false;
  }

  onChange(event) {
    const selectedFiles = <FileList>event.srcElement.files;
    const fileNames = [];
    this.files = new Set();
    for (let i = 0; i < selectedFiles.length; i++) {
      fileNames.push(selectedFiles[i].name);
      this.files.add(selectedFiles[i]);
    }
    document.getElementById('customFileLabel').innerHTML = fileNames.join(', ');
    this.progress = 0;
  }

  onUpload() {
    this.carregando = true;
    if (this.files && this.files.size > 0) {
      this.uploadFileService.upload(this.files, environment.UrlPrincipal + '/api/documento-anexo/' + this.codigo_documento, this.codigo_documento)
        .pipe(
          uploadProgress(progress => {
            this.progress = progress;
          }),
          filterResponse()
        )
        .subscribe(response => { 
          console.log('Upload Concluído');
          this.obter();
          this.carregando = false;
          this.notifierService.notify( 'success', 'Arquivo ENVIADO com sucesso.' );
        });
    }
  }
  codigo_pessoa(files: Set<File>, arg1: string, codigo_documento: number, codigo_pessoa: any) {
    throw new Error('Method not implemented.');
  }

}
