<app-header></app-header>

<div class="panel panel-default panel-border-color panel-border-color-primary">
  <div class="panel-heading panel-heading-divider">Certificado</div>
  <div class="panel-body">
    <p>Esta opção gera um novo certificado irá criar um par de chaves sendo uma pública e outra privada. </p>
    <p>Além disso, ao clicar no botão abaixo a chave pública será enviada para o servidor externo enquanto a chave privada ficará no servidor interno.</p>
    <button type="button" data-toggle="modal" data-target="#md-footer-success" *ngIf="!carregando" class="btn btn-space btn-primary">Gerar Novo Certificado</button>
    <div *ngIf="carregando" style="text-align: center;"><img src="/assets/img/carregando_tabela.gif"></div>
  </div>
</div>
<a href="configuracao" class="btn btn-space btn-default">Voltar</a>

<!--Modal Footer-->
<div id="md-footer-success" tabindex="-1" role="dialog" style="" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                  <div class="text-warning"><span class="modal-main-icon mdi mdi-alert-triangle"></span></div>
                  <h3>Tem certeza que deseja GERAR um novo par de chaves?</h3>
                  <p style="color: red;">Esta operação irá DESTRUIR o par de chaves atual e estes não poderão ser recuperados.</p>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default">Cancelar</button>
                <button type="button" data-dismiss="modal" class="btn btn-warning" (click)="gerarCertificado()">Confirmar</button>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>