
  <div class="splash-container" style="margin-top: -30px;">
    <div class="panel panel-default panel-border-color panel-border-color-primary">
      <div class="panel-heading"><img src="assets/img/logotipo.svg" alt="logo" class="logo-img"><span class="splash-description">Por favor, informe seus dados de acesso.</span></div>
      <div class="panel-body">
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <input id="username" type="text" placeholder="Usuário" autocomplete="off" class="form-control" formControlName="email">
          </div>
          <div class="form-group">
            <input id="password" type="password" placeholder="Senha" class="form-control" formControlName="password">
          </div>
          <div class="form-group row login-tools">
            <div class="col-xs-6 login-remember">
              <!--<div class="be-checkbox">
                <input type="checkbox" id="remember">
                <label for="remember">Remember Me</label>
              </div>-->
            </div>
            <div class="col-xs-6 login-forgot-password" data-toggle="modal" data-target="#form-cadastro"><a style="cursor: pointer;">Esqueceu sua senha?</a></div>
          </div>
          <div class="form-group login-submit">
            <button [disabled]="loading" type="submit" class="btn btn-primary btn-xl">Acessar</button>
            <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
          </div>
        </form>
      </div>
    </div>
  </div>

  <div id="form-cadastro" tabindex="-1" role="dialog" class="modal fade colored-header colored-header-primary">
    <div class="modal-dialog custom-width">
      <div class="modal-content">
        <form #recuperarSenhaForm="ngForm" [formGroup]="RecuperarSenhaForm" novalidate="" class="form-horizontal group-border-dashed">
          <div class="modal-header">
            <button type="button" data-dismiss="modal" aria-hidden="true" class="close md-close"><span class="mdi mdi-close"></span></button>
            <h3 class="modal-title">Informe o e-mail para recuperação da senha</h3>
          </div>
          <div class="modal-body">

            <div class="form-group">
              <label class="col-sm-2 control-label">E-mail</label>
              <div class="col-sm-9">
                <input type="text" placeholder="E-mail" class="form-control" formControlName="email">
              </div>
            </div>
  
          </div>
          <div class="modal-footer">
            <button type="button" data-dismiss="modal" class="btn btn-default md-close">Cancelar</button>
            <button type="button" data-dismiss="modal" class="btn btn-primary md-close" (click)="onRecuperarSenhaSubmit()">Confirmar</button>
          </div>
        </form>
      </div>
    </div>
  </div>