import {Layout} from "./layout";

export class CampoLayout {
    codigo_campo_layout: number;
    codigo_layout: number;
    descricao: string;
    sigla: string;
    // schema: string;
    // tabela: string;
    // campo: string;
    sql: string;
    quantidade_caracteres: number;
    padrao: string;
    ordem: number;
    created_at: Date;
    deleted_at: Date;
    Layout : Layout = new Layout();
}
