import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../../../../app.component';
import { NotifierService } from 'angular-notifier';
import { DemandaTipo } from 'src/app/shared/models/demanda-tipo';
import { DemandaTipoService } from 'src/app/core/services/demanda-tipo.service';

@Component({
    selector: 'app-demanda-tipo-listar',
    templateUrl: './demanda-tipo-listar.component.html',
    styleUrls: ['./demanda-tipo-listar.component.css'],
})
export class DemandaTipoListarComponent implements OnInit {
    demandas_tipo: DemandaTipo;
    codigo_demanda_tipo;
    breadCrumb;
    demandas_tipo_inativos: DemandaTipo;
    carregando;

    constructor(
        private appComponent: AppComponent,
        private demandaTipoService: DemandaTipoService,
        private notifierService: NotifierService,
    ) {}

    ngOnInit() {
        this.breadCrumb = [
            { nome: 'Configuração', caminho: 'configuracao' },
            { nome: 'demandas_tipo', caminho: '/demanda-tipo-listar' },
        ];
        this.appComponent.setTitulo('Demandas Tipo Cadastradas');
        this.appComponent.setBreadcrumb(this.breadCrumb);

        this.listar();
    }

    definirCodigo(codigo_demanda_tipo) {
        this.codigo_demanda_tipo = codigo_demanda_tipo;
    }

    listar(): void {
        this.demandaTipoService.list(false).subscribe(
            (data: DemandaTipo) => {
                this.demandas_tipo = data;
            },
            (error) => console.log(error),
        );

        // demandas inativas
        this.demandaTipoService.list(true).subscribe(
            (data_inativo: DemandaTipo) => {
                this.demandas_tipo_inativos = data_inativo;
            },
            (error) => console.log(error),
        );
    }

    apagar(codigo_demanda_tipo): void {
        this.carregando = true;
        this.demandaTipoService.delete(codigo_demanda_tipo).subscribe(
            (data) => {
                this.listar();
                this.carregando = false;
                this.notifierService.notify(
                    'success',
                    'Registro DELETADO com sucesso.',
                );
            },
            (error) => {
                this.notifierService.notify('error', error);
                this.carregando = false;
            },
        );
    }

    restaurar(codigo_demanda_tipo): void {
        this.demandaTipoService.restore(codigo_demanda_tipo).subscribe(
            (data) => {
                this.listar();
                this.notifierService.notify(
                    'success',
                    'Registro RESTAURADO com sucesso.',
                );
            },
            (error) => {
                this.notifierService.notify(
                    'error',
                    'Não foi possível REATIVAR o registro. ' + error,
                );
            },
        );
    }
}
