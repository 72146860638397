<app-header></app-header>

<div class="row">
  <div class="col-sm-12">
    <div class="panel panel-default panel-table">
    <div class="panel-heading">
        <div class="">
            <button routerLink="/documento-novo" class="btn btn-space btn-success" tabindex="0" aria-controls="table3">Novo Documento</button>
        </div>
    </div>
    <div class="panel-body">

        <div class="tab-container">
            <ul class="nav nav-tabs">
                <li class="active"><a href="#aberta" data-toggle="tab">Vigentes</a></li>
                <li><a href="#encerrada" data-toggle="tab">Excluídos</a></li>
            </ul>
            <div class="tab-content">
                <form #documentoForm="ngForm" [formGroup]="form" novalidate="" class="form-horizontal group-border-dashed">

                    <div class="form-group xs-mt-5">
                      <div class="col-md-5">
                        <label>Tipo de Documento</label>
                        <select class="form-control" formControlName="codigo_documento_tipo">
                          <option *ngFor="let dado of documentos_tipo" value="{{dado.codigo_documento_tipo}}">
                            {{dado.nome}}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-2">
                        <label>Ano</label>
                        <select class="form-control" formControlName="filtro_ano" (change)="definirFiltroAno($event.target.value)">
                            <option *ngFor="let dado of ano" value="{{dado.ano}}">{{dado.ano}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group xs-mt-5">
                      <div class="col-md-3">
                        <button type="button" data-dismiss="modal" class="btn btn-primary" (click)="listar(true)">Filtrar</button>
                        &nbsp;
                        <button type="button" data-dismiss="modal" class="btn btn-default" (click)="listar(false)">Limpar Filtro</button>
                      </div>
                    </div>

                </form>

                <div id="aberta" class="tab-pane active cont">
                    <div class="dataTables_length" id="table3_length">
                        <table id="table2" class="table table-striped table-hover table-fw-widget">
                            <thead>
                                <tr>
                                    <th>Nº</th>
                                    <th>Ano</th>
                                    <th>Tipo</th>
                                    <th>Informações</th>
                                    <th>Observação</th>
                                    <th class="text-center">Ações</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="documentos && documentos.length > 0">
                                <tr class="odd gradeX" *ngFor="let documento of documentos">
                                    <td class="col-md-1">{{documento.numero}}</td>
                                    <td class="col-md-1">{{documento.ano}}</td>
                                    <td class="col-md-2">{{documento.documentotipo.nome}}</td>
                                    <td class="col-md-3"><b>Assunto:</b> {{documento.assunto}}<br /><b>Data:</b> {{documento.data_documento | date: 'dd/MM/yyyy'}}<br /><b>Nº vinculado:</b> <span *ngIf="documento.numero_documento_vinculado">{{documento.numero_documento_vinculado}}</span><span *ngIf="!documento.numero_documento_vinculado"> -</span><br /><b>URL</b>: <span *ngIf="documento.url"><a href="{{documento.url}}" target="_blank">{{documento.url}}</a></span><span *ngIf="!documento.url">Não informado</span><br /><span><b>Arquivo:</b> &nbsp;<a *ngIf="documento.anexo" style="cursor: pointer;" (click)="baixarDocumento(documento.codigo_documento, documento.anexo)"><span class="badge badge-default">baixar</span></a></span></td>
                                    <td class="col-md-3"><span *ngIf="documento.observacao" [innerHTML]="documento.observacao"></span><span *ngIf="!documento.observacao">-</span></td>
                                    <td class="col-md-2 text-center" style="font-size: 22px;">
                                      <a href="/documento-editar/{{documento.codigo_documento}}"><span class="icon mdi mdi-edit"></span></a>
                                      &nbsp;&nbsp;
                                      <a style="cursor: pointer;" data-toggle="modal" data-target="#md-footer-excluir" (click)="definirCodigo(documento.codigo_documento)"><span class="icon mdi mdi-delete"></span></a>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="!documentos || carregando">
                                <tr class="carregando">
                                    <td class="carregando" colspan="7">
                                        <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="documentos && documentos.length == 0">
                                <tr>
                                    <td class="col-md-12" colspan="7">
                                        Não existem documentos cadastrados.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div id="encerrada" class="tab-pane cont">
                    <div class="dataTables_length" id="table3_length">
                        <table id="table2" class="table table-striped table-hover table-fw-widget">
                            <thead>
                                <tr>
                                    <th>Nº</th>
                                    <th>Ano</th>
                                    <th>Tipo</th>
                                    <th>Informações/th>
                                    <th>Observação</th>
                                    <th>Excluído em</th>
                                    <th class="text-center">Ações</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="documentos_inativos && documentos_inativos.length > 0">
                                <tr class="odd gradeX" *ngFor="let documento of documentos_inativos">
                                    <td class="col-md-1">{{documento.numero}}</td>
                                    <td class="col-md-1">{{documento.ano}}</td>
                                    <td class="col-md-2">{{documento.documentotipo.nome}}</td>
                                    <td class="col-md-3"><b>Assunto:</b> {{documento.assunto}}<br /><b>Data:</b> {{documento.data_documento | date: 'dd/MM/yyyy'}}<br /><b>Nº vinculado:</b> <span *ngIf="documento.numero_documento_vinculado">{{documento.numero_documento_vinculado}}</span><span *ngIf="!documento.numero_documento_vinculado"> -</span><br /><b>URL</b>: <span *ngIf="documento.url"><a href="{{documento.url}}" target="_blank">{{documento.url}}</a></span><span *ngIf="!documento.url">Não informado</span><br /><span>Arquivo: <a *ngIf="documento.anexo" style="cursor: pointer;" (click)="baixarDocumento(documento.codigo_documento, documento.anexo)"><span class="badge badge-default">baixar</span></a></span></td>
                                    <a *ngIf="documento.anexo" ><span class="icon mdi mdi-file"></span></a>
                                    <td class="col-md-3"><span *ngIf="documento.observacao" [innerHTML]="documento.observacao"></span><span *ngIf="!documento.observacao">-</span></td>
                                    <td class="col-md-2">{{documento.updated_at | date: 'dd/MM/yyyy HH:mm'}}</td>
                                    <td class="text-center" style="font-size: 22px;">
                                      <a href="" data-toggle="modal" data-target="#md-footer-restaurar" (click)="definirCodigo(documento.codigo_documento)"><span class="badge badge-warning">Reativar</span></a>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="!documentos_inativos">
                                <tr class="carregando">
                                    <td class="carregando" colspan="7">
                                        <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="documentos_inativos && documentos_inativos.length == 0">
                                <tr>
                                    <td class="col-md-12" colspan="7">
                                        Não existem documentos excluídos.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>

    </div>
  </div>
</div>

<!--Modal Footer-->
<div id="md-footer-excluir" tabindex="-1" role="dialog" style="" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <div class="text-warning"><span class="modal-main-icon mdi mdi-alert-triangle"></span></div>
                <h3>Tem certeza que deseja EXCLUIR este registro?</h3>
                <p>Esta operação não poderá ser desfeita.</p>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default">Cancelar</button>
                <button type="button" data-dismiss="modal" class="btn btn-warning" (click)="apagar(codigo_documento)">Confirmar</button>
            </div>
        </div>
    </div>
</div>

<div id="md-footer-restaurar" tabindex="-1" role="dialog" style="" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-header">
              <button type="button" data-dismiss="modal" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
          </div>
          <div class="modal-body">
              <div class="text-center">
                  <div class="text-warning"><span class="modal-main-icon mdi mdi-alert-triangle"></span></div>
                  <h3>Tem certeza que deseja RESTAURAR este registro?</h3>
              </div>
          </div>
          <div class="modal-footer">
              <button type="button" data-dismiss="modal" class="btn btn-default">Cancelar</button>
              <button type="button" data-dismiss="modal" class="btn btn-warning" (click)="restaurar(codigo_documento)">Confirmar</button>
          </div>
      </div>
  </div>
</div>

<app-footer></app-footer>