import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from 'src/app/shared/shared.module';

import { BrowserModule } from '@angular/platform-browser';
import { AuthGuard } from 'src/app/core/authentication/auth.guard';
import { PatrimonioListarComponent } from './patrimonio-listar/patrimonio-listar.component';
import { PatrimonioEditarComponent } from './patrimonio-editar/patrimonio-editar.component';
import { NgxCurrencyModule } from 'ngx-currency';
import { PatrimonioExibirComponent } from './patrimonio-exibir/patrimonio-exibir.component';

const appRoutes: Routes = [
  { path: 'patrimonio-listar', component: PatrimonioListarComponent, canActivate: [AuthGuard] },
  { path: 'patrimonio-novo', component: PatrimonioEditarComponent, canActivate: [AuthGuard] },
  { path: 'patrimonio-editar/:id', component: PatrimonioEditarComponent, canActivate: [AuthGuard] },
  { path: 'patrimonio-exibir/:id', component: PatrimonioExibirComponent, canActivate: [AuthGuard] },
];

export const customCurrencyMaskConfig = {
  align: "right",
  allowNegative: false,
  allowZero: true,
  decimal: ",",
  precision: 2,
  prefix: "",
  suffix: "",
  thousands: ".",
  nullable: null
};

@NgModule({
  imports: [
    BrowserModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    RouterModule.forRoot(appRoutes)
  ],
  declarations: [PatrimonioListarComponent, PatrimonioEditarComponent, PatrimonioExibirComponent]
})
export class PatrimonioModule { }
