import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
@Injectable({
    providedIn: 'root',
})
export class AssociadoPagamentoTipoService {
    private _Url = environment.UrlPrincipal + '/api/associadopagamentotipo';
    private _urlRestaurar =
        environment.UrlApi + '/associado-pagamento-tipo-restaurar';

    constructor(private http: HttpClient) {}

    // list(devolucao) {
    //     return this.http
    //         .get(this._Url + '?devolucao=' + devolucao)
    //         .catch(this.handleError);
    // }

    list(inativo = false, devolucao = null) {
        let devolucao_url;
        if(devolucao) {
            devolucao_url = '&devolucao=true'
        }
        if (inativo == false) {
            return this.http
                .get(this._Url + '?inativos=false' + devolucao_url)
                .catch(this.handleError);
        } else {
            return this.http
                .get(this._Url + '?inativos=true' + devolucao_url)
                .catch(this.handleError);
        }
    }

    // list(inativo) {
    //     if (inativo == false) {
    //         return this.http
    //             .get(this._Url + '?inativos=false')
    //             .catch(this.handleError);
    //     } else {
    //         return this.http
    //             .get(this._Url + '?inativos=true')
    //             .catch(this.handleError);
    //     }
    // }

    find(codigo) {
        return this.http.get(this._Url + '/' + codigo).catch(this.handleError);
    }

    save(dados, codigo): Observable<any> {
        let http;
        if (codigo) {
            http = this.http.put(this._Url + '/' + codigo, dados);
        } else {
            http = this.http.post(this._Url, dados);
        }
        http.map((res) => {
            return res;
        }).catch(this.handleError);
        return http;
    }

    restore(codigo) {
        return this.http.put(this._urlRestaurar + '/' + codigo, null);
    }

    delete(codigo) {
        return this.http.delete(this._Url + '/' + codigo);
    }

    private handleError(error: Response) {
        return error.json();
    }
}
