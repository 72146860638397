import { Injectable } from "@angular/core";
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TipoLayoutService {

  private url: string = environment.UrlApi + "/tipo-layout";

  constructor(private http: HttpClient) {}

  list(para_preenchimento: boolean = null) {
      let url: string = this.url;
      if(para_preenchimento != null){
          url += para_preenchimento ? "/1" : "/0";
      }
    return this.http.get(url)
                    .catch(this.handleError);
  }

  find(id) {
    return this.http.get(this.url + "/" + id)
                    .catch(this.handleError);
  }

  save(dados, codigo_tipo_layout): Observable<any>{
    let http;
    if(codigo_tipo_layout){
        http = this.http.put(this.url + "/" + codigo_tipo_layout, dados);
    } else {
        http = this.http.post(this.url, dados);
    }
    http.map((res) => {
        return res;
    }).catch(this.handleError);
    return http;
  }

  delete(codigo_tipo_layout) {
    return this.http.delete(this.url + "/" + codigo_tipo_layout);
  }

  private handleError(error: Response) {
    return error.json();
  }

}
