<app-header></app-header>

<div class="tab-container">
    <ul class="nav nav-tabs">
        <li class="active"><a href="#ativo" data-toggle="tab">Aguardando Aprovação</a></li>
        <li><a href="#rejeitado" data-toggle="tab">Rejeitados</a></li>
    </ul>
    <div class="tab-content">
        <div id="ativo" class="tab-pane active cont">
            <h4><b>Cadastros aguardando aprovação</b></h4>
            <div class="row">
                <div class="col-sm-12">
                    <div class="panel panel-default panel-table">
                        <div class="panel-body">
                            <div class="dataTables_length" id="table3_length">
                                <table id="table2" class="table table-striped table-hover table-fw-widget">
                                    <thead>
                                        <tr>
                                            <th>Dados Básicos</th>
                                            <th>Lotação</th>
                                            <th>Tipo</th>
                                            <th>Solicitado em:</th>
                                            <th class="text-center">Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="aprovacao_pendente && aprovacao_pendente.length > 0">
                                        <tr class="odd gradeX" *ngFor="let dado of aprovacao_pendente">
                                            <td class="col-md-6"><b>Nome:</b> {{dado.pessoa.nome}} <br /> <b>Siape:</b> {{dado.siape}}  <br /> <b>Matrícula PF:</b> {{dado.matricula_dpf}} <br /> <b>CPF:</b> {{dado.pessoa_fisica.cpf | cpf}} <br /> <b>E-mail:</b> {{dado.pessoa.email}}</td>
                                            <td class="col-md-2" *ngIf="dado.tipo != 'PE'"><span *ngIf="dado.lotacao">{{dado.lotacao.nome}}</span><span *ngIf="!dado.lotacao">Não informado</span></td>
                                            <td class="col-md-2" *ngIf="dado.tipo == 'PE'"><span>Não se aplica</span></td>
                                            <td class="col-md-1"><span *ngIf="dado.tipo == 'AT'">Ativo</span><span *ngIf="dado.tipo == 'AP'">Aposentado</span><span *ngIf="dado.tipo == 'PE'">Pensionista</span></td>
                                            <td class="col-md-2"><span>{{dado.created_at | date: 'dd/MM/yyyy'}}</span></td>
                                            <td class="text-center" style="font-size: 22px;">
                                                <a data-toggle="modal" data-target="#md-visualizar-cadastro" (click)="visualizar(dado.pessoa.codigo_pessoa)"><span class="icon mdi mdi-eye"></span></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="!aprovacao_pendente">
                                        <tr class="carregando">
                                            <td class="carregando" colspan="5">
                                                <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="aprovacao_pendente && aprovacao_pendente.length == 0">
                                        <tr>
                                            <td class="col-md-12" colspan="10">
                                                Nenhum registro encontrado.
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="carregando">
                                        <tr class="carregando">
                                            <td class="carregando" colspan="10">
                                                <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Em processo de aprovação...
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="rejeitado" class="tab-pane cont">
            <h4><b>Cadastros rejeitados</b></h4>
            <div class="row">
                <div class="col-sm-12">
                    <div class="panel panel-default panel-table">
                        <div class="panel-body">
                            <div class="dataTables_length" id="table3_length">
                                <table id="table2" class="table table-striped table-hover table-fw-widget">
                                    <thead>
                                        <tr>
                                            <th>Dados Básicos</th>
                                            <th>Lotação</th>
                                            <th>Tipo</th>
                                            <th>Justificativa</th>
                                            <th class="text-center">Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="registro_rejeitado && registro_rejeitado.length > 0">
                                        <tr class="odd gradeX" *ngFor="let dado of registro_rejeitado">
                                            <td class="col-md-4"><b>Nome:</b> {{dado.nome}} <br /> <b>Siape:</b> {{dado.siape}}  <br /> <b>Matrícula PF:</b> {{dado.matricula_dpf}} <br /> <b>CPF:</b> {{dado.cpf | cpf}} <br /> <b>E-mail:</b> {{dado.email}}</td>
                                            <td class="col-md-2"><span *ngIf="dado.nome_lotacao">{{dado.nome_lotacao}}</span><span *ngIf="!dado.nome_lotacao">Não informado</span></td>
                                            <td class="col-md-2"><span *ngIf="dado.tipo == 'AT'">Ativo</span><span *ngIf="dado.tipo == 'AP'">Aposentado</span><span *ngIf="dado.tipo == 'PE'">Pensionista</span></td>
                                            <td class="col-md-3">{{dado.justificativa_rejeicao}}</td>
                                            <td class="text-center" style="font-size: 22px;">
                                                <a data-toggle="modal" data-target="#md-visualizar-cadastro" (click)="visualizar(dado.codigo_associado_sincronia)"><span class="icon mdi mdi-eye"></span></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="!registro_rejeitado">
                                        <tr class="carregando">
                                            <td class="carregando" colspan="5">
                                                <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="registro_rejeitado && registro_rejeitado.length == 0">
                                        <tr>
                                            <td class="col-md-12" colspan="10">
                                                Nenhum registro encontrado.
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="carregando">
                                        <tr class="carregando">
                                            <td class="carregando" colspan="10">
                                                <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Em processo de aprovação...
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="md-visualizar-cadastro" tabindex="-1" role="dialog" style="" class="modal fade">
    <div class="modal-dialog full-width">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
            </div>
            <div class="modal-body">
                
                <div class="row" *ngIf="associado_sincronia">

                    <div class="col-md-6">
                        <div class="user-info-list panel panel-default">
                        <div class="panel-heading panel-heading-divider"><b>Dados Pessoais</b></div>
                        <div class="panel-body">
                            <div class="row">
                                <label class="col-sm-4 label-exibicao">Tipo de Associado:</label>
                                <div class="col-sm-8">
                                    <span *ngIf="associado_sincronia.tipo == 'AT'" class="label label-primary">Ativo</span>
                                    <span *ngIf="associado_sincronia.tipo == 'AP'" class="label label-warning">Aposentado</span>
                                    <span *ngIf="associado_sincronia.tipo == 'PE'" class="label label-danger">Pensionista</span>
                                </div>
                            </div>
                            <br />
                            <div class="row">
                                <label class="col-sm-4 label-exibicao">SIAPE:</label>
                                <div class="col-sm-8" *ngIf="associado_sincronia.siape">
                                    {{associado_sincronia.siape}}
                                </div>
                            </div>
                            <br />
                            <span *ngIf="associado_sincronia.tipo != 'PE'">
                                <div class="row">
                                    <label class="col-sm-4 label-exibicao">Matrícula PF:</label>
                                    <div class="col-sm-8" *ngIf="associado_sincronia.matricula_dpf">
                                        {{associado_sincronia.matricula_dpf}}
                                    </div>
                                </div>
                                <br />
                            </span>
                            <span *ngIf="associado_sincronia.tipo != 'PE'">
                                <div class="row" *ngIf="associado_sincronia.tipo != 'PE'">
                                    <label class="col-sm-4 label-exibicao">Data da Posse PF:</label>
                                    <div class="col-sm-8" *ngIf="associado_sincronia.data_posse_dpf">
                                        {{associado_sincronia.data_posse_dpf| date: 'dd/MM/yyyy'}}
                                    </div>
                                </div>
                                <br />
                            </span>
                            <span *ngIf="associado_sincronia.tipo != 'PE'">
                                <div class="row" *ngIf="associado_sincronia.tipo != 'PE'">
                                    <label class="col-sm-4 label-exibicao">Classe:</label>
                                    <div class="col-sm-8" *ngIf="associado_sincronia.codigo_classe">
                                        {{associado_sincronia.classe.nome}}
                                    </div>
                                </div>
                                <br />
                            </span>
                            <div class="row">
                                <label class="col-sm-4 label-exibicao">Nome:</label>
                                <div class="col-sm-8" *ngIf="associado_sincronia.pessoa.nome">
                                    {{associado_sincronia.pessoa.nome}}
                                </div>
                            </div>
                            <br />
                            <span *ngIf="associado_sincronia.tipo != 'PE'">
                                <div class="row">
                                    <label class="col-sm-4 label-exibicao">Nome de Guerra:</label>
                                    <div class="col-sm-8" *ngIf="associado_sincronia.nome_guerra">
                                        {{associado_sincronia.nome_guerra}}
                                    </div>
                                </div>
                                <br />
                            </span>
                            <div class="row">
                                <label class="col-sm-4 label-exibicao">Nome da Mãe:</label>
                                <div class="col-sm-8" *ngIf="associado_sincronia.pessoa_fisica.nome_mae">
                                    {{associado_sincronia.pessoa_fisica.nome_mae}}
                                </div>
                            </div>
                            <br />
                            <div class="row">
                                <label class="col-sm-4 label-exibicao">Nome do Pai:</label>
                                <div class="col-sm-8" *ngIf="associado_sincronia.pessoa_fisica.nome_pai">
                                    {{associado_sincronia.pessoa_fisica.nome_pai}}
                                </div>
                            </div>
                            <br />
                            <div class="row" *ngIf="associado_sincronia.tipo_sanguineo">
                                <label class="col-sm-4 label-exibicao">Tipo Sanguineo:</label>
                                <div class="col-sm-8">
                                    {{associado_sincronia.tipo_sanguineo.nome}}
                                </div>
                            </div>
                            <br />
                            <div class="row">
                                <label class="col-sm-4 label-exibicao">Data de Nascimento:</label>
                                <div class="col-sm-8" *ngIf="associado_sincronia.pessoa_fisica.data_nascimento">
                                    {{associado_sincronia.pessoa_fisica.data_nascimento | date: 'dd/MM/yyyy'}}
                                </div>
                            </div>
                            <br />
                            <div class="row">
                                <label class="col-sm-4 label-exibicao">Gênero:</label>
                                <div class="col-sm-8" *ngIf="associado_sincronia.pessoa_fisica.sexo">
                                    <span *ngIf="associado_sincronia.pessoa_fisica.sexo == 'M'">Masculino</span>
                                    <span *ngIf="associado_sincronia.pessoa_fisica.sexo == 'F'">Feminino</span>
                                </div>
                            </div>
                            <br />
                            <div class="row">
                                <label class="col-sm-4 label-exibicao">CPF:</label>
                                <div class="col-sm-8" *ngIf="associado_sincronia.pessoa_fisica.cpf">
                                    {{associado_sincronia.pessoa_fisica.cpf | cpf}}
                                </div>
                            </div>
                            <br />
                            <div class="row" *ngIf="associado_sincronia.tipo == 'PE'">
                                <label class="col-sm-4 label-exibicao">CPF do Cônjuge:</label>
                                <div class="col-sm-8" *ngIf="associado_sincronia.cpf_conjuge_falecido">
                                    {{associado_sincronia.cpf_conjuge_falecido | cpf}}
                                </div>
                            </div>
                            <br />
                            <div class="row">
                                <label class="col-sm-4 label-exibicao">RG:</label>
                                <div class="col-sm-8" *ngIf="associado_sincronia.pessoa_fisica.rg">
                                    {{associado_sincronia.pessoa_fisica.rg}}
                                </div>
                            </div>
                            <br />
                            <div class="row" *ngIf="associado_sincronia.tipo != 'PE'">
                                <label class="col-sm-4 label-exibicao">PIS/PASEP:</label>
                                <div class="col-sm-8" *ngIf="associado_sincronia.pessoa_fisica.pis_pasep">
                                    {{associado_sincronia.pessoa_fisica.pis_pasep}}
                                </div>
                            </div>
                        </div>
                    </div>
                
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="user-info-list panel panel-default">
                            <div class="panel-heading panel-heading-divider"><b>Dados de Contato</b></div>
                            <div class="panel-body">
                                <div class="row">
                                    <label class="col-sm-4 label-exibicao">Telefone Fixo:</label>
                                    <div class="col-sm-8" *ngIf="associado_sincronia.pessoa.telefone_fixo">
                                        {{associado_sincronia.pessoa.telefone_fixo | telefone}}
                                    </div>
                                </div>
                                <br />
                                <div class="row">
                                    <label class="col-sm-4 label-exibicao">Telefone Comercial:</label>
                                    <div class="col-sm-8" *ngIf="associado_sincronia.pessoa.telefone_comercial">
                                        {{associado_sincronia.pessoa.telefone_comercial | telefone}}
                                    </div>
                                </div>
                                <br />
                                <div class="row">
                                    <label class="col-sm-4 label-exibicao">Telefone para Recados:</label>
                                    <div class="col-sm-8" *ngIf="associado_sincronia.pessoa.telefone_recado">
                                        {{associado_sincronia.pessoa.telefone_recado | telefone}}
                                    </div>
                                </div>
                                <br />
                                <div class="row">
                                    <label class="col-sm-4 label-exibicao">Nome para Recados:</label>
                                    <div class="col-sm-8" *ngIf="associado_sincronia.pessoa.nome_recado">
                                        {{associado_sincronia.pessoa.nome_recado}}
                                    </div>
                                </div>
                                <br />
                                <div class="row">
                                    <label class="col-sm-4 label-exibicao">Telefone Celular 1:</label>
                                    <div class="col-sm-8" *ngIf="associado_sincronia.pessoa.celular_1">
                                        {{associado_sincronia.pessoa.celular_1 | telefone}}
                                    </div>
                                </div>
                                <br />
                                <div class="row">
                                    <label class="col-sm-4 label-exibicao">Telefone Celular 2:</label>
                                    <div class="col-sm-8" *ngIf="associado_sincronia.pessoa.celular_2">
                                        {{associado_sincronia.pessoa.celular_2 | telefone}}
                                    </div>
                                </div>
                                <br />
                                <div class="row">
                                    <label class="col-sm-4 label-exibicao">E-mail Pessoal:</label>
                                    <div class="col-sm-8" *ngIf="associado_sincronia.pessoa.email">
                                        {{associado_sincronia.pessoa.email}}
                                    </div>
                                </div>
                                <br />
                                <div class="row">
                                    <label class="col-sm-4 label-exibicao">E-mail Secundário:</label>
                                    <div class="col-sm-8" *ngIf="associado_sincronia.pessoa.email_secundario">
                                        {{associado_sincronia.pessoa.email_secundario}}
                                    </div>
                                </div>
                                <br />
                                <div class="row" *ngIf="associado_sincronia.tipo != 'PE'">
                                    <label class="col-sm-4 label-exibicao">E-mail Institucional:</label>
                                    <div class="col-sm-8" *ngIf="associado_sincronia.pessoa.email_institucional">
                                        {{associado_sincronia.pessoa.email_institucional}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="user-info-list panel panel-default">
                            <div class="panel-heading panel-heading-divider"><b>Endereço Residencial</b></div>
                            <div class="panel-body">
                                <div class="row">
                                    <div class="form-group">
                                        <label class="col-sm-4 label-exibicao">CEP</label>
                                        <div class="col-sm-8" *ngIf="associado_sincronia.pessoa.cep" class="quebra-linha">
                                            {{associado_sincronia.pessoa.cep}}
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div class="row">
                                    <div class="form-group">
                                        <label class="col-sm-4 label-exibicao">Endereço</label>
                                        <div class="col-sm-8" *ngIf="associado_sincronia.pessoa.endereco" class="quebra-linha">
                                            {{associado_sincronia.pessoa.endereco}}
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div class="row">
                                    <div class="form-group">
                                        <label class="col-sm-4 label-exibicao">Número</label>
                                        <div class="col-sm-8" *ngIf="associado_sincronia.pessoa.endereco_numero" class="quebra-linha">
                                            {{associado_sincronia.pessoa.endereco_numero}}
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div class="row">
                                    <div class="form-group">
                                        <label class="col-sm-4 label-exibicao">Complemento</label>
                                        <div class="col-sm-8" *ngIf="associado_sincronia.pessoa.complemento" class="quebra-linha">
                                            {{associado_sincronia.pessoa.complemento}}
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div class="row">
                                    <div class="form-group">
                                        <label class="col-sm-4 label-exibicao">Cidade / UF</label>
                                        <div class="col-sm-8" *ngIf="associado_sincronia.pessoa.cidade" class="quebra-linha">
                                            {{associado_sincronia.pessoa.cidade}}
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div class="row">
                                    <div class="form-group">
                                        <label class="col-sm-4 label-exibicao">Bairro</label>
                                        <div class="col-sm-8" *ngIf="associado_sincronia.pessoa.bairro" class="quebra-linha">
                                            {{associado_sincronia.pessoa.bairro}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="user-info-list panel panel-default">
                            <div class="panel-heading panel-heading-divider"><b>Endereço de Correspondência</b></div>
                            <div class="panel-body">
                                <span *ngIf="!associado_sincronia.pessoa_endereco">O mesmo informado no residêncial</span>
                                <span *ngIf="associado_sincronia.pessoa_endereco">
                                    <div class="row">
                                        <div class="form-group">
                                            <label class="col-sm-4 label-exibicao">CEP</label>
                                            <div class="col-sm-8" *ngIf="associado_sincronia.pessoa_endereco.cep_correspondencia" class="quebra-linha">
                                                {{associado_sincronia.pessoa_endereco.cep_correspondencia}}
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div class="row">
                                        <div class="form-group">
                                            <label class="col-sm-4 label-exibicao">Endereço</label>
                                            <div class="col-sm-8" *ngIf="associado_sincronia.pessoa_endereco.endereco_correspondencia" class="quebra-linha">
                                                {{associado_sincronia.pessoa_endereco.endereco_correspondencia}}
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div class="row">
                                        <div class="form-group">
                                            <label class="col-sm-4 label-exibicao">Número</label>
                                            <div class="col-sm-8" *ngIf="associado_sincronia.pessoa_endereco.numero_correspondencia" class="quebra-linha">
                                                {{associado_sincronia.pessoa_endereco.numero_correspondencia}}
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div class="row">
                                        <div class="form-group">
                                            <label class="col-sm-4 label-exibicao">Complemento</label>
                                            <div class="col-sm-8" *ngIf="associado_sincronia.pessoa_endereco.complemento_correspondencia" class="quebra-linha">
                                                {{associado_sincronia.pessoa_endereco.complemento_correspondencia}}
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div class="row">
                                        <div class="form-group">
                                            <label class="col-sm-4 label-exibicao">Cidade / UF</label>
                                            <div class="col-sm-8" *ngIf="associado_sincronia.pessoa_endereco.cidade_correspondencia" class="quebra-linha">
                                                {{associado_sincronia.pessoa_endereco.cidade_correspondencia}} / {{associado_sincronia.pessoa_endereco.sigla_uf_correspondencia}}
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div class="row">
                                        <div class="form-group">
                                            <label class="col-sm-4 label-exibicao">Bairro</label>
                                            <div class="col-sm-8" *ngIf="associado_sincronia.pessoa_endereco.bairro_correspondencia" class="quebra-linha">
                                                {{associado_sincronia.pessoa_endereco.bairro_correspondencia}}
                                            </div>
                                        </div>
                                    </div>
                                </span>
                    
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <!--<div class="user-info-list panel panel-default">
                            <div class="panel-heading panel-heading-divider"><b>Dados Financeiros</b></div>
                            <div class="panel-body">
                                <span *ngIf="!associado_sincronia.pessoa_banco">Nenhum registro informado</span>
                                <span *ngIf="associado_sincronia.pessoa_banco">
                                    <div class="form-group">
                                        <label class="col-sm-4 label-exibicao">Banco</label>
                                        <div class="col-sm-8" *ngIf="associado_sincronia.pessoa_banco.nome">
                                            {{associado_sincronia.pessoa_banco.nome}}
                                        </div>
                                    </div>
                                    <br />
                                    <div class="form-group">
                                        <label class="col-sm-4 label-exibicao">Agência</label>
                                        <div class="col-sm-8" *ngIf="associado_sincronia.pessoa_banco.agencia">
                                            {{associado_sincronia.pessoa_banco.agencia}}
                                        </div>
                                    </div>
                                    <br />
                                    <div class="form-group">
                                        <label class="col-sm-4 label-exibicao">Conta - DV</label>
                                        <div class="col-sm-8" *ngIf="associado_sincronia.pessoa_banco.conta && associado_sincronia.pessoa_banco.agencia_dv">
                                            {{associado_sincronia.pessoa_banco.conta}} / {{associado_sincronia.pessoa_banco.agencia_dv}}
                                        </div>
                                    </div>
                                    <br />
                                    <div class="form-group">
                                        <label class="col-sm-4 label-exibicao">Operação</label>
                                        <div class="col-sm-8" *ngIf="associado_sincronia.pessoa_banco.operacao">
                                            {{associado_sincronia.pessoa_banco.operacao}}
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <br />-->
                        <div class="user-info-list panel panel-default">
                            <div class="panel-heading panel-heading-divider"><b>Dependentes</b></div>
                            <div class="panel-body">
                                <span *ngIf="!associado_sincronia.associado_dependente">Nenhum dado informado</span>
                                <div class="form-group" *ngIf="associado_sincronia.associado_dependente">
                                    <div class="col-sm-12">
                                        <table id="table2" class="table table-striped table-hover table-fw-widget">
                                            <thead>
                                                <tr>
                                                    <th>Nome</th>
                                                    <th>Grau Parentesco</th>
                                                    <th>Data Nascimento</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngIf="associado_sincronia.associado_dependente && associado_sincronia.associado_dependente.length > 0">
                                                <tr class="odd gradeX" *ngFor="let dado of associado_sincronia.associado_dependente">
                                                    <td class="col-md-3"><span *ngIf="!dado.nome">Não informado</span><span *ngIf="dado.nome">{{dado.nome}}</span></td>
                                                    <td class="col-md-2">{{dado.grau_parentesco}}</td>
                                                    <td class="col-md-3">{{dado.data_nascimento | date: 'dd/MM/yyyy'}}</td>
                                                </tr>
                                            </tbody>
                                            <tbody *ngIf="!associado_sincronia.associado_dependente">
                                                <tr class="carregando">
                                                    <td class="carregando" colspan="5">
                                                        <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody *ngIf="associado_sincronia.associado_dependente && associado_sincronia.associado_dependente.length == 0">
                                                <tr>
                                                    <td class="col-md-12" colspan="10">
                                                        Nenhum registro encontrado.
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="user-info-list panel panel-default" *ngIf="associado_sincronia.tipo != 'PE'">
                            <div class="panel-heading panel-heading-divider"><b>Dados Profissionais</b></div>
                            <div class="panel-body">
                    
                                <div class="row">
                                    <label class="col-sm-4 label-exibicao">Lotação</label>
                                    <div class="col-sm-8" *ngIf="associado_sincronia.codigo_lotacao">
                                        {{associado_sincronia.lotacao.nome}}
                                    </div>
                                </div>
                                <br />
                                <div class="row">
                                    <label class="col-sm-4 label-exibicao">Data de Ingresso no Serviço Público Federal</label>
                                    <div class="col-sm-8" *ngIf="associado_sincronia.data_ingresso_servico_publico">
                                        {{associado_sincronia.data_ingresso_servico_publico | date: 'dd/MM/yyyy'}}
                                    </div>
                                </div>
                                <br />
                                <br />
                                <div class="row">
                                    <label class="col-sm-4 label-exibicao">Aderiu ao Funpresp</label>
                                    <div class="col-sm-8" *ngIf="associado_sincronia.aderiu_funpresp">
                                        <span *ngIf="associado_sincronia.aderiu_funpresp == 'S'">Sim</span>
                                        <span *ngIf="associado_sincronia.aderiu_funpresp == 'N'">Não</span>
                                    </div>
                                </div>
                                <br />
                                <div class="row">
                                    <label class="col-sm-4 label-exibicao">Egresso</label>
                                    <div class="col-sm-8">
                                        <p *ngIf="associado_sincronia.egresso_policia_militar == 'S'">Polícia Militar</p>
                                        <p *ngIf="associado_sincronia.egresso_bombeiro == 'S'">Bombeiro</p>
                                        <p *ngIf="associado_sincronia.egresso_policia_civil == 'S'">Polícia Civil</p>
                                        <p *ngIf="associado_sincronia.egresso_forcas_armadas == 'S'">Forças Armadas</p>
                                    </div>
                                    <div class="col-sm-8" *ngIf="(!associado_sincronia.egresso_policia_militar || associado_sincronia.egresso_policia_militar == 'N') && (!associado_sincronia.egresso_bombeiro || associado_sincronia.egresso_bombeiro == 'N') && (!associado_sincronia.egresso_policia_civil || associado_sincronia.egresso_policia_civil == 'N') && (!associado_sincronia.egresso_forcas_armadas || associado_sincronia.egresso_forcas_armadas == 'N')">
                                        Não
                                    </div>
                                </div>
                                <br />
                                <div class="row">
                                    <label class="col-sm-4 label-exibicao">Titulação Acadêmica</label>
                                    <div class="col-sm-8" *ngIf="associado_sincronia.codigo_titulacao_academica">
                                        {{associado_sincronia.titulacao_academica.nome}}
                                    </div>
                                </div>
                                <br />
                                <div class="row">
                                    <label class="col-sm-4 label-exibicao">Formação Acadêmica</label>
                                    <div class="col-sm-8">
                                        <table id="table2" class="table table-fw-widget" *ngIf="associado_sincronia.formacao_academica && associado_sincronia.formacao_academica.length > 0">
                                            <tbody>
                                                <tr class="odd gradeX" *ngFor="let dado of associado_sincronia.formacao_academica">
                                                    <td class="col-md-12">{{dado.nome}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <br />
                                <div class="row">
                                    <label class="col-sm-4 label-exibicao">Área de Atuação</label>
                                    <div class="col-sm-8">
                                        <table id="table2" class="table table-fw-widget" *ngIf="associado_sincronia.area_atuacao && associado_sincronia.area_atuacao.length > 0">
                                            <tbody>
                                                <tr class="odd gradeX" *ngFor="let dado of associado_sincronia.area_atuacao">
                                                    <td class="col-md-12">{{dado.nome}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                    
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6" *ngIf="associado_sincronia.tipo == 'AP'">
                        <div class="user-info-list panel panel-default">
                            <div class="panel-heading panel-heading-divider"><b>Perícia Particular</b></div>
                            <div class="panel-body">
                                <span *ngIf="!associado_sincronia.pericia_particular_area_atuacao">Nenhum dado informado</span>
                                <span *ngIf="associado_sincronia.pericia_particular_area_atuacao">
                                    <div class="form-group">
                                        <label class="col-sm-6 label-exibicao">Exerce Perícia Particular</label>
                                        <div class="col-sm-6">
                                            <p *ngIf="associado_sincronia.exerce_pericia_particular == 1">Sim</p>
                                            <p *ngIf="associado_sincronia.exerce_pericia_particular != 1">Não</p>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-sm-6 label-exibicao">Autoriza Disponibilizar Contato</label>
                                        <div class="col-sm-6">
                                            <p *ngIf="associado_sincronia.autoriza_disponibilizar_contato == 1">Sim</p>
                                            <p *ngIf="associado_sincronia.autoriza_disponibilizar_contato != 1">Não</p>
                                        </div>
                                    </div>
    
                                    <div class="form-group">
                                        <label class="col-sm-6 label-exibicao">Perícia Particular Área de Atuação</label>
                                        <div class="col-sm-6">
                                            <table id="table2" class="table table-fw-widget">
                                                <tbody *ngIf="associado_sincronia.pericia_particular_area_atuacao && associado_sincronia.pericia_particular_area_atuacao.length > 0">
                                                    <tr class="odd gradeX" *ngFor="let dado of associado_sincronia.pericia_particular_area_atuacao">
                                                        <td class="col-md-12">{{dado.nome}}</td>
                                                    </tr>
                                                </tbody>
                                                <tbody *ngIf="!associado_sincronia.pericia_particular_area_atuacao">
                                                    <tr class="carregando">
                                                        <td class="carregando" colspan="5">
                                                            <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tbody *ngIf="associado_sincronia.pericia_particular_area_atuacao && associado_sincronia.pericia_particular_area_atuacao.length == 0">
                                                    <tr>
                                                        <td class="col-md-12" colspan="10">
                                                            Nenhum registro encontrado.
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-md-4 text-left">
                        <button type="button" data-dismiss="modal" class="btn btn-primary">Fechar</button>
                    </div>
                    <div class="col-md-8 text-right">
                        <button type="button" data-toggle="modal" data-target="#md-rejeitar-cadastro" *ngIf="associado_sincronia && !associado_sincronia.deleted_at" (click)="definirCodigo(associado_sincronia.codigo_associado_sincronia)" class="btn btn-danger">Rejeitar</button>
                        <button type="button" data-toggle="modal" data-target="#md-aprovar-cadastro" (click)="definirCodigo(associado_sincronia.codigo_associado_sincronia)" class="btn btn-success">Aprovar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> 

<div id="md-aprovar-cadastro" tabindex="-1" role="dialog" style="" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <div class="text-success"><span class="modal-main-icon mdi mdi-alert-triangle"></span></div>
                <h3>Tem certeza que deseja APROVAR este cadastro?</h3>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default">Cancelar</button>
                <button type="button" data-dismiss="modal" class="btn btn-success" (click)="aprovar(codigo_associado_sincronia)">Confirmar</button>
            </div>
        </div>
    </div>
</div>  

<!--Modal Footer-->
<div id="md-rejeitar-cadastro" tabindex="-1" role="dialog" style="" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <div class="text-danger"><span class="modal-main-icon mdi mdi-alert-triangle"></span></div>
                <h3>Tem certeza que deseja DESAPROVAR este registro?</h3>
                <p>Esta operação não poderá ser desfeita.</p>
                </div>
                <form #justificativaForm="ngForm" [formGroup]="form" novalidate="" class="form-horizontal group-border-dashed">
                    <div class="form-group xs-mt-10">
                        <label for="inputUsuario" class="col-sm-12 control-label" style="text-align: left;">
                            Informe uma justificativa para rejeição: <span style="color: red;">*</span>
                        </label>
                        <div class="col-sm-12">
                            <textarea rows="8" placeholder="Justificativa" class="form-control" formControlName="justificativa_rejeicao" autocomplete="false" required></textarea>
                            <app-error-msg [control]="form.get('justificativa_rejeicao')" label="Justificativa"></app-error-msg>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default">Cancelar</button>
                <button type="button" data-dismiss="modal" class="btn btn-danger" (click)="apagar(codigo_associado_sincronia)" [disabled]="!justificativaForm.valid">Confirmar</button>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>