import {TipoLayout} from "./tipo-layout";
import {CampoRodapeLayout} from "./campo-rodape-layout";

export class RodapeLayout {
    codigo_rodape_layout: number;
    codigo_tipo_layout: number;
    titulo: string;
    created_at: Date;
    deleted_at: Date;
    pode_alterar: boolean = false;
    tipo_layout: TipoLayout = new TipoLayout();
    campos_rodape_layout: Array<CampoRodapeLayout> = [];
}
