import { Component, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { NotifierService } from 'angular-notifier';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Associado } from 'src/app/shared/models/associado';
import { AssociadoService } from 'src/app/core/services/associado.service';
import { ClasseService } from 'src/app/core/services/classe.service';
import { Classe } from 'src/app/shared/models/classe';
import { FormValidation } from 'src/app/shared/validation/form-validation';
import { TipoSanguineoService } from 'src/app/core/services/tipo-sanguineo.service';
import { TipoSanguineo } from 'src/app/shared/models/tipo-sanguineo';

@Component({
  selector: 'app-associado-novo',
  templateUrl: './associado-novo.component.html',
  styleUrls: ['./associado-novo.component.css']
})
export class AssociadoNovoComponent implements OnInit {

  @ViewChild('associadoForm') formValues;

  form: FormGroup;
  breadCrumb;
  me;
  codigo_pessoa: number;
  classeAssociado: Classe;
  tipoSanguineo;
  tipo_associado: string;
  pessoa_fisica: boolean;
  submited: boolean;
  carregando_salvar: boolean;
  aprovado: string;

  constructor(private appComponent: AppComponent,
              private formBuilder: FormBuilder,
              private routerParam: ActivatedRoute,
              private router: Router,
              private associadoService: AssociadoService,
              private classeService: ClasseService,
              private tipoSanguineoService: TipoSanguineoService,
              private notifierService: NotifierService) { 
              this.codigo_pessoa = this.routerParam.snapshot.params.id;
  }

  ngOnInit() {

    this.breadCrumb = [ 
      {nome: "Home", caminho: "/dashboard"},
      {nome: "Associados", caminho: "/associado-listar"},
      {nome: "Cadastro", caminho: "/dados-pessoais"},
    ];
    this.appComponent.setTitulo('Cadastro do Associado');
    this.appComponent.setBreadcrumb(this.breadCrumb);

    this.form = this.formBuilder.group({
      nome: [null, Validators.required],
      email: [null, [Validators.required, FormValidation.EmailDpfValidator, FormValidation.EmailPermitidoValidator]]
    });

    this.obterClasse();
    this.obterTipoSanguineo();

  }

  definirTipoAssociado(tipo_associado) {
    this.tipo_associado = tipo_associado;
    this.form.patchValue(tipo_associado);
  }

  obterTipoSanguineo() {
    this.tipoSanguineoService.list()
    .subscribe((data: TipoSanguineo) => this.tipoSanguineo = data,
                error => console.log(error));             
  }

  obterClasse() {
    this.classeService.list(false)
    .subscribe((data: Classe) => this.classeAssociado = data,
                error => console.log(error));
                
  }

  // convenience getter for easy access to form fields
  get f() {    
    return this.form.controls;
  }

  aprovar(codigo_pessoa):void {
    let associado = {'aprovado': 'S', 'codigo_pessoa': this.codigo_pessoa, 'criar_plano': true};
    this.associadoService.save(associado, codigo_pessoa).subscribe((data) => {
      this.notifierService.notify( 'success', 'Cadastro APROVADO com sucesso.' );
      this.router.navigate(['associado-listar']);
    }, (error) => {
      this.notifierService.notify( 'danger', 'Não foi possível APROVAR o cadastro, ocorreu um erro. ' + error );
    });
  }

  onSubmit(): void {
    this.submited = true;
    this.carregando_salvar = true;
    let associado = this.form.value as Associado;    
    if (this.form.valid) {
      associado.codigo_pessoa = this.codigo_pessoa;
      this.associadoService.preCadastro(associado).subscribe((data) => {
        this.notifierService.notify( 'success', 'Dados pessoais ATUALIZADOS com sucesso.' );
        this.carregando_salvar = false;
        this.router.navigate(['dados-pessoais/' + data.codigo_pessoa]);
      }, (error) => {
        this.carregando_salvar = false;
        this.notifierService.notify( 'error', 'Algo deu errado: ' + error );
      });
    } else {
      console.log(this.form.controls);
      this.carregando_salvar = false;
      this.notifierService.notify( 'error', 'Existem erros no preenchimento do formulário.');
    }
  }

}
