import { Component, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute, Router } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { filterResponse, uploadProgress } from 'src/app/shared/helpers/rxjs-operators';
import { environment } from 'src/environments/environment';

import { Material } from '../../../shared/models/material';
import { MaterialService } from '../../../core/services/material.service';
import { MaterialLocalizacaoService } from 'src/app/core/services/material-localizacao.service';
import { MaterialLocalizacao } from 'src/app/shared/models/material-localizacao';
import { LocalizacaoService } from 'src/app/core/services/localizacao.service';
import { Localizacao } from 'src/app/shared/models/localizacao';
import { MaterialAnexo } from 'src/app/shared/models/material-anexo';
import { MaterialAnexoService } from 'src/app/core/services/material-anexo.service';
import { UploadFileService } from 'src/app/core/services/upload.service';

@Component({
  selector: 'app-patrimonio-exibir',
  templateUrl: './patrimonio-exibir.component.html',
  styleUrls: ['./patrimonio-exibir.component.css']
})
export class PatrimonioExibirComponent implements OnInit {

  @ViewChild('materialForm') formValues;
  @ViewChild('materialBaixaForm') formBaixaValues;

  files: Set<File>;
  progress = 0;
  carregando_anexo: boolean;
  anexos: any;

  form: FormGroup;
  formBaixa: FormGroup;
  codigo_material: any;
  material: Material;
  breadCrumb;
  submited: boolean;
  carregando: boolean;
  assunto: string;
  codigo_material_tipo: number;
  codigo_material_situacao: number;
  codigo_pessoa: any;
  codigo_localizacao: any;
  materialLocalizacao: MaterialLocalizacao;
  localizacoes: Localizacao;
  codigo_material_anexo: any;

  constructor(private appComponent: AppComponent,
              private routerParam: ActivatedRoute,
              private router: Router,
              private formBuilder: FormBuilder,
              private uploadFileService: UploadFileService,
              private localizacaoService: LocalizacaoService,
              private materialService: MaterialService,
              private materialAnexoService: MaterialAnexoService,
              private materialLocalizacaoService: MaterialLocalizacaoService,
              private notifierService: NotifierService) {
              this.codigo_material = this.routerParam.snapshot.params.id;
  }

  ngOnInit() {
    this.breadCrumb = [
      {nome: 'Patrimônio', caminho: '/patrimonio-listar'}
    ];
    this.appComponent.setTitulo('Detalhe do Patrimônio');
    this.appComponent.setBreadcrumb(this.breadCrumb);

    this.form = this.formBuilder.group({
      descricao: [null, Validators.required],
    });

    this.codigo_pessoa = parseInt(localStorage.getItem('codigo_pessoa'));

    this.formBaixa = this.formBuilder.group({
      observacao_baixa: [null, Validators.required],
    });

    this.exibir();
    this.exibirHistorico();
    this.listarArquivos();

  }

  definirCodigo(codigo_material) {
    this.codigo_material = codigo_material;
  }

  definirCodigoArquivoAnexo(codigo_material_anexo) {
    this.codigo_material_anexo = codigo_material_anexo;
  }

  definirCodigoLocalizacao(codigo_localizacao) {
    this.codigo_localizacao = codigo_localizacao;
  }

  obterLocalizacao(codigo_localizacao) {
    this.localizacaoService.list(false, codigo_localizacao)
    .subscribe((data: Localizacao) => {
      this.localizacoes = data;
    }, error => console.log(error));
  }

  exibir(): void {
    this.materialService.find(this.codigo_material, false).subscribe((data: Material) => {
      this.material = data;
      this.obterLocalizacao(data.codigo_localizacao);
    }, error => console.log(error));
  }

  exibirHistorico(): void {
    this.materialLocalizacaoService.find(this.codigo_material, true).subscribe((data: MaterialLocalizacao) => {
      this.materialLocalizacao = data;
      console.log(data);
      
    }, error => console.log(error));
  }

  listarArquivos(): void {
    this.carregando_anexo = true;
    this.materialAnexoService.list(this.codigo_material).subscribe((data: MaterialAnexo) => {
      this.anexos = data;
      this.carregando_anexo = true;
    }, error => console.log(error));
  }

  atualizarLocalizacao(): void {
    this.submited = true;
    this.carregando = true;
    const localizacao = {'codigo_localizacao': this.codigo_localizacao, 'codigo_material': this.codigo_material};
    this.materialLocalizacaoService.save(localizacao, null).subscribe((data) => {
      this.exibir();
      this.exibirHistorico();
      this.notifierService.notify( 'success', 'Localização atualizada com sucesso.' );
      this.carregando = false;
    }, (error) => {
      this.notifierService.notify( 'error', 'Não foi cadastrar a mensagem. ' + error );
      this.carregando = false;
    });
  }

  baixar(): void {
    this.submited = true;
    this.carregando = true;
    const material = this.formBaixa.value as Material;
    if (this.formBaixa.valid) {
      this.materialService.baixar(material, this.codigo_material).subscribe((data) => {
        this.notifierService.notify( 'success', 'BAIXA de material realizada com sucesso.' );
        this.exibir();
        this.exibirHistorico();
        this.carregando = false;
      }, (error) => {
        this.notifierService.notify( 'error', error );
        this.carregando = false;
      });
    } else {
        console.log(this.formBaixa);
        this.notifierService.notify( 'error', 'Informe uma justificativa para a baixa.');
        this.carregando = false;
      }
  }

  restaurar():void {
    if (this.codigo_localizacao) {
      const localizacao = {'codigo_localizacao': this.codigo_localizacao, 'codigo_material': this.codigo_material};
      this.materialService.restore(localizacao, this.codigo_material).subscribe((data) => {
        this.exibir();
        this.exibirHistorico();
        this.notifierService.notify( 'success', 'Registro REATIVADO com sucesso.' );
      }, (error) => {
        this.notifierService.notify( 'danger', 'Não foi possível REATIVADO o registro, ocorreu um erro. ' + error );
      });
    } else {
      console.log(this.formBaixa);
      this.notifierService.notify( 'error', 'Informe a localização atual.');
    }
  }
  
  baixarDocumento(codigo_material_anexo, nome): void {
    console.log(nome);
    this.carregando_anexo = true;
    this.materialAnexoService.downloadArquivo(codigo_material_anexo, nome);
    this.carregando_anexo = false;
  }

  onChange(event) {
    const selectedFiles = <FileList>event.srcElement.files;
    const fileNames = [];
    this.files = new Set();
    for (let i = 0; i < selectedFiles.length; i++) {
      fileNames.push(selectedFiles[i].name);
      this.files.add(selectedFiles[i]);
    }
    document.getElementById('customFileLabel').innerHTML = fileNames.join(', ');
    console.log('>>'+selectedFiles.length);
    this.progress = 0;
  }

  onUpload() {
    this.carregando = true;
    if (this.files && this.files.size > 0) {
      this.uploadFileService.upload(this.files, environment.UrlPrincipal + '/api/materialanexo', this.codigo_material, this.codigo_pessoa)
        .pipe(
          uploadProgress(progress => {
            this.progress = progress;
          }),
          filterResponse()
        )
        .subscribe(response => {
          console.log('Upload Concluído');
          this.listarArquivos();
          this.carregando = false;
          this.notifierService.notify( 'success', 'Arquivo ENVIADO com sucesso.');
          $('body').removeClass('modal-open');
          $('body').css('padding-right', '');
          $(".modal-backdrop").remove();
          $('#md-footer-novo-arquivo').modal('hide');
        }, (error) => {
          this.notifierService.notify( 'error', 'Erro ao carregar arquivo' );
          this.carregando_anexo = false;
        });
    } else {
      this.notifierService.notify( 'error', 'Necessário selecionar um arquivo para envio.' );
    }
  }

  excluirArquivo(): void {
    this.submited = true;
    this.carregando_anexo = true;
    this.materialAnexoService.delete(this.codigo_material_anexo).subscribe((data) => {
      this.listarArquivos();
      this.notifierService.notify( 'success', 'Anexo excluído com sucesso.' );
      this.carregando_anexo = false;
    }, (error) => {
      this.notifierService.notify( 'error', error );
      this.carregando_anexo = false;
    });
  }


}
