export const environment = {
  production: true,
  UrlPrincipal: 'https://admin.api.sga.org.br',
  UrlApi: 'https://admin.api.sga.org.br/api',
  UrlPessoaPerfil: 'https://admin.api.sga.org.br/api/pessoaperfil',
  UrlPerfilAcesso: 'https://admin.api.sga.org.br/api/pessoaacesso',
  UrlPessoaFisica: 'https://admin.api.sga.org.br/api/pessoafisica',
  UrlLogin: 'https://admin.api.sga.org.br/api/login',
  UrlConsulta: 'https://admin.api.sga.org.br/api/pessoa',
  UrlMe: 'https://admin.api.sga.org.br/api/me',
  UrlLayout: 'https://admin.api.sga.org.br/api/layout',
  UrlTipoLayout: 'https://admin.api.sga.org.br/api/tipo-layout',
  UrlAlterarSenha: 'https://admin.api.sga.org.br/api/pessoaacesso'
};
