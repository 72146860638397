import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../../../../app.component';
import { NotifierService } from 'angular-notifier';
import { BeneficioTipo } from 'src/app/shared/models/beneficio-tipo';
import { BeneficioTipoService } from 'src/app/core/services/beneficio-tipo.service';

@Component({
    selector: 'app-beneficio-tipo-listar',
    templateUrl: './beneficio-tipo-listar.component.html',
    styleUrls: ['./beneficio-tipo-listar.component.css'],
})
export class BeneficioTipoListarComponent implements OnInit {
    beneficios_tipo: BeneficioTipo;
    codigo_beneficio_tipo;
    breadCrumb;
    beneficios_tipo_inativos: BeneficioTipo;
    carregando;

    constructor(
        private appComponent: AppComponent,
        private beneficioTipoService: BeneficioTipoService,
        private notifierService: NotifierService,
    ) {}

    ngOnInit() {
        this.breadCrumb = [
            { nome: 'Configuração', caminho: 'configuracao' },
            { nome: 'beneficios_tipo', caminho: '/beneficio-tipo-listar' },
        ];
        this.appComponent.setTitulo('Beneficios Tipo Cadastradas');
        this.appComponent.setBreadcrumb(this.breadCrumb);

        this.listar();
    }

    definirCodigo(codigo_beneficio_tipo) {
        this.codigo_beneficio_tipo = codigo_beneficio_tipo;
    }

    listar(): void {
        this.beneficioTipoService.list(false).subscribe(
            (data: BeneficioTipo) => {
                this.beneficios_tipo = data;
            },
            (error) => console.log(error),
        );

        // demandas inativas
        this.beneficioTipoService.list(true).subscribe(
            (data_inativo: BeneficioTipo) => {
                this.beneficios_tipo_inativos = data_inativo;
            },
            (error) => console.log(error),
        );
    }

    apagar(codigo_beneficio_tipo): void {
        this.carregando = true;
        this.beneficioTipoService.delete(codigo_beneficio_tipo).subscribe(
            (data) => {
                this.listar();
                this.carregando = false;
                this.notifierService.notify(
                    'success',
                    'Registro DELETADO com sucesso.',
                );
            },
            (error) => {
                this.notifierService.notify('error', error);
                this.carregando = false;
            },
        );
    }

    restaurar(codigo_beneficio_tipo): void {
        this.beneficioTipoService.restore(codigo_beneficio_tipo).subscribe(
            (data) => {
                this.listar();
                this.notifierService.notify(
                    'success',
                    'Registro RESTAURADO com sucesso.',
                );
            },
            (error) => {
                this.notifierService.notify(
                    'error',
                    'Não foi possível REATIVAR o registro. ' + error,
                );
            },
        );
    }
}
