import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { AppComponent } from 'src/app/app.component';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { AssociadoSituacaoTipo } from 'src/app/shared/models/associado-situacao-tipo';
import { AssociadoSituacaoTipoService } from 'src/app/core/services/associado-situacao-tipo.service';

@Component({
    selector: 'app-associado-situacao-tipo-editar',
    templateUrl: './associado-situacao-tipo-editar.component.html',
    styleUrls: ['./associado-situacao-tipo-editar.component.css'],
})
export class AssociadoSituacaoTipoEditarComponent implements OnInit {
    @ViewChild('associadoSituacaoTipoForm') formValues;

    form: FormGroup;
    breadCrumb;
    associados_situacao_tipo: AssociadoSituacaoTipo;
    carregando: boolean;
    submited: boolean;
    codigo_associado_situacao_tipo: any;

    constructor(
        private appComponent: AppComponent,
        private formBuilder: FormBuilder,
        private routerParam: ActivatedRoute,
        private router: Router,
        private associadoSituacaoTipoService: AssociadoSituacaoTipoService,
        private notifierService: NotifierService,
    ) {
        this.codigo_associado_situacao_tipo =
            this.routerParam.snapshot.params.id;
    }

    ngOnInit() {
        this.carregando = true;
        this.breadCrumb = [
            { nome: 'Configuração', caminho: '/configuracao' },
            {
                nome: 'associados_situacao_tipo',
                caminho: '/associado-situacao-tipo-listar',
            },
        ];
        if (this.codigo_associado_situacao_tipo) {
            this.appComponent.setTitulo('Alteração de associado situacao tipo');
        } else {
            this.appComponent.setTitulo('Cadastro de associado situacao tipo');
        }
        this.appComponent.setBreadcrumb(this.breadCrumb);

        this.form = this.formBuilder.group({
            codigo_associado_situacao_tipo: [null],
            nome: [null, Validators.required],
        });

        this.obter();
    }

    obter(): void {
        this.associadoSituacaoTipoService
            .find(this.codigo_associado_situacao_tipo)
            .subscribe((data: AssociadoSituacaoTipo) => {
                this.form.patchValue({
                    codigo_associado_situacao_tipo:
                        data.codigo_associado_situacao_tipo,
                    nome: data.nome,
                });
            });
    }

    onSubmit(): void {
        this.submited = true;
        this.carregando = true;
        const associado_situacao_tipo = this.form
            .value as AssociadoSituacaoTipo;
        if (this.form.valid) {
            this.associadoSituacaoTipoService
                .save(
                    associado_situacao_tipo,
                    this.codigo_associado_situacao_tipo,
                )
                .subscribe(
                    (data) => {
                        this.notifierService.notify(
                            'success',
                            'Associado situacao tipo cadastrada com sucesso.',
                        );
                        this.carregando = false;
                        this.router.navigate([
                            'associado-situacao-tipo-listar',
                        ]);
                    },
                    (error) => {
                        this.notifierService.notify(
                            'error',
                            'Não foi cadastrar uma nova associado situacao tipo. ' +
                                error,
                        );
                        this.carregando = false;
                    },
                );
        } else {
            console.log(this.form);
            this.notifierService.notify(
                'error',
                'Existem erros no preenchimento do formulário.',
            );
            this.carregando = false;
        }
    }
}
