import {Component, OnInit} from '@angular/core';
import {TipoLayoutService} from "../../../core/services/tipo-layout.service";
import {Router} from "@angular/router";
import {NotifierService} from "angular-notifier";
import {TipoLayout} from "../../../shared/models/tipo-layout";
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {ArquivoService} from "../../../core/services/arquivo.service";
import {AssociadoPossivel} from "../../../shared/models/associado-possivel";
import {environment} from "../../../../environments/environment";
import {Layout} from "../../../shared/models/layout";
import {LayoutService} from "../../../core/services/layout.service";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";

@Component({
    selector: 'app-arquivo-form',
    templateUrl: './arquivo-form.component.html',
    styleUrls: ['./arquivo-form.component.css']
})
export class ArquivoFormComponent implements OnInit {

    public tipos_layout: Array<TipoLayout> = [];
    public form: FormGroup;
    public form_associado: FormGroup;
    public meses: Array<{id: number, descricao: string}> = [];
    public associados_pagamento: Array<AssociadoPossivel> = [];
    public associados_sem_pagamento: Array<AssociadoPossivel> = [];
    public carregado: boolean = false;
    public mostrar_carregando: boolean = false;
    public colors_com_pagamento: Array<string> = [];
    public colors_sem_pagamento: Array<string> = [];
    public cor_alterada: boolean = false;
    public checked_all: boolean = false;
    public layouts: Array<Layout> = [];
    public layouts_aceite: Array<Layout> = [];
    public checks_selecionados: Array<{codigo_pessoa: number, check: boolean}> = [];
    public selecionados = {ano_referencia: null, mes_referencia: null, sigla_tipo_layout: null, codigo_layout: null, codigo_layout_aceite: null, associados_gerados: null, nome_cpf_associado: null};
    public md_envio_arquivo_ref: BsModalRef;

    constructor(private arquivoService: ArquivoService, private tipoLayoutService: TipoLayoutService, private layoutService: LayoutService, private router: Router, private notifierService: NotifierService, private modalService: BsModalService) {
    
    }

    ngOnInit() {
        this.form = new FormGroup({
            sigla_tipo_layout: new FormControl("", [Validators.required]),
            codigo_layout: new FormControl({value: "", disabled: true}, [Validators.required]),
            codigo_layout_aceite: new FormControl({value: "", disabled: true}, [Validators.required]),
            ano_referencia: new FormControl("", [Validators.required]),
            mes_referencia: new FormControl("", [Validators.required]),
            associados_gerados: new FormControl("", []),
            nome_cpf_associado: new FormControl("", []),
        });
        this.form_associado = new FormGroup({});
        this.listTiposLayout();
        this.listMeses();
    }

    listMeses(): void{
        this.meses.push({id: 1, descricao: "Janeiro"});
        this.meses.push({id: 2, descricao: "Fevereiro"});
        this.meses.push({id: 3, descricao: "Março"});
        this.meses.push({id: 4, descricao: "Abril"});
        this.meses.push({id: 5, descricao: "Maio"});
        this.meses.push({id: 6, descricao: "Junho"});
        this.meses.push({id: 7, descricao: "Julho"});
        this.meses.push({id: 8, descricao: "Agosto"});
        this.meses.push({id: 9, descricao: "Setembro"});
        this.meses.push({id: 10, descricao: "Outubro"});
        this.meses.push({id: 11, descricao: "Novembro"});
        this.meses.push({id: 12, descricao: "Dezembro"});
    }

    listTiposLayout(): void{
        this.tipos_layout = [];
        this.layouts = [];
        this.layouts_aceite = [];
        this.form.patchValue({
            "codigo_layout": "",
            "codigo_layout_aceite": "",
        });
        this.tipoLayoutService.list(true).subscribe((tipos_layout: Array<TipoLayout>) => {
            this.tipos_layout = tipos_layout as Array<TipoLayout>;
        });
    }
    
    listarLayouts(tipo_layout: string): void{
        this.layouts = [];
        this.layouts_aceite = [];
        this.layoutService.list(tipo_layout).subscribe((layouts: Array<Layout>) => {
            this.layouts = layouts;
            if(layouts.length > 0){
                this.form.patchValue({
                    "codigo_layout": layouts[0].codigo_layout,
                });
            }
        }, () => {
            this.notifierService.notify('error', 'Ocorreu um erro ao carregar os layouts, selecione novamente o tipo de layout');
        });
        this.layoutService.listByTipoEquivalente(tipo_layout).subscribe((layouts: Array<Layout>) => {
            this.layouts_aceite = layouts;
            if(layouts.length > 0){
                this.form.patchValue({
                    "codigo_layout_aceite": layouts[0].codigo_layout,
                });
            }
        }, () => {
            this.notifierService.notify('error', 'Ocorreu um erro ao carregar os layouts de consiginação, selecione novamente o tipo de layout');
        });
    }

    voltar(): void {
        this.router.navigate(['layout/arquivo']);
    }

    list(ano_referencia: number, mes_referencia: number, sigla_tipo_layout: string, codigo_layout: number, codigo_layout_aceite: number, associados_gerados: number = null, nome_cpf_associado: string = null): void{
        this.carregado = false;
        this.cor_alterada = false;
        this.colors_com_pagamento = [];
        this.colors_sem_pagamento = [];
        if(this.form.valid) {
            this.mostrar_carregando = true;
            this.selecionados.ano_referencia = ano_referencia;
            this.selecionados.mes_referencia = mes_referencia;
            this.selecionados.sigla_tipo_layout = sigla_tipo_layout;
            this.selecionados.codigo_layout = codigo_layout;
            this.selecionados.codigo_layout_aceite = codigo_layout_aceite;
            this.selecionados.associados_gerados = associados_gerados;
            this.selecionados.nome_cpf_associado = nome_cpf_associado;
            this.arquivoService.listPossiveis(ano_referencia, mes_referencia, sigla_tipo_layout, codigo_layout, codigo_layout_aceite, associados_gerados, nome_cpf_associado).subscribe((data: any) => {
                this.associados_pagamento = data.pagamento as Array<AssociadoPossivel>;
                this.associados_sem_pagamento = data.sem_pagamento as Array<AssociadoPossivel>;
                if(this.associados_pagamento.length > 0) {
                    let form: FormArray = new FormArray([]);
                    this.checked_all = true;
                    this.associados_pagamento.forEach((associado: AssociadoPossivel, i: number) => {
                        this.colors_com_pagamento[i] = null;
                        let checked: boolean = associado.nome_arquivo ? false : true;
                        let associado_check: {codigo_pessoa: number, check: boolean} = this.checks_selecionados.find(v => v.codigo_pessoa === associado.codigo_pessoa);
                        if(associado_check){
                            checked = associado_check.check;
                        }else{
                            this.checks_selecionados.push({codigo_pessoa: associado.codigo_pessoa, check: checked});
                        }
                        if(!checked){
                            this.checked_all = false;
                        }
                        form.push(new FormGroup({
                            "codigo_pessoa": new FormControl(checked, [Validators.required]),
                        }));
                    });
                    this.form_associado.setControl("itens", form);
                }
                if(this.associados_sem_pagamento.length > 0) {
                    this.associados_sem_pagamento.forEach((associado: AssociadoPossivel, i: number) => {
                        this.colors_sem_pagamento[i] = null;
                    });
                }
                this.carregado = true;
                this.mostrar_carregando = false;
            }, (error) => {
                this.mostrar_carregando = false;
                this.notifierService.notify('error', error);
            });
        } else {
            this.notifierService.notify('error', "Informe os campos obrigatórios");
        }
    }
    
    check(codigo_pessoa: number, event){
        let item: {codigo_pessoa: number, check: boolean} = this.checks_selecionados.find(v => v.codigo_pessoa === codigo_pessoa);
        if(item){
            item.check = event.target.checked;
        }else{
            this.checks_selecionados.push({codigo_pessoa: codigo_pessoa, check: event.target.checked});
        }
        if(!event.target.checked){
            this.checked_all = false;
        }else{
            let qtd_checkeds: number = 0;
            if(this.checks_selecionados.length > 0){
                this.checks_selecionados.forEach((item: {codigo_pessoa: number, check: boolean}) => {
                    if(item.check) {
                        qtd_checkeds++;
                    }
                });
            }
            if(qtd_checkeds == this.checks_selecionados.length){
                this.checked_all = true;
            }else{
                this.checked_all = false;
            }
        }
    }

    toggle(event){
        let itens: FormArray = this.form_associado.get("itens") as FormArray;
        itens.controls.map(control => control.get("codigo_pessoa").setValue(event.target.checked));
        if(this.checks_selecionados.length > 0){
            this.checks_selecionados.forEach((item: {codigo_pessoa: number, check: boolean}) => {
                item.check = event.target.checked;
            });
        }
    }

    linkFichaFinanceira(i: number, com_pagamento: boolean): void{
        if(com_pagamento){
            this.colors_com_pagamento[i] = "#FFFF00";
        }else{
            this.colors_sem_pagamento[i] = "#FFFF00";
        }
        this.cor_alterada = true;
    }
    
    openModalEnvioArquivo(content_modal): void{
        this.md_envio_arquivo_ref = this.modalService.show(content_modal, {keyboard: false, focus: true});
    }

    save(): void{
        const associados: Array<AssociadoPossivel> = this.form_associado.value.itens.map((field, i) => field.codigo_pessoa ? this.associados_pagamento[i] : null).filter(v => v !== null);
        if(associados.length > 0){
            this.mostrar_carregando = true;
            this.arquivoService.generateFile(this.selecionados.ano_referencia, this.selecionados.mes_referencia, this.selecionados.sigla_tipo_layout, this.selecionados.codigo_layout, associados).subscribe((res) => {
                this.mostrar_carregando = false;
                this.list(this.selecionados.ano_referencia, this.selecionados.mes_referencia, this.selecionados.sigla_tipo_layout, this.selecionados.codigo_layout, this.selecionados.codigo_layout_aceite, this.selecionados.associados_gerados, this.selecionados.nome_cpf_associado);
                this.notifierService.notify('success', "Arquivo gerado com sucesso");
                window.open(environment.UrlApi + "/layout/arquivo/file/" + res.arquivo.codigo_arquivo, '_blank');
            }, () => {
                this.mostrar_carregando = false;
            });
        } else {
            this.notifierService.notify('error', "Informe pelo menos um associado");
        }
    }

    downloadFile(codigo_arquivo: number){
        window.open(environment.UrlApi + "/layout/arquivo/file/" + codigo_arquivo, '_blank');
    }
    
    submitConsulta(): void{
        let codigo_layout_desabilitado: boolean = this.form.get("codigo_layout").disabled;
        let codigo_layout_aceite_desabilitado: boolean = this.form.get("codigo_layout_aceite").disabled;
        if(codigo_layout_desabilitado){
            this.form.get("codigo_layout").enable();
        }
        if(codigo_layout_aceite_desabilitado){
            this.form.get("codigo_layout_aceite").enable();
        }
        this.list(this.form.value.ano_referencia, this.form.value.mes_referencia, this.form.value.sigla_tipo_layout, this.form.value.codigo_layout, this.form.value.codigo_layout_aceite, this.form.value.associados_gerados, this.form.value.nome_cpf_associado);
        if(codigo_layout_desabilitado){
            this.form.get("codigo_layout").disable();
        }
        if(codigo_layout_aceite_desabilitado){
            this.form.get("codigo_layout_aceite").disable();
        }
    }

}
