import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from '../../core/services/authentication.service';
import { NotifierService } from 'angular-notifier';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService,
                private notifierService: NotifierService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                if(err.url.indexOf("login")==-1) {                   
                    // auto logout if 401 response returned from api
                    this.authenticationService.logout();
                    location.reload();
                    console.log('Tente logar novamente.');
                    
                } else {
                    this.notifierService.notify( 'error', 'Usuário ou senha inválido.');
                }
                
            }

            const error = (err.error) ? err.error : err.message || err.statusText;
            return throwError(error);

        }))
    }
}