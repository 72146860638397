import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgxCurrencyModule } from 'ngx-currency';
import { BrowserModule } from '@angular/platform-browser';
import { AuthGuard } from 'src/app/core/authentication/auth.guard';
import { PlanoListarComponent } from '../tabela-apoio/plano/plano-listar/plano-listar.component';
import { PlanoEditarComponent } from '../tabela-apoio/plano/plano-editar/plano-editar.component';
import { ClasseListarComponent } from '../tabela-apoio/classe/classe-listar/classe-listar.component';
import { ClasseEditarComponent } from '../tabela-apoio/classe/classe-editar/classe-editar.component';

import { AssociadoPagamentoTipoListarComponent } from '../tabela-apoio/associado-pagamento-tipo/associado-pagamento-tipo-listar/associado-pagamento-tipo-listar.component';
import { AssociadoPagamentoTipoEditarComponent } from '../tabela-apoio/associado-pagamento-tipo/associado-pagamento-tipo-editar/associado-pagamento-tipo-editar.component';

import { AssociadoSituacaoTipoListarComponent } from '../tabela-apoio/associado-situacao-tipo/associado-situacao-tipo-listar/associado-situacao-tipo-listar.component';
import { AssociadoSituacaoTipoEditarComponent } from '../tabela-apoio/associado-situacao-tipo/associado-situacao-tipo-editar/associado-situacao-tipo-editar.component';

import { BeneficioTipoListarComponent } from '../tabela-apoio/beneficio-tipo/beneficio-tipo-listar/beneficio-tipo-listar.component';
import { BeneficioTipoEditarComponent } from '../tabela-apoio/beneficio-tipo/beneficio-tipo-editar/beneficio-tipo-editar.component';

import { DemandaTipoListarComponent } from '../tabela-apoio/demanda-tipo/demanda-tipo-listar/demanda-tipo-listar.component';
import { DemandaTipoEditarComponent } from '../tabela-apoio/demanda-tipo/demanda-tipo-editar/demanda-tipo-editar.component';

import { DocumentoTipoListarComponent } from '../tabela-apoio/documento-tipo/documento-tipo-listar/documento-tipo-listar.component';
import { DocumentoTipoEditarComponent } from '../tabela-apoio/documento-tipo/documento-tipo-editar/documento-tipo-editar.component';

import { PendenciaTipoListarComponent } from '../tabela-apoio/pendencia-tipo/pendencia-tipo-listar/pendencia-tipo-listar.component';
import { PendenciaTipoEditarComponent } from '../tabela-apoio/pendencia-tipo/pendencia-tipo-editar/pendencia-tipo-editar.component';

const appRoutes: Routes = [
    {
        path: 'plano-listar',
        component: PlanoListarComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'plano-editar',
        component: PlanoEditarComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'plano-editar/:id',
        component: PlanoEditarComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'classe-listar',
        component: ClasseListarComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'classe-editar',
        component: ClasseEditarComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'classe-editar/:id',
        component: ClasseEditarComponent,
        canActivate: [AuthGuard],
    },

    {
        path: 'associado-pagamento-tipo-listar',
        component: AssociadoPagamentoTipoListarComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'associado-pagamento-tipo-editar',
        component: AssociadoPagamentoTipoEditarComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'associado-pagamento-tipo-editar/:id',
        component: AssociadoPagamentoTipoEditarComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'associado-situacao-tipo-listar',
        component: AssociadoSituacaoTipoListarComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'associado-situacao-tipo-editar',
        component: AssociadoSituacaoTipoEditarComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'associado-situacao-tipo-editar/:id',
        component: AssociadoSituacaoTipoEditarComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'beneficio-tipo-listar',
        component: BeneficioTipoListarComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'beneficio-tipo-editar',
        component: BeneficioTipoEditarComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'beneficio-tipo-editar/:id',
        component: BeneficioTipoEditarComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'demanda-tipo-listar',
        component: DemandaTipoListarComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'demanda-tipo-editar',
        component: DemandaTipoEditarComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'demanda-tipo-editar/:id',
        component: DemandaTipoEditarComponent,
        canActivate: [AuthGuard],
    },

    {
        path: 'documento-tipo-listar',
        component: DocumentoTipoListarComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'documento-tipo-editar',
        component: DocumentoTipoEditarComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'documento-tipo-editar/:id',
        component: DocumentoTipoEditarComponent,
        canActivate: [AuthGuard],
    },

    {
        path: 'pendencia-tipo-listar',
        component: PendenciaTipoListarComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'pendencia-tipo-editar',
        component: PendenciaTipoEditarComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'pendencia-tipo-editar/:id',
        component: PendenciaTipoEditarComponent,
        canActivate: [AuthGuard],
    },
];

export const customCurrencyMaskConfig = {
    align: 'right',
    allowNegative: false,
    allowZero: true,
    decimal: ',',
    precision: 2,
    prefix: '',
    suffix: '',
    thousands: '.',
    nullable: null,
};

@NgModule({
    imports: [
        BrowserModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
        NgxMaskModule.forRoot(),
        RouterModule.forRoot(appRoutes),
    ],
    declarations: [
        PlanoListarComponent,
        PlanoEditarComponent,
        ClasseListarComponent,
        ClasseEditarComponent,
        AssociadoPagamentoTipoListarComponent,
        AssociadoPagamentoTipoEditarComponent,
        AssociadoSituacaoTipoEditarComponent,
        AssociadoSituacaoTipoListarComponent,
        BeneficioTipoListarComponent,
        BeneficioTipoEditarComponent,
        DemandaTipoListarComponent,
        DemandaTipoEditarComponent,
        DocumentoTipoListarComponent,
        DocumentoTipoEditarComponent,
        PendenciaTipoListarComponent,
        PendenciaTipoEditarComponent,
    ],
})
export class TabelaApoioModule {}
