<app-header></app-header>

<div class="row wizard-row">
  <div class="col-md-12 fuelux">
    <div class="block-wizard panel panel-default">
      <div id="wizard1" class="wizard wizard-ux">
        <ul class="steps">
          <li data-step="1"><a href="dados-pessoais/{{codigo_pessoa}}">Dados Pessoais</a><span class="chevron"></span></li>
          <li data-step="2"><a href="dados-financeiros/{{codigo_pessoa}}">Dados Financeiros</a><span class="chevron"></span></li>
          <li data-step="3" class="active"><a href="dados-administrativos/{{codigo_pessoa}}">Dados Administrativos</a><span class="chevron"></span></li>
          <li data-step="4"><a href="dados-controle/{{codigo_pessoa}}">Dados de Controle</a><span class="chevron"></span></li>
          <li data-step="5"><a href="dados-complementares/{{codigo_pessoa}}">Dados Complementares</a><span class="chevron"></span></li>
        </ul>
        <div class="step-content">
          <div *ngIf="carregando" style="text-align: center;"><img src="/assets/img/carregando_tabela.gif"></div>
          <div data-step="1" class="step-pane active" *ngIf="!carregando">
            <form #associadoForm="ngForm" [formGroup]="form" data-parsley-namespace="data-parsley-" data-parsley-validate="" novalidate="" class="form-horizontal group-border-dashed">
              <div class="form-group">
                <label class="col-sm-3 control-label">Situação do Associado</label>
                <label class="col-sm-2 control-label" style="text-align: left;" *ngIf="tipo == 'AT'">ATIVO</label>
                <label class="col-sm-2 control-label" style="text-align: left;" *ngIf="tipo == 'AP'">APOSENTADO</label>
                <label class="col-sm-2 control-label" style="text-align: left;" *ngIf="tipo == 'PE'">PENSIONISTA</label>
              </div>
              <div class="form-group" *ngIf="tipo == 'PE'">
                <label class="col-sm-3 control-label">SIAPE Instituidor</label>
                <div class="col-sm-2">
                  <input type="text" placeholder="SIAPE Instituidor" class="form-control" maxlength="7"  formControlName="siape_instituidor">
                  <app-error-msg [control]="form.get('siape_instituidor')" label="Data de Falecimento Associado"></app-error-msg>
                </div>
                <div class="col-sm-7">
                  <label class="control-label">
                    {{nome_siape_instituidor}}
                  </label>
                </div>
              </div>
              <div class="form-group" [class.has-error]="f.data_falecimento.touched && f.data_falecimento.invalid == true" *ngIf="data_falecimento">
                <label class="col-sm-3 control-label">Data de Falecimento Associado</label>
                <div class="col-sm-2">
                  <input type="text" placeholder="DD/MM/YYYY" class="form-control" [dropSpecialCharacters]="false" mask="00/00/0000" formControlName="data_falecimento">
                  <app-error-msg [control]="form.get('data_falecimento')" label="Data de Falecimento Associado"></app-error-msg>
                </div>
              </div>
              <div class="form-group"  *ngIf="!data_falecimento">
                <label class="col-sm-3 control-label">Data de Falecimento Associado</label>
                <div class="col-sm-2" *ngIf="data_falecimento_form">
                  <input type="text" placeholder="DD/MM/YYYY" class="form-control" [dropSpecialCharacters]="false" mask="00/00/0000" formControlName="data_falecimento">
                  <app-error-msg [control]="form.get('data_falecimento')" label="Data de Falecimento Associado"></app-error-msg>
                </div>
                <div class="col-sm-2">
                  <button data-toggle="modal" data-target="#md-data-falecimento" class="btn btn-primary btn-space wizard-next" *ngIf="!data_falecimento">
                    <span *ngIf="!data_falecimento_form">
                      Informar Falecimento
                    </span>
                    <span *ngIf="data_falecimento_form">
                      Alterar data de Falecimento
                    </span>
                  </button>
                </div>
              </div>
              <div class="form-group" [class.has-error]="(f.contrato_serpro.touched || submited) && f.contrato_serpro.invalid == true">
                <label class="col-sm-3 control-label">Contrato SERPRO</label>
                <div class="col-sm-6">
                  <input type="text" placeholder="Contrato SERPRO" class="form-control" maxlength="20" formControlName="contrato_serpro">
                  <app-error-msg [control]="form.get('contrato_serpro')" label="Contrato SERPRO"></app-error-msg>
                  <span class="erro" *ngIf="submited && !f.contrato_serpro.touched && f.contrato_serpro.invalid == true">
                    Contrato SERPRO deve ter 20 caracteres.
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-3 control-label">Código UPAG</label>
                <div class="col-sm-2">
                  <input type="text" placeholder="Código UPAG" class="form-control" maxlength="6" formControlName="codigo_upag">
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-3 control-label">UF UPAG</label>
                <div class="col-sm-2">
                  <select class="select2 form-control" formControlName="sigla_uf_upag">
                    <option *ngFor="let uf of Ufs" value="{{uf.sigla_uf}}">
                      {{uf.sigla_uf}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-3 control-label">Unidade Repasse</label>
                <div class="col-sm-6">
                  <select class="select2 form-control" formControlName="codigo_unidade_repasse">
                    <option *ngFor="let dado of Lotacoes" value="{{dado.codigo_lotacao}}">
                      {{dado.nome}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-offset-3 col-sm-9">
                  <a href="dados-financeiros/{{codigo_pessoa}}" data-wizard="#wizard1" class="btn btn-primary btn-space wizard-next">Anterior</a>&nbsp;&nbsp;&nbsp;&nbsp;
                  <a href="dados-controle/{{codigo_pessoa}}" data-wizard="#wizard1" class="btn btn-primary btn-space wizard-next">Avançar</a>&nbsp;&nbsp;&nbsp;&nbsp;
                  <button data-wizard="#wizard1" class="btn btn-primary btn-space wizard-next" (click)="onSubmit()">Salvar</button>&nbsp;&nbsp;&nbsp;&nbsp;
                  <button data-wizard="#wizard1" class="btn btn-success btn-space wizard-next" (click)="onSubmit(true)">Salvar e Avançar</button>
                  &nbsp; <img *ngIf="carregando_salvar" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="md-data-falecimento" tabindex="-1" role="dialog" style="" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-header">
              <button type="button" data-dismiss="modal" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
          </div>
          <div class="modal-body">
            <form #associadoFalecimentoForm="ngForm" [formGroup]="formFalecimento" data-parsley-namespace="data-parsley-" data-parsley-validate="" novalidate="" class="form-horizontal group-border-dashed">
              <div class="form-group">
                <label class="col-sm-5 control-label">Data de Falecimento Associado</label>
                <div class="col-sm-5">
                  <input type="text" placeholder="DD/MM/YYYY" class="form-control" [dropSpecialCharacters]="false" mask="00/00/0000" formControlName="data_falecimento_form">
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-offset-5 col-sm-5">
                  <button type="button" data-dismiss="modal" class="btn btn-default">Cancelar</button>&nbsp;&nbsp;
                  <button type="button" data-dismiss="modal" data-toggle="modal" data-target="#md-data-falecimento-confirmacao" class="btn btn-warning" (click)="definirDataFalecimento()">Confirmar</button>
                </div>
              </div>
            </form>
          </div>
      </div>
  </div>
</div>

<div id="md-data-falecimento-confirmacao" tabindex="-1" role="dialog" style="" class="modal fade">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-header">
              <button type="button" data-dismiss="modal" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
          </div>
          <div class="modal-body">
              <div class="text-center">
                  <div class="text-warning"><span class="modal-main-icon mdi mdi-alert-triangle"></span></div>
              <h3>Tem certeza que deseja informar a DATA DE FALECIMENTO?</h3>
              <p style="color: red;">Esta operação irá gerar uma BAIXA no cadastro do associado</p>
              </div>
          </div>
          <div class="modal-footer">
              <button type="button" data-dismiss="modal" class="btn btn-default" (click)="limparDataFalecimento()">Cancelar</button>
              <button type="button" data-dismiss="modal" class="btn btn-warning" (click)="onSubmit(false)">Confirmar</button>
          </div>
      </div>
  </div>
</div>

<app-footer></app-footer>