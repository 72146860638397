// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
    production: true,
    UrlPrincipal: 'https://admin.api.sga.org.br',
    UrlApi: 'https://admin.api.sga.org.br/api',
    UrlPessoaPerfil: 'https://admin.api.sga.org.br/api/pessoaperfil',
    UrlPerfilAcesso: 'https://admin.api.sga.org.br/api/pessoaacesso',
    UrlPessoaFisica: 'https://admin.api.sga.org.br/api/pessoafisica',
    UrlLogin: 'https://admin.api.sga.org.br/api/login',
    UrlConsulta: 'https://admin.api.sga.org.br/api/pessoa',
    UrlMe: 'https://admin.api.sga.org.br/api/me',
    UrlLayout: 'https://admin.api.sga.org.br/api/layout',
    UrlTipoLayout: 'https://admin.api.sga.org.br/api/tipo-layout',
    UrlAlterarSenha: 'https://admin.api.sga.org.br/api/pessoaacesso'
  };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
