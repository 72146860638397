<app-header></app-header>

<div class="row">
  <div class="col-md-4">
    <div class="panel panel-flat">
      <div class="panel-heading">
        <b>Nº {{material.numero_patrimonio}}</b>
      </div>
      <div class="panel-body">
        <p><b>Localização Atual</b>
        <br />
        <span class="label label-success">{{material.localizacao}}</span></p>
        <br />
        <p><b>Ações:</b></p>
        <a href="patrimonio-editar/{{codigo_material}}" class="btn btn-space btn-default"><i class="icon icon-left mdi mdi-edit"></i> Editar</a>
        &nbsp;
        <button data-toggle="modal" data-target="#md-alterar-localizacao" class="btn btn-space btn-default"><i class="icon icon-left mdi mdi-globe-alt"></i> Alterar Local</button>
        &nbsp;
        <button data-toggle="modal" data-target="#md-baixar" class="btn btn-space btn-default" *ngIf="!material.data_baixa"><i class="icon icon-left mdi mdi-long-arrow-down"></i> Baixar</button>
        &nbsp;
        <button data-toggle="modal" data-target="#md-footer-restaurar" class="btn btn-space btn-default" *ngIf="material.data_baixa"><i class="icon icon-left mdi mdi-long-arrow-up"></i> Reativar</button>
      </div>
    </div>
    <div class="panel panel-flat">
      <div class="panel-heading">
        <b>Anexos</b>
        &nbsp;&nbsp;&nbsp;
        <button data-toggle="modal" data-target="#md-footer-novo-arquivo" class="btn btn-space btn-default"><i class="icon icon-left mdi mdi-file"></i> Novo Anexo</button>
      </div>
      <div class="panel-body" *ngIf="anexos && anexos.length == 0">
        <p>Nenhum anexo encontrado</p>
      </div>
      <div class="panel-body" *ngIf="anexos && anexos.length > 0">
        <div *ngFor="let dado of anexos">
          <div class="col-md-10">
            <p style="cursor:pointer" (click)="baixarDocumento(dado.codigo_material_anexo, dado.nome)">{{dado.nome | slice:0:35}}<span *ngIf="dado.nome.length > 35"> ...</span></p>
          </div>
          <div class="col-md-2">
            <span data-toggle="modal" data-target="#md-footer-excluir-arquivo" *ngIf="dado.codigo_pessoa == codigo_pessoa" (click)="definirCodigoArquivoAnexo(dado.codigo_material_anexo)" class="mdi mdi-close-circle"></span>
          </div>
        </div>
      </div>
      <div class="panel-body" *ngIf="!anexos">
        <img *ngIf="carregando_anexo" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
      </div>
    </div>
    <a href="patrimonio-listar" class="btn btn-space btn-default">Voltar</a>
  </div>

  <div class="col-md-8">

    <div class="panel panel-contrast">
      <div class="panel-heading panel-heading-contrast">
        <b>Dados do Material</b>
      </div>
      <div class="panel-body">
        <p><b>Categoria:</b>&nbsp;&nbsp;{{material.categoria.nome}}</p>
        <p><b>Nº Patrimônio Antigo:</b>&nbsp;&nbsp;<span *ngIf="material.conta_contabil">{{material.numero_patrimonio_antigo}}</span><span *ngIf="!material.numero_patrimonio_antigo">Não informado</span></p>
        <p><b>Descrição:</b>&nbsp;&nbsp;{{material.descricao}}</p>
        <p><b>Data da Compra:</b>&nbsp;&nbsp;{{material.data_compra}}</p>
        <p><b>Nº da Nota:</b>&nbsp;&nbsp;{{material.numero_nota}}</p>
        <p><b>Fornecedor:</b>&nbsp;&nbsp;{{material.nome_fornecedor}}</p>
        <p><b>Valor:</b>&nbsp;&nbsp;{{material.valor | currency:'BRL':true:'.2':'pt'}}</p>
        <p><b>Conta Contábil:</b>&nbsp;&nbsp;<span *ngIf="material.conta_contabil">{{material.conta_contabil}}</span><span *ngIf="!material.conta_contabil">Não informado</span></p>
      </div>
    </div>

    <div class="panel panel-contrast">
      <div class="panel-heading panel-heading-contrast">
        <b>Histórico de Movimentação</b>
      </div>
      <div class="panel-body">
        <div *ngFor="let dado of materialLocalizacao">
          <span *ngIf="!dado.droped_at && !dado.reactivated_at"><b>{{dado.localizacao.nome}}</b></span>
          <span *ngIf="dado.droped_at"><b style="color: red;">Baixa de patrimônio</b><br />{{dado.observacao}}</span>
          <span *ngIf="dado.reactivated_at"><b style="color: green;">Reativação de patrimônio</b><br /><b>{{dado.localizacao.nome}}</b></span>
          <span class="panel-subtitle">{{dado.created_at | date: 'dd/MM/yyyy HH:mm'}}</span>
        </div>
      </div>
    </div>
    
  </div>
</div>


<!-- Modal novo arquivo -->
<div id="md-footer-novo-arquivo" tabindex="-1" role="dialog" style="" class="modal fade colored-header colored-header-primary">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-header">
              <button type="button" data-dismiss="modal" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
              <h3 class="modal-title">Envio de Arquivo</h3>
          </div>
          <div class="modal-body">
              
              <form #tratamentoForm="ngForm" [formGroup]="form" novalidate="" class="form-horizontal group-border-dashed">
                  <div class="custom-file">
                      <p>Arquivos permitidos: jpeg | jpg | png | gif | doc | docx | pdf | xls | xlsx</p>
                      <input type="file" class="custom-file-input" id="customFile" (change)="onChange($event)" multiple />
                      <br />
                      <label class="custom-file-label" for="customFile" id="customFileLabel" *ngIf="files"></label>
                    </div>
                  <br />
                  <br />
                  <div class="progress" *ngIf="carregando">
                      <div class="progress-bar" role="progressbar" [style.width]="progress + '%'">
                          {{ progress }}%
                      </div>
                  </div>
                </form>

          </div>
          <div class="modal-footer">
              <button type="button" data-dismiss="modal" class="btn btn-default">Cancelar</button>
              <button type="button" class="btn btn-primary" (click)="onUpload()">Enviar</button>
          </div>
      </div>
  </div>
</div>

<!--Modal Footer-->
<div id="md-footer-excluir-arquivo" tabindex="-1" role="dialog" style="" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <div class="text-warning"><span class="modal-main-icon mdi mdi-alert-triangle"></span></div>
                <h3>Tem certeza que deseja EXCLUIR este anexo?</h3>
                <p>Esta operação não poderá ser desfeita.</p>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default">Cancelar</button>
                <button type="button" data-dismiss="modal" class="btn btn-warning" (click)="excluirArquivo()">Confirmar</button>
            </div>
        </div>
    </div>
</div>   

<!--Modal Footer-->
<div id="md-alterar-localizacao" tabindex="-1" role="dialog" style="" class="modal fade colored-header colored-header-primary">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
              <button type="button" data-dismiss="modal" aria-hidden="true" class="close md-close"><span class="mdi mdi-close"></span></button>
              <h3 class="modal-title">Alterar Localização</h3>
            </div>
            <div class="modal-body">
              <form novalidate="" class="form-horizontal group-border-dashed">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <div class="col-sm-12">
                        <select class="form-control" (change)="definirCodigoLocalizacao($event.target.value)">
                          <option>Selecione</option>
                          <option *ngFor="let dado of localizacoes;" [value]="dado.codigo_localizacao">
                            {{dado.nome}}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default">Cancelar</button>
                <button type="button" data-dismiss="modal" class="btn btn-primary" (click)="atualizarLocalizacao()">Confirmar</button>
            </div>
        </div>
    </div>
</div>   

<!--Modal Footer-->
<div id="md-baixar" tabindex="-1" role="dialog" style="" class="modal fade colored-header colored-header-warning">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
              <button type="button" data-dismiss="modal" aria-hidden="true" class="close md-close"><span class="mdi mdi-close"></span></button>
              <h3 class="modal-title">Baixar Material</h3>
            </div>
            <form #materialBaixaForm="ngForm" [formGroup]="formBaixa" novalidate="" class="form-horizontal group-border-dashed">
              <div class="modal-body">
                <div class="form-group xs-mt-10">
                  <div class="col-sm-12">
                      <label>Justificativa para baixa <span class="obrigatorio">*</span> </label>
                      <textarea cols="10" rows="5" placeholder="Descreva uma justificativa para a baixa deste material" class="form-control" formControlName="observacao_baixa"></textarea>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                  <button type="button" data-dismiss="modal" class="btn btn-default">Cancelar</button>
                  <button type="button" data-dismiss="modal" class="btn btn-warning" (click)="baixar()">Confirmar</button>
              </div>
            </form>
        </div>
    </div>
</div>   

<div id="md-footer-restaurar" tabindex="-1" role="dialog" style="" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
            </div>
            <form novalidate="" class="form-horizontal group-border-dashed">
              <div class="modal-body">
                <div class="form-group xs-mt-10">
                  <div class="col-sm-12">
                      <label>Confirme a localização atual do material <span class="obrigatorio">*</span> </label>
                      <select class="form-control" (change)="definirCodigoLocalizacao($event.target.value)">
                        <option>Selecione</option>
                        <option *ngFor="let dado of localizacoes;" [value]="dado.codigo_localizacao">
                          {{dado.nome}}
                        </option>
                      </select>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                  <button type="button" data-dismiss="modal" class="btn btn-default">Cancelar</button>
                  <button type="button" data-dismiss="modal" class="btn btn-warning" (click)="restaurar()">Confirmar</button>
              </div>
            </form>
        </div>
    </div>
</div>

<app-footer></app-footer>