import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppComponent } from 'src/app/app.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { NotifierService } from 'angular-notifier';
import { AssociadoService } from 'src/app/core/services/associado.service';
import { Associado } from 'src/app/shared/models/associado';
import { UfService } from 'src/app/core/services/uf.service';
import { Uf } from 'src/app/shared/models/uf';
import { Lotacao } from 'src/app/shared/models/lotacao';
import { LotacaoService } from 'src/app/core/services/lotacao.service';
import { TitulacaoAcademica } from 'src/app/shared/models/titulacao-academica';
import { TitulacaoAcademicaService } from 'src/app/core/services/titulacao_academica.service';
import { FormacaoAcademica } from 'src/app/shared/models/formacao-academica';
import { FormacaoAcademicaService } from 'src/app/core/services/formacao-academica.service';
import { FormacaoAcademicaAssociadoService } from 'src/app/core/services/formacao-academica-associado.service';
import { FormacaoAcademicaAssociado } from 'src/app/shared/models/formacao-academica-associado';
import { AreaAtuacaoAssociadoService } from 'src/app/core/services/area-atuacao-associado.service';
import { AreaAtuacaoService } from 'src/app/core/services/area-atuacao.service';
import { AreaAtuacao } from 'src/app/shared/models/area-atuacao';
import { AreaAtuacaoAssociado } from 'src/app/shared/models/area-atuacao-associado';
import { DependenteAssociado } from 'src/app/shared/models/dependente-associado';
import { DependenteAssociadoService } from 'src/app/core/services/dependente-associado.service';
import { AreaAtuacaoPericiaParticular } from 'src/app/shared/models/area-atuacao-pericia-particular';
import { AreaAtuacaoPericiaParticularService } from 'src/app/core/services/area-atuacao-pericia-particular.service';
import { FormValidation } from 'src/app/shared/validation/form-validation';
import { Cep } from 'src/app/shared/models/cep';
import { DatePipe } from '@angular/common'; //import do datePipe
declare var $:JQueryStatic;

@Component({
  selector: 'app-dados-complementares',
  templateUrl: './dados-complementares.component.html',
  styleUrls: ['./dados-complementares.component.css'],
  providers: [DatePipe] //DatePipe como provider
})
export class DadosComplementaresComponent implements OnInit {

  @ViewChild('associadoForm') formValues;
  @ViewChild('enderecoForm') formEnderecoValues;
  @ViewChild('contatoForm') formContatoValues;
  @ViewChild('formacaoAcademicaAssociadoForm') formFormacaoAcademicaValues;
  @ViewChild('areaAtuacaoAssociadoForm') formAreaAtuacaoValues;
  @ViewChild('areaAtuacaoPericiaParticularForm') formAreaAtuacaoPericiaParticularValues;
  @ViewChild('periciaForm') formPericiaValues;
  @ViewChild('dependenteAssociadoForm') formDependenteValues;

  form: FormGroup;
  formContato: FormGroup;
  formFormacaoAcademica: FormGroup;
  formAreaAtuacao: FormGroup;
  formDependente: FormGroup;
  formPericia: FormGroup;
  formAreaAtuacaoPericiaParticular: FormGroup;
  formProfissional: FormGroup;
  breadCrumb;
  Ufs: Uf;
  Lotacoes: Lotacao;
  TitulacaoAcademica: TitulacaoAcademica;
  FormacaoAcademica: FormacaoAcademica;
  FormacaoAcademicaAssociado: FormacaoAcademicaAssociado;
  carregando: boolean = false;
  codigo_pessoa: number;
  preencher_correspondencia: string;
  email: string;
  email_secundario: string;
  celular_1: string;
  sms_1: string;
  celular_2: string;  
  sms_2: string;
  newsletter_1: string;
  newsletter_2: string;
  aderiu_funpresp: string;
  egresso_policia_civil: string;
  egresso_policia_militar: string;
  egresso_bombeiro: string;
  egresso_forcas_armadas: string;
  exerce_pericia_particular: string;
  autoriza_disponibilizar_contato: string;
  codigo_formacao_academica: number;
  codigo_associado_formacao_academica: number;
  codigo_associado_dependente: number;
  formacao_academica: FormacaoAcademica;
  areaAtuacao: AreaAtuacao;
  AreaAtuacaoAssociado: AreaAtuacaoAssociado;
  areaAtuacaoPericiaParticular: AreaAtuacaoPericiaParticular;
  dependentesAssociado: DependenteAssociado;
  atualizarTabela: boolean;
  codigo_pericia_particular_area_atuacao: number;
  cep: string;
  submited_contato;
  submited_endereco;
  codigo_associado_area_atuacao: any;
  prioridade_nova: any;
  prioridade: string;
  tipo_associado: string;
  carregando_cep: boolean;
  cep_nao_encontrado: any;
  carregando_cep_correspondencia: boolean;
  cep_nao_encontrado_correspondencia: any;

  constructor(private appComponent: AppComponent,
              private formBuilder: FormBuilder,
              private routerParam: ActivatedRoute,
              private router: Router,
              private authenticationService: AuthenticationService,
              private datePipe: DatePipe,
              private associadoService: AssociadoService,
              private ufService: UfService,
              private lotacaoService: LotacaoService,
              private titulacaoAcademicaService: TitulacaoAcademicaService,
              private formacaoAcademicaService: FormacaoAcademicaService,
              private formacaoAcademicaAssociadoService: FormacaoAcademicaAssociadoService,
              private areaAtuacaoService: AreaAtuacaoService,
              private areaAtuacaoPericiaParticularService: AreaAtuacaoPericiaParticularService,
              private areaAtuacaoAssociadoService: AreaAtuacaoAssociadoService,
              private dependenteAssociadoService: DependenteAssociadoService,
              private notifierService: NotifierService) { 
              this.codigo_pessoa = this.routerParam.snapshot.params.id;
  }

  ngOnInit() {
    this.breadCrumb = [ 
      {nome: "Home", caminho: "/dashboard"},
      {nome: "Associados", caminho: "/associado-listar"},
      {nome: "Cadastro", caminho: "/dados-pessoais"},
    ];
    this.appComponent.setTitulo('Cadastro do Associado');
    this.appComponent.setBreadcrumb(this.breadCrumb);

    this.carregando = false;

    this.form = this.formBuilder.group({
      cep: [null, Validators.required],  
      endereco: [null, Validators.required],  
      complemento: [null],
      endereco_numero: [null],
      cidade: [null, Validators.required],
      bairro: [null, Validators.required],
      sigla_uf: [null, Validators.required],
      cep_correspondencia: [null],  
      endereco_correspondencia: [null],  
      complemento_correspondencia: [null],
      numero_correspondencia: [null],
      cidade_correspondencia: [null],
      bairro_correspondencia: [null],
      sigla_uf_correspondencia: [null],
    });

    this.formProfissional = this.formBuilder.group({
      codigo_formacao_academica: [null],
      codigo_titulacao_academica: [null],
      aderiu_funpresp: [null],
      codigo_lotacao: [null],
      data_ingresso_servico_publico: [null, FormValidation.dataValidator],
      egresso_policia_civil: [null],
      egresso_policia_militar: [null],
      egresso_bombeiro: [null],
      egresso_forcas_armadas: [null],
    });

    this.formContato = this.formBuilder.group({
      telefone_fixo: [null],
      telefone_comercial: [null],
      telefone_recado: [null],
      nome_recado: [null],
      celular_1: [null, Validators.required],
      celular_2: [null],
      email: [null, [Validators.required, FormValidation.EmailDpfValidator, FormValidation.EmailPermitidoValidator, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      email_secundario: [null, [FormValidation.EmailDpfValidator, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      email_institucional: [null, [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    });

    this.formFormacaoAcademica = this.formBuilder.group({
      codigo_formacao_academica: [null, Validators.required],
    });

    this.formAreaAtuacao = this.formBuilder.group({
      codigo_area_atuacao: [null, Validators.required],
    });

    this.formDependente = this.formBuilder.group({
      nome: [null, Validators.required],
      grau_parentesco: [null, Validators.required],
      prioridade: [null, Validators.required],
      data_nascimento: [null, [Validators.required, FormValidation.dataValidator]],
    });

    this.formPericia = this.formBuilder.group({
      exerce_pericia_particular: [null, Validators.required],
      autoriza_disponibilizar_contato: [null, Validators.required],
    });

    this.formAreaAtuacaoPericiaParticular = this.formBuilder.group({
      codigo_area_atuacao: [null, Validators.required],
    });

    this.obterUf();
    this.obterLotacao();
    this.obterTitulacaoAcademica();
    this.obterFormacaoAcademica();
    this.obterFormacaoAcademicaAssociado();
    this.obterAreaAtuacao();
    this.obterAreaAtuacaoAssociado();
    this.obterAreaAtuacaoPericiaParticular();
    this.obterDependenteAssociado();

    if(this.codigo_pessoa) {
      this.obter();
    }
    this.carregando = true;
  }

  obter(): void {
    this.associadoService.find(this.codigo_pessoa).subscribe((data: Associado) => {
      this.form.patchValue({
        cep: data.pessoa.cep,
        endereco: data.pessoa.endereco,
        complemento: data.pessoa.complemento,
        endereco_numero: data.pessoa.endereco_numero,
        cidade: data.pessoa.cidade,
        bairro: data.pessoa.bairro,
        sigla_uf: data.pessoa.sigla_uf,
      });
      this.formProfissional.patchValue({
        codigo_formacao_academica: data.codigo_formacao_academica,
        codigo_titulacao_academica: data.codigo_titulacao_academica,
        codigo_lotacao: data.codigo_lotacao,
        data_ingresso_servico_publico: this.datePipe.transform(data.data_ingresso_servico_publico, 'dd/MM/yyyy'),
        aderiu_funpresp: data.aderiu_funpresp,
        egresso_policia_civil: data.egresso_policia_civil,
        egresso_policia_militar: data.egresso_policia_militar,
        egresso_bombeiro: data.egresso_bombeiro,
        egresso_forcas_armadas: data.egresso_forcas_armadas,
        exerce_pericia_particular: data.exerce_pericia_particular,
        autoriza_disponibilizar_contato: data.autoriza_disponibilizar_contato,
      });
      this.formContato.patchValue({
        telefone_fixo: data.pessoa.telefone_fixo,
        telefone_comercial: data.pessoa.telefone_comercial,
        telefone_recado: data.pessoa.telefone_recado,
        nome_recado: data.pessoa.nome_recado,
        celular_1: data.pessoa.celular_1,
        celular_2: data.pessoa.celular_2,
        email: data.pessoa.email,
        email_secundario: data.pessoa.email_secundario,
        email_institucional: data.pessoa.email_institucional,
      });
      if(data.pessoa_endereco) {
        this.preencher_correspondencia = data.pessoa_endereco.cep_correspondencia;
        this.form.patchValue({
          cep_correspondencia: data.pessoa_endereco.cep_correspondencia,
          endereco_correspondencia: data.pessoa_endereco.endereco_correspondencia,
          complemento_correspondencia: data.pessoa_endereco.complemento_correspondencia,
          numero_correspondencia: data.pessoa_endereco.numero_correspondencia,
          cidade_correspondencia: data.pessoa_endereco.cidade_correspondencia,
          bairro_correspondencia: data.pessoa_endereco.bairro_correspondencia,
          sigla_uf_correspondencia: data.pessoa_endereco.sigla_uf_correspondencia
        });
      }

      this.formPericia.patchValue({
        exerce_pericia_particular: data.exerce_pericia_particular,
        autoriza_disponibilizar_contato: data.autoriza_disponibilizar_contato,
      });

      this.tipo_associado = data.tipo;
      this.cep = data.pessoa.cep;
      this.email = data.pessoa.email;
      this.email_secundario = data.pessoa.email_secundario;
      this.sms_1 = data.pessoa.celular_1_sms;
      this.celular_1 = data.pessoa.celular_1;
      this.sms_2 = data.pessoa.celular_2_sms;
      this.celular_2 = data.pessoa.celular_2;
      this.newsletter_1 = data.pessoa.email_newsletter;
      this.newsletter_2 = data.pessoa.email_secundario_newsletter;

    });
  }

  definirEmailSecundario(valor): void {
    if(valor) {
      this.email_secundario = 'S';
    }
  }
  
  definirPreechimento():void {
    if(this.preencher_correspondencia == 'S') {
      this.preencher_correspondencia = 'N';
      this.form.patchValue({
        cep_correspondencia: null,
        endereco_correspondencia: null,
        complemento_correspondencia: null,
        cidade_correspondencia: null,
        bairro_correspondencia: null,
        sigla_uf_correspondencia: null
      });
    } else {
      this.preencher_correspondencia = 'S';

      const cepCorrespondenciaControl = this.form.get('cep_correspondencia');
      const enderecoCorrespondenciaControl = this.form.get('endereco_correspondencia');
      const cidadeCorrespondenciaControl = this.form.get('cidade_correspondencia');
      const bairroCorrespondenciaControl = this.form.get('bairro_correspondencia');
      const siglaUfCorrespondenciaControl = this.form.get('sigla_uf_correspondencia');
      
      cepCorrespondenciaControl.setValidators(Validators.required);
      enderecoCorrespondenciaControl.setValidators(Validators.required);
      cidadeCorrespondenciaControl.setValidators(Validators.required);
      bairroCorrespondenciaControl.setValidators(Validators.required);
      siglaUfCorrespondenciaControl.setValidators(Validators.required);

      cepCorrespondenciaControl.updateValueAndValidity();
      enderecoCorrespondenciaControl.updateValueAndValidity();
      cidadeCorrespondenciaControl.updateValueAndValidity();
      bairroCorrespondenciaControl.updateValueAndValidity();
      siglaUfCorrespondenciaControl.updateValueAndValidity();

    }
    console.log(this.preencher_correspondencia);      
  }

  definirSMS1(valor):void {
    if(valor == 'S') {
      this.sms_1 = 'S';
      this.sms_2 = 'N';
    } else if(valor == 'N') {
      this.sms_1 = 'N';
    }
  }

  definirSMS2(valor):void {
    if(valor == 'S') {
      this.sms_2 = 'S';
      this.sms_1 = 'N';
    } else if(valor == 'N') {
      this.sms_2 = 'N';
    }
  }

  definirNewsLetter1(valor):void {
    if(valor == 'S') {
      this.newsletter_1 = 'S';
      this.newsletter_2 = 'N';
    } else if(valor == 'N') {
      this.newsletter_1 = 'N';
    }
  }

  definirNewsLetter2(valor):void {
    if(valor == 'S') {
      this.newsletter_2 = 'S';
      this.newsletter_1 = 'N';
    } else if(valor == 'N') {
      this.newsletter_2 = 'N';
    }
  }

  definirCodigoFormacaoAcademicaAssociado(codigo):void {
    this.codigo_associado_formacao_academica = codigo;
  }

  definirCodigoDependenteAssociado(codigo):void {
    this.codigo_associado_dependente = codigo;
  }

  definirCodigoAreaAtuacaoPericia(codigo):void {
    this.codigo_pericia_particular_area_atuacao = codigo;
  }

  definirCodigoAreaAtuacaoAssociado(codigo):void {
    this.codigo_associado_area_atuacao = codigo;
  }

  obterEnderecoCep(cep, correspondencia) {
    //this.formEnderecoValues.resetForm();
    if(!correspondencia) {
      this.carregando_cep = true;
      this.form.patchValue({
        cep: cep
      });
    } else {
      this.carregando_cep_correspondencia = true;
      this.form.patchValue({
        cep_correspondencia: cep
      });
    }
    let cep_inteiro = cep.replace(/\D+/g, "");
    this.associadoService.buscaCep(cep_inteiro).subscribe((data: Cep) => 
    { 
      if(!correspondencia) {
        if(data[0]) {
          this.form.patchValue({
            cep: data[0].co_cep,
            endereco: data[0].no_logradouro,
            complemento: data[0].tx_complemento,
            cidade: data[0].no_localidade,
            bairro: data[0].no_bairro,
            sigla_uf: data[0].sg_uf_localidade
          });
          this.carregando_cep = false;
          this.cep_nao_encontrado = null;
        } else {
          this.cep_nao_encontrado = "CEP não encontrado na base, informe o endereço manualmente";
          this.carregando_cep = false;
        }
      } else {
        if(data[0]) {
          this.form.patchValue({
            cep_correspondencia: data[0].co_cep,
            endereco_correspondencia: data[0].no_logradouro,
            complemento_correspondencia: data[0].tx_complemento,
            cidade_correspondencia: data[0].no_localidade,
            bairro_correspondencia: data[0].no_bairro,
            sigla_uf_correspondencia: data[0].sg_uf_localidade
          });
          this.carregando_cep_correspondencia = false;
          this.cep_nao_encontrado_correspondencia = null;
        } else {
          this.cep_nao_encontrado_correspondencia = "CEP não encontrado na base, informe o endereço manualmente";
          this.carregando_cep_correspondencia = false;
        }
      }
    });
  }

  obterUf() {
    this.ufService.list()
    .subscribe((data: Uf) => this.Ufs = data,
                error => console.log(error));
                
  }

  obterLotacao() {
    this.lotacaoService.list()
    .subscribe((data: Lotacao) => this.Lotacoes = data,
                error => console.log(error));
  }

  obterTitulacaoAcademica() {
    this.titulacaoAcademicaService.list()
    .subscribe((data: TitulacaoAcademica) => this.TitulacaoAcademica = data,
                error => console.log(error));
  }

  obterFormacaoAcademica() {
    this.formacaoAcademicaService.list(this.codigo_pessoa)
    .subscribe((data: FormacaoAcademica) => this.FormacaoAcademica = data,
                error => console.log(error));
  }

  obterAreaAtuacao() {
    this.areaAtuacaoService.list(this.codigo_pessoa)
    .subscribe((data: AreaAtuacao) => {
      this.areaAtuacao = data;
    }, error => console.log(error));
  }

  obterFormacaoAcademicaAssociado() {
    this.formacaoAcademicaAssociadoService.list(this.codigo_pessoa)
    .subscribe((data: FormacaoAcademicaAssociado) => {
      this.FormacaoAcademicaAssociado = data;
      console.log(data);
    },
                error => console.log(error));
  }

  obterAreaAtuacaoAssociado() {
    this.areaAtuacaoAssociadoService.list(this.codigo_pessoa)
    .subscribe((data: AreaAtuacaoAssociado) => {
      this.AreaAtuacaoAssociado = data;
      console.log(data);
    },
      error => console.log(error)
    );
  }

  obterAreaAtuacaoPericiaParticular() {
    this.areaAtuacaoPericiaParticularService.list(this.codigo_pessoa)
    .subscribe((data: AreaAtuacaoPericiaParticular) => {
      this.areaAtuacaoPericiaParticular = data;
    },
                error => console.log(error));
  }

  obterDependenteAssociado() {
    this.dependenteAssociadoService.list(this.codigo_pessoa)
    .subscribe((data: DependenteAssociado) => {
      this.atualizarTabela = false;
      return this.dependentesAssociado = data;
    },
                error => console.log(error));
  }

  obterDependente(codigo_associado_dependente) {
    this.dependenteAssociadoService.find(codigo_associado_dependente)
    .subscribe((data: DependenteAssociado) => {
      this.codigo_associado_dependente = data.codigo_associado_dependente;      
      this.prioridade = data.prioridade;
      this.formDependente.patchValue({
        nome: data.nome,
        grau_parentesco: data.grau_parentesco,
        prioridade: data.prioridade,
        data_nascimento: this.datePipe.transform(data.data_nascimento, 'dd/MM/yyyy'),
      });
      this.atualizarTabela = false;
      this.prioridade_nova = '';
    },
                error => console.log(error));
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }
  get fContato() { return this.formContato.controls; }
  get fProfissional() { return this.formProfissional.controls; }
  get fDependente() { return this.formDependente.controls; }

  limparFormacaoAcademica(): void {
    this.formFormacaoAcademicaValues.resetForm();
  }

  incluirFormacaoAcademica(): void {
    let formacaoAcademicaAssociado = this.formFormacaoAcademica.value as FormacaoAcademicaAssociado;
    formacaoAcademicaAssociado.codigo_pessoa = this.codigo_pessoa;
    this.formacaoAcademicaAssociadoService.save(formacaoAcademicaAssociado).subscribe((data) => {
      this.obterFormacaoAcademicaAssociado();
      this.obterFormacaoAcademica();
      this.limparFormacaoAcademica();
      this.notifierService.notify( 'success', 'Dados de formação acadêmica incluídos com sucesso.' );
    }, (error) => {
      this.notifierService.notify( 'error', 'Não foi possível atualizar dados de formação acadêmica, pois ocorreu um erro. ' + error );
    });
  }

  limparFormAreaAtuacao(): void {
    this.formAreaAtuacaoValues.resetForm();
  }

  incluirAreaAtuacao(): void {
    let areaAtuacaoAssociado = this.formAreaAtuacao.value as AreaAtuacaoAssociado;
    areaAtuacaoAssociado.codigo_pessoa = this.codigo_pessoa;
    this.areaAtuacaoAssociadoService.save(areaAtuacaoAssociado).subscribe((data) => {
      this.obterAreaAtuacaoAssociado();
      this.obterAreaAtuacao();
      this.limparFormAreaAtuacao();
      this.notifierService.notify( 'success', 'Dados de área de atuação incluídos com sucesso.' );
    }, (error) => {
      this.notifierService.notify( 'error', 'Não foi possível atualizar dados de área de atuação, pois ocorreu um erro. ' + error );
    });
  }

  limparFormAreaAtuacaoPericiaParticular(): void {
    this.formAreaAtuacaoPericiaParticularValues.resetForm();
  }

  incluirAreaAtuacaoPericiaParticular(): void {
    this.atualizarTabela = true;
    let areaAtuacaoPericiaParticular = this.formAreaAtuacaoPericiaParticular.value as AreaAtuacaoPericiaParticular;
    areaAtuacaoPericiaParticular.codigo_pessoa = this.codigo_pessoa;
    this.areaAtuacaoPericiaParticularService.save(areaAtuacaoPericiaParticular).subscribe((data) => {
      this.obterAreaAtuacaoPericiaParticular();
      this.limparFormAreaAtuacaoPericiaParticular();
      this.notifierService.notify( 'success', 'Dados de área de atuação de perícia particular incluídos com sucesso.' );
    }, (error) => {
      this.notifierService.notify( 'error', 'Não foi possível atualizar dados de área de atuação, pois ocorreu um erro. ' + error );
    });
  }

  limparFormDependente(): void {
    this.codigo_associado_dependente = null;
    this.formDependenteValues.resetForm();
  }

  definirPrioridadeNova(prioridade_nova) {
    this.prioridade_nova = prioridade_nova;
  }

  apagarPrioridadeNova() {
    this.prioridade_nova = null;
  }

  incluirDependente(apagar_prioridade_nova = null): void {
    
    this.atualizarTabela = true;
    let dependente = this.formDependente.value as DependenteAssociado;
    dependente.codigo_pessoa = this.codigo_pessoa;
    dependente.codigo_associado_dependente = this.codigo_associado_dependente;
    if(apagar_prioridade_nova) {
      this.prioridade_nova = null;
    } else {
      dependente.prioridade_nova = this.prioridade_nova;
    }
    if (this.formDependente.valid) {
      this.dependenteAssociadoService.save(dependente, this.codigo_associado_dependente).subscribe((data) => {
        if(this.codigo_associado_dependente) {
          this.notifierService.notify( 'success', 'Dados de dependente atualizados com sucesso.');
        } else {
          this.notifierService.notify( 'success', 'Dependente adicionado com sucesso.');
        }
        this.obterDependenteAssociado();
        this.limparFormDependente();
        this.codigo_associado_dependente = null;
        this.prioridade_nova = null;
        $('body').removeClass('modal-open');        
        $('body').css('padding-right', '');
        $(".modal-backdrop").remove();
        $('#form-cadastro-dependente').modal('hide');
      }, (error) => {
        this.notifierService.notify( 'error', error );
        this.obterDependenteAssociado();
      });
    } else {
      this.notifierService.notify( 'error', 'Verifique os erros no preenchimento do formulário.');
    }
  }

  onSubmit(): void {
    this.submited_endereco = true;
    let associado = this.form.value as Associado;
    associado.codigo_pessoa = this.codigo_pessoa;
    if (this.form.valid) {
      this.associadoService.save(associado, this.codigo_pessoa).subscribe((data) => {
        this.notifierService.notify( 'success', 'Dados de endereço atualizados com sucesso.' );
      }, (error) => {
        this.notifierService.notify( 'error', 'Não foi possível atualizar dados de endereço, pois ocorreu um erro. ' + error );
      });
    } else {      
      this.notifierService.notify( 'error', 'Verifique os erros no preenchimento do formulário.');
    }
  }

  onSubmitContato(): void {
    this.submited_contato = true;
    let associado = this.formContato.value as Associado;   
    if (this.formContato.valid) {
      associado.codigo_pessoa = this.codigo_pessoa;
      associado.celular_1_sms = this.sms_1;
      associado.celular_2_sms = this.sms_2;
      associado.email_newsletter = this.newsletter_1;
      associado.email_secundario_newsletter = this.newsletter_2;
      this.associadoService.save(associado, this.codigo_pessoa).subscribe((data) => {
        this.notifierService.notify( 'success', 'Dados de contato atualizados com sucesso.');
      }, (error) => {      
        this.notifierService.notify( 'error', error );
      });
    } else {
      console.log(this.formContato.controls);
      this.notifierService.notify( 'error', 'Verifique os erros no preenchimento do formulário.');
    }
  }

  onSubmitProfissional(): void {
    let associado = this.formProfissional.value as Associado;    
    associado.codigo_pessoa = this.codigo_pessoa;
    if (this.formProfissional.valid) {
      this.associadoService.save(associado, this.codigo_pessoa).subscribe((data) => {
        this.notifierService.notify( 'success', 'Dados profissionais atualizados com sucesso.');
      }, (error) => {
        this.notifierService.notify( 'error', 'Não foi possível atualizar Dados Profissionais, pois ocorreu um erro. ' + error );
      });
    } else {
      console.log(this.fProfissional.controls);
      this.notifierService.notify( 'error', 'Verifique os erros no preenchimento do formulário.');
    }
  }

  onSubmitPericia(): void {
    let associado = this.formPericia.value as Associado;
    associado.codigo_pessoa = this.codigo_pessoa;
    this.associadoService.save(associado, this.codigo_pessoa).subscribe((data) => {
      this.notifierService.notify( 'success', 'Dados de perícia particular atualizados com sucesso.');
    }, (error) => {
      this.notifierService.notify( 'error', 'Não foi possível atualizar Dados Profissionais, pois ocorreu um erro. ' + error );
    });
  }

  apagarFormacaoAcademica(codigo_associado_formacao_academica): void {
    this.formacaoAcademicaAssociadoService.delete(codigo_associado_formacao_academica).subscribe(() => {
      this.notifierService.notify( 'success', 'Registro deletado com sucesso.' );
      this.obterFormacaoAcademicaAssociado();
    }, (error) => {
      this.notifierService.notify( 'danger', 'Não foi possível deletar o registro, ocorreu um erro. ' + error );
    });
  }

  apagarDependente(codigo_dependente): void {
    this.dependenteAssociadoService.delete(codigo_dependente).subscribe(() => {
      this.notifierService.notify( 'success', 'Registro deletado com sucesso.' );
      this.obterDependenteAssociado();
    }, (error) => {
      this.notifierService.notify( 'danger', 'Não foi possível deletar o registro, ocorreu um erro. ' + error );
    });
  }

  excluirFormacaoAcademica():void {
    this.formacaoAcademicaAssociadoService.delete((data) => {

    });
  }

  apagarAreaAtuacao(codigo_associado_area_atuacao): void {
    this.areaAtuacaoAssociadoService.delete(codigo_associado_area_atuacao).subscribe(() => {
      this.obterAreaAtuacaoAssociado();
      this.notifierService.notify( 'success', 'Registro deletado com sucesso.' );
    }, (error) => {
      this.notifierService.notify( 'danger', 'Não foi possível deletar o registro, ocorreu um erro. ' + error );
    });
  }

  apagarAreaAtuacaoPericiaParticular(codigo_pericia_particular_area_atuacao): void {
      this.areaAtuacaoPericiaParticularService.delete(codigo_pericia_particular_area_atuacao).subscribe(() => {
      this.obterAreaAtuacaoPericiaParticular();
      this.notifierService.notify( 'success', 'Registro deletado com sucesso.' );
    }, (error) => {
      this.notifierService.notify( 'danger', 'Não foi possível deletar o registro, ocorreu um erro. ' + error );
    });
  }

  excluir():void {
    this.areaAtuacaoAssociadoService.delete((data) => {

    });
  }

}
