import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { AppComponent } from 'src/app/app.component';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { DemandaTipo } from 'src/app/shared/models/demanda-tipo';
import { DemandaTipoService } from 'src/app/core/services/demanda-tipo.service';

@Component({
    selector: 'app-demanda-tipo-editar',
    templateUrl: './demanda-tipo-editar.component.html',
    styleUrls: ['./demanda-tipo-editar.component.css'],
})
export class DemandaTipoEditarComponent implements OnInit {
    @ViewChild('demandaTipoForm') formValues;

    form: FormGroup;
    breadCrumb;
    demandas_tipo: DemandaTipo;
    carregando: boolean;
    submited: boolean;
    codigo_demanda_tipo: any;

    constructor(
        private appComponent: AppComponent,
        private formBuilder: FormBuilder,
        private routerParam: ActivatedRoute,
        private router: Router,
        private demandaTipoService: DemandaTipoService,
        private notifierService: NotifierService,
    ) {
        this.codigo_demanda_tipo = this.routerParam.snapshot.params.id;
    }

    ngOnInit() {
        this.carregando = true;
        this.breadCrumb = [
            { nome: 'Configuração', caminho: '/configuracao' },
            { nome: 'demandas_tipo', caminho: '/demanda-tipo-listar' },
        ];
        if (this.codigo_demanda_tipo) {
            this.appComponent.setTitulo('Alteração de demanda tipo');
        } else {
            this.appComponent.setTitulo('Cadastro de demanda tipo');
        }
        this.appComponent.setBreadcrumb(this.breadCrumb);

        this.form = this.formBuilder.group({
            codigo_demanda_tipo: [null],
            nome: [null, Validators.required],
        });

        this.obter();
    }

    obter(): void {
        this.demandaTipoService
            .find(this.codigo_demanda_tipo)
            .subscribe((data: DemandaTipo) => {
                this.form.patchValue({
                    codigo_demanda_tipo: data.codigo_demanda_tipo,
                    nome: data.nome,
                });
            });
    }

    onSubmit(): void {
        this.submited = true;
        this.carregando = true;
        const demanda_tipo = this.form.value as DemandaTipo;
        if (this.form.valid) {
            this.demandaTipoService
                .save(demanda_tipo, this.codigo_demanda_tipo)
                .subscribe(
                    (data) => {
                        this.notifierService.notify(
                            'success',
                            'Demanda tipo cadastrada com sucesso.',
                        );
                        this.carregando = false;
                        this.router.navigate(['demanda-tipo-listar']);
                    },
                    (error) => {
                        this.notifierService.notify(
                            'error',
                            'Não foi cadastrar uma nova Demanda Tipo. ' + error,
                        );
                        this.carregando = false;
                    },
                );
        } else {
            console.log(this.form);
            this.notifierService.notify(
                'error',
                'Existem erros no preenchimento do formulário.',
            );
            this.carregando = false;
        }
    }
}
