import { Component } from '@angular/core';
import { AuthenticationService } from './core/services/authentication.service';

import * as bootstrap from "bootstrap";
import * as $ from "jquery";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  user: any;
  me: any;

  constructor(private authenticationService: AuthenticationService) {}

  ngOnInit() {
    
  }

  titulo: string;
  breadcrumbs: any = [];

  setTitulo(titulo) {
    this.titulo = titulo;
  }

  setBreadcrumb(breadcrumb) {   
    this.breadcrumbs = breadcrumb;
  }

  logout() {
    this.authenticationService.logout();
  }

}
