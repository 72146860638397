import { Component, OnInit } from '@angular/core';
import {TipoLayout} from "../../../shared/models/tipo-layout";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Schema} from "../../../shared/models/schema";
import {Tabela} from "../../../shared/models/tabela";
import {Campo} from "../../../shared/models/campo";
import {AppComponent} from "../../../app.component";
import {TipoLayoutService} from "../../../core/services/tipo-layout.service";
import {TabelaService} from "../../../core/services/tabela.service";
import {Router} from "@angular/router";
import {NotifierService} from "angular-notifier";
import {RodapeLayout} from "../../../shared/models/rodape-layout";
import {RodapeLayoutService} from "../../../core/services/rodape-layout.service";
import {CampoRodapeLayout} from "../../../shared/models/campo-rodape-layout";

@Component({
  selector: 'app-rodape-form',
  templateUrl: './rodape-form.component.html',
  styleUrls: ['./rodape-form.component.css']
})
export class RodapeFormComponent implements OnInit {

  public breadCrumb;
  public rodape_layout: RodapeLayout;
  public tipos_layout: Array<TipoLayout> = [];
  public form: FormGroup;
  public form_campos: FormGroup;
  // public schemas: Array<Schema> = [];
  // public tabelas: Array<Tabela> = [];
  // public campos: Array<Campo> = [];
  public existe_padrao: boolean = true;

  constructor(private appComponent: AppComponent,
              private rodapeLayoutService: RodapeLayoutService,
              private tipoLayoutService: TipoLayoutService,
              /*private tabelaService: TabelaService,*/
              private router: Router,
              private notifierService: NotifierService,) {

  }

  ngOnInit() {
    this.breadCrumb = [
      {nome: "Configuração", caminho: "/configuracao"},
      {nome: "Rodapé", caminho: "/layout/rodape"}
    ];
    this.appComponent.setTitulo('Rodapé');
    this.appComponent.setBreadcrumb(this.breadCrumb);
    this.rodape_layout = new RodapeLayout();
    this.form = new FormGroup({
      codigo_tipo_layout: new FormControl("", [
        Validators.required,
      ]),
    });
    this.form_campos = new FormGroup({
      sigla: new FormControl("", []),
      descricao: new FormControl("", []),
      // schema: new FormControl("", []),
      // tabela: new FormControl("", []),
      // campo: new FormControl("", []),
      sql: new FormControl("", []),
      quantidade_caracteres: new FormControl(null, []),
      padrao: new FormControl("", []),
      ordem: new FormControl("", []),
    });
    // this.form_campos.get('schema').disable();
    // this.form_campos.get('tabela').disable();
    // this.form_campos.get('campo').disable();
    this.form_campos.get('sql').disable();
    this.listarTipoLayout();
    // this.listarSchemas();
  }

  public listarTipoLayout(): void{
    this.tipoLayoutService.list().subscribe((tipos_layout: Array<TipoLayout>) => {
      this.tipos_layout = tipos_layout;
    });
  }

  // public listarSchemas(): void{
  //   this.tabelaService.listSchemas().subscribe((schemas: Array<Schema>) => {
  //     this.schemas = schemas;
  //   });
  // }
  //
  // public listarTabelas(schema): void{
  //   this.tabelaService.listTabelas(schema).subscribe((tabelas: Array<Tabela>) => {
  //     this.tabelas = tabelas;
  //   });
  // }
  //
  // public listarCampos(tabela, schema): void{
  //   this.tabelaService.listCampos(tabela, schema).subscribe((campos: Array<Campo>) => {
  //     this.campos = campos;
  //   });
  // }

  save(): void {
    this.rodape_layout.codigo_tipo_layout = this.form.get('codigo_tipo_layout').value;
    let message: string = "";
    if(!this.form.get('codigo_tipo_layout').value) {
      message = "Informe o tipo de layout";
    } else if(this.rodape_layout.campos_rodape_layout.length == 0) {
      message = "Informe pelo menos um campo";
    } else {
      this.rodapeLayoutService.save(this.rodape_layout).subscribe((data: any) => {
        this.notifierService.notify('success', 'Rodapé cadastrado com sucesso');
        this.router.navigate(['layout/rodape']);
      }, (error) => {
        this.notifierService.notify('error', 'Ocorreu um erro ao cadastrar o rodapé, tente novamente');
      });
    }
    if(message){
      this.notifierService.notify('error', message);
    }
  }

  prepararCampos(): void{
    this.form_campos.patchValue({
      sigla: "",
      descricao: "",
      // schema: "",
      // tabela: "",
      // campo: "",
      sql: "",
      quantidade_caracteres: "",
      padrao: "",
      ordem: "",
    });
    // this.form_campos.get('schema').disable();
    // this.form_campos.get('tabela').disable();
    // this.form_campos.get('campo').disable();
    this.form_campos.get('sql').disable();
    this.form_campos.get('padrao').enable();
    this.existe_padrao = true;
  }

  adicionarCampo(): void{
    let campo_rodape_layout: CampoRodapeLayout = new CampoRodapeLayout();
    let msg: Array<string> = [];
    campo_rodape_layout.quantidade_caracteres = this.form_campos.get('quantidade_caracteres').value;
    campo_rodape_layout.descricao = this.form_campos.get('descricao').value;
    campo_rodape_layout.sigla = this.form_campos.get('sigla').value;
    if(!this.form_campos.get('quantidade_caracteres').value){
      msg.push("Informe a quantidade de caracteres");
    }else if(this.existe_padrao && (!this.form_campos.get('padrao').value || this.form_campos.get('quantidade_caracteres').value != this.form_campos.get('padrao').value.length)){
      msg.push('A quantidade de caracteres do padrão não está igual a quantidade de caracteres informada');
    } else if(!this.existe_padrao && !this.form_campos.get('sql').value){
      msg.push("Informe o campo sql");
    // } else if(!this.existe_padrao && (!this.form_campos.get('schema').value || !this.form_campos.get('tabela').value || !this.form_campos.get('campo').value)){
    //   msg.push("Informe os campos schema, tabela e campo");
    }
    if(msg.length > 0){
      msg.forEach((message: string) => {
        this.notifierService.notify('error', message);
      });
    } else {
      if (this.form_campos.get('padrao').value) {
        campo_rodape_layout.padrao = this.form_campos.get('padrao').value;
        // campo_rodape_layout.schema = "";
        // campo_rodape_layout.tabela = "";
        // campo_rodape_layout.campo = "";
        campo_rodape_layout.sql = "";
      } else {
        campo_rodape_layout.padrao = "";
        // campo_rodape_layout.schema = this.form_campos.get('schema').value;
        // campo_rodape_layout.tabela = this.form_campos.get('tabela').value;
        // campo_rodape_layout.campo = this.form_campos.get('campo').value;
        campo_rodape_layout.sql = this.form_campos.get('sql').value;
      }
      this.rodape_layout.campos_rodape_layout.push(campo_rodape_layout);
      $('#md-layout-form').modal('hide');
    }
  }

  removerLinha(i: number): void{
    let campos_rodape_layout: Array<CampoRodapeLayout> = [];
    if(this.rodape_layout.campos_rodape_layout.length > 0){
      this.rodape_layout.campos_rodape_layout.forEach((campo_rodape_layout: CampoRodapeLayout, index) => {
        if(index != i) {
          campos_rodape_layout.push(campo_rodape_layout);
        }
      });
    }
    this.rodape_layout.campos_rodape_layout = campos_rodape_layout;
  }

  definirCampoPadrao(): void{
    this.existe_padrao = !this.existe_padrao;
    if(this.existe_padrao){
      // this.form_campos.get('schema').disable();
      // this.form_campos.get('tabela').disable();
      // this.form_campos.get('campo').disable();
      this.form_campos.get('sql').disable();
      this.form_campos.get('padrao').enable();
    }else{
      // this.form_campos.get('schema').enable();
      // this.form_campos.get('tabela').enable();
      // this.form_campos.get('campo').enable();
      this.form_campos.get('sql').enable();
      this.form_campos.get('padrao').disable();
    }
    this.form_campos.patchValue({
      padrao: "",
      // schema: "",
      // tabela: "",
      // campo: "",
      campo: "sql",
    });
  }

  voltar(): void{
    this.router.navigate(['layout/rodape']);
  }

}
