import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MaterialAnexoService {

  private _Url = environment.UrlPrincipal + '/api/materialanexo';
  private _UrlDownload = environment.UrlPrincipal + '/api/materialanexo-download';

  constructor(private http: HttpClient) {}

  list(codigo) {
    return this.http.get(this._Url + '?codigo_material=' + codigo)
                    .catch(this.handleError);
  }

  find(id) {
    return this.http.get(this._Url + '/' + id)
                    .catch(this.handleError);
  }

  save(dados, codigo): Observable<any> {
    let http;
    if (codigo) {
        http = this.http.put(this._Url + '/' + codigo, dados);
    } else {
        http = this.http.post(this._Url, dados);
    }
    http.map((res) => {
        return res;
    }).catch(this.handleError);
    return http;
  }

  delete(codigo) {
    return this.http.delete(this._Url + '/' + codigo);
  }

  downloadArquivo(codigo, filename: string = 'documento.docx'): void {
    const baseUrl = this._UrlDownload + "/" + codigo;
    this.http.get(baseUrl,{responseType: 'blob' as 'json'}).subscribe(
        (response: any) =>{
            let dataType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'; //response.type;
            let binaryData = [];
            binaryData.push(response);
            let downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            downloadLink.setAttribute('download', filename);
            document.body.appendChild(downloadLink);
            downloadLink.click();
        }
    )
  }


  private handleError(error: Response) {
    return error.json();
  }

}
