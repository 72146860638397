<app-header></app-header>

<div class="row">
    <div class="col-sm-12">
        <div class="panel panel-default">
            <br />
            <div class="row" style="padding: 15px;">
                <div class="col-md-9">
                    <span><b>DADOS DO ASSOCIADO</b></span>
                    <br />
                    <div *ngIf="carregando">
                        <p>Carregando...</p>
                    </div>
                    <div *ngIf="!carregando">
                        <span class="ficha-financeira-span"><b>Associado:</b>&nbsp;&nbsp;{{associado_nome}}</span>
                        <br />
                        <span class="ficha-financeira-span"><b>Matrícula PF:</b>&nbsp;&nbsp;<span *ngIf="associado_matricula_dpf != 'null'">{{associado_matricula_dpf}}</span><span *ngIf="associado_matricula_dpf == 'null'">-</span></span>
                        <br />
                        <span class="ficha-financeira-span"><b>Tipo de Associado:</b>&nbsp;&nbsp;<span *ngIf="associado_tipo == 'AT'"> Ativo</span><span *ngIf="associado_tipo == 'AP'"> Aposentado</span><span *ngIf="associado_tipo == 'PE'"> Pensionista</span></span>
                        <br />
                        <span class="ficha-financeira-span"><b>Associado APCF:</b>&nbsp;&nbsp;<span *ngIf="associado_apcf == '1'"> Filiado</span><span *ngIf="!associado_apcf || associado_apcf == '0'"> Desfiliado</span></span>
                        <br />
                        <span *ngIf="associado_tipo != 'PE'"><span class="ficha-financeira-span"><b>Associado APCF Sindical:</b>&nbsp;&nbsp;<span *ngIf="associado_apcf_sindical == '1'"> Filiado</span><span *ngIf="!associado_apcf_sindical || associado_apcf_sindical == '0'"> Desfiliado</span></span></span>
                    </div>
                </div>
                <div class="col-md-3">

                </div>
            </div>
            <br />
            <div class="panel-body">
                <div class="row">
                    <div class="tab-container">
                        <ul class="nav nav-tabs">
                            <li class="active"><a href="#apcf" data-toggle="tab">APCF</a></li>
                            <li *ngIf="associado_tipo != 'PE'"><a href="#apcf_sindical" data-toggle="tab">APCF Sindical</a></li>
                        </ul>
                        <div class="tab-content">
                            <div id="apcf" class="tab-pane active cont">
                                <button *ngIf="this.situacao_apcf == 'B'" data-toggle="modal" data-target="#formSituacaoApcf" (click)="limpar(); novoCadastro()" class="btn btn-space btn-success" tabindex="0" aria-controls="table3">Nova Adesão APCF</button>
                                <button *ngIf="this.situacao_apcf == 'A'" data-toggle="modal" data-target="#formSituacaoApcf" (click)="limpar(); novoCadastro()" class="btn btn-space btn-danger" tabindex="0" aria-controls="table3">Nova Baixa APCF</button>
                                <table id="table2" class="table table-striped table-hover table-fw-widget">
                                    <thead>
                                        <tr>
                                            <th class="text-center">Tipo</th>
                                            <th class="text-center">Situação</th>
                                            <th>Motivo</th>
                                            <th>Data</th>
                                            <th class="text-center">Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="associadoSituacoesApcf && associadoSituacoesApcf.length > 0">
                                        <tr class="odd gradeX" *ngFor="let associado_situacao of associadoSituacoesApcf; let i = index;">
                                            <td class="col-md-1 text-center"><span class="label label-primary">APCF</span></td>
                                            <td class="col-md-1 text-center"><span class="label label-danger" *ngIf="associado_situacao.situacao == 'B'">Baixa</span><span class="label label-success" *ngIf="associado_situacao.situacao == 'A'">Adesão</span></td>
                                            <td class="col-md-2">{{associado_situacao.situacaotipo.nome}}</td>
                                            <td class="col-md-6">{{associado_situacao.data_ocorrencia | date: 'dd/MM/yyyy'}}</td>
                                            <td class="col-md-2 text-center" style="font-size: 22px;">
                                                <span *ngIf="i==0">
                                                    <a data-toggle="modal" data-target="#formSituacaoApcf" (click)="carregar(associado_situacao.codigo_associado_situacao);definirCodigo(associado_situacao.codigo_associado_situacao)"><span class="mdi mdi-edit"></span></a>
                                                    &nbsp;
                                                    <a data-toggle="modal" *ngIf="associadoSituacoesApcf.length > 1" data-target="#md-footer-delete" (click)="definirCodigo(associado_situacao.codigo_associado_situacao)"><span class="icon mdi mdi-delete"></span></a>
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="!associadoSituacoesApcf">
                                        <tr class="carregando">
                                            <td class="carregando" colspan="7">
                                                <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="associadoSituacoesApcf && associadoSituacoesApcf.length == 0">
                                        <tr>
                                            <td class="col-md-12" colspan="7">
                                                Nenhum registro encontrado.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div id="apcf_sindical" class="tab-pane cont">
                                <button *ngIf="this.situacao_sindical == 'B'" data-toggle="modal" data-target="#formSituacaoApcfSindical" (click)="limpar()" class="btn btn-space btn-success" tabindex="0" aria-controls="table3">Nova Adesão APCF Sindical</button>
                                <button *ngIf="this.situacao_sindical == 'A'" data-toggle="modal" data-target="#formSituacaoApcfSindical" (click)="limpar()" class="btn btn-space btn-danger" tabindex="0" aria-controls="table3">Nova Baixa APCF Sindical</button>
                                <table id="table2" class="table table-striped table-hover table-fw-widget">
                                    <thead>
                                        <tr>
                                            <th class="text-center">Tipo</th>
                                            <th class="text-center">Situação</th>
                                            <th>Motivo</th>
                                            <th>Data</th>
                                            <th class="text-center">Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="associadoSituacoesApcfSindical && associadoSituacoesApcfSindical.length > 0">
                                        <tr class="odd gradeX" *ngFor="let associado_situacao of associadoSituacoesApcfSindical; let i = index;">
                                            <td class="col-md-1 text-center"><span class="label label-primary">APCF Sindical</span></td>
                                            <td class="col-md-1 text-center"><span class="label label-danger" *ngIf="associado_situacao.situacao == 'B'">Baixa</span><span class="label label-success" *ngIf="associado_situacao.situacao == 'A'">Adesão</span></td>
                                            <td class="col-md-2">{{associado_situacao.situacaotipo.nome}}</td>
                                            <td class="col-md-6">{{associado_situacao.data_ocorrencia | date: 'dd/MM/yyyy'}}</td>
                                            <td class="col-md-2 text-center" style="font-size: 22px;">
                                                <span *ngIf="i==0">
                                                    <a data-toggle="modal" data-target="#formSituacaoApcfSindical" (click)="carregar(associado_situacao.codigo_associado_situacao);definirCodigo(associado_situacao.codigo_associado_situacao)"><span class="mdi mdi-edit"></span></a>
                                                    &nbsp;
                                                    <a data-toggle="modal" *ngIf="associadoSituacoesApcfSindical.length > 1" data-target="#md-footer-delete" (click)="definirCodigo(associado_situacao.codigo_associado_situacao)"><span class="icon mdi mdi-delete"></span></a>
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="!associadoSituacoesApcfSindical">
                                        <tr class="carregando">
                                            <td class="carregando" colspan="7">
                                                <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="associadoSituacoesApcfSindical && associadoSituacoesApcfSindical.length == 0">
                                        <tr>
                                            <td class="col-md-12" colspan="7">
                                                Nenhum registro encontrado.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--Modal Situacao Apcf-->
<div id="formSituacaoApcf" tabindex="-1" role="dialog" class="modal fade colored-header colored-header-primary">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close md-close"><span class="mdi mdi-close"></span></button>
                <h3 class="modal-title"><span *ngIf="situacao_apcf == 'B'">Nova Adesão</span><span *ngIf="situacao_apcf == 'A'">Nova Baixa</span></h3>
            </div>
            <div class="modal-body" *ngIf="carregando">
                <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
            </div>
            <div class="modal-body" *ngIf="!carregando">
                <form #situacaoForm="ngForm" [formGroup]="form" novalidate="" class="form-horizontal group-border-dashed">
                    <div class="form-group" [class.has-error]="(f.codigo_associado_situacao_tipo.touched || submitted) && f.codigo_associado_situacao_tipo.invalid == true" *ngIf="((situacao_apcf=='B' && atualiza) || (situacao_apcf=='A' && novo_cadastro))">
                        <span class="col-sm-4" style="text-align: right;">
                            <label class="control-label">Motivo <span class="obrigatorio">*</span></label>
                        </span>
                        <span class="col-sm-6">
                            <select class="select2 form-control" formControlName="codigo_associado_situacao_tipo">
                                <option *ngFor="let associado_situacao_tipo of AssociadoSituacaoTipos" value="{{associado_situacao_tipo.codigo_associado_situacao_tipo}}">
                                    {{associado_situacao_tipo.nome}}
                                </option>
                            </select>
                            <app-error-msg [control]="form.get('codigo_associado_situacao_tipo')" label="Tipo da Situação"></app-error-msg>
                        </span>
                    </div>
                    <div class="form-group" [class.has-error]="(f.data_ocorrencia.touched || submitted) && f.data_ocorrencia.invalid == true">
                        <label for="inputNome" class="col-sm-4 control-label">Data da ocorrência <span class="obrigatorio">*</span></label>
                        <div class="col-sm-6">
                        <input id="inputNome" type="text" placeholder="DD/MM/YYYY" [dropSpecialCharacters]="false" mask="00/00/0000" class="form-control" formControlName="data_ocorrencia">
                        <app-error-msg [control]="form.get('data_ocorrencia')" label="Data da ocorrência"></app-error-msg>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default md-close">Cancelar</button>
                <button type="button" data-dismiss="modal" class="btn btn-primary md-close" (click)="onSubmitApcf()">Confirmar</button>
                &nbsp;&nbsp;
                <img *ngIf="carregando" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            </div>
        </div>
    </div>
</div>

<!--Modal Situacao Apcf Sindical-->
<div id="formSituacaoApcfSindical" tabindex="-1" role="dialog" class="modal fade colored-header colored-header-primary">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close md-close"><span class="mdi mdi-close"></span></button>
                <h3 class="modal-title"><span *ngIf="situacao_sindical == 'B'">Nova Adesão</span><span *ngIf="situacao_sindical == 'A'">Nova Baixa</span></h3>
            </div>
            <div class="modal-body" *ngIf="carregando">
                <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
            </div>
            <div class="modal-body" *ngIf="!carregando">
                <form #situacaoForm="ngForm" [formGroup]="form" novalidate="" class="form-horizontal group-border-dashed">
                    <div class="form-group" [class.has-error]="(f.codigo_associado_situacao_tipo.touched || submitted) && f.codigo_associado_situacao_tipo.invalid == true" *ngIf="((situacao_sindical=='B' && atualiza) || (situacao_sindical=='A' && novo_cadastro))">
                        <span class="col-sm-4" style="text-align: right;">
                            <label class="control-label">Motivo <span class="obrigatorio">*</span></label>
                        </span>
                        <span class="col-sm-6">
                            <select class="select2 form-control" formControlName="codigo_associado_situacao_tipo">
                                <option *ngFor="let associado_situacao_tipo of AssociadoSituacaoTipos" value="{{associado_situacao_tipo.codigo_associado_situacao_tipo}}">
                                    {{associado_situacao_tipo.nome}}
                                </option>
                            </select>
                            <app-error-msg [control]="form.get('codigo_associado_situacao_tipo')" label="Tipo da Situação"></app-error-msg>
                        </span>
                    </div>
                    <div class="form-group" [class.has-error]="(f.data_ocorrencia.touched || submitted) && f.data_ocorrencia.invalid == true">
                        <label for="inputNome" class="col-sm-4 control-label">Data da ocorrência <span class="obrigatorio">*</span></label>
                        <div class="col-sm-6">
                        <input id="inputNome" type="text" placeholder="DD/MM/YYYY" [dropSpecialCharacters]="false" mask="00/00/0000" class="form-control" formControlName="data_ocorrencia">
                        <app-error-msg [control]="form.get('data_ocorrencia')" label="Data da ocorrência"></app-error-msg>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default md-close">Cancelar</button>
                <button type="button" data-dismiss="modal" class="btn btn-primary md-close" (click)="onSubmitSindical()">Confirmar</button>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-sm-12">
        <a href="associado-listar" class="btn btn-space btn-default">Voltar</a>
    </div>
</div>


<!--Modal Footer-->
<div id="md-footer-delete" tabindex="-1" role="dialog" style="" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <div class="text-warning"><span class="modal-main-icon mdi mdi-alert-triangle"></span></div>
                <h3>Tem certeza que deseja excluir este registro?</h3>
                <p>Esta operação não poderá ser desfeita.</p>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default">Cancelar</button>
                <button type="button" data-dismiss="modal" class="btn btn-warning" (click)="apagar(codigo_associado_situacao)">Confirmar</button>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>