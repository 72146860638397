import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MaterialService {

  private _Url = environment.UrlPrincipal + '/api/material';
  private _UrlRestaurar = environment.UrlPrincipal + '/api/material-restaurar';
  private _UrlBaixar = environment.UrlPrincipal + '/api/material-baixar';

  constructor(private http: HttpClient) {}

  list(inativos, numero_patrimonio = null, codigo_localizacao = null, codigo_categoria = null) {
    let condicao;
    condicao = 'inativos=' + inativos;
    if(numero_patrimonio) {
      condicao = condicao + '&numero_patrimonio=' + numero_patrimonio;
    }
    if(codigo_localizacao) {
      condicao = condicao + '&codigo_localizacao=' + codigo_localizacao;
    }
    if(codigo_categoria) {
      condicao = condicao + '&codigo_categoria=' + codigo_categoria;
    }
    return this.http.get(this._Url + '?' + condicao)
                    .catch(this.handleError);
  }

  find(id, historico) {
    return this.http.get(this._Url + '/' + id + '?historico=' + historico)
                    .catch(this.handleError);
  }

  save(dados, codigo): Observable<any> {
    let http;
    if (codigo) {
        http = this.http.put(this._Url + '/' + codigo, dados);
    } else {
        http = this.http.post(this._Url, dados);
    }
    http.map((res) => {
        return res;
    }).catch(this.handleError);
    return http;
  }

  restore(localizacao, codigo) {
    return this.http.put(this._UrlRestaurar + '?codigo_material=' + codigo, localizacao);
  }

  baixar(dados, codigo) {
    let http;
    http = this.http.put(this._UrlBaixar + '/' + codigo, dados);
    http.map((res) => {
      return res;
    }).catch(this.handleError);
    return http;
  }

  delete(codigo) {
    return this.http.delete(this._Url + '/' + codigo);
  }

  private handleError(error: Response) {
    return error.json();
  }

}
