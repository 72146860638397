import { Component, OnInit } from '@angular/core';
import { ColaboradorService } from 'src/app/core/services/colaborador.service';
import { Colaborador } from 'src/app/shared/models/colaborador';
import { NotifierService } from 'angular-notifier';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-colaborador-listar',
  templateUrl: './colaborador-listar.component.html',
  styleUrls: ['./colaborador-listar.component.css']
})
export class ColaboradorListarComponent implements OnInit {

  colaboradores: Colaborador;
  codigo_pessoa;
  breadCrumb;

  constructor(private appComponent: AppComponent,
              private colaboradorService: ColaboradorService,
              private notifierService: NotifierService) {

  }

  ngOnInit() {
    this.breadCrumb = [ 
      {nome: "Colaboradores", caminho: "/colaborador"}
    ];
    this.appComponent.setTitulo('Cadastro de Colaboradores');
    this.appComponent.setBreadcrumb(this.breadCrumb);

    this.listar();
  }

  definirCodigo(codigo_pessoa) {
    this.codigo_pessoa = codigo_pessoa;
  }

  listar(): void {
    this.colaboradorService.list(null, null).subscribe((data: Colaborador) => this.colaboradores = data,
            error => console.log(error));
  }

  apagar(codigo_pessoa):void {   
    this.colaboradorService.delete(codigo_pessoa).subscribe((data) => {
      this.listar();
      this.notifierService.notify( 'success', 'Registro DELETADO com sucesso.' );
    }, (error) => {
      this.notifierService.notify( 'danger', 'Não foi possível DELETAR o registro, ocorreu um erro. ' + error );
    });
  }

}
