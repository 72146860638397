import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LayoutListarComponent} from './layout-listar/layout-listar.component';
import {NgxMaskModule} from "ngx-mask";
import {Ng2BRPipesModule} from "ng2-brpipes";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SharedModule} from "../../shared/shared.module";
import {RouterModule, Routes} from "@angular/router";
import {AuthGuard} from "../../core/authentication/auth.guard";
import {LayoutFormComponent} from './layout-form/layout-form.component';
import {CabecalhoListarComponent} from './cabecalho-listar/cabecalho-listar.component';
import {CabecalhoFormComponent} from './cabecalho-form/cabecalho-form.component';
import {RodapeListarComponent} from './rodape-listar/rodape-listar.component';
import {RodapeFormComponent} from './rodape-form/rodape-form.component';
import {ArquivoListarComponent} from './arquivo-listar/arquivo-listar.component';
import {ArquivoFormComponent} from './arquivo-form/arquivo-form.component';
import {ConsignacaoListarComponent} from "./consignacao-listar/consignacao-listar.component";
import {ConsignacaoFormComponent} from "./consignacao-form/consignacao-form.component";
import {HtmlPipe} from "../../../pipes/html.pipe";

const appRoutes: Routes = [
    {path: 'layout', component: LayoutListarComponent, canActivate: [AuthGuard]},
    {path: 'layout/form', component: LayoutFormComponent, canActivate: [AuthGuard]},
    {path: 'layout/arquivo', component: ArquivoListarComponent, canActivate: [AuthGuard]},
    {path: 'layout/consignacao', component: ConsignacaoListarComponent, canActivate: [AuthGuard]},
    {path: 'layout/cabecalho', component: CabecalhoListarComponent, canActivate: [AuthGuard]},
    {path: 'layout/rodape', component: RodapeListarComponent, canActivate: [AuthGuard]},
    {path: 'layout/arquivo/form', component: ArquivoFormComponent, canActivate: [AuthGuard]},
    {path: 'layout/consignacao/form', component: ConsignacaoFormComponent, canActivate: [AuthGuard]},
    {path: 'layout/cabecalho/form', component: CabecalhoFormComponent, canActivate: [AuthGuard]},
    {path: 'layout/rodape/form', component: RodapeFormComponent, canActivate: [AuthGuard]},
];

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        Ng2BRPipesModule,
        NgxMaskModule.forRoot(),
        RouterModule.forRoot(appRoutes),
    ],
    declarations: [
        LayoutListarComponent,
        LayoutFormComponent,
        CabecalhoListarComponent,
        CabecalhoFormComponent,
        RodapeListarComponent,
        RodapeFormComponent,
        ArquivoListarComponent,
        ArquivoFormComponent,
        ConsignacaoListarComponent,
        ConsignacaoFormComponent,
        HtmlPipe,
    ],
    exports: [
        HtmlPipe,
    ]
})
export class LayoutModule {
}
