import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

@Pipe({
	name: 'html',
	pure: false
})
export class HtmlPipe implements PipeTransform {
	
	constructor(private domSanitizer: DomSanitizer) {
	
	}
	
	transform(value: string): unknown {
		return this.domSanitizer.bypassSecurityTrustHtml(value);
	}
	
}
