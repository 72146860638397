<app-header></app-header>

<div class="panel panel-default panel-border-color panel-border-color-primary">
  <div class="panel-heading panel-heading-divider">
    <form [formGroup]="form" (ngSubmit)="save()" class="form-horizontal group-border-dashed">
      <div class="panel-body">
        <div class="form-group">
          <span class="col-sm-3" style="text-align: right;">
            <label class="control-label">Tipo de Layout <span class="obrigatorio">*</span></label>
          </span>
          <span class="col-sm-3">
            <select class="select2 form-control" formControlName="codigo_tipo_layout">
              <option value="">Selecione</option>
              <option *ngFor="let tipo_layout of tipos_layout" [value]="tipo_layout.sigla">
                {{tipo_layout.descricao}}
              </option>
            </select>
            </span>
        </div>
        <table class="table table-striped table-hover table-fw-widget">
          <tr>
            <th class="col-sm-2">Descri&ccedil;&atilde;o</th>
            <th class="col-sm-1">Qtd caracteres</th>
            <th class="col-sm-1">Padr&atilde;o</th>
<!--            <th class="col-sm-2">Schema</th>-->
<!--            <th class="col-sm-2">Tabela</th>-->
<!--            <th class="col-sm-2">Campo</th>-->
            <th class="col-sm-6">SQL</th>
            <th class="col-sm-2" style="text-align: right;">
              <button data-toggle="modal" data-target="#md-layout-form" (click)="prepararCampos()" type="button" class="btn btn-space btn-primary" title="Adicionar campo" alt="Adicionar campo">
                <i class="glyphicon glyphicon-plus"></i>
              </button>
            </th>
          </tr>
          <tr *ngFor="let campo_layout of this.layout.campos_layout; let i = index">
            <td>{{campo_layout.descricao}}</td>
            <td>{{campo_layout.quantidade_caracteres}}</td>
            <td>{{campo_layout.padrao ? campo_layout.padrao : "-"}}</td>
<!--            <td>{{campo_layout.schema ? campo_layout.schema : "-"}}</td>-->
<!--            <td>{{campo_layout.tabela ? campo_layout.tabela : "-"}}</td>-->
<!--            <td>{{campo_layout.campo ? campo_layout.campo : "-"}}</td>-->
            <td>{{campo_layout.sql ? campo_layout.sql : "-"}}</td>
            <th style="text-align: right;">
              <button type="button" class="btn btn-space btn-danger" (click)="removerLinha(i);" title="Remover campo" alt="Remover campo">
                <i class="glyphicon glyphicon-remove"></i>
              </button>
            </th>
          </tr>
        </table>

        <div class="form-group">
          <div class="col-sm-offset-3 col-sm-7">
            <button type="submit" class="btn btn-space btn-primary">Salvar</button>
            <button type="button" class="btn btn-space btn-default" (click)="voltar()">Voltar</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div id="md-layout-form" tabindex="-1" role="dialog" style="" class="modal fade">
  <form [formGroup]="form_campos" (ngSubmit)="adicionarCampo()" class="form-horizontal group-border-dashed">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" data-dismiss="modal" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
        </div>
        <div class="modal-body">
          <div class="text-center">
            <div class="form-group">
              <label class="col-sm-3 control-label">Sigla <span class="obrigatorio">*</span></label>
              <span class="col-sm-9">
                <input id="sigla" type="text" placeholder="Sigla" class="form-control" formControlName="sigla">
              </span>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label">Descri&ccedil;&atilde;o <span class="obrigatorio">*</span></label>
              <span class="col-sm-9">
                <input id="descricao" type="text" placeholder="Descri&ccedil;&atilde;o" class="form-control" formControlName="descricao">
              </span>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label">Qtd. caracteres <span class="obrigatorio">*</span></label>
              <span class="col-sm-9">
                <input id="quantidade_caracteres" step="1" min="1" type="number" placeholder="Qtd de caracteres" class="form-control" formControlName="quantidade_caracteres">
              </span>
            </div>
            <div class="form-group">
              <div class="be-checkbox">
                <input id="check10" type="checkbox" (click)="definirCampoPadrao()" checked>
                <label for="check10">Existe padr&atilde;o</label>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label">Padr&atilde;o</label>
              <span class="col-sm-9">
                <input id="padrao" type="text" placeholder="Padr&atilde;o" class="form-control" formControlName="padrao" [readonly]="!existe_padrao">
              </span>
            </div>
<!--            <div class="form-group">-->
<!--              <label class="col-sm-3 control-label">Schema</label>-->
<!--              <span class="col-sm-9">-->
<!--                <select #schema_select class="select2 form-control" formControlName="schema" (change)="listarTabelas(schema_select.value); tabela_select.value = ''; campo_select.value = '';">-->
<!--                  <option value="">Selecione</option>-->
<!--                  <option *ngFor="let schema of schemas" value="{{schema.nome}}">-->
<!--                    {{schema.nome}}-->
<!--                  </option>-->
<!--                </select>-->
<!--              </span>-->
<!--            </div>-->
<!--            <div class="form-group">-->
<!--              <label class="col-sm-3 control-label">Tabela</label>-->
<!--              <span class="col-sm-9">-->
<!--                <select #tabela_select class="select2 form-control" formControlName="tabela" (change)="listarCampos(tabela_select.value, schema_select.value); campo_select.value = '';">-->
<!--                  <option value="">Selecione</option>-->
<!--                  <option *ngFor="let tabela of tabelas" [value]="tabela.nome">-->
<!--                    {{tabela.nome}}-->
<!--                  </option>-->
<!--                </select>-->
<!--              </span>-->
<!--            </div>-->
<!--            <div class="form-group">-->
<!--              <label class="col-sm-3 control-label">Campo</label>-->
<!--              <span class="col-sm-9">-->
<!--                <select #campo_select class="select2 form-control" formControlName="campo" [disabled]="existe_padrao">-->
<!--                  <option value="">Selecione</option>-->
<!--                  <option *ngFor="let campo of campos" [value]="campo.nome">-->
<!--                    {{campo.nome}}-->
<!--                  </option>-->
<!--                </select>-->
<!--              </span>-->
<!--            </div>-->
            <div class="form-group">
              <label class="col-sm-3 control-label">SQL</label>
              <span class="col-sm-9">
                <textarea id="sql" placeholder="SQL" class="form-control" formControlName="sql" [disabled]="existe_padrao"></textarea>
              </span>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary btn-space" (click)="adicionarCampo()">Adicionar campo</button>
          <button type="button" data-dismiss="modal" class="btn btn-default btn-space">Cancelar</button>
        </div>
      </div>
    </div>
  </form>
</div>

<app-footer></app-footer>
