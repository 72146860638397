import { Component, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { UploadFileService } from 'src/app/core/services/upload.service';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute, Router } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { filterResponse, uploadProgress } from 'src/app/shared/helpers/rxjs-operators';
import { environment } from 'src/environments/environment';

import { Demanda } from '../../../shared/models/demanda';
import { DemandaService } from '../../../core/services/demanda.service';
import { ColaboradorService } from 'src/app/core/services/colaborador.service';
import { Colaborador } from 'src/app/shared/models/colaborador';
import { DemandaAnexo } from 'src/app/shared/models/demanda-anexo';
import { DemandaAnexoService } from 'src/app/core/services/demanda-anexo.service';
import { DemandaTipoService } from 'src/app/core/services/demanda-tipo.service';
import { DemandaTipo } from 'src/app/shared/models/demanda-tipo';

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';


declare var $: any;
@Component({
  selector: 'app-demanda-exibir',
  templateUrl: './demanda-exibir.component.html',
  styleUrls: ['./demanda-exibir.component.css']
})
export class DemandaExibirComponent implements OnInit {

  @ViewChild('demandaForm') formValues;
  @ViewChild('tipoDemandaForm') formValuesTipo;

  public config = {
    placeholder: 'Escreva um comentário',
    toolbar: [ 'bold', 'italic' ]
  }

  files: Set<File>;
  progress = 0;
  carregando_anexo: boolean;
  anexos: any;

  form: FormGroup;
  formDemanda: FormGroup;
  codigo_demanda: any;
  codigo_demanda_anexo: any;
  demanda: Demanda;
  breadCrumb;
  submited: boolean;
  carregando: boolean;
  assunto: string;
  codigo_demanda_tipo: number;
  codigo_demanda_situacao: number;
  demandaHistorico: Demanda;
  codigo_colaborador: number;
  colaboradores: Colaborador;
  codigo_pessoa: number;
  demandaTipo: DemandaTipo;

  public Editor = ClassicEditor;

  constructor(private appComponent: AppComponent,
              private uploadFileService: UploadFileService,
              private routerParam: ActivatedRoute,
              private router: Router,
              private formBuilder: FormBuilder,
              private demandaService: DemandaService,
              private demandaAnexoService: DemandaAnexoService,
              private colaboradorService: ColaboradorService,
              private demandaTipoService: DemandaTipoService,
              private notifierService: NotifierService) {

              this.codigo_demanda = this.routerParam.snapshot.params.id;

  }

  ngOnInit() {
    this.breadCrumb = [
      {nome: 'Demandas', caminho: '/demanda-listar'}
    ];
    this.appComponent.setTitulo('Detalhe da Demanda');
    this.appComponent.setBreadcrumb(this.breadCrumb);

    this.form = this.formBuilder.group({
      descricao: [null, Validators.required],
    });

    this.codigo_pessoa = parseInt(localStorage.getItem('codigo_pessoa'));

    this.formDemanda = this.formBuilder.group({
      codigo_demanda_tipo: [null, Validators.required],
    });

    this.exibir();
    this.exibirHistorico();
    this.listarArquivos();

  }

  definirCodigo(codigo_demanda) {
    this.codigo_demanda = codigo_demanda;
  }
  
  definirCodigoArquivoAnexo(codigo_demanda_anexo) {
    this.codigo_demanda_anexo = codigo_demanda_anexo;
  }
  
  definirCodigoColaborador(codigo_colaborador) {
    this.codigo_colaborador = codigo_colaborador;
  }

  obterDemandaTipo(codigo) {
    this.demandaTipoService.list(codigo)
    .subscribe((data: DemandaTipo) => this.demandaTipo = data,
                error => console.log(error));
  }

  exibir(): void {
    this.demandaService.find(this.codigo_demanda, false).subscribe((data: Demanda) => {
      this.demanda = data;
      console.log(this.demanda);
      
      if(data.codigo_colaborador) {
        this.listarColaboradores(data.codigo_colaborador);
      }
      this.obterDemandaTipo(data.codigo_demanda_tipo);
    }, error => console.log(error));
  }

  exibirHistorico(): void {
    this.demandaService.find(this.codigo_demanda, true).subscribe((data: Demanda) => {
      this.demandaHistorico = data;
      this.carregando = false;
    }, error => console.log(error));
  }

  listarColaboradores(codigo_colaborador): void {
    this.colaboradorService.list(this.codigo_pessoa, codigo_colaborador).subscribe((data: Colaborador) => this.colaboradores = data,
            error => console.log(error));
  }
  
  listarArquivos(): void {
    this.carregando_anexo = true;
    this.demandaAnexoService.list(this.codigo_demanda).subscribe((data: DemandaAnexo) => {
      this.anexos = data;
      this.carregando_anexo = true;
    }, error => console.log(error));
  }

  atualizarColaborador(capturar): void {
    this.carregando = true;
    const demanda = this.form.value as Demanda;
    if(capturar && capturar == true) {
      demanda.codigo_colaborador = this.codigo_pessoa;
    } else {
      demanda.codigo_colaborador = this.codigo_colaborador;
    }
    demanda.codigo_colaborador_logado = parseInt(localStorage.getItem('codigo_pessoa'));
    demanda.codigo_demanda_pai = this.codigo_demanda;
    demanda.codigo_demandante = this.demanda.codigo_demandante;
    demanda.numero = this.demanda.numero;
    demanda.assunto = this.demanda.assunto;
    demanda.codigo_demanda_tipo = this.demanda.codigo_demanda_tipo;
    demanda.codigo_demanda_situacao = this.demanda.codigo_demanda_situacao;
    demanda.nova_atribuicao = true;
    demanda.sincronizado = 'N';
    this.demandaService.save(demanda, null).subscribe((data: Demanda) => {
      this.exibir();
      this.exibirHistorico();
    }, error => console.log(error));

  }

  onSubmit(): void {
    this.submited = true;
    this.carregando = true;
    const demanda = this.form.value as Demanda;
    if (this.form.valid) {
      demanda.codigo_colaborador_logado = parseInt(localStorage.getItem('codigo_pessoa'));
      demanda.codigo_colaborador = this.codigo_pessoa;
      demanda.codigo_demanda_pai = this.codigo_demanda;
      demanda.assunto = this.demanda.assunto;
      demanda.codigo_demanda_tipo = this.demanda.codigo_demanda_tipo;
      demanda.codigo_demanda_situacao = this.demanda.codigo_demanda_situacao;
      this.demandaService.save(demanda, null).subscribe((data) => {
        if(this.codigo_colaborador) {
          this.atualizarColaborador(false);
        }
        this.form.reset();
        this.exibirHistorico();
        this.notifierService.notify( 'success', 'Mensagem enviada com sucesso.' );
      }, (error) => {
        this.notifierService.notify( 'error', 'Não foi possível cadastrar a mensagem. ' + error );
        this.carregando = false;
      });
    } else {
      console.log(this.form);
      this.notifierService.notify( 'error', 'Antes de enviar é preciso preencher um comentário.');
      this.carregando = false;
    }
  }

  alterarTipoDemanda(): void {
    this.submited = true;
    this.carregando = true;
    const demanda = this.formDemanda.value as Demanda;
    demanda.codigo_colaborador = this.codigo_pessoa;
    demanda.codigo_demanda_pai = this.codigo_demanda;
    demanda.assunto = this.demanda.assunto;
    demanda.codigo_demanda_situacao = this.demanda.codigo_demanda_situacao;
    if (this.formDemanda.valid) {
      this.demandaService.save(demanda, null).subscribe((data) => {
        this.form.reset();
        const codigo_demanda = data.codigo_demanda;
        this.notifierService.notify( 'success', 'Demanda alterada com sucesso.' );
        this.exibir();
        this.exibirHistorico();
        this.carregando = false;
      }, (error) => {
        this.notifierService.notify( 'error', 'Não foi cadastrar uma nova demanda. ' + error );
        this.carregando = false;
      });
    } else {
      console.log(this.form);
      this.notifierService.notify( 'error', 'Existem erros no preenchimento do formulário.');
      this.carregando = false;
    }
  }

  finalizar(): void {
    this.submited = true;
    this.carregando = true;
    const demanda = {'codigo_demanda_situacao': 4, 'codigo_colaborador': parseInt(localStorage.getItem('codigo_pessoa'))};
    this.demandaService.finalizar(this.codigo_demanda, demanda).subscribe((data) => {
      this.exibir();
      this.router.navigate(['demanda-listar']);
      this.notifierService.notify( 'success', 'Demanda finalizada com sucesso.' );
      this.carregando = false;
    }, (error) => {
      this.notifierService.notify( 'error', 'Não foi cadastrar a mensagem. ' + error );
      this.carregando = false;
    });
  }
  
  baixarDocumento( nome ): void {
    this.carregando_anexo = true;
    this.demandaAnexoService.downloadArquivo(this.codigo_demanda, nome);
    this.carregando_anexo = false;
  }

  onChange(event) {
    const selectedFiles = <FileList>event.srcElement.files;
    const fileNames = [];
    this.files = new Set();
    for (let i = 0; i < selectedFiles.length; i++) {
      fileNames.push(selectedFiles[i].name);
      this.files.add(selectedFiles[i]);
    }
    document.getElementById('customFileLabel').innerHTML = fileNames.join(', ');
    this.progress = 0;
  }

  onUpload() {
    this.carregando = true;
    if (this.files && this.files.size > 0) {
      this.uploadFileService.upload(this.files, environment.UrlPrincipal + '/api/demandaanexo', this.codigo_demanda, this.codigo_pessoa)
        .pipe(
          uploadProgress(progress => {
            this.progress = progress;
          }),
          filterResponse()
        )
        .subscribe(response => { 
          console.log('Upload Concluído');
          this.listarArquivos();
          this.carregando = false;
          this.notifierService.notify( 'success', 'Arquivo ENVIADO com sucesso.' );
        });
    }
  }

  excluirArquivo(codigo_demanda_anexo): void {
    this.submited = true;
    this.carregando_anexo = true;
    this.demandaAnexoService.delete(codigo_demanda_anexo).subscribe((data) => {
      this.listarArquivos();
      this.notifierService.notify( 'success', 'Anexo excluído com sucesso.' );
      this.carregando_anexo = false;
    }, (error) => {
      this.notifierService.notify( 'error', error );
      this.carregando_anexo = false;
    });
  }

}
