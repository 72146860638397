import {Component, OnInit, TemplateRef} from '@angular/core';
import {NotifierService} from "angular-notifier";
import {LayoutService} from "../../../core/services/layout.service";
import {AppComponent} from "../../../app.component";
import {Layout} from "../../../shared/models/layout";
import {CampoLayout} from "../../../shared/models/campo-layout";
import {FormControl, FormGroup} from "@angular/forms";
import {isUndefined} from "util";
import {TipoLayout} from "../../../shared/models/tipo-layout";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {CabecalhoLayout} from "../../../shared/models/cabecalho-layout";
import {forkJoin} from "rxjs";
import {CabecalhoLayoutService} from "../../../core/services/cabecalho-layout.service";
import {RodapeLayoutService} from "../../../core/services/rodape-layout.service";
import {RodapeLayout} from "../../../shared/models/rodape-layout";


@Component({
    selector: 'app-layout-listar',
    templateUrl: './layout-listar.component.html',
    styleUrls: ['./layout-listar.component.css']
})
export class LayoutListarComponent implements OnInit {

    public breadCrumb;
    public layouts: Array<any> = [];
    public tipos_layout: Array<TipoLayout> = [];
    public layout: Layout = null;
    public campos_layout: Array<CampoLayout> = [];
    public carregado: boolean = false;
    public form_campos: FormGroup;
    public existe_padrao: boolean = true;
    public modal_layout_detail_ref: BsModalRef;
    public modal_layout_form_ref: BsModalRef;
    public titulo: string = null;
    public codigo_cabecalho: number = null;
    public codigo_rodape: number = null;
    public cabecalhos: Array<CabecalhoLayout> = [];
    public rodapes: Array<RodapeLayout> = [];

    constructor(private appComponent: AppComponent,
                private layoutService: LayoutService,
                private notifierService: NotifierService,
                private modalService: BsModalService,
                private cabecalhoService: CabecalhoLayoutService,
                private rodapeService: RodapeLayoutService,) {

    }

    ngOnInit() {
        this.breadCrumb = [
            {nome: "Configuração", caminho: "/configuracao"},
            {nome: "Layout", caminho: "/layout"}
        ];
        // this.appComponent.setTitulo('Layout');
        // this.appComponent.setBreadcrumb(this.breadCrumb);

        this.listar();
        this.form_campos = new FormGroup({
            codigo: new FormControl("", []),
            sigla: new FormControl("", []),
            descricao: new FormControl("", []),
            sql: new FormControl("", []),
            quantidade_caracteres: new FormControl(null, []),
            padrao: new FormControl("", []),
            ordem: new FormControl("", []),
        });
        this.form_campos.get('sql').disable();
    }

    listar(): void {
        this.layouts = [];
        this.carregado = false;
        this.layoutService.list().subscribe((data: Array<Layout>) => {
            this.carregado = true;
            if (data.length > 0) {
                data.forEach((layout: Layout) => {
                    if (layout.tipo_layout.sigla) {
                        let existe_tipo_layout: boolean = false;
                        if(this.tipos_layout.length > 0) {
                            this.tipos_layout.forEach((tipo_layout: TipoLayout) => {
                                if(tipo_layout.sigla == layout.tipo_layout.sigla){
                                    existe_tipo_layout = true;
                                }
                            });
                        }
                        if(!existe_tipo_layout){
                            this.tipos_layout.push(layout.tipo_layout);
                        }
                        if (isUndefined(this.layouts[layout.tipo_layout.sigla])) {
                            this.layouts[layout.tipo_layout.sigla] = [];
                        }
                        this.layouts[layout.tipo_layout.sigla].push(layout);
                    }
                });
            }
        }, () => {
            this.carregado = true;
        });
    }

    setCampos(layout: Layout, content_modal: TemplateRef<any>): void {
        this.layout = layout;
        this.titulo = layout.titulo;
        this.codigo_cabecalho = null;
        this.codigo_rodape = null;
        this.campos_layout = layout.campos_layout;
        console.log(layout);
        if(layout.codigo_cabecalho_layout) {
            this.codigo_cabecalho = layout.codigo_cabecalho_layout;
        }
        if(layout.codigo_rodape_layout){
            this.codigo_rodape = layout.codigo_rodape_layout;
        }
        if (this.codigo_cabecalho && this.codigo_rodape){
            forkJoin([this.cabecalhoService.list(layout.tipo_layout.sigla), this.rodapeService.list(layout.tipo_layout.sigla)]).subscribe((res) => {
                this.cabecalhos = res[0];
                this.rodapes = res[1];
                this.modal_layout_detail_ref = this.modalService.show(content_modal, {class: 'modal-lg', keyboard: false, focus: true});
            });
        }else{
            this.modal_layout_detail_ref = this.modalService.show(content_modal, {class: 'modal-lg', keyboard: false, focus: true});
        }
    }

    gerarArquivo(layout: Layout): void {
        this.layoutService.generateFile(layout).subscribe(() => {
            this.notifierService.notify('success', "Arquivo gerado com sucesso");
        }, () => {
            this.notifierService.notify('error', "Ocorreu um erro ao gerar arquivo");
        });
    }

    copiar(layout: Layout): void {
        this.layoutService.copy(layout).subscribe(() => {
            this.notifierService.notify('success', 'Layout copiado com sucesso');
            this.listar();
        }, () => {
            this.notifierService.notify('error', 'Ocorreu um erro ao copiar layout');
        });
    }

    subir(campo_layout: CampoLayout, i: number): void {
        let campos_layout: Array<CampoLayout> = [];
        if (this.campos_layout.length > 0) {
            this.campos_layout.forEach((campo_layout: CampoLayout, index) => {
                let tmp_campo_layout: CampoLayout = null;
                if (index == i) {
                    tmp_campo_layout = this.campos_layout[index - 1];
                    tmp_campo_layout.ordem = index - 1;
                } else if (index == (i - 1)) {
                    tmp_campo_layout = this.campos_layout[index + 1];
                    tmp_campo_layout.ordem = index + 1;
                } else {
                    tmp_campo_layout = campo_layout;
                }
                campos_layout.push(tmp_campo_layout);
            });
            this.campos_layout = campos_layout;
            this.layout.campos_layout = campos_layout;
        }
    }

    descer(campo_layout: CampoLayout, i: number): void {
        let campos_layout: Array<CampoLayout> = [];
        if (this.campos_layout.length > 0) {
            this.campos_layout.forEach((campo_layout: CampoLayout, index) => {
                let tmp_campo_layout: CampoLayout = null;
                if (index == i) {
                    tmp_campo_layout = this.campos_layout[index + 1];
                    tmp_campo_layout.ordem = index + 1;
                } else if (index == (i + 1)) {
                    tmp_campo_layout = this.campos_layout[index - 1];
                    tmp_campo_layout.ordem = index - 1;
                } else {
                    tmp_campo_layout = campo_layout;
                }
                campos_layout.push(tmp_campo_layout);
            });
            this.campos_layout = campos_layout;
            this.layout.campos_layout = campos_layout;
        }
    }

    atualizar(layout: Layout, campos_layout: Array<CampoLayout>): void {
        if(this.titulo) {
            layout.titulo = this.titulo;
            if(this.codigo_cabecalho) {
                layout.codigo_cabecalho_layout = this.codigo_cabecalho;
            }
            if(this.codigo_rodape) {
                layout.codigo_rodape_layout = this.codigo_rodape;
            }
            this.layoutService.update(layout, campos_layout).subscribe(() => {
                this.notifierService.notify('success', 'Layout atualizado com sucesso');
                this.modal_layout_detail_ref.hide();
                this.listar();
                this.campos_layout = [];
                this.codigo_cabecalho = null;
                this.codigo_rodape = null;
                this.titulo = null;
                this.layout = null;
            }, () => {
                this.notifierService.notify('error', 'Ocorreu um erro ao atualizar o layout');
            });
        }else{
            this.notifierService.notify('error', 'Informe o título');
        }
    }

    excluir(i: number): void {
        let campos_layout: Array<CampoLayout> = [];
        if (this.campos_layout.length > 0) {
            this.campos_layout.forEach((campo_layout: CampoLayout, index) => {
                if (index != i) {
                    campos_layout.push(campo_layout);
                }
            });
            this.campos_layout = campos_layout;
        }
    }

    editar(campo_layout: CampoLayout, content_modal: TemplateRef<any>): void {
        this.form_campos.patchValue({
            codigo: campo_layout.codigo_campo_layout,
            sigla: campo_layout.sigla,
            descricao: campo_layout.descricao,
            // schema: "",
            // tabela: "",
            // campo: "",
            sql: campo_layout.sql,
            quantidade_caracteres: campo_layout.quantidade_caracteres,
            padrao: campo_layout.padrao,
            ordem: campo_layout.ordem,
        });
        this.existe_padrao = campo_layout.padrao ? true : false;
        this.form_campos.get('sigla').disable();
        if(this.existe_padrao){
            this.form_campos.get('sql').disable();
            this.form_campos.get('padrao').enable();
        }else{
            this.form_campos.get('sql').enable();
            this.form_campos.get('padrao').disable();
        }
        this.modal_layout_form_ref = this.modalService.show(content_modal, {class: 'modal-lg', keyboard: false, focus: true});
    }

    definirCampoPadrao(): void {
        this.existe_padrao = !this.existe_padrao;
        if (this.existe_padrao) {
            // this.form_campos.get('schema').disable();
            // this.form_campos.get('tabela').disable();
            // this.form_campos.get('campo').disable();
            this.form_campos.get('sql').disable();
            this.form_campos.get('padrao').enable();
        } else {
            // this.form_campos.get('schema').enable();
            // this.form_campos.get('tabela').enable();
            // this.form_campos.get('campo').enable();
            this.form_campos.get('sql').enable();
            this.form_campos.get('padrao').disable();
        }
        this.form_campos.patchValue({
            padrao: "",
            // schema: "",
            // tabela: "",
            // campo: "",
            sql: "",
        });
    }

    public addCampo(content_modal: TemplateRef<any>): void {
        this.form_campos.get('sigla').enable();
        // $('#md-layout-detail').modal('hide');
        // this.listarSchemas();
        this.form_campos.patchValue({
            codigo: "",
            sigla: "",
            descricao: "",
            // schema: "",
            // tabela: "",
            // campo: "",
            sql: "",
            quantidade_caracteres: "",
            padrao: "",
            ordem: "",
        });
        // this.form_campos.get('schema').disable();
        // this.form_campos.get('tabela').disable();
        // this.form_campos.get('campo').disable();
        this.form_campos.get('sql').disable();
        this.form_campos.get('padrao').enable();
        this.existe_padrao = true;
        this.modal_layout_form_ref = this.modalService.show(content_modal, {class: 'modal-lg', keyboard: false, focus: true});
    }

    // public listarSchemas(): void{
    //   this.tabelaService.listSchemas().subscribe((schemas: Array<Schema>) => {
    //     this.schemas = schemas;
    //   });
    // }
    //
    // public listarTabelas(schema): void{
    //   this.tabelaService.listTabelas(schema).subscribe((tabelas: Array<Tabela>) => {
    //     this.tabelas = tabelas;
    //   });
    // }
    //
    // public listarCampos(tabela, schema): void{
    //   this.tabelaService.listCampos(tabela, schema).subscribe((campos: Array<Campo>) => {
    //     this.campos = campos;
    //   });
    // }

    adicionarCampo(): void {
        let campo_layout: CampoLayout = new CampoLayout();
        let msg: Array<string> = [];
        campo_layout.codigo_campo_layout = this.form_campos.get('codigo').value;
        campo_layout.quantidade_caracteres = this.form_campos.get('quantidade_caracteres').value;
        campo_layout.descricao = this.form_campos.get('descricao').value;
        campo_layout.sigla = this.form_campos.get('sigla').value;
        if (!this.form_campos.get('quantidade_caracteres').value) {
            msg.push("Informe a quantidade de caracteres");
        } else if (this.existe_padrao && (!this.form_campos.get('padrao').value || this.form_campos.get('quantidade_caracteres').value != this.form_campos.get('padrao').value.length)) {
            msg.push('A quantidade de caracteres do padrão não está igual a quantidade de caracteres informada');
        } else if (!this.existe_padrao && !this.form_campos.get('sql').value) {
            msg.push("Informe o campo sql");
            // } else if(!this.existe_padrao && (!this.form_campos.get('schema').value || !this.form_campos.get('tabela').value || !this.form_campos.get('campo').value)){
            //   msg.push("Informe os campos schema, tabela e campo");
        }
        if (msg.length > 0) {
            msg.forEach((message: string) => {
                this.notifierService.notify('error', message);
            });
        } else {
            if (this.form_campos.get('padrao').value) {
                campo_layout.padrao = this.form_campos.get('padrao').value;
                // campo_layout.schema = "";
                // campo_layout.tabela = "";
                // campo_layout.campo = "";
                campo_layout.sql = "";
            } else {
                campo_layout.padrao = "";
                // campo_layout.schema = this.form_campos.get('schema').value;
                // campo_layout.tabela = this.form_campos.get('tabela').value;
                // campo_layout.campo = this.form_campos.get('campo').value;
                campo_layout.sql = this.form_campos.get('sql').value;
            }
            if(campo_layout.codigo_campo_layout) {
                if(this.layout.campos_layout.length > 0){
                    this.layout.campos_layout.forEach((tmp_campo_layout: CampoLayout, i: number) => {
                        if(tmp_campo_layout.codigo_campo_layout == campo_layout.codigo_campo_layout){
                            this.layout.campos_layout[i] = campo_layout;
                        }
                    });
                }
                if(this.campos_layout.length > 0){
                    this.campos_layout.forEach((tmp_campo_layout: CampoLayout, i: number) => {
                        if(tmp_campo_layout.codigo_campo_layout == campo_layout.codigo_campo_layout){
                            this.campos_layout[i] = campo_layout;
                        }
                    });
                }
            }else{
                this.layout.campos_layout.push(campo_layout);
            }
            this.modal_layout_form_ref.hide();
        }
    }


}
