import { Injectable } from "@angular/core";
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AssociadoPagamentoAdicionalParcelaService {

  constructor(private http: HttpClient) {}

  private _Url = environment.UrlPrincipal + '/api/associadopgadicionalparcela';

  list(inativo, codigo) {
    if(inativo == false) {
      return this.http.get(this._Url + '?inativo=false&codigo_associado='+codigo)
      .catch(this.handleError);  
    } else {
      return this.http.get(this._Url + '?inativo=true&codigo_associado='+codigo)
                      .catch(this.handleError);
    }
  }

  find(id) {
    return this.http.get(this._Url + "/" + id)
                    .catch(this.handleError);
  }

  save(dados, codigo): Observable<any>{   
    let http;
    if(codigo || dados.codigo){
        http = this.http.put(this._Url + "/" + dados.codigo_associado_pagamento_adicional_parcela, dados);
    } else {
        http = this.http.post(this._Url, dados);
    }
    http.map((res) => {
        return res;
    }).catch(this.handleError);
    return http;
  }

  delete(codigo) {
    return this.http.delete(this._Url + "/" + codigo);
  }

  private handleError(error: Response) {
    return error.json();
  }

}