<app-header></app-header>
<div class="panel panel-default panel-border-color panel-border-color-primary">
  <div class="panel-heading panel-heading-divider">Alterar Senha</div>
  <div class="panel-body">
    <form #alterarSenhaForm="ngForm" [formGroup]="form" novalidate="" class="form-horizontal group-border-dashed">

        <div class="form-group">
          <label class="col-sm-4 control-label">Senha Atual</label>
          <div class="col-sm-7">
            <input type="password" placeholder="Senha Atual" class="form-control" formControlName="senha_atual">
            <div *ngIf="submitted && f.senha_atual.errors" class="obrigatorio">
              <div *ngIf="f.senha_atual.errors">Campo obrigatório com no mínimo 6 caracteres</div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label class="col-sm-4 control-label">Nova Senha</label>
          <div class="col-sm-7">
            <input type="password" placeholder="Nova Senha" class="form-control" formControlName="nova_senha">
            <div *ngIf="submitted && f.nova_senha.errors" class="obrigatorio">
              <div *ngIf="f.nova_senha.errors">Campo obrigatório com no mínimo 6 caracteres</div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label class="col-sm-4 control-label">Confirmação da Nova Senha</label>
          <div class="col-sm-7">
            <input type="password" placeholder="Confirmação da Nova Senha" class="form-control" formControlName="nova_senha_confirmacao">
            <div *ngIf="submitted && f.nova_senha_confirmacao.errors" class="obrigatorio">
              <div *ngIf="f.nova_senha_confirmacao.errors">Campo obrigatório com no mínimo 6 caracteres</div>
            </div>
            <div *ngIf="nova_senha" class="obrigatorio">Senha e confirmação de senha não conferem</div>
          </div>
        </div>

        <div class="form-group">
          <div class="col-sm-offset-4 col-sm-7">
            <button class="btn btn-space btn-default" routerLink="/dashboard">Cancelar</button>
            <button type="submit" class="btn btn-space btn-primary" (click)="alterarSenha()">Confirmar</button>
          </div>
        </div>

    </form>
  </div>
</div>
<app-footer></app-footer>