import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DemandaService {

  private _Url = environment.UrlPrincipal + '/api/demanda';
  private _UrlFinalizar = environment.UrlPrincipal + '/api/demanda-finalizar';
  private _UrlRestaurar = environment.UrlPrincipal + '/api/demanda-restaurar';

  constructor(private http: HttpClient) {}

  list(inativos, demandante, codigo_demanda_tipo, created_at) {
    return this.http.get(this._Url + '?inativos=' + inativos + '&demandante=' + demandante + '&codigo_demanda_tipo=' + codigo_demanda_tipo + '&created_at=' + created_at)
                    .catch(this.handleError);
  }

  find(id, historico) {
    return this.http.get(this._Url + '/' + id + '?historico=' + historico)
                    .catch(this.handleError);
  }

  save(dados, codigo): Observable<any> {
    let http;
    if (codigo) {
        http = this.http.put(this._Url + '/' + codigo, dados);
    } else {
        http = this.http.post(this._Url, dados);
    }
    http.map((res) => {
        return res;
    }).catch(this.handleError);
    return http;
  }

  finalizar(codigo_demanda, demanda) {
    return this.http.put(this._UrlFinalizar + '?codigo_demanda=' + codigo_demanda, demanda);
  }

  restore(codigo_demanda, demanda) {
    return this.http.put(this._UrlRestaurar + '?codigo_demanda=' + codigo_demanda, demanda);
  }

  delete(codigo_demanda) {
    return this.http.delete(this._Url + '/' + codigo_demanda);
  }

  private handleError(error: Response) {
    return error.json();
  }

}
