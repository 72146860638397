import { Injectable } from "@angular/core";
import { Observable } from 'rxjs/Observable';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import { HttpClient } from '@angular/common/http';
import { map } from "rxjs/operators";
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

    constructor(private http: HttpClient) { }

    login(email: string, password: string): Observable<any> {
        return this.http.post(environment.UrlLogin, { email, password })
                .pipe(map(user => {
                    return user;
        }));
    }

    me(): Observable<any> {
        return this.http.post(environment.UrlMe, null)
                .pipe(map(user => {
                    return user;
        }));
    }

    alterarSenha(dados): Observable<any>{
        let http;
        http = this.http.post(environment.UrlAlterarSenha, dados);
        http.map((res) => {
            return res;
        }).catch(this.handleError);
        return http;
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.removeItem('codigo_pessoa');
        localStorage.removeItem('nome_pessoa');
        localStorage.removeItem('nome_guerra');
    }

    private handleError(error: Response) {
        return error.json();
    }

}