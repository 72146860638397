import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { SolicitacaoAssociadoService } from 'src/app/core/services/solicitacao-associado.service';
import { SolicitacaoService } from 'src/app/core/services/solicitacao.service';
import { NotifierService } from 'angular-notifier';
import { Associado } from 'src/app/shared/models/associado';
import { Solicitacao } from 'src/app/shared/models/solicitacao';
import { AssociadoService } from 'src/app/core/services/associado.service';

@Component({
  selector: 'app-associado-alteracao-listar',
  templateUrl: './associado-alteracao-listar.component.html',
  styleUrls: ['./associado-alteracao-listar.component.css']
})
export class AssociadoAlteracaoListarComponent implements OnInit {

  associados: Associado;
  solicitacao: Solicitacao;
  codigo_pessoa;
  codigo_solicitacao;
  breadCrumb;
  carregando;
  listaAlteracao: any = {};
  solicitacao_associado_area_atuacao: any;

  constructor(private appComponent: AppComponent,
              private associadoService: AssociadoService,
              private solicitacaoService: SolicitacaoService,
              private solicitacaoAssociadoService: SolicitacaoAssociadoService,
              private notifierService: NotifierService) {

  }

  ngOnInit() {
    this.breadCrumb = [ 
      {nome: "Associados", caminho: "/associado-listar"}
    ];
    this.appComponent.setTitulo('Alteração de Cadastro de Associados');
    this.appComponent.setBreadcrumb(this.breadCrumb);
    this.listar();
  }

  aprovarAlteracaoEnderecoCorrespondencia(campo, valor) {
    this.listaAlteracao['form_correspondencia'][campo] = valor;
    console.log(this.listaAlteracao);
  }

  desaprovarAlteracaoEnderecoCorrespondencia(campo) {
    delete this.listaAlteracao['form_correspondencia'][campo];
    console.log(this.listaAlteracao);
  }

  aprovarAlteracao(campo, valor) {
    this.listaAlteracao[campo] = valor;
    console.log(this.listaAlteracao);
  }

  desaprovarAlteracao(campo) {
    delete this.listaAlteracao[campo];
    console.log(this.listaAlteracao);
  }

  definirCodigo(codigo_pessoa, codigo_solicitacao) {
    this.codigo_pessoa = codigo_pessoa;
    this.codigo_solicitacao = codigo_solicitacao;
    //console.log('Definir codigo ' + codigo_solicitacao);
    //this.obter(codigo_solicitacao);
  }

  obter(codigo_solicitacao): void {
    this.solicitacao = null;
    this.solicitacaoService.find(codigo_solicitacao).subscribe((data: Solicitacao) => 
    { 
      this.solicitacao = data;
      this.solicitacao_associado_area_atuacao = data.solicitacao_associado_area_atuacao;
      console.log(this.solicitacao);
      
    }, error => console.log(error));
  }

  listar(): void {
    this.solicitacaoService.list().subscribe((data: Associado) => 
    { 
      this.associados = data;
    }, error => console.log(error));
  }

  confirmar(opcao):void {
    this.carregando = true;
    if(opcao == true) {
      this.listaAlteracao = {};
      this.listaAlteracao['desaprovar'] = true;
    }
    this.listaAlteracao['codigo_colaborador'] = localStorage.getItem('codigo_pessoa');
    this.listaAlteracao['codigo_pessoa'] = this.codigo_pessoa;
    this.listaAlteracao['codigo_solicitacao'] = this.codigo_solicitacao;
    this.solicitacaoService.confirmar(this.listaAlteracao).subscribe((data) => {
      this.carregando = false;
      this.solicitacao = null;     
      this.listar();
      $('body').removeClass('modal-open');        
      $('body').css('padding-right', '');
      $(".modal-backdrop").remove();
      $('#md-aprovacao').modal('hide');
      this.notifierService.notify( 'success', 'Informações ATUALIZADAS com sucesso.' );
    }, (error) => {
      this.notifierService.notify( 'error', error );
    });
  }

}
