import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { CertificadoService } from 'src/app/core/services/certificado.service';

@Component({
  selector: 'app-sincronizacao',
  templateUrl: './sincronizacao.component.html',
  styleUrls: ['./sincronizacao.component.css']
})
export class SincronizacaoComponent implements OnInit {

  breadCrumb;
  carregando;
  resultado: any;

  constructor(private appComponent: AppComponent,
              private formBuilder: FormBuilder,
              private routerParam: ActivatedRoute,
              private notifierService: NotifierService,
              private router: Router,
              private certificadoService: CertificadoService) { 

              }

  ngOnInit() {
    this.breadCrumb = [ 
      {nome: "Configurações", caminho: "/configuracao"}, {nome: "Sincronizar", caminho: "/sincronizar"}
    ];
    this.appComponent.setTitulo('Sincronização de Bases de Dados');
    this.appComponent.setBreadcrumb(this.breadCrumb);
  }

  gerarCertificado() {
    this.carregando = true;
    this.certificadoService.gerar().subscribe((data) => {
      this.notifierService.notify( 'success', 'Par de chaves gerado com sucesso. A chave pública foi enviada para o servidor externo/público.');
      this.carregando = false;
    });
  }

  sincronizar() {
    this.carregando = true;
    this.resultado = null; 
    this.certificadoService.sincronizar().subscribe((data) => {
      this.resultado = data;
      this.notifierService.notify( 'success', 'Processo de SINCRONIZAÇÃO concluído.');
      this.carregando = false;
    }, (message) => {
      this.carregando = false;
      this.notifierService.notify( 'error', 'Erro na SINCRONIZAÇÃO, procure o suporte técnico.');
    });
  }

}
