import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { AppComponent } from 'src/app/app.component';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { Classe } from 'src/app/shared/models/classe';
import { ClasseService } from 'src/app/core/services/classe.service';

@Component({
  selector: 'app-classe-editar',
  templateUrl: './classe-editar.component.html',
  styleUrls: ['./classe-editar.component.css']
})
export class ClasseEditarComponent implements OnInit {


  @ViewChild('classeForm') formValues;

  form: FormGroup;
  breadCrumb;
  classes: Classe;
  carregando: boolean;
  submited: boolean;
  codigo_classe: any;

  constructor(private appComponent: AppComponent,
              private formBuilder: FormBuilder,
              private routerParam: ActivatedRoute,
              private router: Router,
              private classeService: ClasseService,
              private notifierService: NotifierService) 
  {
              this.codigo_classe = this.routerParam.snapshot.params.id;
  }

  ngOnInit() {

    this.carregando = true;
    this.breadCrumb = [
      {nome: 'Configuração', caminho: '/configuracao'},
      {nome: 'classes', caminho: '/classe-listar'},
    ];
    if(this.codigo_classe) {
      this.appComponent.setTitulo('Alteração de classe');
    } else {
      this.appComponent.setTitulo('Cadastro de classe');
    }
    this.appComponent.setBreadcrumb(this.breadCrumb);

    this.form = this.formBuilder.group({
      codigo_classe: [null],
      nome: [null, Validators.required]
    });

    this.obter();

  }

  obter(): void {
    this.classeService.find(this.codigo_classe).subscribe((data: Classe) => {
      this.form.patchValue({
        codigo_classe: data.codigo_classe,
        nome: data.nome
      });
    });
  }

  onSubmit(): void {
    this.submited = true;
    this.carregando = true;
    const classe = this.form.value as Classe;
    if (this.form.valid) {
      this.classeService.save(classe, this.codigo_classe).subscribe((data) => {
        this.notifierService.notify( 'success', 'Classe cadastrada com sucesso.' );
        this.carregando = false;
        this.router.navigate(['classe-listar']);
      }, (error) => {
        this.notifierService.notify( 'error', 'Não foi cadastrar uma nova Classe. ' + error );
        this.carregando = false;
      });
    } else {
      console.log(this.form);
      this.notifierService.notify( 'error', 'Existem erros no preenchimento do formulário.');
      this.carregando = false;
    }
  }

}
