import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { AppComponent } from 'src/app/app.component';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { PendenciaTipo } from 'src/app/shared/models/pendencia-tipo';
import { PendenciaTipoService } from 'src/app/core/services/pendencia-tipo.service';

@Component({
    selector: 'app-pendencia-tipo-editar',
    templateUrl: './pendencia-tipo-editar.component.html',
    styleUrls: ['./pendencia-tipo-editar.component.css'],
})
export class PendenciaTipoEditarComponent implements OnInit {
    @ViewChild('pendenciaTipoForm') formValues;

    form: FormGroup;
    breadCrumb;
    pendencias_tipo: PendenciaTipo;
    carregando: boolean;
    submited: boolean;
    codigo_pendencia_tipo: any;

    constructor(
        private appComponent: AppComponent,
        private formBuilder: FormBuilder,
        private routerParam: ActivatedRoute,
        private router: Router,
        private pendenciaTipoService: PendenciaTipoService,
        private notifierService: NotifierService,
    ) {
        this.codigo_pendencia_tipo = this.routerParam.snapshot.params.id;
    }

    ngOnInit() {
        this.carregando = true;
        this.breadCrumb = [
            { nome: 'Configuração', caminho: '/configuracao' },
            { nome: 'pendencias_tipo', caminho: '/pendencia-tipo-listar' },
        ];
        if (this.codigo_pendencia_tipo) {
            this.appComponent.setTitulo('Alteração de pendencia tipo');
        } else {
            this.appComponent.setTitulo('Cadastro de pendencia tipo');
        }
        this.appComponent.setBreadcrumb(this.breadCrumb);

        this.form = this.formBuilder.group({
            codigo_pendencia_tipo: [null],
            nome: [null, Validators.required],
        });

        this.obter();
    }

    obter(): void {
        this.pendenciaTipoService
            .find(this.codigo_pendencia_tipo)
            .subscribe((data: PendenciaTipo) => {
                this.form.patchValue({
                    codigo_pendencia_tipo: data.codigo_pendencia_tipo,
                    nome: data.nome,
                });
            });
    }

    onSubmit(): void {
        this.submited = true;
        this.carregando = true;
        const pendencia_tipo = this.form.value as PendenciaTipo;
        if (this.form.valid) {
            this.pendenciaTipoService
                .save(pendencia_tipo, this.codigo_pendencia_tipo)
                .subscribe(
                    (data) => {
                        this.notifierService.notify(
                            'success',
                            'Pendencia tipo cadastrada com sucesso.',
                        );
                        this.carregando = false;
                        this.router.navigate(['pendencia-tipo-listar']);
                    },
                    (error) => {
                        this.notifierService.notify(
                            'error',
                            'Não foi cadastrar uma nova Pendencia Tipo. ' +
                                error,
                        );
                        this.carregando = false;
                    },
                );
        } else {
            console.log(this.form);
            this.notifierService.notify(
                'error',
                'Existem erros no preenchimento do formulário.',
            );
            this.carregando = false;
        }
    }
}
