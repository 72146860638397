import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { AppComponent } from 'src/app/app.component';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { AssociadoPagamentoTipo } from 'src/app/shared/models/associado-pagamento-tipo';
import { AssociadoPagamentoTipoService } from 'src/app/core/services/associado-pagamento-tipo.service';

@Component({
    selector: 'app-associado-pagamento-tipo-editar',
    templateUrl: './associado-pagamento-tipo-editar.component.html',
    styleUrls: ['./associado-pagamento-tipo-editar.component.css'],
})
export class AssociadoPagamentoTipoEditarComponent implements OnInit {
    @ViewChild('associadoPagamentoTipoForm') formValues;

    form: FormGroup;
    breadCrumb;
    associados_pagamento_tipo: AssociadoPagamentoTipo;
    carregando: boolean;
    submited: boolean;
    codigo_associado_pagamento_tipo: any;

    constructor(
        private appComponent: AppComponent,
        private formBuilder: FormBuilder,
        private routerParam: ActivatedRoute,
        private router: Router,
        private associadoPagamentoTipoService: AssociadoPagamentoTipoService,
        private notifierService: NotifierService,
    ) {
        this.codigo_associado_pagamento_tipo =
            this.routerParam.snapshot.params.id;
    }

    ngOnInit() {
        this.carregando = true;
        this.breadCrumb = [
            { nome: 'Configuração', caminho: '/configuracao' },
            {
                nome: 'associados_pagamento_tipo',
                caminho: '/associado-pagamento-tipo-listar',
            },
        ];
        if (this.codigo_associado_pagamento_tipo) {
            this.appComponent.setTitulo(
                'Alteração de associado pagamento tipo',
            );
        } else {
            this.appComponent.setTitulo('Cadastro de associado pagamento tipo');
        }
        this.appComponent.setBreadcrumb(this.breadCrumb);

        this.form = this.formBuilder.group({
            codigo_associado_pagamento_tipo: [null],
            nome: [null, Validators.required],
        });

        this.obter();
    }

    obter(): void {
        this.associadoPagamentoTipoService.find(
            this.codigo_associado_pagamento_tipo,
        ).subscribe((data: AssociadoPagamentoTipo) => {
            this.form.patchValue({
                codigo_associado_pagamento_tipo:
                    data.codigo_associado_pagamento_tipo,
                nome: data.nome,
            });
        });
    }

    onSubmit(): void {
        this.submited = true;
        this.carregando = true;
        const associado_pagamento_tipo = this.form
            .value as AssociadoPagamentoTipo;
        if (this.form.valid) {
            this.associadoPagamentoTipoService
                .save(
                    associado_pagamento_tipo,
                    this.codigo_associado_pagamento_tipo,
                )
                .subscribe(
                    (data) => {
                        this.notifierService.notify(
                            'success',
                            'Associado Pagamento Tipo cadastrada com sucesso.',
                        );
                        this.carregando = false;
                        this.router.navigate([
                            'associado-pagamento-tipo-listar',
                        ]);
                    },
                    (error) => {
                        this.notifierService.notify(
                            'error',
                            'Não foi cadastrar uma nova Associado Pagamento Tipo. ' +
                                error,
                        );
                        this.carregando = false;
                    },
                );
        } else {
            console.log(this.form);
            this.notifierService.notify(
                'error',
                'Existem erros no preenchimento do formulário.',
            );
            this.carregando = false;
        }
    }
}
