<app-header></app-header>

<div class="row">
  <div class="col-sm-12">
    <div class="panel panel-default panel-table">
    <br />
        <div class="row ficha-financeira">
            <div class="col-md-9">
                <span><b>DADOS DO ASSOCIADO</b></span>
                <br />
                <span class="ficha-financeira-span"><b>Associado:</b>&nbsp;&nbsp;{{associado_nome}}</span>
                <br />
                <span class="ficha-financeira-span"><b>Matrícula PF:</b>&nbsp;&nbsp;<span *ngIf="associado_matricula_dpf != 'null'">{{associado_matricula_dpf}}</span><span *ngIf="associado_matricula_dpf == 'null'">-</span></span>
                <br />
                <span class="ficha-financeira-span"><b>Tipo de Associado:</b>&nbsp;&nbsp;<span *ngIf="associado_tipo == 'AT'"> Ativo</span><span *ngIf="associado_tipo == 'AP'"> Aposentado</span><span *ngIf="associado_tipo == 'PE'"> Pensionista</span></span>
                <br />
                <span class="ficha-financeira-span"><b>Associado APCF:</b>&nbsp;&nbsp;<span *ngIf="associado_apcf == '1'"> Filiado</span><span *ngIf="associado_apcf == '0'"> Desfiliado</span></span>
                <br />
                <span class="ficha-financeira-span"><b>Associado APCF Sindical:</b>&nbsp;&nbsp;<span *ngIf="associado_apcf_sindical == '1'"> Filiado</span><span *ngIf="associado_apcf_sindical == '0'"> Desfiliado</span></span>
            </div>
            <div class="col-md-3">

            </div>
        </div>
    <br />
    <div class="panel-body">
        <div class="tab-container">
            <ul class="nav nav-tabs">
                <li class="active"><a href="#aberta" data-toggle="tab">Benefícios</a></li>
                <li><a href="#adicional" data-toggle="tab">Benefícios Excluídos</a></li>
            </ul>
            <div class="tab-content">
                <div id="aberta" class="tab-pane active cont">
                    <!-- <button data-toggle="modal" data-target="#formPagamentoAdicional" (click)="definirCodigo(dado.codigo_associado_pagamento)" class="btn btn-space btn-success" tabindex="0" aria-controls="table3">Novo Benefício</button> -->
                    <div class="btn-group btn-hspace">
                        <button type="button" data-toggle="dropdown" class="btn btn-space btn-success dropdown-toggle">Novo Benefício <span class="icon-dropdown mdi mdi-chevron-down"></span></button>
                        <ul role="menu" class="dropdown-menu">
                            <li><a data-toggle="modal" data-target="#formSeguroVida" (click)="limpar()">Seguro de Vida</a></li>
                            <li><a data-toggle="modal" data-target="#formPatrocinio" (click)="limpar()">Patrocínio</a></li>
                            <li><a data-toggle="modal" data-target="#formAuxilioJuridicoIndividual" (click)="limpar()">Auxílio Jurídico I</a></li>
                            <li><a data-toggle="modal" data-target="#formAuxilioJuridicoColetivo" (click)="limpar()">Auxílio Jurídico II</a></li>
                            <li><a data-toggle="modal" data-target="#formAuxilioNatalidade" (click)="limpar()">Auxílio Natalidade</a></li>
                            <li><a data-toggle="modal" data-target="#formAuxilioFuneral" (click)="limpar()">Auxílio Funeral</a></li>
                            <li><a data-toggle="modal" data-target="#formPlanoSaude" (click)="limpar()">Plano de Saúde</a></li>
                            <li><a data-toggle="modal" data-target="#formAuxilioParticipacaoCientifica" (click)="limpar()">Auxílio Participação Científica</a></li>
                            <li><a data-toggle="modal" data-target="#formCartaFianca" (click)="limpar()">Carta Fiança</a></li>
                            <li><a data-toggle="modal" data-target="#formAuxilioPostMortem" (click)="limpar()">Auxílio Post Mortem</a></li>
                            <li><a data-toggle="modal" data-target="#formClubeConvenio" (click)="limpar()">Convênios: Declaração</a></li>
                        </ul>
                    </div>
                    <div class="dataTables_length" id="table3_length">
                        <table id="table2" class="table table-striped table-hover table-fw-widget">
                            <thead>
                                <tr>
                                    <th>Nº/Ano</th>
                                    <th>Data do Registro</th>
                                    <th>Tipo</th>
                                    <th>Valor</th>
                                    <th>Data da Concessão / Adesão</th>
                                    <th>Concedido</th>
                                    <th class="text-center">Ações</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="beneficios && beneficios.length > 0 && existe_associado_beneficio">
                                <tr class="odd gradeX" *ngFor="let dado of beneficios">
                                    <td class="col-md-2">{{dado.codigo_associado_beneficio}} / {{dado.created_at | date: 'yyyy'}}</td>
                                    <td class="col-md-2">{{dado.created_at | date: 'dd/MM/yyyy'}}</td>
                                    <td class="col-md-2">
                                        {{dado.beneficiotipo.nome}}
                                        <br />
                                        <div *ngIf="dado.beneficioclubeconvenio">
                                            Tipo:
                                            <span *ngIf="dado.beneficioclubeconvenio.convenio_tipo=='A'">Automóvel</span>
                                            <span *ngIf="dado.beneficioclubeconvenio.convenio_tipo=='C'">Crédito</span>
                                            <span *ngIf="dado.beneficioclubeconvenio.convenio_tipo=='E'">Educacional</span>
                                            <span *ngIf="dado.beneficioclubeconvenio.convenio_tipo=='O'">Outros</span>
                                        </div>
                                    </td>
                                    <td class="col-md-2"><span *ngIf="dado.valor">{{dado.valor | currency:'BRL':true:'.2':'pt'}}</span><span *ngIf="!dado.valor"> - </span></td>
                                    <td class="col-md-2"><span *ngIf="dado.data_concessao">{{dado.data_concessao | date: 'dd/MM/yyyy'}} </span><span *ngIf="dado.aviso_requerimento" class="obrigatorio"><br />Requerimento e Óbito > 6 meses</span><span *ngIf="dado.beneficioplanosaude">{{dado.beneficioplanosaude.data_adesao | date: 'dd/MM/yyyy'}}</span></td>
                                    <td class="col-md-1"><span *ngIf="!dado.data_concessao && !dado.beneficioplanosaude" class="label label-danger">Não</span><span *ngIf="dado.data_concessao || dado.beneficioplanosaude" class="label label-success">Sim</span></td>
                                    <td class="text-center" style="font-size: 22px;">
                                        <a *ngIf="dado.codigo_beneficio_tipo == 6" data-toggle="modal" data-target="#formSeguroVida" (click)="limpar();obter(dado.codigo_associado_beneficio, dado.codigo_beneficio_tipo);definirCodigo(dado.codigo_associado_beneficio)"><span class="mdi mdi-edit"></span></a>
                                        <a *ngIf="dado.codigo_beneficio_tipo == 7" data-toggle="modal" data-target="#formPatrocinio" (click)="limpar();obter(dado.codigo_associado_beneficio, dado.codigo_beneficio_tipo);definirCodigo(dado.codigo_associado_beneficio)"><span class="mdi mdi-edit"></span></a>
                                        <a *ngIf="dado.codigo_beneficio_tipo == 10" data-toggle="modal" data-target="#formAuxilioJuridicoIndividual" (click)="limpar();obter(dado.codigo_associado_beneficio, dado.codigo_beneficio_tipo);definirCodigo(dado.codigo_associado_beneficio)"><span class="mdi mdi-edit"></span></a>
                                        <a *ngIf="dado.codigo_beneficio_tipo == 1" data-toggle="modal" data-target="#formAuxilioJuridicoColetivo" (click)="limpar();obter(dado.codigo_associado_beneficio, dado.codigo_beneficio_tipo);definirCodigo(dado.codigo_associado_beneficio)"><span class="mdi mdi-edit"></span></a>
                                        <a *ngIf="dado.codigo_beneficio_tipo == 8" data-toggle="modal" data-target="#formAuxilioNatalidade" (click)="limpar();obter(dado.codigo_associado_beneficio, dado.codigo_beneficio_tipo);definirCodigo(dado.codigo_associado_beneficio)"><span class="mdi mdi-edit"></span></a>
                                        <a *ngIf="dado.codigo_beneficio_tipo == 9" data-toggle="modal" data-target="#formAuxilioFuneral" (click)="limpar();obter(dado.codigo_associado_beneficio, dado.codigo_beneficio_tipo);definirCodigo(dado.codigo_associado_beneficio)"><span class="mdi mdi-edit"></span></a>
                                        <a *ngIf="dado.codigo_beneficio_tipo == 5" data-toggle="modal" data-target="#formPlanoSaude" (click)="limpar();obter(dado.codigo_associado_beneficio, dado.codigo_beneficio_tipo);definirCodigo(dado.codigo_associado_beneficio)"><span class="mdi mdi-edit"></span></a>
                                        <a *ngIf="dado.codigo_beneficio_tipo == 4" data-toggle="modal" data-target="#formAuxilioParticipacaoCientifica" (click)="limpar();obter(dado.codigo_associado_beneficio, dado.codigo_beneficio_tipo);definirCodigo(dado.codigo_associado_beneficio)"><span class="mdi mdi-edit"></span></a>
                                        <a *ngIf="dado.codigo_beneficio_tipo == 2" data-toggle="modal" data-target="#formCartaFianca" (click)="limpar();obter(dado.codigo_associado_beneficio, dado.codigo_beneficio_tipo);definirCodigo(dado.codigo_associado_beneficio)"><span class="mdi mdi-edit"></span></a>
                                        <a *ngIf="dado.codigo_beneficio_tipo == 3" data-toggle="modal" data-target="#formAuxilioPostMortem" (click)="limpar();obter(dado.codigo_associado_beneficio, dado.codigo_beneficio_tipo);definirCodigo(dado.codigo_associado_beneficio)"><span class="mdi mdi-edit"></span></a>
                                        <a *ngIf="dado.codigo_beneficio_tipo == 11" data-toggle="modal" data-target="#formClubeConvenio" (click)="limpar();obter(dado.codigo_associado_beneficio, dado.codigo_beneficio_tipo);definirCodigo(dado.codigo_associado_beneficio)"><span class="mdi mdi-edit"></span></a>
                                        &nbsp;
                                        <a data-toggle="modal" data-target="#modal-excluir" (click)="definirCodigo(dado.codigo_associado_beneficio)"><span class="mdi mdi-delete"></span></a>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="!beneficios">
                                <tr class="carregando">
                                    <td class="carregando" colspan="7">
                                        <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="beneficios && (beneficios.length == 0 || !existe_associado_beneficio)">
                                <tr>
                                    <td class="col-md-12" colspan="7">
                                        Nenhum registro encontrado.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
                <div id="adicional" class="tab-pane cont">
                    <div class="dataTables_length" id="table3_length">
                      <table id="table2" class="table table-striped table-hover table-fw-widget">
                        <thead>
                            <tr>
                                <th>Tipo</th>
                                <th>Valor</th>
                                <th>Data da Concessão</th>
                                <th>Concedido</th>
                                <th>Data Exclusão</th>
                                <th class="text-center">Ações</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="beneficios_inativos && beneficios_inativos.length > 0 && existe_associado_beneficio_inativo">
                            <tr class="odd gradeX" *ngFor="let dado of beneficios_inativos">
                                <td class="col-md-4">
                                    {{dado.beneficiotipo.nome}}
                                    <br />
                                    <div>
                                        <span *ngIf="dado.beneficioclubeconvenio=='A'">Automóvel</span>
                                        <span *ngIf="dado.beneficioclubeconvenio=='C'">Crédito</span>
                                        <span *ngIf="dado.beneficioclubeconvenio=='E'">Educacional</span>
                                        <span *ngIf="dado.beneficioclubeconvenio=='O'">Outros</span>
                                    </div>
                                </td>
                                <td class="col-md-2">{{dado.valor | currency:'BRL':true:'.2':'pt'}}</td>
                                <td class="col-md-2">{{dado.data_concessao | date: 'dd/MM/yyyy'}}</td>
                                <td class="col-md-2"><span *ngIf="!dado.data_concessao" class="label label-danger">Não</span><span *ngIf="dado.data_concessao" class="label label-success">Sim</span></td>
                                <td class="col-md-2">{{dado.deleted_at | date: 'dd/MM/yyyy'}}</td>
                                <td class="text-center" style="font-size: 22px;">
                                    <a data-toggle="modal" data-target="#formReativar" (click)="definirCodigo(dado.codigo_associado_beneficio)"><span class="badge badge-warning">Reativar</span></a>
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="!beneficios_inativos">
                            <tr class="carregando">
                                <td class="carregando" colspan="7">
                                    <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="beneficios_inativos && (beneficios_inativos.length == 0 || !existe_associado_beneficio_inativo)">
                            <tr>
                                <td class="col-md-12" colspan="7">
                                    Nenhum registro encontrado.
                                </td>
                            </tr>
                        </tbody>
                      </table>
                    </div>

                </div>
            </div>
        </div>
    </div>
  </div>
</div>
<div class="row">
    <div class="col-sm-12">
        <a href="associado-listar" class="btn btn-space btn-default" style="margin-left: 16px;">Voltar</a>
    </div>
</div>



<!--Modal Plano de Saúde-->
<div id="formPlanoSaude" tabindex="-1" role="dialog" class="modal fade colored-header colored-header-primary">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close md-close"><span class="mdi mdi-close"></span></button>
                <h3 class="modal-title">Plano de Saúde</h3>
            </div>
            <div class="modal-body">
                <form #planoSaudeForm="ngForm" [formGroup]="formPlanoSaude" novalidate="">
                
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Tipo do Plano <span style="color: red;">*</span></label>
                                
                                    <select class="form-control" formControlName="plano_tipo">
                                        <option value="M">Médico</option>
                                        <option value="O">Odontológico</option>
                                    </select>
                                
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Seguradora <span class="obrigatorio">*</span></label>
                                <input type="text" class="form-control" formControlName="nome_seguradora">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Data da Adesão <span class="obrigatorio">*</span></label>
                                <input type="text" placeholder="DD/MM/YYYY" class="form-control" [dropSpecialCharacters]="false" mask="00/00/0000" formControlName="data_adesao">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Data da Exclusão </label>
                                <input type="text" placeholder="DD/MM/YYYY" class="form-control" [dropSpecialCharacters]="false" mask="00/00/0000" formControlName="data_exclusao">
                            </div>
                        </div>
                    </div>
                </form>              
            </div>
           
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-space btn-default md-close">Cancelar</button>
                <button type="button" class="btn btn-space btn-primary md-close" (click)="incluirPlanoSaude()">Cadastrar</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal Carta Fiança -->
<div id="formCartaFianca" tabindex="-1" role="dialog" class="modal fade colored-header colored-header-primary">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close md-close"><span class="mdi mdi-close"></span></button>
                <h3 class="modal-title">Carta Fiança</h3>
            </div>
            <div class="modal-body">
                <form #cartaFiancaForm="ngForm" [formGroup]="formCartaFianca" novalidate="">

                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Endereço do Imóvel <span class="obrigatorio">*</span></label>
                                    <input type="text" class="form-control" formControlName="endereco_imovel">
                                </div>
                            </div>
                        </div>

                        <div class="row" *ngIf="!internacional || internacional == 'false'">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Unidade da Federação <span style="color: red;">*</span></label>
                                    <select class="form-control" formControlName="sigla_uf">
                                        <option *ngFor="let dado of Ufs" value="{{dado.sigla_uf}}">
                                            {{dado.sigla_uf}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Cidade <span class="obrigatorio">*</span></label>
                                    <input type="text" class="form-control" formControlName="cidade">
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Data início da Vigência <span class="obrigatorio">*</span></label>
                                    <input type="text" placeholder="DD/MM/YYYY" class="form-control" [dropSpecialCharacters]="false" mask="00/00/0000" formControlName="data_inicio_vigencia">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Data fim da Vigência <span class="obrigatorio">*</span></label>
                                    <input type="text" placeholder="DD/MM/YYYY" class="form-control" [dropSpecialCharacters]="false" mask="00/00/0000" formControlName="data_fim_vigencia">
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Data da Concessão <span class="obrigatorio">*</span></label>
                                    <input type="text" placeholder="DD/MM/YYYY" class="form-control" [dropSpecialCharacters]="false" mask="00/00/0000" formControlName="data_concessao">
                                    
                                </div>
                            </div>
                        </div>

                </form>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default md-close">Cancelar</button>
                <button type="button" class="btn btn-primary md-close" (click)="incluirCartaFianca()">Cadastrar</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal Auxilio Participacao Cientifica -->
<div id="formAuxilioParticipacaoCientifica" tabindex="-1" role="dialog" class="modal fade colored-header colored-header-primary">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close md-close"><span class="mdi mdi-close"></span></button>
                <h3 class="modal-title">Auxílio Participação Científica</h3>
            </div>
            <div class="modal-body">
                <form #auxilioParticipacaoCientificaForm="ngForm" [formGroup]="formAuxilioParticipacaoCientifica" novalidate="">

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Tipo <span style="color: red;">*</span></label>
                                <select class="form-control" formControlName="internacional" (change)="definirTipoEvento($event.target.value)">
                                    <option value="false">Nacional</option>
                                    <option value="true">Internacional</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6"  *ngIf="internacional && internacional == 'true'">
                            <div class="form-group">
                                <label>País <span style="color: red;">*</span></label>
                                <select class="form-control" formControlName="codigo_pais">
                                    <option *ngFor="let dado of paises" value="{{dado.codigo_pais}}">
                                        {{dado.nome}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Evento <span class="obrigatorio">*</span></label>
                                <input type="text" class="form-control" formControlName="evento">
                            </div>
                        </div>
                    </div>

                    <div class="row" *ngIf="!internacional || internacional == 'false'">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Unidade da Federação <span style="color: red;">*</span></label>
                                <select class="form-control" formControlName="sigla_uf">
                                    <option *ngFor="let dado of Ufs" value="{{dado.sigla_uf}}">
                                        {{dado.sigla_uf}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Cidade <span class="obrigatorio">*</span></label>
                                <input type="text" class="form-control" formControlName="cidade">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Data do Evento <span class="obrigatorio">*</span></label>
                                <input type="text" placeholder="DD/MM/YYYY" class="form-control" [dropSpecialCharacters]="false" mask="00/00/0000" formControlName="data_evento">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Data da Concessão <span class="obrigatorio">*</span></label>
                                <input type="text" placeholder="DD/MM/YYYY" class="form-control" [dropSpecialCharacters]="false" mask="00/00/0000" formControlName="data_concessao">
                                
                            </div>
                            <br />
                            <div class="form-group">
                                <label>Valor <span class="obrigatorio">*</span></label>
                                <input type="text" placeholder="R$" class="form-control" currencyMask formControlName="valor">
                                
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default md-close">Cancelar</button>
                <button type="button" class="btn btn-primary md-close" (click)="incluirAuxilioParticipacaoCientifica()">Cadastrar</button>
            </div>
        </div>
    </div>
</div>

<!--Modal Seguro de Vida-->
<div id="formSeguroVida" tabindex="-1" role="dialog" class="modal fade colored-header colored-header-primary">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close md-close"><span class="mdi mdi-close"></span></button>
                <h3 class="modal-title">Seguro de Vida</h3>
            </div>
            <div class="modal-body">
                <form #seguroVidaForm="ngForm" [formGroup]="formSeguroVida" novalidate="">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Data da Concessão <span class="obrigatorio">*</span></label>
                            <input type="text" placeholder="DD/MM/YYYY" class="form-control" [dropSpecialCharacters]="false" mask="00/00/0000" formControlName="data_concessao">
                            
                        </div>
                        <br />
                        <div class="form-group">
                            <label>Valor <span class="obrigatorio">*</span></label>
                            <input type="text" placeholder="R$" class="form-control" currencyMask formControlName="valor">
                            
                        </div>
                    </div>
                </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default md-close">Cancelar</button>
                <button type="button" class="btn btn-primary md-close" (click)="incluirSeguroVida()">Cadastrar</button>
            </div>
        </div>
    </div>
</div>

<!--Modal Patrocinio-->
<div id="formPatrocinio" tabindex="-1" role="dialog" class="modal fade colored-header colored-header-primary">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close md-close"><span class="mdi mdi-close"></span></button>
                <h3 class="modal-title">Patrocínio</h3>
            </div>
            <div class="modal-body">
                <form #contaPagarForm="ngForm" [formGroup]="formPatrocinio" novalidate="">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Data da Concessão <span class="obrigatorio">*</span></label>
                                <input type="text" placeholder="DD/MM/YYYY" class="form-control" [dropSpecialCharacters]="false" mask="00/00/0000" formControlName="data_concessao">
                            </div>
                            <br />
                            <div class="form-group">
                                <label>Valor <span class="obrigatorio">*</span></label>
                                <input type="text" placeholder="R$" class="form-control" currencyMask formControlName="valor">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row no-margin-y">
                                <div class="form-group col-xs-12">
                                    <label>Descrição</label>
                                    <textarea cols="5" rows="8" class="form-control" formControlName="descricao"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default md-close">Cancelar</button>
                <button type="button" data-dismiss="modal" class="btn btn-primary md-close" (click)="incluirPatrocinio()">Cadastrar</button>
            </div>
        </div>
    </div>
</div>

<!--Modal Auxilio Juridico Individual-->
<div id="formAuxilioJuridicoIndividual" tabindex="-1" role="dialog" class="modal fade colored-header colored-header-primary">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close md-close"><span class="mdi mdi-close"></span></button>
                <h3 class="modal-title">Auxílio Jurídico I</h3>
            </div>
            <div class="modal-body">
                <form #contaPagarForm="ngForm" [formGroup]="formAuxilioJuridicoIndividual" novalidate="">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Data da Concessão <span class="obrigatorio">*</span></label>
                                <input type="text" placeholder="DD/MM/YYYY" class="form-control" [dropSpecialCharacters]="false" mask="00/00/0000" formControlName="data_concessao">
                            </div>
                            <br />
                            <div class="form-group">
                                <label>Valor <span class="obrigatorio">*</span></label>
                                <input type="text" placeholder="R$" class="form-control" currencyMask formControlName="valor">
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default md-close">Cancelar</button>
                <button type="button" data-dismiss="modal" class="btn btn-primary md-close" (click)="incluirAuxilioJuridicoIndividual()">Cadastrar</button>
            </div>
        </div>
    </div>
</div>

<!--Modal Auxilio Juridico Coletivo-->
<div id="formAuxilioJuridicoColetivo" tabindex="-1" role="dialog" class="modal fade colored-header colored-header-primary">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close md-close"><span class="mdi mdi-close"></span></button>
                <h3 class="modal-title">Auxílio Jurídico II</h3>
            </div>
            <div class="modal-body">
                <form #contaPagarForm="ngForm" [formGroup]="formAuxilioJuridicoColetivo" novalidate="">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Modalidade <span style="color: red;">*</span></label>
                                <select class="form-control" formControlName="codigo_modalidade_juridico">
                                    <option *ngFor="let dado of modalidades" value="{{dado.codigo_modalidade_juridico}}">
                                        {{dado.nome}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Tipo de Contratação <span style="color: red;">*</span></label>
                                <select class="form-control" formControlName="contratacao_tipo" (change)="definirTipoContratacao($event.target.value)">
                                    <option value="D">Direta (APCF)</option>
                                    <option value="I">Indireta (Associado)</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="contratacao_tipo == 'D'">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Data da Ressarcimento <span class="obrigatorio">*</span></label>
                                <input type="text" placeholder="DD/MM/YYYY" class="form-control" [dropSpecialCharacters]="false" mask="00/00/0000" formControlName="data_concessao">
                            </div>
                            <br />
                            <div class="form-group">
                                <label>Valor <span class="obrigatorio">*</span></label>
                                <input type="text" placeholder="R$" class="form-control" currencyMask formControlName="valor">
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="contratacao_tipo == 'I'">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Nome do Escritório Contratado <span class="obrigatorio">*</span></label>
                                <input type="text" class="form-control" formControlName="nome_escritorio_advocacia">
                            </div>
                            <br />
                            <div class="form-group">
                                <label>Endereço <span class="obrigatorio">*</span></label>
                                <input type="text" class="form-control" formControlName="endereco_contratado">
                            </div>
                            <br />
                            <div class="form-group">
                                <label>Nome do Advogado <span class="obrigatorio">*</span></label>
                                <input type="text" class="form-control" formControlName="nome_contratado">
                            </div>
                            <br />
                            <div class="form-group">
                                <label>OAB <span class="obrigatorio">*</span></label>
                                <input type="text" class="form-control" formControlName="oab_contratado">
                            </div>
                            <br />
                            <div class="form-group">
                                <label>Telefone <span class="obrigatorio">*</span></label>
                                <input type="text" class="form-control" [dropSpecialCharacters]="true" mask="(00) 0000-0000||(00)0 0000-0000" formControlName="telefone_contratado">
                            </div>
                            <br />
                            <div class="form-group">
                                <label>E-mail <span class="obrigatorio">*</span></label>
                                <input type="text" class="form-control" formControlName="email_contratado">
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default md-close">Cancelar</button>
                <button type="button" class="btn btn-primary md-close" (click)="incluirAuxilioJuridicoColetivo()">Cadastrar</button>
            </div>
        </div>
    </div>
</div>

<!--Modal Auxilio Natalidade-->
<div id="formAuxilioNatalidade" tabindex="-1" role="dialog" class="modal fade colored-header colored-header-primary">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close md-close"><span class="mdi mdi-close"></span></button>
                <h3 class="modal-title">Auxílio Natalidade</h3>
            </div>
            <div class="modal-body">
                <form #contaPagarForm="ngForm" [formGroup]="formAuxilioNatalidade" novalidate="">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Data da Concessão <span class="obrigatorio">*</span></label>
                                <input type="text" placeholder="DD/MM/YYYY" class="form-control" [dropSpecialCharacters]="false" mask="00/00/0000" formControlName="data_concessao">
                            </div>
                            <br />
                            <div class="form-group">
                                <label>Valor <span class="obrigatorio">*</span></label>
                                <input type="text" placeholder="R$" class="form-control" currencyMask formControlName="valor">
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default md-close">Cancelar</button>
                <button type="button" data-dismiss="modal" class="btn btn-primary md-close" (click)="incluirAuxilioNatalidade()">Cadastrar</button>
            </div>
        </div>
    </div>
</div>

<!--Modal Auxilio Funeral-->
<div id="formAuxilioFuneral" tabindex="-1" role="dialog" class="modal fade colored-header colored-header-primary">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close md-close"><span class="mdi mdi-close"></span></button>
                <h3 class="modal-title">Auxílio Funeral</h3>
            </div>
            <div class="modal-body">
                <form #contaPagarForm="ngForm" [formGroup]="formAuxilioFuneral" novalidate="">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Data da Concessão <span class="obrigatorio">*</span></label>
                                <input type="text" placeholder="DD/MM/YYYY" class="form-control" [dropSpecialCharacters]="false" mask="00/00/0000" formControlName="data_concessao">
                            </div>
                            <br />
                            <div class="form-group">
                                <label>Valor <span class="obrigatorio">*</span></label>
                                <input type="text" placeholder="R$" class="form-control" currencyMask formControlName="valor">
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default md-close">Cancelar</button>
                <button type="button" data-dismiss="modal" class="btn btn-primary md-close" (click)="incluirAuxilioFuneral()">Cadastrar</button>
            </div>
        </div>
    </div>
</div>

<!--Modal Auxilio Post Mortem-->
<div id="formAuxilioPostMortem" tabindex="-1" role="dialog" class="modal fade colored-header colored-header-primary">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close md-close"><span class="mdi mdi-close"></span></button>
                <h3 class="modal-title">Auxílio Post Mortem</h3>
            </div>
            <div class="modal-body">
                <form #beneficioPostMortemForm="ngForm" [formGroup]="formPostMortem" novalidate="">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Data do Requerimento <span class="obrigatorio">*</span></label>
                                <input type="text" placeholder="DD/MM/YYYY" class="form-control" [dropSpecialCharacters]="false" mask="00/00/0000" formControlName="data_requerimento">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Data da Óbito <span class="obrigatorio">*</span></label>
                                <input type="text" placeholder="DD/MM/YYYY" class="form-control" [dropSpecialCharacters]="false" mask="00/00/0000" formControlName="data_obito">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Valor Concedido <span class="obrigatorio">*</span></label>
                                <input type="text" placeholder="R$" class="form-control" currencyMask formControlName="valor">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Data do Pagamento <span class="obrigatorio">*</span></label>
                                <input type="text" placeholder="DD/MM/YYYY" class="form-control" [dropSpecialCharacters]="false" mask="00/00/0000" formControlName="data_concessao">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Nome do Beneficiário <span class="obrigatorio">*</span></label>
                                <input type="text" class="form-control" formControlName="nome_beneficiario">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Grau de Parentesco <span style="color: red;">*</span></label>
                                <select class="form-control" formControlName="grau_parentesco">
                                    <option value="C">Cônjuge</option>
                                    <option value="O">Companheiro(a)</option>
                                    <option value="F">Filhos ou Enteados</option>
                                    <option value="P">Pais</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default md-close">Cancelar</button>
                <button type="button" data-dismiss="modal" class="btn btn-primary md-close" (click)="incluirAuxilioPostMortem()">Cadastrar</button>
            </div>
        </div>
    </div>
</div>

<!--Modal Convênios: Declaração-->
<div id="formClubeConvenio" tabindex="-1" role="dialog" class="modal fade colored-header colored-header-primary">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close md-close"><span class="mdi mdi-close"></span></button>
                <h3 class="modal-title">Convênios: Declaração</h3>
            </div>
            <div class="modal-body">
                <form #clubeConvenioForm="ngForm" [formGroup]="formClubeConvenio" novalidate="">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Tipo de Convênios <span style="color: red;">*</span></label>
                                <select class="form-control" formControlName="convenio_tipo" (change)="definirTipoConvenio($event.target.value)">
                                    <option value="A">Automóvel</option>
                                    <option value="C">Crédito</option>
                                    <option value="E">Educacional</option>
                                    <option value="O">Outros</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Data da Emissão da Declaração <span class="obrigatorio">*</span></label>
                                <input type="text" placeholder="DD/MM/YYYY" class="form-control" [dropSpecialCharacters]="false" mask="00/00/0000" formControlName="data_emissao_declaracao">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Finalidade <span class="obrigatorio">*</span></label>
                                <input type="text" class="form-control" formControlName="finalidade">
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default md-close">Cancelar</button>
                <button type="button" class="btn btn-primary md-close" (click)="incluirClubeConvenio()">Cadastrar</button>
            </div>
        </div>
    </div>
</div>

<!--Modal Footer-->
<div id="modal-excluir" tabindex="-1" role="dialog" style="" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <div class="text-warning"><span class="modal-main-icon mdi mdi-alert-triangle"></span></div>
                    <h3>Tem certeza que deseja EXCLUIR este benefício?</h3>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default">Cancelar</button>
                <button type="button" data-dismiss="modal" class="btn btn-warning" (click)="apagar(codigo_associado_beneficio)">Confirmar</button>
            </div>
        </div>
    </div>
</div>   

<div id="formReativar" tabindex="-1" role="dialog" style="" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <div class="text-warning"><span class="modal-main-icon mdi mdi-alert-triangle"></span></div>
                    <h3>Tem certeza que deseja RESTAURAR este registro?</h3>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default">Cancelar</button>
                <button type="button" data-dismiss="modal" class="btn btn-warning" (click)="restaurar(codigo_associado_beneficio)">Confirmar</button>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>