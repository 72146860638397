<app-header></app-header>

<div class="panel panel-default panel-border-color panel-border-color-primary">
  <div class="panel-heading panel-heading-divider" *ngIf="!codigo_documento"><span>Gerar Novo Documento</span></div>
  <div class="panel-heading panel-heading-divider" *ngIf="codigo_documento"><span>Alterar Documento</span></div>
  <div class="panel-body" *ngIf="carregando">
    <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
  </div>
  <div class="panel-body" *ngIf="!carregando">
    <form #documentoForm="ngForm" [formGroup]="form" novalidate="" class="form-horizontal group-border-dashed">

      <div class="form-group xs-mt-5">
        <label for="inputEmail" class="col-sm-3 control-label">
          <b>Nº / Ano</b>
        </label>
        <div class="col-sm-5" *ngIf="!codigo_documento">
          <p style="margin-top: 10px;">
            Gerado pelo sistema na confirmação do cadastro.
          </p>
        </div>
        <div class="col-sm-5" *ngIf="codigo_documento">
          <p style="margin-top: 10px;">
            {{numero}} / {{ano}}
          </p>
        </div>
      </div>

      <div class="form-group xs-mt-10">
        <label for="inputNome" class="col-sm-3 control-label">
          <span class="obrigatorio">*</span> <b>Nº vinculado</b>
        </label>
        <div class="col-sm-3">
          <input type="text" class="form-control" formControlName="numero_documento_vinculado">
          <app-error-msg [control]="form.get('numero_documento_vinculado')" label="Nº do documento vinculado"></app-error-msg>
          <div *ngIf="submitted && form.numero_documento_vinculado.errors" class="obrigatorio">
            <div *ngIf="form.numero_documento_vinculado.errors.required">Campo obrigatório</div>
          </div>
        </div>
      </div>

      <div class="form-group xs-mt-5">
        <label for="inputEmail" class="col-sm-3 control-label">
          <span class="obrigatorio">*</span> <b>Tipo</b>
        </label>
        <div class="col-sm-5" *ngIf="!codigo_documento_tipo">
          <select class="select2 form-control" formControlName="codigo_documento_tipo">
            <option *ngFor="let dado of documentos_tipo" value="{{dado.codigo_documento_tipo}}">
              {{dado.nome}}
            </option>
          </select>
          <div *ngIf="submitted && form.codigo_documento_tipo.errors" class="obrigatorio">
            <div *ngIf="form.codigo_documento_tipo.errors">Campo obrigatório</div>
          </div>
        </div>
        <div class="col-sm-5" *ngIf="codigo_documento_tipo">
          <p style="margin-top: 10px;">
            {{nome_documento_tipo}}
          </p>
        </div>
      </div>

      <div class="form-group xs-mt-10">
        <label for="inputNome" class="col-sm-3 control-label">
          <span class="obrigatorio">*</span> <b>Data</b>
        </label>
        <div class="col-sm-3">
          <input type="text" placeholder="DD/MM/YYYY" class="form-control" [dropSpecialCharacters]="false" mask="00/00/0000" formControlName="data_documento">
          <app-error-msg [control]="form.get('data_documento')" label="Data do Documento"></app-error-msg>
          <div *ngIf="submitted && form.data_documento.errors" class="obrigatorio">
            <div *ngIf="form.data_documento.errors.required">Campo obrigatório</div>
          </div>
        </div>
      </div>

      <div class="form-group xs-mt-10">
        <label for="inputNome" class="col-sm-3 control-label">
          <span class="obrigatorio">*</span> <b>Assunto</b>
        </label>
        <div class="col-sm-5">
          <input id="inputNome" type="text" class="form-control" formControlName="assunto">
          <div *ngIf="submitted && form.assunto.errors" class="obrigatorio">
            <div *ngIf="form.assunto.errors.required">Campo obrigatório</div>
          </div>
        </div>
      </div>

      <div class="form-group xs-mt-10">
        <label for="inputNome" class="col-sm-3 control-label">
          <b>URL</b>
        </label>
        <div class="col-sm-5">
          <input id="inputNome" type="text" class="form-control" formControlName="url">
          <div *ngIf="submitted && form.url.errors" class="obrigatorio">
            <div *ngIf="form.url.errors.required">Campo obrigatório</div>
          </div>
        </div>
      </div>

      <div class="form-group xs-mt-10">
        <label for="inputNome" class="col-sm-3 control-label">
          <b>Arquivo</b>
        </label>
        <div class="col-sm-5">
          <button *ngIf="!anexo" data-toggle="modal" data-target="#md-footer-novo-arquivo" class="btn btn-space btn-default"><i class="icon icon-left mdi mdi-file"></i> Incluir documento</button>
          <button *ngIf="anexo" data-toggle="modal" data-target="#md-footer-novo-arquivo" class="btn btn-space btn-default"><i class="icon icon-left mdi mdi-file"></i> Alterar documento</button>
          <p *ngIf="anexo" style="margin-top: 10px; cursor: pointer;" (click)="baixarDocumento(codigo_documento, anexo)"><b>Documento anexado:</b> {{anexo | slice:0:25}}<span *ngIf="anexo.length > 25"> ...</span>&nbsp;&nbsp;&nbsp;<i class="mdi mdi-download"></i></p>
          <div *ngIf="submitted && form.anexo.errors" class="obrigatorio">
            <div *ngIf="form.anexo.errors.required">Campo obrigatório</div>
          </div>
        </div>
      </div>

      <div class="form-group xs-mt-10">
        <label for="inputNome" class="col-sm-3 control-label">
          <b>Observação</b>
        </label>
        <div class="col-sm-5">
          <ckeditor [config]="config" formControlName="observacao" [editor]="Editor"></ckeditor>
          <div *ngIf="submitted && form.observacao.errors" class="obrigatorio">
            <div *ngIf="form.observacao.errors.required">Campo obrigatório</div>
          </div>
        </div>
      </div>

      <div class="form-group">
          <div class="col-sm-offset-3 col-sm-7">
            <button class="btn btn-space btn-default" routerLink="/documento-listar">Cancelar</button>
            <button type="button" data-dismiss="modal" class="btn btn-space btn-success" (click)="onSubmit()">Confirmar</button>
          </div>
      </div>

    </form>
  </div>
</div>

<!-- Modal novo arquivo -->
<div id="md-footer-novo-arquivo" tabindex="-1" role="dialog" style="" class="modal fade colored-header colored-header-primary">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-header">
              <button type="button" data-dismiss="modal" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
              <h3 class="modal-title">Envio de Arquivo</h3>
          </div>
          <div class="modal-body">
              
              <form #tratamentoForm="ngForm" [formGroup]="form" novalidate="" class="form-horizontal group-border-dashed">
                  <div class="custom-file">
                      <p>Arquivos permitidos: jpeg | jpg | png | gif | doc | docx | pdf | xls | xlsx</p>
                      <input type="file" class="custom-file-input" id="customFile" (change)="onChange($event)" multiple />
                      <br />
                      <label class="custom-file-label" for="customFile" id="customFileLabel" *ngIf="files"></label>
                    </div>
                  <br />
                  <br />
                  <div class="progress" *ngIf="carregando">
                      <div class="progress-bar" role="progressbar" [style.width]="progress + '%'">
                          {{ progress }}%
                      </div>
                  </div>
                </form>

          </div>
          <div class="modal-footer">
              <button type="button" data-dismiss="modal" class="btn btn-default">Cancelar</button>
              <button type="button" data-dismiss="modal" class="btn btn-primary" (click)="onUpload()">Enviar</button>
          </div>
      </div>
  </div>
</div>

<!--Modal Footer-->
<div id="md-footer-success" tabindex="-1" role="dialog" style="" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <div class="text-warning"><span class="modal-main-icon mdi mdi-alert-triangle"></span></div>
                <h3>Caso haja plano vigente para esta classe, o plano atual será desativado e um novo será criado.<br /> Confirma esta operação?</h3>
                <p>Esta operação não poderá ser desfeita.</p>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default">Cancelar</button>
                <button type="button" data-dismiss="modal" class="btn btn-warning" (click)="onSubmit()">Confirmar</button>
            </div>
        </div>
    </div>
</div>   

<app-footer></app-footer>
