<app-header></app-header>

<div class="row">
    <div class="col-sm-12">
        <div class="panel panel-default panel-table">
            <div class="panel-heading">

            </div>
            <div class="panel-body">
                <form [formGroup]="form" (ngSubmit)="list(tipo_layout.value, ano_referencia.value)" class="form-horizontal group-border-dashed">
                    <div class="form-group">
                      <span class="col-sm-3" style="text-align: right;">
                        <label class="control-label">Tipo de Layout <span class="obrigatorio">*</span></label>
                      </span>
                        <span class="col-sm-3">
                            <select #tipo_layout class="select2 form-control" formControlName="tipo_layout" required>
                                <option value="">Selecione</option>
                                <option *ngFor="let tipo_layout of tipos_layout" [value]="tipo_layout.sigla">
                                    {{tipo_layout.descricao}}
                                </option>
                            </select>
                      </span>
                    </div>
                    <div class="form-group">
                      <span class="col-sm-3" style="text-align: right;">
                        <label class="control-label">Ano refer&ecirc;ncia <span class="obrigatorio">*</span></label>
                      </span>
                        <span class="col-sm-3">
                            <select #ano_referencia class="select2 form-control" formControlName="ano_referencia" required>
                                <option value="">Selecione</option>
                                <option *ngFor="let ano of anos" [value]="ano">
                                    {{ano}}
                                </option>
                            </select>
                      </span>
                    </div>
                    <div class="form-group">
                        <div class="col-sm-offset-3 col-sm-7">
                            <button type="submit" class="btn btn-space btn-primary">Pesquisar</button>
                            <button type="button" class="btn btn-space btn-default" (click)="gerarNovo()">Gerar novo</button>
                        </div>
                    </div>
                </form>
                <hr/>
                <div class="dataTables_length" id="table3_length">
                    <table id="table2" class="table table-striped table-hover table-fw-widget" *ngIf="carregado">
                        <thead>
                            <tr>
                                <th>Tipo</th>
                                <th>Layout</th>
                                <th>Ano refer&ecirc;ncia</th>
                                <th>M&ecirc;s refer&ecirc;ncia</th>
                                <th>Qtd. associados</th>
                                <th>Data de gera&ccedil;&atilde;o</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="arquivos && arquivos.length > 0">
                            <tr class="odd gradeX" *ngFor="let arquivo of arquivos">
                                <td>{{arquivo.tipo_layout.descricao}}</td>
                                <td>{{arquivo.layout.titulo}}</td>
                                <td>{{arquivo.mes_referencia}}</td>
                                <td>{{arquivo.ano_referencia}}</td>
                                <td>{{arquivo.associados.length}}</td>
                                <td>{{arquivo.created_at | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
                                <td>
                                    <button class="btn btn-primary" (click)="downloadFile(arquivo.codigo_arquivo)">
                                        Download
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="!arquivos">
                            <tr class="carregando">
                                <td class="carregando" colspan="6">
                                    <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="arquivos && arquivos.length == 0">
                            <tr>
                                <td class="col-md-12" colspan="6">
                                    Nenhum registro encontrado.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="carregando" *ngIf="mostrar_carregando">
                    <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
                </div>
            </div>
        </div>
    </div>
</div>
