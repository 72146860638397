import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { AppComponent } from 'src/app/app.component';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { BeneficioTipo } from 'src/app/shared/models/beneficio-tipo';
import { BeneficioTipoService } from 'src/app/core/services/beneficio-tipo.service';

@Component({
    selector: 'app-beneficio-tipo-editar',
    templateUrl: './beneficio-tipo-editar.component.html',
    styleUrls: ['./beneficio-tipo-editar.component.css'],
})
export class BeneficioTipoEditarComponent implements OnInit {
    @ViewChild('beneficioTipoForm') formValues;

    form: FormGroup;
    breadCrumb;
    beneficios_tipo: BeneficioTipo;
    carregando: boolean;
    submited: boolean;
    codigo_beneficio_tipo: any;

    constructor(
        private appComponent: AppComponent,
        private formBuilder: FormBuilder,
        private routerParam: ActivatedRoute,
        private router: Router,
        private beneficioTipoService: BeneficioTipoService,
        private notifierService: NotifierService,
    ) {
        this.codigo_beneficio_tipo = this.routerParam.snapshot.params.id;
    }

    ngOnInit() {
        this.carregando = true;
        this.breadCrumb = [
            { nome: 'Configuração', caminho: '/configuracao' },
            { nome: 'beneficios_tipo', caminho: '/beneficio-tipo-listar' },
        ];
        if (this.codigo_beneficio_tipo) {
            this.appComponent.setTitulo('Alteração de beneficio tipo');
        } else {
            this.appComponent.setTitulo('Cadastro de beneficio tipo');
        }
        this.appComponent.setBreadcrumb(this.breadCrumb);

        this.form = this.formBuilder.group({
            codigo_beneficio_tipo: [null],
            nome: [null, Validators.required],
        });

        this.obter();
    }

    obter(): void {
        this.beneficioTipoService
            .find(this.codigo_beneficio_tipo)
            .subscribe((data: BeneficioTipo) => {
                this.form.patchValue({
                    codigo_beneficio_tipo: data.codigo_beneficio_tipo,
                    nome: data.nome,
                });
            });
    }

    onSubmit(): void {
        this.submited = true;
        this.carregando = true;
        const beneficio_tipo = this.form.value as BeneficioTipo;
        if (this.form.valid) {
            this.beneficioTipoService
                .save(beneficio_tipo, this.codigo_beneficio_tipo)
                .subscribe(
                    (data) => {
                        this.notifierService.notify(
                            'success',
                            'Beneficio tipo cadastrada com sucesso.',
                        );
                        this.carregando = false;
                        this.router.navigate(['beneficio-tipo-listar']);
                    },
                    (error) => {
                        this.notifierService.notify(
                            'error',
                            'Não foi cadastrar uma nova Beneficio Tipo. ' +
                                error,
                        );
                        this.carregando = false;
                    },
                );
        } else {
            console.log(this.form);
            this.notifierService.notify(
                'error',
                'Existem erros no preenchimento do formulário.',
            );
            this.carregando = false;
        }
    }
}
