import {Injectable} from "@angular/core";
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import { environment } from '../../../environments/environment';

import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class AreaAtuacaoPericiaParticularService {

  private _Url = environment.UrlPrincipal + '/api/areaatuacaopericiaparticular';

  constructor(private http: HttpClient) {}

  list(codigo) {
    return this.http.get(this._Url + '?codigo_pessoa=' + codigo)
            .catch(this.handleError);
  }

  save(dados): Observable<any>{
    let http;
    http = this.http.post(this._Url, dados);
    http.map((res) => {
        return res;
    }).catch(this.handleError);
    return http;
  }

  delete(codigo) {
    return this.http.delete(this._Url + "/" + codigo);
  }

  private handleError(error: Response) {
    return error.json();
  }

}
