import { Component, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute, Router } from '@angular/router';
import { RepasseService } from '../../../core/services/repasse.service';
import { Repasse } from '../../../shared/models/repasse';
import { LotacaoService } from 'src/app/core/services/lotacao.service';
import { Lotacao } from 'src/app/shared/models/lotacao';
import { RepasseSolicitacaoService } from 'src/app/core/services/repasse-solicitacao.service';
import { RepasseSolicitacao } from 'src/app/shared/models/repasse-solicitacao';
import { UfService } from 'src/app/core/services/uf.service';
import { Uf } from 'src/app/shared/models/uf';


@Component({
  selector: 'app-repasse-listar',
  templateUrl: './repasse-listar.component.html',
  styleUrls: ['./repasse-listar.component.css']
})
export class RepasseListarComponent implements OnInit {

  breadCrumb;
  submitted: boolean;

  codigo_associado_pagamento_adicional_parcela: any;
  codigo_plano: any;
  carregando: boolean;
  carregando_dados: boolean;
  filtro_ano: any;
  filtro_mes: any;
  filtro_lotacao: any;
  ano: any;
  selected: any;
  startYear: any;
  mes_atual: any;
  meses: any;
  codigo_lotacao: any;
  codigo_pessoa: any;
  codigo_repasse: any;
  repasses: Repasse;
  lotacoes: Lotacao;
  repasses_pendentes: RepasseSolicitacao;
  repasses_inativos: RepasseSolicitacao;
  codigo_repasse_solicitacao: any;
  sigla_uf: any;
  ufs: Uf;
  filtro_sigla_uf: any;
  carregando_repasse: boolean;

  constructor(private appComponent: AppComponent,
              private routerParam: ActivatedRoute,
              private router: Router,
              private repasseService: RepasseService,
              private UfService: UfService,
              private repasseSolicitacaoService: RepasseSolicitacaoService,
              private lotacaoService: LotacaoService,
              private notifierService: NotifierService) {
              this.codigo_pessoa = this.routerParam.snapshot.params.id;
  }

  ngOnInit() {
    this.breadCrumb = [
      {nome: 'Associados', caminho: 'associado-listar'}, 
      {nome: 'Repasse', caminho: '/repasse-listar'}
    ];
    this.appComponent.setTitulo('Repasse');
    this.appComponent.setBreadcrumb(this.breadCrumb);

    this.listar();
    this.listarPendenciasAprovacao();
    this.listarInativos();
    this.obterMes();
    this.obterAno();
    this.obterLotacao();
    this.obterUf();
    this.carregando = false;

  }

  definirCodigo(codigo_repasse) {
    this.codigo_repasse = codigo_repasse;
  }

  obterLotacao() {
    this.lotacaoService.list()
    .subscribe((data: Lotacao) => this.lotacoes = data,
                error => console.log(error));
  }

  obterUf() {
    this.UfService.list()
    .subscribe((data: Uf) => this.ufs = data,
                error => console.log(error));
  }

  obterAno() {
    this.filtro_ano = new Date().getFullYear();   
    this.startYear = 2020;
    this.ano = [{"ano": this.startYear}];
    for(let i=this.startYear+1; i <= this.filtro_ano+1; i++) {
      this.ano.push({"ano": i});
    }
  }

  obterMes() {
    this.meses = [
                  {"codigo": "1", "nome": "Janeiro"}, 
                  {"codigo": "2", "nome": 'Fevereiro'}, 
                  {"codigo": "3", "nome": "Março"},
                  {"codigo": "4", "nome": "Abril"},
                  {"codigo": "5", "nome": "Maio"},
                  {"codigo": "6", "nome": "Junho"},
                  {"codigo": "7", "nome": "Julho"},
                  {"codigo": "8", "nome": "Agosto"},
                  {"codigo": "9", "nome": "Setembro"},
                  {"codigo": "10", "nome": "Outubro"},
                  {"codigo": "11", "nome": "Novembro"},
                  {"codigo": "12", "nome": "Dezembro"},
                 ];
  }

  definirFiltroMes(mes) {
    this.filtro_mes = mes;
  }

  definirFiltroLotacao(lotacao) {
    this.filtro_lotacao = lotacao;
  }

  definirFiltroRegional(sigla_uf) {
    this.filtro_sigla_uf = sigla_uf;
  }

  definirFiltroAno(ano) {
    this.filtro_ano = ano;
  }

  definirCodigoRepasseSolicitacao(codigo_repasse_solicitacao) {
    this.codigo_repasse_solicitacao = codigo_repasse_solicitacao;
  }

  listarPendenciasAprovacao(): void {
    // planos ativos
    this.repasses = null;
    this.carregando = true;

    this.repasseSolicitacaoService.list(false).subscribe((data: RepasseSolicitacao) => {
      this.repasses_pendentes = data;
      this.carregando = false;
    }, error => console.log(error));
  }

  listarInativos(): void {
    // planos ativos
    this.repasses = null;
    this.carregando = true;

    this.repasseSolicitacaoService.list(true).subscribe((data: RepasseSolicitacao) => {
      this.repasses_inativos = data;
      this.carregando = false;
    }, error => console.log(error));
  }

  listar(): void {
    // planos ativos
    this.repasses = null;
    this.carregando = true;
    if(!this.filtro_mes) {
      this.filtro_mes = new Date().getMonth() + 1;
    } else if(this.filtro_mes == 'todos') {
      this.filtro_mes = null;
    }

    if(!this.filtro_ano) {
      this.filtro_ano = new Date().getFullYear();
    }
    this.repasseService.list(this.filtro_sigla_uf, this.filtro_mes, this.filtro_ano).subscribe((data: Repasse) => {
      this.repasses = data;
      this.carregando = false;
      this.mes_atual = new Date().getMonth();
      this.mes_atual = this.mes_atual + 1;
      console.log(this.mes_atual+1);
      
    }, error => console.log(error));
  }

  onSubmit(): void {
    this.carregando_repasse = true;
    let repasse = {'data_referencia': '01/' + this.filtro_mes + '/' + this.filtro_ano};
    this.repasseService.save(repasse, null).subscribe((data) => {
      this.notifierService.notify( 'success', 'Repasse CRIADO com sucesso.' );
      this.carregando_repasse = false;
      this.listar();
      this.listarPendenciasAprovacao();
      this.listarInativos();
    }, (error) => {
      this.notifierService.notify( 'error', 'Não foi aprovar o repasse. ' + error );
      this.carregando_repasse = false;
    });
  }

  aprovar(codigo_repasse_situacao): void {
    this.carregando = true;
    if(codigo_repasse_situacao) {
      let situacao = {'aprovado': 'S'};
      this.repasseSolicitacaoService.save(situacao, codigo_repasse_situacao).subscribe((data) => {
        this.notifierService.notify( 'success', 'Repasse APROVADO com sucesso.' );
        this.carregando = false;
        this.listar();
        this.listarPendenciasAprovacao();
        this.listarInativos();
      }, (error) => {
        this.notifierService.notify( 'error', 'Não foi aprovar o repasse. ' + error );
        this.carregando = false;
      });
    } else {
      this.notifierService.notify( 'error', 'Informe o código do repasse para aprovação.');
      this.carregando = false;
    }
  }

  desaprovar(codigo_repasse_situacao): void {
    this.carregando = true;
    if(codigo_repasse_situacao) {
      let situacao = [{'aprovado': 'N'}]
      this.repasseSolicitacaoService.delete(codigo_repasse_situacao).subscribe((data) => {
        this.notifierService.notify( 'success', 'Repasse DESAPROVADO com sucesso.' );
        this.carregando = false;
        this.listar();
        this.listarPendenciasAprovacao();
        this.listarInativos();
      }, (error) => {
        this.notifierService.notify( 'error', 'Não foi desaprovar o repasse. ' + error );
        this.carregando = false;
      });
    } else {
      this.notifierService.notify( 'error', 'Informe o código do repasse para continuar com a desaprovação.');
      this.carregando = false;
    }
  }

}
