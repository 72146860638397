import { Component, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { AssociadoService } from 'src/app/core/services/associado.service';
import { NotifierService } from 'angular-notifier';
import { Associado } from 'src/app/shared/models/associado';
import { PendenciaTipoService } from 'src/app/core/services/pendencia-tipo.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Plano } from 'src/app/shared/models/plano';
import { PlanoService } from 'src/app/core/services/plano.service';
import { AssociadoPagamentoService } from 'src/app/core/services/associado-pagamento.service';
import { AssociadoPagamento } from 'src/app/shared/models/associado-pagamento';
import * as moment from 'src/assets/lib/moment.js/moment';
import { ClasseService } from 'src/app/core/services/classe.service';
import { Classe } from 'src/app/shared/models/classe';
import { LotacaoService } from 'src/app/core/services/lotacao.service';
import { Lotacao } from 'src/app/shared/models/lotacao';

@Component({
  selector: 'app-associado-listar',
  templateUrl: './associado-listar.component.html',
  styleUrls: ['./associado-listar.component.css']
})
export class AssociadoListarComponent implements OnInit {

  @ViewChild('formPlano') formValues;
  @ViewChild('formFiltro') formFiltroValues;

  planoForm: FormGroup;
  filtroForm: FormGroup;

  associados: Associado;
  codigo_pessoa;
  breadCrumb;
  aprovacao_pendente: Associado;
  associados_inativos: Associado;
  planos: Plano;
  codigo_plano: any;
  submitted: boolean;
  codigo_associado: any;
  carregando: boolean;
  isento_mensalidade: any;
  contagem: any;
  total: any;
  percentual: any;
  carregando_paginacao: boolean;
  classeAssociado: Classe;
  filtro_classe: any;
  filtro_tipo: any;
  Lotacoes: Lotacao;
  filtro_unidade_repasse: any;

  constructor(private appComponent: AppComponent,
              private formBuilder: FormBuilder,
              private associadoService: AssociadoService,
              private classeService: ClasseService,
              private lotacaoService: LotacaoService,
              private planoService: PlanoService,
              private associadoPagamentoService: AssociadoPagamentoService,
              private pendenciaTipoService: PendenciaTipoService,
              private notifierService: NotifierService) {

  }

  ngOnInit() {
    this.breadCrumb = [ 
      {nome: "Associados", caminho: "/associado-listar"}
    ];
    this.appComponent.setTitulo('Cadastro de Associados');
    this.appComponent.setBreadcrumb(this.breadCrumb);

    this.planoForm = this.formBuilder.group({
      codigo_plano: [null, Validators.required],
      desconto: [null],
    });

    this.filtroForm = this.formBuilder.group({
      nome: [null]
    });

    this.listar();
    this.obterClasse();
    this.obterPlano();
    this.obterLotacao();
  }

  definirFiltroClasse(classe) {
    this.filtro_classe = classe;
  }

  definirFiltroLotacao(unidade_repasse) {
    this.filtro_unidade_repasse = unidade_repasse;
  }

  definirFiltroTipo(tipo) {
    this.filtro_tipo = tipo;
  }

  definirCodigo(codigo_pessoa) {
    this.codigo_pessoa = codigo_pessoa;
  }

  definirAssociado(associado) {
    localStorage.removeItem('associado_nome');
    localStorage.removeItem('associado_tipo');
    localStorage.removeItem('associado_matricula_dpf');
    localStorage.removeItem('associado_apcf');
    localStorage.removeItem('associado_apcf_sindical');
    localStorage.setItem('associado_nome', associado.pessoa.nome);
    localStorage.setItem('associado_tipo', associado.tipo);
    localStorage.setItem('associado_matricula_dpf', associado.matricula_dpf);
    localStorage.setItem('associado_apcf', associado.associado_apcf);
    localStorage.setItem('associado_apcf_sindical', associado.associado_apcf_sindical);
  }

  obterClasse() {
    this.classeService.list(false)
    .subscribe((data: Classe) => this.classeAssociado = data,
                error => console.log(error));
                
  }

  obterPlano() {
    this.carregando = true;
    this.planoService.list(false).subscribe((data: Plano) => {
      this.planos = data;
      this.carregando = false;
    }, error => console.log(error));
  }

  obterLotacao() {
    this.lotacaoService.list()
    .subscribe((data: Lotacao) => this.Lotacoes = data,
                error => console.log(error));
  }

  obterPlanoAssociado(codigo_associado): void {
    this.carregando = true;
    this.codigo_associado = codigo_associado;
    let mes = moment().endOf('month').format('MM');
    this.associadoPagamentoService.list(codigo_associado, mes).subscribe((data: AssociadoPagamento) => {
      this.planoForm.patchValue({
        codigo_plano: data[0].plano.codigo_plano,
        desconto: data[0].plano.desconto,
      });
      this.isento_mensalidade = data[0].associado.isento_mensalidade;
      this.carregando = false;
    }, error => console.log(error));
  }

  // convenience getter for easy access to form fields
  get f() {    
    return this.filtroForm.controls;
  }

  listar(contagem = null): void {
    this.associados = null;
    this.carregando_paginacao = true;

    // associados ativos
    this.associadoService.list(false, true, contagem, this.f.nome.value, this.filtro_classe, this.filtro_tipo, this.filtro_unidade_repasse).subscribe((data: Associado) => 
    { 
      this.associados = data;
      this.contagem = data[0].contagem;
      this.total = data[0].total;
      this.percentual = Math.round((this.contagem * 100) / this.total);
      this.carregando_paginacao = false;
    }, error => console.log(error));

    // associados com aprovacao pendentes
    //this.associadoService.list(false, false, contagem).subscribe((data: Associado) => 
    //{ 
    //  this.aprovacao_pendente = data;
    //}, error => console.log(error));

    // associados inativos
    this.associadoService.list(true, true, contagem).subscribe((data: Associado) => 
    { 
      this.associados_inativos = data;
    }, error => console.log(error));

  }

  incluirPlanoPagamento(): void {
    this.submitted = true;
    let plano = this.planoForm.value as Associado;
    plano.codigo_associado = this.codigo_associado;
    if (this.planoForm.valid) {
      this.associadoService.atualizarPlano(plano, this.codigo_associado).subscribe((data) => {
        this.listar();
        $('body').removeClass('modal-open');        
        $('body').css('padding-right', '');
        $(".modal-backdrop").remove();
        $('#formPlano').modal('hide');
        this.planoForm.reset();
        this.notifierService.notify( 'success', 'Plano ATUALIZADO com sucesso.' );
        this.submitted = false;
      }, (error) => {      
        this.notifierService.notify( 'error', error.message);
      });
    } else {
      this.notifierService.notify( 'error', 'Preencha os campos obrigatórios.');
      console.log(this.planoForm);
    }
  }

  aprovar(codigo_pessoa):void {
    let associado = {'aprovado': 'S', 'codigo_pessoa': this.codigo_pessoa, 'criar_plano': true};
    this.associadoService.save(associado, codigo_pessoa).subscribe((data) => {
      this.listar();
      this.notifierService.notify( 'success', 'Cadastro APROVADO com sucesso.' );
    }, (error) => {
      this.notifierService.notify( 'error', 'Não foi possível APROVAR o cadastro, ocorreu um erro. ' + error );
    });
  }

  restaurar(codigo_pessoa):void {   
    this.associadoService.restore(codigo_pessoa).subscribe((data) => {
      this.listar();
      this.notifierService.notify( 'success', 'Registro RESTAURADO com sucesso.' );
    }, (error) => {
      this.notifierService.notify( 'error', 'Não foi possível REATIVAR o registro. ' + error );
    });
  }

  apagar(codigo_pessoa):void {   
    this.associadoService.delete(codigo_pessoa).subscribe((data) => {
      this.listar();
      this.notifierService.notify( 'success', 'Registro APAGADO com sucesso.' );
    }, (error) => {
      this.notifierService.notify( 'error', 'Não foi possível APAGAR o registro, ocorreu um erro. ' + error );
    });
  }
}
