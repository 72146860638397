<app-header></app-header>

<div class="row">
    <div class="col-sm-12">
        <div class="panel panel-default panel-table">
            <div class="panel-heading">

            </div>
            <div class="panel-body">
                <form [formGroup]="form" (ngSubmit)="list(tipo_layout.value, ano_referencia.value)" class="form-horizontal group-border-dashed">
                    <div class="form-group">
                      <span class="col-sm-3" style="text-align: right;">
                        <label class="control-label">Tipo de Layout <span class="obrigatorio">*</span></label>
                      </span>
                        <span class="col-sm-3">
                            <select #tipo_layout class="select2 form-control" formControlName="tipo_layout" required>
                                <option value="">Selecione</option>
                                <option *ngFor="let tipo_layout of tipos_layout" [value]="tipo_layout.sigla">
                                    {{tipo_layout.descricao}}
                                </option>
                            </select>
                      </span>
                    </div>
                    <div class="form-group">
                      <span class="col-sm-3" style="text-align: right;">
                        <label class="control-label">Ano refer&ecirc;ncia <span class="obrigatorio">*</span></label>
                      </span>
                        <span class="col-sm-3">
                            <select #ano_referencia class="select2 form-control" formControlName="ano_referencia" required>
                                <option value="">Selecione</option>
                                <option *ngFor="let ano of anos" [value]="ano">
                                    {{ano}}
                                </option>
                            </select>
                      </span>
                    </div>
                    <div class="form-group">
                        <div class="col-sm-offset-3 col-sm-7">
                            <button type="submit" class="btn btn-space btn-primary">Pesquisar</button>
                            <button type="button" class="btn btn-space btn-default" (click)="gerarNovo()">Cadastrar novo</button>
                        </div>
                    </div>
                </form>
                <hr/>
                <div class="dataTables_length" id="table3_length">
                    <table id="table2" class="table table-striped table-hover table-fw-widget" *ngIf="carregado">
                        <thead>
                            <tr>
                                <th>Tipo</th>
                                <th>Layout</th>
                                <th>Ano refer&ecirc;ncia</th>
                                <th>M&ecirc;s refer&ecirc;ncia</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody *ngIf="consignacoes && consignacoes.length > 0">
                            <tr class="odd gradeX" *ngFor="let consignacao of consignacoes; let i = index">
                                <td>{{consignacao.tipo_layout.descricao}}</td>
                                <td>{{consignacao.layout.titulo}}</td>
                                <td>{{consignacao.ano_referencia}}</td>
                                <td>{{consignacao.mes_referencia}}</td>
                                <td>
                                    <button class="btn btn-primary" (click)="downloadFile(consignacao.codigo_arquivo)">
                                        Download
                                    </button>
                                    &nbsp;
                                    <button *ngIf="i == 0 && !consignacao.data_compensacao" class="btn btn-success" (click)="compensar(consignacao.codigo_arquivo)">
                                        Compensar
                                    </button>
                                    &nbsp;
                                    <button *ngIf="i == 0 && consignacao.data_compensacao" class="btn btn-warning" (click)="openModalDescompensar(consignacao.codigo_arquivo, md_consignacao)">
                                        Cancelar compensa&ccedil;&atilde;o
                                    </button>
                                    &nbsp;
                                    <button *ngIf="i == 0 && !consignacao.data_compensacao" class="btn btn-danger" (click)="excluir(consignacao.codigo_arquivo)">
                                        Excluir
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="!consignacoes">
                            <tr class="carregando">
                                <td class="carregando" colspan="5">
                                    <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="consignacoes && consignacoes.length == 0">
                            <tr>
                                <td class="col-md-12" colspan="5">
                                    Nenhum registro encontrado.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="carregando" *ngIf="mostrar_carregando">
                    <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #md_consignacao class="modal fade" tabindex="-1" role="dialog" aria-labelledby="md-consignacao-form">
    <form>
        <div class="modal-header">
            <button type="button" (click)="md_consignacao_ref.hide()" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
        </div>
        <div class="modal-body">
            <div class="text-center">
                Essa a&ccedil;&atilde;o n&atilde;o &eacute; recomendada caso tenha enviado um arquivo de consigna&ccedil;&atilde;o para o SERPRO
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary btn-space" (click)="descompensar(codigo_arquivo)">Confirmar</button>
            <button type="button" class="btn btn-default btn-space" (click)="md_consignacao_ref.hide()">Cancelar</button>
        </div>
    </form>
</ng-template>
