import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {NgxMaskModule} from 'ngx-mask';
import { CpfCnpjPipe } from '../../../pipes/cpfcnpj.pipe';

import { ColaboradorEditarComponent } from './colaborador-editar/colaborador-editar.component';
import { ColaboradorListarComponent } from './colaborador-listar/colaborador-listar.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { AuthGuard } from 'src/app/core/authentication/auth.guard';


const appRoutes: Routes = [
  {path: 'colaborador-listar', component: ColaboradorListarComponent, canActivate: [AuthGuard]},
  {path: 'colaborador-editar', component: ColaboradorEditarComponent, canActivate: [AuthGuard]},
  {path: 'colaborador-editar/:id', component: ColaboradorEditarComponent, canActivate: [AuthGuard]},
];

@NgModule({
  imports: [
    BrowserModule,
    SharedModule,
    FormsModule, 
    
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    RouterModule.forRoot(appRoutes)
  ],
  declarations: [
    ColaboradorEditarComponent, 
    ColaboradorListarComponent,
    CpfCnpjPipe
  ]
})
export class ColaboradorModule { }
