import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';

@Injectable({
  providedIn: 'root'
})
export class AssociacaoPendenteService {

  constructor(private http: HttpClient) {}

  private _Url = environment.UrlPrincipal + '/api/associadosincronia';
  private _UrlDelete = environment.UrlPrincipal + '/api/associadosincroniadeletar';

  list(contagem = 10, rejeitado = false): Observable<any> {
    return this.http.get(this._Url + '?rejeitado=' + rejeitado + '&contagem='+contagem)
                    .pipe(catchError(this.handleError));
  }

  find(id) {
    return this.http.get(this._Url + "/" + id)
                    .pipe(catchError(this.handleError));
  }

  save(dados, codigo): Observable<any> {
    let http;
    //if(codigo || dados.codigo){
      http = this.http.put(this._Url + "/" + codigo, dados);
    //}
    return http.pipe(
      map((res) => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  aprovar(dados, codigo): Observable<any> {
    let http;
    http = this.http.post(environment.UrlPrincipal + '/api/associado-aprovar' + "/" + codigo, dados);
    return http.pipe(
      map((res) => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  delete(dados, codigo): Observable<any> {
    let http;
    http = this.http.put(this._UrlDelete + "/" + codigo, dados);
    return http.pipe(
      map((res) => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('Algum erro ocorreu:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend retornou o codigo ${error.status}, a resposta foi: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Ocorreu um erro desconhecido. Tente novamente mais tarde.'));
  }

}
