import {Component, OnInit, TemplateRef} from '@angular/core';

import {AppComponent} from "../../../app.component";
import {NotifierService} from "angular-notifier";
import {FormControl, FormGroup} from "@angular/forms";
import {RodapeLayout} from "../../../shared/models/rodape-layout";
import {CampoRodapeLayout} from "../../../shared/models/campo-rodape-layout";
import {RodapeLayoutService} from "../../../core/services/rodape-layout.service";
import {TipoLayout} from "../../../shared/models/tipo-layout";
import {isUndefined} from "util";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";

@Component({
    selector: 'app-rodape-listar',
    templateUrl: './rodape-listar.component.html',
    styleUrls: ['./rodape-listar.component.css']
})
export class RodapeListarComponent implements OnInit {

    public breadCrumb;
    public rodapes_layout: Array<any> = [];
    public tipos_layout: Array<TipoLayout> = [];
    public rodape_layout: RodapeLayout = null;
    public campos_rodape_layout: Array<CampoRodapeLayout> = [];
    // public schemas: Array<Schema> = [];
    // public tabelas: Array<Tabela> = [];
    // public campos: Array<Campo> = [];
    public form_campos: FormGroup;
    public existe_padrao: boolean = true;
    public carregado: boolean = false;
    public modal_rodape_detail_ref: BsModalRef;
    public modal_rodape_form_ref: BsModalRef;
    public titulo: string = null;

    constructor(private appComponent: AppComponent,
                private rodapeLayoutService: RodapeLayoutService,
                private notifierService: NotifierService,
                private modalService: BsModalService,
                /*private tabelaService: TabelaService*/) {

    }

    ngOnInit() {
        this.breadCrumb = [
            {nome: "Configuração", caminho: "/configuracao"},
            {nome: "Rodapé", caminho: "/layout/rodape"}
        ];
        this.appComponent.setTitulo('Rodapé');
        this.appComponent.setBreadcrumb(this.breadCrumb);

        this.listar();

        this.form_campos = new FormGroup({
            codigo: new FormControl("", []),
            sigla: new FormControl("", []),
            descricao: new FormControl("", []),
            // schema: new FormControl("", []),
            // tabela: new FormControl("", []),
            // campo: new FormControl("", []),
            sql: new FormControl("", []),
            quantidade_caracteres: new FormControl(null, []),
            padrao: new FormControl("", []),
            ordem: new FormControl("", []),
        });
        // this.form_campos.get('schema').disable();
        // this.form_campos.get('tabela').disable();
        // this.form_campos.get('campo').disable();
        this.form_campos.get('sql').disable();
    }

    listar(): void {
        this.rodapes_layout = [];
        this.carregado = false;
        this.rodapeLayoutService.list().subscribe((data: Array<RodapeLayout>) => {
            this.carregado = true;
            if (data.length > 0) {
                data.forEach((rodape_layout: RodapeLayout) => {
                    let existe_tipo_layout: boolean = false;
                    if (this.tipos_layout.length > 0) {
                        this.tipos_layout.forEach((tipo_layout: TipoLayout) => {
                            if (tipo_layout.sigla == rodape_layout.tipo_layout.sigla) {
                                existe_tipo_layout = true;
                            }
                        });
                    }
                    if (!existe_tipo_layout) {
                        this.tipos_layout.push(rodape_layout.tipo_layout);
                    }
                    if (isUndefined(this.rodapes_layout[rodape_layout.tipo_layout.sigla])) {
                        this.rodapes_layout[rodape_layout.tipo_layout.sigla] = [];
                    }
                    this.rodapes_layout[rodape_layout.tipo_layout.sigla].push(rodape_layout);
                });
            }
        }, () => {
            this.carregado = true;
            error => console.log(error);
        });
    }
    
    setCampos(rodape_layout: RodapeLayout, content_modal: TemplateRef<any>): void {
        this.rodape_layout = rodape_layout;
        this.titulo = this.rodape_layout.titulo;
        this.campos_rodape_layout = rodape_layout.campos_rodape_layout;
        this.modal_rodape_detail_ref = this.modalService.show(content_modal, {
            class: 'modal-lg',
            keyboard: false,
            focus: true
        });
    }

    copiar(rodapeLayout: RodapeLayout): void {
        this.rodapeLayoutService.copy(rodapeLayout).subscribe(() => {
            this.notifierService.notify('success', 'Rodapé copiado com sucesso');
            this.listar();
        }, () => {
            this.notifierService.notify('error', 'Ocorreu um erro ao copiar rodapé');
        });
    }

    subir(campo_rodape_layout: CampoRodapeLayout, i: number): void {
        let campos_rodape_layout: Array<CampoRodapeLayout> = [];
        if (this.campos_rodape_layout.length > 0) {
            this.campos_rodape_layout.forEach((campo_rodape_layout: CampoRodapeLayout, index) => {
                let tmp_campo_rodape_layout: CampoRodapeLayout = null;
                if (index == i) {
                    tmp_campo_rodape_layout = this.campos_rodape_layout[index - 1];
                    tmp_campo_rodape_layout.ordem = index - 1;
                } else if (index == (i - 1)) {
                    tmp_campo_rodape_layout = this.campos_rodape_layout[index + 1];
                    tmp_campo_rodape_layout.ordem = index + 1;
                } else {
                    tmp_campo_rodape_layout = campo_rodape_layout;
                }
                campos_rodape_layout.push(tmp_campo_rodape_layout);
            });
            this.campos_rodape_layout = campos_rodape_layout;
        }
    }

    descer(campo_rodape_layout: CampoRodapeLayout, i: number): void {
        let campos_rodape_layout: Array<CampoRodapeLayout> = [];
        if (this.campos_rodape_layout.length > 0) {
            this.campos_rodape_layout.forEach((campo_rodape_layout: CampoRodapeLayout, index) => {
                let tmp_campo_rodape_layout: CampoRodapeLayout = null;
                if (index == i) {
                    tmp_campo_rodape_layout = this.campos_rodape_layout[index + 1];
                    tmp_campo_rodape_layout.ordem = index + 1;
                } else if (index == (i + 1)) {
                    tmp_campo_rodape_layout = this.campos_rodape_layout[index - 1];
                    tmp_campo_rodape_layout.ordem = index - 1;
                } else {
                    tmp_campo_rodape_layout = campo_rodape_layout;
                }
                campos_rodape_layout.push(tmp_campo_rodape_layout);
            });
            this.campos_rodape_layout = campos_rodape_layout;
        }
    }

    atualizar(rodape_layout: RodapeLayout, campos_rodape_layout: Array<CampoRodapeLayout>): void {
        if(this.titulo) {
            rodape_layout.titulo = this.titulo;
            this.rodapeLayoutService.update(rodape_layout, campos_rodape_layout).subscribe(() => {
                this.notifierService.notify('success', 'Layout rodapé atualizado com sucesso');
                this.modal_rodape_detail_ref.hide();
                this.campos_rodape_layout = [];
                this.rodape_layout = null;
                this.listar();
            }, () => {
                this.notifierService.notify('error', 'Ocorreu um erro ao atualizar o layout do rodapé');
            });
        }else{
            this.notifierService.notify('error', 'Informe o título');
        }
    }

    editar(campo_layout: CampoRodapeLayout, content_modal: TemplateRef<any>): void {
        this.form_campos.patchValue({
            codigo: campo_layout.codigo_campo_rodape_layout,
            sigla: campo_layout.sigla,
            descricao: campo_layout.descricao,
            // schema: "",
            // tabela: "",
            // campo: "",
            sql: campo_layout.sql,
            quantidade_caracteres: campo_layout.quantidade_caracteres,
            padrao: campo_layout.padrao,
            ordem: campo_layout.ordem,
        });
        this.existe_padrao = campo_layout.padrao ? true : false;
        this.form_campos.get('sigla').disable();
        if(this.existe_padrao){
            this.form_campos.get('sql').disable();
            this.form_campos.get('padrao').enable();
        }else{
            this.form_campos.get('sql').enable();
            this.form_campos.get('padrao').disable();
        }
        this.modal_rodape_form_ref = this.modalService.show(content_modal, {class: 'modal-lg', keyboard: false, focus: true});
    }

    excluir(i: number): void {
        let campos_rodape_layout: Array<CampoRodapeLayout> = [];
        if (this.campos_rodape_layout.length > 0) {
            this.campos_rodape_layout.forEach((campo_rodape_layout: CampoRodapeLayout, index) => {
                if (index != i) {
                    campos_rodape_layout.push(campo_rodape_layout);
                }
            });
            this.campos_rodape_layout = campos_rodape_layout;
        }
    }

    definirCampoPadrao(): void {
        this.existe_padrao = !this.existe_padrao;
        if (this.existe_padrao) {
            // this.form_campos.get('schema').disable();
            // this.form_campos.get('tabela').disable();
            // this.form_campos.get('campo').disable();
            this.form_campos.get('sql').disable();
            this.form_campos.get('padrao').enable();
        } else {
            // this.form_campos.get('schema').enable();
            // this.form_campos.get('tabela').enable();
            // this.form_campos.get('campo').enable();
            this.form_campos.get('sql').enable();
            this.form_campos.get('padrao').disable();
        }
        this.form_campos.patchValue({
            padrao: "",
            // schema: "",
            // tabela: "",
            // campo: "",
            sql: "",
        });
    }

    public addCampo(content_modal: TemplateRef<any>): void {
        this.form_campos.get('sigla').enable();
        // $('#md-layout-rodape-detail').modal('hide');
        this.modal_rodape_form_ref = this.modalService.show(content_modal, {
            class: 'modal-lg',
            keyboard: false,
            focus: true
        });
        // this.listarSchemas();
        this.form_campos.patchValue({
            codigo: "",
            sigla: "",
            descricao: "",
            // schema: "",
            // tabela: "",
            // campo: "",
            sql: "",
            quantidade_caracteres: "",
            padrao: "",
            ordem: "",
        });
        // this.form_campos.get('schema').disable();
        // this.form_campos.get('tabela').disable();
        // this.form_campos.get('campo').disable();
        this.form_campos.get('sql').disable();
        this.form_campos.get('padrao').enable();
        this.existe_padrao = true;
    }

    // public listarSchemas(): void{
    //   this.tabelaService.listSchemas().subscribe((schemas: Array<Schema>) => {
    //     this.schemas = schemas;
    //   });
    // }
    //
    // public listarTabelas(schema): void{
    //   this.tabelaService.listTabelas(schema).subscribe((tabelas: Array<Tabela>) => {
    //     this.tabelas = tabelas;
    //   });
    // }
    //
    // public listarCampos(tabela, schema): void{
    //   this.tabelaService.listCampos(tabela, schema).subscribe((campos: Array<Campo>) => {
    //     this.campos = campos;
    //   });
    // }

    adicionarCampo(): void {
        let campo_rodape_layout: CampoRodapeLayout = new CampoRodapeLayout();
        let msg: Array<string> = [];
        campo_rodape_layout.quantidade_caracteres = this.form_campos.get('quantidade_caracteres').value;
        campo_rodape_layout.descricao = this.form_campos.get('descricao').value;
        campo_rodape_layout.sigla = this.form_campos.get('sigla').value;
        campo_rodape_layout.codigo_campo_rodape_layout = this.form_campos.get('codigo').value;
        if (!this.form_campos.get('quantidade_caracteres').value) {
            msg.push("Informe a quantidade de caracteres");
        } else if (this.existe_padrao && (!this.form_campos.get('padrao').value || this.form_campos.get('quantidade_caracteres').value != this.form_campos.get('padrao').value.length)) {
            msg.push('A quantidade de caracteres do padrão não está igual a quantidade de caracteres informada');
            // } else if(!this.existe_padrao && (!this.form_campos.get('schema').value || !this.form_campos.get('tabela').value || !this.form_campos.get('campo').value)){
        } else if (!this.existe_padrao && !this.form_campos.get('sql').value) {
            // msg.push("Informe os campos schema, tabela e campo");
            msg.push("Informe os campos sql");
        }
        if (msg.length > 0) {
            msg.forEach((message: string) => {
                this.notifierService.notify('error', message);
            });
        } else {
            if (this.form_campos.get('padrao').value) {
                campo_rodape_layout.padrao = this.form_campos.get('padrao').value;
                // campo_rodape_layout.schema = "";
                // campo_rodape_layout.tabela = "";
                // campo_rodape_layout.campo = "";
                campo_rodape_layout.sql = "";
            } else {
                campo_rodape_layout.padrao = "";
                // campo_rodape_layout.schema = this.form_campos.get('schema').value;
                // campo_rodape_layout.tabela = this.form_campos.get('tabela').value;
                // campo_rodape_layout.campo = this.form_campos.get('campo').value;
                campo_rodape_layout.sql = this.form_campos.get('sql').value;
            }
            if(campo_rodape_layout.codigo_campo_rodape_layout) {
                if(this.rodape_layout.campos_rodape_layout.length > 0){
                    this.rodape_layout.campos_rodape_layout.forEach((tmp_campo_rodape_layout: CampoRodapeLayout, i: number) => {
                        if(tmp_campo_rodape_layout.codigo_campo_rodape_layout == campo_rodape_layout.codigo_campo_rodape_layout){
                            this.rodape_layout.campos_rodape_layout[i] = campo_rodape_layout;
                        }
                    });
                }
                if(this.campos_rodape_layout.length > 0){
                    this.campos_rodape_layout.forEach((tmp_campo_rodape_layout: CampoRodapeLayout, i: number) => {
                        if(tmp_campo_rodape_layout.codigo_campo_rodape_layout == campo_rodape_layout.codigo_campo_rodape_layout){
                            this.campos_rodape_layout[i] = campo_rodape_layout;
                        }
                    });
                }
            }else{
                this.rodape_layout.campos_rodape_layout.push(campo_rodape_layout);
            }
            this.modal_rodape_form_ref.hide();
        }
    }

}
