<app-header></app-header>

<div class="row">
    <div class="col-sm-12">
        <div class="panel panel-default panel-table">
            <div class="panel-heading">

            </div>
            <div class="panel-body">

                <div class="dataTables_length" id="table3_length">
<!--                    <div class="col-sm-12">-->
<!--                        <div class="dt-buttons btn-group">-->
<!--                            <div class="tools">-->
<!--                                <span class="panel-subtitle">-->
<!--                                  <a routerLink="/layout/form" class="btn btn-default buttons-copy buttons-html5" tabindex="0" aria-controls="table3">-->
<!--                                    <span>Novo layout</span>-->
<!--                                  </a>-->
<!--                                </span>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
                    <ul class="nav nav-tabs" *ngIf="tipos_layout.length > 0">
                        <li role="presentation" id="nav-{{tipo_layout.sigla}}-tab" data-toggle="tab" *ngFor="let tipo_layout of tipos_layout; let i = index" [ngClass]="{'active': i == 0}">
                            <a href="#nav-{{tipo_layout.sigla}}" data-toggle="tab">{{tipo_layout.descricao}}</a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade" *ngFor="let tipo_layout of tipos_layout; let i = index" [ngClass]="{'in active': i == 0}" id="nav-{{tipo_layout.sigla}}" role="tabpanel">
                            <table id="table2" class="table table-striped table-hover table-fw-widget">
                                <thead>
                                    <tr>
                                        <th>T&iacute;tulo</th>
                                        <th>Data cria&ccedil;&atilde;o</th>
                                        <th>Data exclus&atilde;o</th>
                                        <th class="text-right">A&ccedil;&otilde;es</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="layouts && layouts[tipo_layout.sigla] && layouts[tipo_layout.sigla].length > 0">
                                    <tr class="odd gradeX" *ngFor="let layout of layouts[tipo_layout.sigla]">
                                        <td [ngClass]="{'text-bold': !layout.deleted_at}">{{layout.titulo}}</td>
                                        <td [ngClass]="{'text-bold': !layout.deleted_at}">{{layout.created_at | date: 'dd/MM/yyyy hh:mm:ss'}}</td>
                                        <td [ngClass]="{'text-bold': !layout.deleted_at}">{{layout.deleted_at ? (layout.deleted_at | date: 'dd/MM/yyyy hh:mm:ss') : '-'}}</td>
                                        <td style="text-align: right;">
                                            <button data-toggle="modal" type="button" class="btn btn-space btn-warning" title="Visualizar campos" alt="Visualizar campos" (click)="setCampos(layout, md_layout_detail)">
                                                <i class="glyphicon glyphicon-eye-open"></i>
                                            </button>
                                            <button type="button" class="btn btn-space btn-danger" title="Copiar registro" alt="Copiar registro" (click)="copiar(layout)">
                                                <i class="glyphicon glyphicon-copy"></i>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr class="carregando" *ngIf="!carregado">
                                        <td class="carregando" colspan="3">
                                            <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
                                        </td>
                                    </tr>
                                    <tr *ngIf="layouts && layouts[tipo_layout.sigla].length == 0">
                                        <td class="col-md-12" colspan="3">
                                            Nenhum registro encontrado.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--<div id="md-layout-detail" tabindex="-1" role="dialog" style="" class="modal fade">-->
<!--    <div class="modal-dialog modal-lg">-->
<!--        <div class="modal-content">-->
<!--            <div class="modal-header">-->
<!--                <button type="button" data-dismiss="modal" aria-hidden="true" class="close">-->
<!--                    <span class="mdi mdi-close"></span></button>-->
<!--            </div>-->
<!--            <div class="modal-body">-->
<!--                <div class="text-center">-->
<!--                    <table class="table table-striped table-hover table-fw-widget">-->
<!--                        <thead>-->
<!--                            <tr>-->
<!--                                <th class="col-sm-3">Descri&ccedil;&atilde;o</th>-->
<!--                                <th class="col-sm-2">Qtd carac.</th>-->
<!--                                <th class="col-sm-1">Padr&atilde;o</th>-->
<!--                                &lt;!&ndash;                <th class="col-sm-1">Schema</th>&ndash;&gt;-->
<!--                                &lt;!&ndash;                <th class="col-sm-2">Tabela</th>&ndash;&gt;-->
<!--                                &lt;!&ndash;                <th class="col-sm-2">Campo</th>&ndash;&gt;-->
<!--                                <th class="col-sm-3">SQL</th>-->
<!--                                <th class="col-sm-3">-->
<!--                                    <button data-toggle="modal" *ngIf="layout && layout.pode_alterar" (click)="addCampo(md_layout_form)" type="button" class="btn btn-space btn-primary" title="Adicionar campo" alt="Adicionar campo">-->
<!--                                        <i class="glyphicon glyphicon-plus"></i>-->
<!--                                    </button>-->
<!--                                </th>-->
<!--                            </tr>-->
<!--                        </thead>-->
<!--                        <tbody>-->
<!--                            <tr *ngFor="let campo_layout of campos_layout; let i = index">-->
<!--                                <td>{{campo_layout.descricao}}</td>-->
<!--                                <td>{{campo_layout.quantidade_caracteres}}</td>-->
<!--                                <td>{{campo_layout.padrao ? campo_layout.padrao : "-"}}</td>-->
<!--                                &lt;!&ndash;                <td>{{campo_layout.schema ? campo_layout.schema : "-"}}</td>&ndash;&gt;-->
<!--                                &lt;!&ndash;                <td>{{campo_layout.tabela ? campo_layout.tabela : "-"}}</td>&ndash;&gt;-->
<!--                                &lt;!&ndash;                <td>{{campo_layout.campo ? campo_layout.campo : "-"}}</td>&ndash;&gt;-->
<!--                                <td>{{campo_layout.sql ? campo_layout.sql : "-"}}</td>-->
<!--                                <td style="text-align: right;">-->
<!--                                    <button type="button" class="btn btn-space btn-primary" title="Subir" alt="Subir" (click)="subir(campo_layout, i)" *ngIf="i > 0">-->
<!--                                        <i class="glyphicon glyphicon-arrow-up"></i>-->
<!--                                    </button>-->
<!--                                    <button type="button" class="btn btn-space btn-warning" title="Descer" alt="Descer" (click)="descer(campo_layout, i)" *ngIf="(i + 1) != campos_layout.length">-->
<!--                                        <i class="glyphicon glyphicon-arrow-down"></i>-->
<!--                                    </button>-->
<!--                                    <button type="button" class="btn btn-space btn-danger" title="Excluir" alt="Excluir" (click)="excluir(i)">-->
<!--                                        <i class="glyphicon glyphicon-remove"></i>-->
<!--                                    </button>-->
<!--                                </td>-->
<!--                            </tr>-->
<!--                        </tbody>-->
<!--                    </table>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="modal-footer">-->
<!--                <button type="button" class="btn btn-primary" (click)="atualizar(layout, campos_layout)">Salvar</button>-->
<!--                <button type="button" data-dismiss="modal" class="btn btn-default">Fechar</button>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

    <ng-template #md_layout_detail class="modal fade" tabindex="-1" role="dialog" aria-labelledby="md-layout-detail">
        <div class="modal-header">
            <h4 class="modal-title" id="md-layout-detail">Layout</h4>
            <button type="button" (click)="modal_layout_detail_ref.hide()" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <label class="col-sm-3 control-label">T&iacute;tulo <span class="obrigatorio">*</span></label>
                <span class="col-sm-9">
                    <input id="titulo" type="text" placeholder="Título" class="form-control" [(ngModel)]="titulo">
                </span>
            </div>
            <div class="form-group" *ngIf="codigo_cabecalho">
                <label class="col-sm-3 control-label">Cabe&ccedil;alho <span class="obrigatorio">*</span></label>
                <span class="col-sm-9">
                    <select class="select2 form-control" id="codigo_cabecalho" placeholder="Cabeçalho" [(ngModel)]="codigo_cabecalho">
                        <option *ngFor="let cabecalho of cabecalhos" value="{{cabecalho.codigo_cabecalho_layout}}">{{cabecalho.titulo}}</option>
                    </select>
                </span>
            </div>
            <div class="form-group" *ngIf="codigo_rodape">
                <label class="col-sm-3 control-label">Rodap&eacute; <span class="obrigatorio">*</span></label>
                <span class="col-sm-9">
                    <select class="select2 form-control" id="codigo_rodape" placeholder="Rodapé" [(ngModel)]="codigo_rodape">
                        <option *ngFor="let rodape of rodapes" value="{{rodape.codigo_rodape_layout}}">{{rodape.titulo}}</option>
                    </select>
                </span>
            </div>
            <div class="text-center">
                <table class="table table-striped table-hover table-fw-widget">
                    <thead>
                        <tr>
                            <th class="col-sm-1">Sigla</th>
                            <th class="col-sm-3">Descri&ccedil;&atilde;o</th>
                            <th class="col-sm-1">Qtd<br/>carac.</th>
                            <th class="col-sm-1">Padr&atilde;o</th>
                            <!--                <th class="col-sm-1">Schema</th>-->
                            <!--                <th class="col-sm-2">Tabela</th>-->
                            <!--                <th class="col-sm-2">Campo</th>-->
                            <th class="col-sm-3">SQL</th>
                            <th class="col-sm-3">
                                <button data-toggle="modal" *ngIf="layout && layout.pode_alterar" type="button" (click)="addCampo(md_layout_form)" class="btn btn-space btn-primary" title="Adicionar campo" alt="Adicionar campo">
                                    <i class="glyphicon glyphicon-plus"></i>
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let campo_layout of campos_layout; let i = index">
                            <td>{{campo_layout.sigla}}</td>
                            <td>{{campo_layout.descricao}}</td>
                            <td>{{campo_layout.quantidade_caracteres}}</td>
                            <td>{{campo_layout.padrao ? campo_layout.padrao : "-"}}</td>
                            <!--                <td>{{campo_layout.schema ? campo_layout.schema : "-"}}</td>-->
                            <!--                <td>{{campo_layout.tabela ? campo_layout.tabela : "-"}}</td>-->
                            <!--                <td>{{campo_layout.campo ? campo_layout.campo : "-"}}</td>-->
                            <td>{{campo_layout.sql ? campo_layout.sql : "-"}}</td>
                            <td style="text-align: right;">
                                <button type="button" class="btn btn-space btn-primary" title="Subir" alt="Subir" (click)="subir(campo_layout, i)" *ngIf="i > 0">
                                    <i class="glyphicon glyphicon-arrow-up"></i>
                                </button>
                                <button type="button" class="btn btn-space btn-light" title="Editar" alt="Editar" (click)="editar(campo_layout, md_layout_form)">
                                    <i class="glyphicon glyphicon-pencil"></i>
                                </button>
                                <br/>
                                <button type="button" class="btn btn-space btn-warning" title="Descer" alt="Descer" (click)="descer(campo_layout, i)" *ngIf="(i + 1) != campos_layout.length">
                                    <i class="glyphicon glyphicon-arrow-down"></i>
                                </button>
                                <button type="button" class="btn btn-space btn-danger" title="Excluir" alt="Excluir" (click)="excluir(i)">
                                    <i class="glyphicon glyphicon-remove"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="atualizar(layout, campos_layout)">Salvar</button>
            <button type="button" class="btn btn-default" (click)="modal_layout_detail_ref.hide()">Fechar</button>
        </div>
    </ng-template>

<ng-template #md_layout_form class="modal fade" tabindex="-1" role="dialog" aria-labelledby="md-layout-form">
    <form [formGroup]="form_campos" (ngSubmit)="adicionarCampo()" class="form-horizontal group-border-dashed">
        <div class="modal-header">
            <h4 class="modal-title" id="md-layout-form">Layout</h4>
            <button type="button" (click)="modal_layout_form_ref.hide()" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
        </div>
        <div class="modal-body">
            <div class="text-center">
                <div class="form-group">
                    <label class="col-sm-3 control-label">Sigla <span class="obrigatorio">*</span></label>
                    <span class="col-sm-9">
                        <input id="sigla" type="text" placeholder="Sigla" class="form-control" formControlName="sigla">
                    </span>
                </div>
                <div class="form-group">
                    <label class="col-sm-3 control-label">Descri&ccedil;&atilde;o
                        <span class="obrigatorio">*</span></label>
                    <span class="col-sm-9">
          <input id="descricao" type="text" placeholder="Descri&ccedil;&atilde;o" class="form-control" formControlName="descricao">
        </span>
                </div>
                <div class="form-group">
                    <label class="col-sm-3 control-label">Qtd. caracteres
                        <span class="obrigatorio">*</span></label>
                    <span class="col-sm-9">
          <input id="quantidade_caracteres" step="1" min="1" type="number" placeholder="Qtd de caracteres" class="form-control" formControlName="quantidade_caracteres">
        </span>
                </div>
                <div class="form-group">
                    <div class="be-checkbox col-sm-9">
                        <input id="check10" type="checkbox" (click)="definirCampoPadrao()" [checked]="existe_padrao">
                        <label for="check10">Existe padr&atilde;o</label>
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-sm-3 control-label">Padr&atilde;o</label>
                    <span class="col-sm-9">
          <input id="padrao" type="text" placeholder="Padr&atilde;o" class="form-control" formControlName="padrao" [readonly]="!existe_padrao">
        </span>
                </div>
                <!--            <div class="form-group">-->
                <!--              <label class="col-sm-3 control-label">Schema</label>-->
                <!--              <span class="col-sm-9">-->
                <!--                  <select #schema_select class="select2 form-control" formControlName="schema" (change)="listarTabelas(schema_select.value); tabela_select.value = ''; campo_select.value = '';">-->
                <!--                    <option value="">Selecione</option>-->
                <!--                    <option *ngFor="let schema of schemas" value="{{schema.nome}}">-->
                <!--                      {{schema.nome}}-->
                <!--                    </option>-->
                <!--                  </select>-->
                <!--                </span>-->
                <!--            </div>-->
                <!--            <div class="form-group">-->
                <!--              <label class="col-sm-3 control-label">Tabela</label>-->
                <!--              <span class="col-sm-9">-->
                <!--                  <select #tabela_select class="select2 form-control" formControlName="tabela" (change)="listarCampos(tabela_select.value, schema_select.value); campo_select.value = '';">-->
                <!--                    <option value="">Selecione</option>-->
                <!--                    <option *ngFor="let tabela of tabelas" [value]="tabela.nome">-->
                <!--                      {{tabela.nome}}-->
                <!--                    </option>-->
                <!--                  </select>-->
                <!--                </span>-->
                <!--            </div>-->
                <!--            <div class="form-group">-->
                <!--              <label class="col-sm-3 control-label">Campo</label>-->
                <!--              <span class="col-sm-9">-->
                <!--                  <select #campo_select class="select2 form-control" formControlName="campo" [disabled]="existe_padrao">-->
                <!--                    <option value="">Selecione</option>-->
                <!--                    <option *ngFor="let campo of campos" [value]="campo.nome">-->
                <!--                      {{campo.nome}}-->
                <!--                    </option>-->
                <!--                  </select>-->
                <!--                </span>-->
                <!--            </div>-->
                <div class="form-group">
                    <label class="col-sm-3 control-label">SQL</label>
                    <span class="col-sm-9">
          <textarea id="sql" formControlName="sql" placeholder="SQL" class="form-control" rows="3" [readonly]="existe_padrao"></textarea>
        </span>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary btn-space" (click)="adicionarCampo()">Salvar campo</button>
            <button type="button" class="btn btn-default btn-space" (click)="modal_layout_form_ref.hide()">Cancelar</button>
        </div>
    </form>
</ng-template>
<app-footer></app-footer>
