import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class PessoaAcessoService {

  constructor(private http: HttpClient) {}

  alterarSenha(dados): Observable<any>{
    let http;
    http = this.http.post(environment.UrlPerfilAcesso, dados);
    return http;
  }

  save(dados): Observable<any>{
    let http;
    http = this.http.post(environment.UrlPerfilAcesso, dados);
    http.map((res) => {
        return res;
    }).catch(console.log('Erro ao consultar acesso ao sistema.'));
    return http;
  }

}
