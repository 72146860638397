import {Injectable} from "@angular/core";
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import { environment } from '../../../environments/environment';

import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class AssociadoSituacaoService {

  private _Url = environment.UrlPrincipal + '/api/associadosituacao';

  constructor(private http: HttpClient) {}

  list(codigo_pessoa, apcf_sindical = false) {
    return this.http.get(this._Url + "?codigo_pessoa=" + codigo_pessoa + '&apcf_sindical=' + apcf_sindical)
                    .catch(this.handleError);
  }

  find(id) {
    return this.http.get(this._Url + "/" + id)
                    .catch(this.handleError);
  }

  save(dados, codigo_associado_situacao): Observable<any> {
    let http;
    if(!dados.codigo_associado_situacao) {
      dados.codigo_associado_situacao = codigo_associado_situacao;
    }
    if(codigo_associado_situacao || dados.codigo_associado_situacao){
        http = this.http.put(this._Url + "/" + dados.codigo_associado_situacao, dados);
    } else {
        http = this.http.post(this._Url, dados);
    }
    http.map((res) => {
        return res;
    }).catch(this.handleError);
    return http;
  }

  delete(codigo_associado_situacao) {
    return this.http.delete(this._Url + "/" + codigo_associado_situacao);
  }

  private handleError(error: Response) {
    return error.json();
  }

}
