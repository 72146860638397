import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from 'src/app/shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { AuthGuard } from 'src/app/core/authentication/auth.guard';

import { NgxCurrencyModule } from 'ngx-currency';
import { Ng2BRPipesModule } from 'ng2-brpipes';

import { RepasseListarComponent } from './repasse-listar/repasse-listar.component';

const appRoutes: Routes = [
  { path: 'repasse-listar', component: RepasseListarComponent, canActivate: [AuthGuard]  }
];

export const customCurrencyMaskConfig = {
  align: "right",
  allowNegative: false,
  allowZero: true,
  decimal: ",",
  precision: 2,
  prefix: "",
  suffix: "",
  thousands: ".",
  nullable: null
};

@NgModule({
  declarations: [RepasseListarComponent],
  imports: [
    BrowserModule,
    SharedModule,
    FormsModule, 
    ReactiveFormsModule,
    Ng2BRPipesModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    NgxMaskModule.forRoot(),
    RouterModule.forRoot(appRoutes)
  ]
})
export class RepasseModule { }
