<app-header></app-header>

<div class="row">
  <div class="col-sm-12">
    <div class="panel panel-default panel-table">
    <div class="panel-heading">

    </div>
    <div class="panel-body">

        <div class="dataTables_length" id="table3_length">
            <div class="col-sm-6">
                <div class="dt-buttons btn-group">
                    <div class="tools">
                        <span class="panel-subtitle"><a routerLink="/colaborador-editar" class="btn btn-default buttons-copy buttons-html5" tabindex="0" aria-controls="table3"><span>Novo colaborador</span></a></span>
                    </div>
                </div>
            </div>
            
            <table id="table2" class="table table-striped table-hover table-fw-widget">
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>E-mail</th>
                        <th>CPF</th>
                        <th>Perfil</th>
                        <th class="text-center">Ações</th>
                    </tr>
                </thead>
                <tbody *ngIf="colaboradores && colaboradores.length > 0">
                    <tr class="odd gradeX" *ngFor="let colaborador of colaboradores">
                        
                        <td class="col-md-3">{{colaborador.nome}}</td>
                        <td class="col-md-3">{{colaborador.email}}</td>
                        <td class="col-md-2">{{colaborador.pessoa_fisica.cpf | cpfcnpj}}</td>                       
                        <td class="col-md-2">{{colaborador.pessoa_perfil.descricao}}</td>                       
                        <td class="text-center" style="font-size: 22px;">
                            <a href="/colaborador-editar/{{colaborador.codigo_pessoa}}"><span class="icon mdi mdi-edit"></span></a>
                            &nbsp;&nbsp;
                            <a data-toggle="modal" data-target="#md-footer-success" (click)="definirCodigo(colaborador.codigo_pessoa)"><span class="icon mdi mdi-delete"></span></a>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="!colaboradores">
                    <tr class="carregando">
                        <td class="carregando" colspan="5">
                            <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="colaboradores && colaboradores.length == 0">
                    <tr>
                        <td class="col-md-12" colspan="5">
                            Nenhum registro encontrado.
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
  </div>
</div>

<!--Modal Footer-->
<div id="md-footer-success" tabindex="-1" role="dialog" style="" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <div class="text-warning"><span class="modal-main-icon mdi mdi-alert-triangle"></span></div>
                <h3>Tem certeza que deseja excluir este registro?</h3>
                <p>Esta operação não poderá ser desfeita.</p>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default">Cancelar</button>
                <button type="button" data-dismiss="modal" class="btn btn-warning" (click)="apagar(codigo_pessoa)">Confirmar</button>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>