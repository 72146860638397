import {Injectable} from "@angular/core";
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import { environment } from '../../../environments/environment';

import { HttpClient } from "@angular/common/http";
@Injectable({
  providedIn: 'root'
})
export class AssociadoPendenciaService {

  private _Url = environment.UrlPrincipal + '/api/associadopendencia';
  private _urlRestaurar = environment.UrlPrincipal + '/api/pendencia-restaurar';

  constructor(private http: HttpClient) {}

  list(inativo=false, codigo_pessoa) {
    return this.http.get(this._Url + "?codigo_pessoa=" + codigo_pessoa + '&inativo='+inativo)
                    .catch(this.handleError);
  }

  find(id) {
    return this.http.get(this._Url + "/" + id)
                    .catch(this.handleError);
  }

  save(dados, codigo_associado_pendencia): Observable<any>{
    let http;
    if(codigo_associado_pendencia || dados.codigo_associado_pendencia){
        http = this.http.put(this._Url + "/" + dados.codigo_associado_pendencia, dados);
    } else {
        http = this.http.post(this._Url, dados);
    }
    http.map((res) => {
        return res;
    }).catch(this.handleError);
    return http;
  }

  restore(codigo_associado_pendencia) {
    return this.http.put(this._urlRestaurar + '?codigo_associado_pendencia=' + codigo_associado_pendencia, null);
  }

  delete(codigo_associado_pendencia) {
    return this.http.delete(this._Url + "/" + codigo_associado_pendencia);
  }

  private handleError(error: Response) {
    return error.json();
  }

}
