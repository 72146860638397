import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { CertificadoService } from 'src/app/core/services/certificado.service';

@Component({
  selector: 'app-certificado',
  templateUrl: './certificado.component.html',
  styleUrls: ['./certificado.component.css']
})
export class CertificadoComponent implements OnInit {

  breadCrumb;
  carregando;

  constructor(private appComponent: AppComponent,
              private formBuilder: FormBuilder,
              private routerParam: ActivatedRoute,
              private notifierService: NotifierService,
              private router: Router,
              private certificadoService: CertificadoService) { 

              }

  ngOnInit() {
    this.breadCrumb = [ 
      {nome: "Configurações", caminho: "/configuracao"}, {nome: "Certificado", caminho: "/certificado"}
    ];
    this.appComponent.setTitulo('Renovar Certificados');
    this.appComponent.setBreadcrumb(this.breadCrumb);
  }

  gerarCertificado() {
    this.carregando = true;
    this.certificadoService.gerar().subscribe((data) => {
      this.notifierService.notify( 'success', 'Par de chaves gerado com sucesso. A chave pública foi enviada para o servidor externo/público.');
      this.carregando = false;
    }, (error) => {
      this.carregando = false;
      this.notifierService.notify( 'error', 'Erro na GERAÇÃO do certificado.');
    });
  }

  sincronizar() {
    this.carregando = true;
    this.certificadoService.sincronizar().subscribe((data) => {
      this.notifierService.notify( 'success', 'Sincronia dos bancos de dados realizada com sucesso.');
      this.carregando = false;
    }, (error) => {
      this.carregando = false;
      this.notifierService.notify( 'error', 'Erro na SINCRONIZAÇÃO, procure o suporte técnico.');
    });
  }


}
