import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { NotifierService } from 'angular-notifier';
import { MaterialService } from '../../../core/services/material.service';
import { Material } from '../../../shared/models/material';
import { Colaborador } from 'src/app/shared/models/colaborador';
import { Categoria } from 'src/app/shared/models/categoria';
import { ColaboradorService } from 'src/app/core/services/colaborador.service';
import { Localizacao } from 'src/app/shared/models/localizacao';
import { LocalizacaoService } from 'src/app/core/services/localizacao.service';
import { CategoriaService } from 'src/app/core/services/categoria.service';

@Component({
  selector: 'app-patrimonio-listar',
  templateUrl: './patrimonio-listar.component.html',
  styleUrls: ['./patrimonio-listar.component.css']
})
export class PatrimonioListarComponent implements OnInit {

  materiais: Material;
  codigo_material;
  breadCrumb;
  materiais_inativos: Material;
  codigo_localizacao: any;
  numero_patrimonio: any;
  codigo_categoria: any;
  categorias: Categoria;
  localizacoes: Localizacao;

  constructor(private appComponent: AppComponent,
              private materialService: MaterialService,
              private categoriaService: CategoriaService,
              private localizacaoService: LocalizacaoService,
              private notifierService: NotifierService) { }

  ngOnInit() {
    this.breadCrumb = [
      {nome: 'Patrimônios', caminho: '/patrimonio-listar'}
    ];
    this.appComponent.setTitulo('Patrimônios Cadastrados');
    this.appComponent.setBreadcrumb(this.breadCrumb);

    this.listar();
    this.obterLocalizacao();
    this.obterCategorias();

  }

  definirCodigo(codigo_material) {
    this.codigo_material = codigo_material;
  }

  definirFiltroNumeroPatrimonio(numero_patrimonio) {
    this.numero_patrimonio = numero_patrimonio;
  }

  definirFiltroLocalizacao(codigo_localizacao) {
    this.codigo_localizacao = codigo_localizacao;
  }

  definirFiltroCategoria(codigo_categoria) {
    this.codigo_categoria = codigo_categoria;
  }

  obterCategorias() {
    this.categoriaService.list(false)
    .subscribe((data: Categoria) => {
      this.categorias = data;
    }, error => console.log(error));
  }

  obterLocalizacao() {
    this.localizacaoService.list(false)
    .subscribe((data: Localizacao) => {
      this.localizacoes = data;
    }, error => console.log(error));
  }

  listar(): void {
    // patrimonio ativos
    this.materialService.list(false, this.numero_patrimonio, this.codigo_localizacao, this.codigo_categoria).subscribe((data: Material) => {
      this.materiais = data;
    }, error => console.log(error));

    // patrimonios inativos
    this.materialService.list(true).subscribe((data: Material) => {
      this.materiais_inativos = data;
    }, error => console.log(error));
  }

  restaurar():void {
    if (this.codigo_localizacao) {
      const localizacao = {'codigo_localizacao': this.codigo_localizacao, 'codigo_material': this.codigo_material};
      this.materialService.restore(localizacao, this.codigo_material).subscribe((data) => {
        this.listar();
        this.notifierService.notify( 'success', 'Registro REATIVADO com sucesso.' );
      }, (error) => {
        this.notifierService.notify( 'danger', 'Não foi possível REATIVADO o registro, ocorreu um erro. ' + error );
      });
    } else {
      this.notifierService.notify( 'error', 'Informe a localização atual.');
    }
  }

}
