    <div class="be-wrapper be-nosidebar-left">
      <div class="be-content">
        <div class="main-content container">
          <div class="page-head">
            <h2 class="page-head-title">{{titulo}}</h2>
              <ol class="breadcrumb" *ngIf="breadcrumbs?.length > 0">
                <li><a href="dashboard"> <span class="icon mdi mdi-home"></span></a></li>&nbsp;
                <li *ngFor="let item of breadcrumbs"><a href="{{item.caminho}}">{{item.nome}}</a></li>
              </ol>
          </div>
          <router-outlet></router-outlet>
          <notifier-container></notifier-container>
        </div>
      </div>
    </div>
