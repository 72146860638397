import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { RouterModule, Routes } from '@angular/router';
import { CertificadoComponent } from './certificado/certificado.component';
import { SincronizacaoComponent } from './sincronizacao/sincronizacao.component';
import { ConfiguracaoComponent } from './configuracao.component';

const appRoutes: Routes = [
  {path: 'configuracao', component: ConfiguracaoComponent},
  {path: 'certificado', component: CertificadoComponent},
  {path: 'sincronizar', component: SincronizacaoComponent},
];

@NgModule({
  imports: [
    BrowserModule,
    SharedModule,
    FormsModule, 
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    RouterModule.forRoot(appRoutes)
  ],
  declarations: [CertificadoComponent, SincronizacaoComponent, ConfiguracaoComponent]
})
export class ConfiguracaoModule { }
