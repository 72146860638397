import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppComponent } from '../../../app.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { NotifierService } from 'angular-notifier';
import { Material } from 'src/app/shared/models/material';
import { MaterialService } from '../../../core/services/material.service';
import { Categoria } from '../../../shared/models/categoria';
import { CategoriaService } from '../../../core/services/categoria.service';
import { LocalizacaoService } from 'src/app/core/services/localizacao.service';
import { Localizacao } from 'src/app/shared/models/localizacao';
import { FormValidation } from 'src/app/shared/validation/form-validation';

@Component({
  selector: 'app-patrimonio-editar',
  templateUrl: './patrimonio-editar.component.html',
  styleUrls: ['./patrimonio-editar.component.css']
})
export class PatrimonioEditarComponent implements OnInit {

  @ViewChild('materialForm') formValues;

  form: FormGroup;
  breadCrumb;
  material: Material;
  localizacoes: Localizacao;
  categorias: Categoria;
  carregando: boolean;
  submitted: boolean;
  codigo_material: number;
  numero_patrimonio: string;

  constructor(private appComponent: AppComponent,
              private formBuilder: FormBuilder,
              private routerParam: ActivatedRoute,
              private router: Router,
              private authenticationService: AuthenticationService,
              private materialService: MaterialService,
              private localizacaoService: LocalizacaoService,
              private categoriaService: CategoriaService,
              private notifierService: NotifierService) {
              this.codigo_material = this.routerParam.snapshot.params.id;
  }

  ngOnInit() {

    this.carregando = true;
    this.breadCrumb = [
      {nome: 'Home', caminho: '/dashboard'},
      {nome: 'Patrimônio', caminho: '/patrimonio-listar'},
    ];
    this.appComponent.setTitulo('Novo Patrimônio');
    this.appComponent.setBreadcrumb(this.breadCrumb);

    this.form = this.formBuilder.group({
      numero_patrimonio: [null],
      numero_patrimonio_antigo: [null],
      conta_contabil: [null],
      numero_nota: [null, Validators.required],
      descricao: [null, Validators.required],
      data_compra: [null, [Validators.required, FormValidation.dataValidator]],
      valor: [null, Validators.required],
      nome_fornecedor: [null, Validators.required],
      codigo_categoria: [null, Validators.required],
      codigo_localizacao: [null, Validators.required],
    });
    if(this.codigo_material) {
      const codigoLocalizacaoControl = this.form.get('codigo_localizacao');
      codigoLocalizacaoControl.setValidators(null);
      codigoLocalizacaoControl.updateValueAndValidity();
    }

    this.obterCategorias();
    this.obterLocalizacao();
    if(this.codigo_material ) {
      this.obterMaterial();
    }

  }

  obterMaterial(): void {
    this.materialService.find(this.codigo_material, false).subscribe((data: Material) => {
      this.numero_patrimonio = data.numero_patrimonio;
      this.form.patchValue({
        numero_patrimonio: data.numero_patrimonio,
        numero_patrimonio_antigo: data.numero_patrimonio_antigo,
        numero_nota: data.numero_nota,
        descricao: data.descricao,
        data_compra: data.data_compra,
        valor: data.valor,
        nome_fornecedor: data.nome_fornecedor,
        conta_contabil: data.conta_contabil,
        codigo_categoria: data.codigo_categoria,
        codigo_localizacao: data.codigo_localizacao
      });
    }, error => console.log(error));
  }

  obterCategorias() {
    this.categoriaService.list(false)
    .subscribe((data: Categoria) => this.categorias = data,
                error => console.log(error));
  }

  obterLocalizacao() {
    this.localizacaoService.list(false, null)
    .subscribe((data: Localizacao) => this.localizacoes = data,
                error => console.log(error));
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit(): void {
    this.submitted = true;
    this.carregando = true;
    const material = this.form.value as Material;
    if (this.form.valid) {
      material.codigo_colaborador = parseInt(localStorage.getItem('codigo_pessoa'));
      console.log(material);
      
      this.materialService.save(material, this.codigo_material).subscribe((data) => {
        this.notifierService.notify( 'success', 'Material cadastrado com sucesso.' );
        this.carregando = false;
        this.router.navigate(['patrimonio-exibir/' + data.codigo_material]);
      }, (error) => {
        this.notifierService.notify( 'error', 'Não foi cadastrar um novo material. ' + error );
        this.carregando = false;
      });
    } else {
      console.log(this.form);
      this.notifierService.notify( 'error', 'Existe erro no preenchimento do formulário.');
      this.carregando = false;
    }
  }

}
