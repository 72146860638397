
<div style="padding-bottom: 20px;">
  <nav class="navbar navbar-default navbar-fixed-top be-top-header">
    <div class="container-fluid">
      <div class="navbar-header"><a href="dashboard" class="navbar-brand"></a></div>
      <div class="be-right-navbar">
        <ul class="nav navbar-nav navbar-right be-user-nav">
          <li class="dropdown"><a href="#" data-toggle="dropdown" role="button" aria-expanded="false" class="dropdown-toggle"><img src="assets/img/avatar.png" alt="Avatar"><span class="user-name">Túpac Amaru</span></a>
            <ul role="menu" class="dropdown-menu">
              <li>
                <div class="user-info">
                  <!-- <div class="user-name"><span class="user-name" *ngIf="!nome_guerra">{{nome_pessoa}}</span><span class="user-name" *ngIf="nome_guerra">{{nome_guerra}}</span></div> -->
                </div>
              </li>
              <li><a href="perfil"><span class="icon mdi mdi-face"></span> Perfil</a></li>
              <!--<li data-toggle="modal" data-target="#form-alterar-senha" style="cursor: pointer;"><a><span class="icon mdi mdi-settings"></span> Alterar Senha</a></li>-->
              <li><a href="alterar-senha"><span class="icon mdi mdi-settings"></span> Alterar Senha</a></li>
              <li (click)="logout()" style="cursor: pointer;"><a><span class="icon mdi mdi-power"></span> Sair</a></li>
            </ul>
          </li>
        </ul>
        <ul class="nav navbar-nav navbar-right be-icons-nav">
          <!--<li class="dropdown"><a href="sincronizar" role="button" aria-expanded="false" class="be-toggle-right-sidebar"><span class="icon mdi mdi-refresh-sync"></span></a></li>-->
          <li class="dropdown"><a href="configuracao" role="button" aria-expanded="false" class="be-toggle-right-sidebar"><span class="icon mdi mdi-settings"></span></a></li>
        </ul>
      </div>
      <a data-toggle="collapse" data-target="#be-navbar-collapse" class="be-toggle-top-header-menu collapsed">Navegação</a>
      <div id="be-navbar-collapse" class="navbar-collapse collapse">
        <ul class="nav navbar-nav">
          <li><a href="dashboard">Home</a></li>
          <li class="dropdown"><a href="#" data-toggle="dropdown" role="button" aria-expanded="false" class="dropdown-toggle">Associados <span class="mdi mdi-caret-down"></span></a>
            <ul role="menu" class="dropdown-menu">
              <li><a href="associado-listar">Associados</a></li>
              <li><a href="associacao-pendente">Aprovar novo filiado</a></li>
              <!--<li><a href="associado-alteracao-listar">Aprovar alteração cadastral</a></li>-->
            </ul>
          </li>
          <li><a href="demanda-listar">Demandas</a></li>
          <li><a href="documento-listar">Documentos</a></li>
          <li><a href="patrimonio-listar">Patrimônio</a></li>
          <!--<li><a href="tables-general.html">Serviços</a></li>-->
          <li><a href="repasse-listar">Repasse</a></li>
          <li><a href="colaborador-listar">Colaboradores</a></li>
          <!--<li><a href="quadro-estatutario">Quadro Estatutário</a></li>-->
        </ul>
      </div>
    </div>
  </nav>
</div>
