import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '../../core/services/authentication.service';
import { NotifierService } from 'angular-notifier';
import { PessoaAcessoService } from 'src/app/core/services/pessoa-acesso.service';

@Component({
            selector: 'app-login',
            templateUrl: 'login.component.html'
           })
export class LoginComponent implements OnInit {

    @ViewChild('recuperarSenhaForm') formValues;

    loginForm: FormGroup;
    RecuperarSenhaForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private notifierService: NotifierService,
        private pessoaAcessoService: PessoaAcessoService,
        private authenticationService: AuthenticationService) {}

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            email: [null, Validators.required],
            password: [null, Validators.required]
        });
        this.RecuperarSenhaForm = this.formBuilder.group({
            email: [null, Validators.required]
        });

        // reset login status
        this.authenticationService.logout();

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'dashboard';
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }
    get rS() { return this.RecuperarSenhaForm.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService.login(this.f.email.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                data => {
                    // login successful if there's a jwt token in the response
                    if (data && data.access_token) {
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        localStorage.setItem('currentUser', JSON.stringify(data));
                    }
                    this.router.navigate([this.returnUrl]);
                },
                error => {
                    this.error = error;
                    this.loading = false;
                });
    }

    onRecuperarSenhaSubmit() {
        let dados = this.RecuperarSenhaForm.value;
        this.pessoaAcessoService.save(dados).subscribe((data) => {
          this.formValues.resetForm();
          this.notifierService.notify( 'success', 'E-mail enviado com as informações para alteração da senha.' );
        }, (error) => {
          this.notifierService.notify( 'error', 'Não foi possível CADASTRAR/ATUALIZAR o registro, ocorreu um erro. ' + error );
        });        
    }

}