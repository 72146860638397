import { BrowserModule } from '@angular/platform-browser';
import { NgModule, DEFAULT_CURRENCY_CODE } from '@angular/core';

import { RouterModule, Routes} from '@angular/router';
import { NotifierModule, NotifierOptions } from 'angular-notifier';

import { AppComponent } from './app.component';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { ColaboradorModule } from './modules/colaborador/colaborador.module';
import { LoginModule } from './modules/login/login.module';
import { AlterarSenhaModule } from './modules/alterar-senha/alterar-senha.module';
import { PerfilModule } from './modules/perfil/perfil.module';
import { AssociadoModule } from './modules/associado/associado.module';
import { RepasseModule } from './modules/repasse/repasse.module';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtInterceptor } from './shared/helpers/jwt.interceptor';
import { ErrorInterceptor } from './shared/helpers/error.interceptor';
import { CommonModule } from '@angular/common';
import { ConfiguracaoModule } from './modules/configuracao/configuracao.module';
import { SuporteModule } from './modules/suporte/suporte.module';
import { TabelaApoioModule } from './modules/tabela-apoio/tabela-apoio.module';
import { DocumentosModule } from './modules/documentos/documentos.module';
import { PatrimonioModule } from './modules/patrimonio/patrimonio.module';
import { MoedaBrPipe } from './moeda-br.pipe';
import {LayoutModule} from "./modules/layout/layout.module";
import { ModalModule } from 'ngx-bootstrap/modal';

const appRoutes: Routes = [
  {path: '', redirectTo: 'login', pathMatch: 'full'}
];

const notifierDefaultOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 20
    },
    vertical: {
      position: 'top',
      distance: 100,
      gap: 10
    }
  }
};

@NgModule({
    declarations: [
        AppComponent,
        MoedaBrPipe,
    ],
    imports: [
        CommonModule,
        BrowserModule,
        HttpClientModule,
        DashboardModule,
        ColaboradorModule,
        LoginModule,
        AlterarSenhaModule,
        PerfilModule,
        AssociadoModule,
        SuporteModule,
        TabelaApoioModule,
        DocumentosModule,
        PatrimonioModule,
        ConfiguracaoModule,
        LayoutModule,
        RepasseModule,
        RouterModule.forRoot(appRoutes),
        ModalModule.forRoot(),
        NotifierModule.withConfig(notifierDefaultOptions),
    
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        {provide: DEFAULT_CURRENCY_CODE, useValue: 'pt-BR'}
    ],
    exports: [
    
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
