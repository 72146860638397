import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Ng2BRPipesModule } from 'ng2-brpipes';
import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from 'src/app/shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { AuthGuard } from 'src/app/core/authentication/auth.guard';
import {ProgressBarModule} from "angular-progress-bar"
 
import { DadosPessoaisComponent } from './dados-pessoais/dados-pessoais.component';
import { DadosFinanceirosComponent } from './dados-financeiros/dados-financeiros.component';
import { DadosAdministrativosComponent } from './dados-administrativos/dados-administrativos.component';
import { DadosControleComponent } from './dados-controle/dados-controle.component';
import { DadosComplementaresComponent } from './dados-complementares/dados-complementares.component';
import { AssociadoListarComponent } from './associado-listar/associado-listar.component';
import { PendenciasComponent } from './pendencias/pendencias.component';
import { AssociadoSituacaoComponent } from './associado-situacao/associado-situacao.component';
import { AssociadoAlteracaoListarComponent } from './associado-alteracao-listar/associado-alteracao-listar.component';
import { FichaFinanceiraComponent } from './ficha-financeira/ficha-financeira.component';
import { NgxCurrencyModule } from 'ngx-currency';
import { BeneficiosComponent } from './beneficios/beneficios.component';
import { AssociacaoPendenteComponent } from './associacao-pendente/associacao-pendente.component';
import { AssociadoNovoComponent } from './associado-novo/associado-novo.component';

const appRoutes: Routes = [
  { path: 'associado-novo', component: AssociadoNovoComponent, canActivate: [AuthGuard]  },
  { path: 'associado-listar', component: AssociadoListarComponent, canActivate: [AuthGuard]  },
  { path: 'associado-alteracao-listar', component: AssociadoAlteracaoListarComponent, canActivate: [AuthGuard]  },
  { path: 'associacao-pendente', component: AssociacaoPendenteComponent, canActivate: [AuthGuard]  },
  { path: 'associado-situacao/:id', component: AssociadoSituacaoComponent, canActivate: [AuthGuard]  },
  { path: 'dados-pessoais', component: DadosPessoaisComponent, canActivate: [AuthGuard]  },
  { path: 'dados-pessoais/:id', component: DadosPessoaisComponent, canActivate: [AuthGuard]  },
  { path: 'dados-financeiros/:id', component: DadosFinanceirosComponent, canActivate: [AuthGuard]  },
  { path: 'dados-administrativos/:id', component: DadosAdministrativosComponent, canActivate: [AuthGuard]  },
  { path: 'dados-controle/:id', component: DadosControleComponent, canActivate: [AuthGuard]  },
  { path: 'dados-complementares/:id', component: DadosComplementaresComponent, canActivate: [AuthGuard]  },
  { path: 'pendencias/:id', component: PendenciasComponent, canActivate: [AuthGuard]  },
  { path: 'ficha-financeira/:id', component: FichaFinanceiraComponent, canActivate: [AuthGuard]  },
  { path: 'beneficios/:id', component: BeneficiosComponent, canActivate: [AuthGuard]  },
];

export const customCurrencyMaskConfig = {
  align: "right",
  allowNegative: false,
  allowZero: true,
  decimal: ",",
  precision: 2,
  prefix: "",
  suffix: "",
  thousands: ".",
  nullable: null
};

@NgModule({
  imports: [
    BrowserModule,
    SharedModule,
    FormsModule, 
    ReactiveFormsModule,
    ProgressBarModule,
    Ng2BRPipesModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    NgxMaskModule.forRoot(),
    RouterModule.forRoot(appRoutes)
  ],
  declarations: [
                 AssociadoNovoComponent,
                 DadosPessoaisComponent, 
                 DadosFinanceirosComponent, 
                 DadosAdministrativosComponent, 
                 DadosControleComponent, 
                 DadosComplementaresComponent, 
                 AssociadoListarComponent, 
                 PendenciasComponent, 
                 AssociadoSituacaoComponent,
                 AssociadoAlteracaoListarComponent,
                 FichaFinanceiraComponent,
                 BeneficiosComponent,
                 AssociacaoPendenteComponent
                ]
})
export class AssociadoModule { }
