import { log } from 'util';
import { Documento } from './../../../shared/models/documento';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { DocumentoService } from 'src/app/core/services/documento.service';
import { NotifierService } from 'angular-notifier';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { DocumentoTipoService } from 'src/app/core/services/documento-tipo.service';
import { DocumentoTipo } from 'src/app/shared/models/documento-tipo';
import { DocumentoAnexoService } from 'src/app/core/services/documento-anexo.service';

@Component({
  selector: 'app-documento-listar',
  templateUrl: './documento-listar.component.html',
  styleUrls: ['./documento-listar.component.css']
})
export class DocumentoListarComponent implements OnInit {

  @ViewChild('documentoForm') formValues;

  form: FormGroup;
  documentos: Documento;
  codigo_documento;
  breadCrumb;
  documentos_inativos: Documento;
  documentos_tipo: DocumentoTipo;
  submited: boolean;
  carregando: boolean;
  filtro_ano_inicial: number;
  filtro_ano_final: number;
  startYear: number;
  ano: { ano: number; }[];
  filtro_ano: number;

  constructor(private appComponent: AppComponent,
              private formBuilder: FormBuilder,
              private documentoService: DocumentoService,
              private documentoTipoService: DocumentoTipoService,
              private documentoAnexoService: DocumentoAnexoService,
              private notifierService: NotifierService) { }

  ngOnInit() {
    this.breadCrumb = [
      {nome: 'documentos', caminho: '/documento-listar'}
    ];
    this.appComponent.setTitulo('Documentos Cadastrados');
    this.appComponent.setBreadcrumb(this.breadCrumb);

    this.form = this.formBuilder.group({
      codigo_documento_tipo: [null, Validators.required],
      filtro_ano: [null],
    });

    this.listar(false);
    this.obterDocumentoTipo();
    this.obterAno();

  }

  obterAno() {
    this.filtro_ano_inicial = new Date().getFullYear();
    this.filtro_ano_final = new Date().getFullYear();
    this.startYear = 2022;
    this.ano = [{"ano": this.startYear}];
    for(let i=this.startYear+1; i <= this.filtro_ano_final; i++) {
      this.ano.push({"ano": i});
    }
  }

  definirFiltroAno(ano) {
    this.filtro_ano = ano;
  }

  definirCodigo(codigo_documento) {
    this.codigo_documento = codigo_documento;
  }

  obterDocumentoTipo() {
    this.documentoTipoService.list(false)
    .subscribe((data: DocumentoTipo) => this.documentos_tipo = data,
                error => console.log(error));
  }

  listar(filtro): void {

    let ano_pesquisa: number;
    // documentos ativas
    this.documentos = null;
    this.carregando = true;

    const documento = this.form.value as Documento;
    if(!filtro) {
      documento.codigo_documento_tipo = null;
      documento.ano = null;
      ano_pesquisa = null;
      this.form.controls.codigo_documento_tipo.setValue(null);
      this.form.controls.filtro_ano.setValue(null);
      this.filtro_ano = null;
    }

    if(!this.filtro_ano) {
      ano_pesquisa = null;
    } else {
      ano_pesquisa = this.filtro_ano;
    }

    this.documentoService.list(false, documento.codigo_documento_tipo, ano_pesquisa).subscribe((data: Documento) => {
      this.documentos = data;
      this.carregando = false;
    }, error => console.log(error));

    // documentos inativas
    this.documentoService.list(true, documento.codigo_documento_tipo, ano_pesquisa).subscribe((data: Documento) => {
      this.documentos_inativos = data;
    }, error => console.log(error));

  }

  baixarDocumento( codigo_documento, nome ): void {
    this.documentoAnexoService.downloadArquivo(codigo_documento, nome);
  }

  restaurar(codigo_documento):void {
    this.documentoService.restore(codigo_documento).subscribe((data) => {
      this.listar(false);
      this.notifierService.notify( 'success', 'Registro RESTAURADO com sucesso.' );
    }, (error) => {
      this.notifierService.notify( 'danger', 'Não foi possível RESTAURAR o registro, ocorreu um erro. ' + error );
    });
  }

  apagar(codigo_documento):void {
    this.documentoService.delete(codigo_documento).subscribe((data) => {
      this.listar(false);
      this.notifierService.notify( 'success', 'Registro DELETADO com sucesso.' );
    }, (error) => {
      this.notifierService.notify( 'danger', 'Não foi possível DELETAR o registro, ocorreu um erro. ' + error );
    });
  }

}
