<app-header></app-header>

<div class="panel panel-default panel-border-color panel-border-color-primary">
  <div class="panel-heading panel-heading-divider">Cadastro do Plano</div>
  <div class="panel-body">
    <form #planoForm="ngForm" [formGroup]="form" novalidate="" class="form-horizontal group-border-dashed">

      <div class="form-group xs-mt-5" *ngIf="!codigo_plano || (codigo_plano && codigo_classe)">
        <label for="inputEmail" class="col-sm-3 control-label">
          Classe
        </label>
        <div class="col-sm-5" *ngIf="!codigo_plano && (!classes || classes.length > 0)">
          <select class="select2 form-control" formControlName="codigo_classe">
            <option *ngFor="let dado of classes" value="{{dado.codigo_classe}}">
              {{dado.nome}}
            </option>
          </select>
        </div>
        <div class="col-sm-5" *ngIf="!codigo_plano && (!classes || classes.length == 0)" style="margin-top: 10px;">
          <p>Não há classe para ser selecionada sem plano</p>
        </div>
        <div class="col-sm-5" *ngIf="codigo_plano && nome_classe" style="margin-top: 10px;">
          <p>{{nome_classe}}</p>
        </div>
      </div>

      <div class="form-group xs-mt-10">
        <label for="inputNome" class="col-sm-3 control-label">
          Descrição <span class="obrigatorio">*</span>
        </label>
        <div class="col-sm-8">
          <input id="inputDescricao" type="text" placeholder="Descrição" class="form-control" formControlName="descricao">
        </div>
      </div>

      <div class="form-group xs-mt-10">
        <label for="inputNome" class="col-sm-3 control-label">
          Valor <span class="obrigatorio">*</span>
        </label>
        <div class="col-sm-3">
          <input id="inputNome" type="text" placeholder="R$" class="form-control" formControlName="valor" currencyMask>
        </div>
      </div>

      <div class="form-group">
          <div class="col-sm-offset-3 col-sm-7">
            <button class="btn btn-space btn-default" routerLink="/plano-listar">Cancelar</button>
            <a class="btn btn-space btn-primary" *ngIf="!codigo_plano" (click)="onSubmit()">Confirmar</a>
            <a class="btn btn-space btn-primary" *ngIf="codigo_plano" data-toggle="modal" data-target="#md-footer-success">Atualizar</a>
          </div>
      </div>

    </form>
  </div>
</div>


<!--Modal Footer-->
<div id="md-footer-success" tabindex="-1" role="dialog" style="" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                <div class="text-warning"><span class="modal-main-icon mdi mdi-alert-triangle"></span></div>
                <h3>Caso este plano esteja sendo utilizado para algum associado, este será desativado e um novo será criado. Todos os associados vinculados a este terão os planos atualizados para o novo.<br /> Confirma esta operação?</h3>
                <p>Esta operação não poderá ser desfeita.</p>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default">Cancelar</button>
                <button type="button" data-dismiss="modal" class="btn btn-warning" (click)="onSubmit()">Confirmar</button>
            </div>
        </div>
    </div>
</div>   

<app-footer></app-footer>
