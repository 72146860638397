import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {RodapeLayout} from "../../shared/models/rodape-layout";
import {map} from "rxjs/operators";
import {CampoRodapeLayout} from "../../shared/models/campo-rodape-layout";

@Injectable({
  providedIn: 'root'
})
export class RodapeLayoutService {

  private url: string = environment.UrlApi + "/layout/rodape";

  constructor(private http: HttpClient) {}

  list(tipo_layout: string = null) : Observable<RodapeLayout[]> {
    let url: string = this.url;
    if(tipo_layout){
      url += "/" + tipo_layout;
    }
    return this.http.get(url)
      .pipe(map((res: any) => {
        let rodapes_layout: Array<RodapeLayout> = [];
        res.forEach((item: any) => {
          let campos_rodape_layout: Array<CampoRodapeLayout> = item.campos_rodape_layout as Array<CampoRodapeLayout>;
          let rodape_layout: RodapeLayout = item as RodapeLayout;
          rodape_layout.campos_rodape_layout = campos_rodape_layout;
          rodapes_layout.push(rodape_layout);
        });
        return rodapes_layout;
      }))
      .catch(this.handleError);
  }

  find(id) {
    return this.http.get(this.url + "/" + id)
      .catch(this.handleError);
  }

  save(rodape_layout: RodapeLayout): Observable<any>{
    let http;
    if(rodape_layout.codigo_rodape_layout){
      http = this.http.put(this.url + "/" + rodape_layout.codigo_rodape_layout, rodape_layout);
    } else {
      http = this.http.post(this.url, rodape_layout);
    }
    http.map((res) => {
      return res;
    }).catch(this.handleError);
    return http;
  }

  update(rodape_layout: RodapeLayout, campos_rodape_layout: Array<CampoRodapeLayout>) {
    return this.http.put(this.url + "/update/" + rodape_layout.codigo_rodape_layout, {
            titulo: rodape_layout.titulo,
            campos: campos_rodape_layout,
        }).catch(this.handleError);
  }

  delete(codigo_rodape_layout): Observable<any> {
    return this.http.delete(this.url + "/" + codigo_rodape_layout);
  }

  copy(rodape_layout: RodapeLayout) {
    return this.http.put(this.url + "/copy/" + rodape_layout.codigo_rodape_layout, rodape_layout)
      .catch(this.handleError);
  }


  private handleError(error: Response) {
    return error.json();
  }

}
