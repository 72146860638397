import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UploadFileService {

  private _url = environment.UrlPrincipal + '/api/demandaanexo';

  constructor(private http: HttpClient) { }

  upload(files: Set<File>, url: string = null, codigo = null, codigo_pessoa = null) {
    if(!url) {
        url = this._url; 
    }
    const formData = new FormData();
    let i = 0;
    files.forEach(file => { 
      formData.append('file_' + i, file, file.name);
      i++;
    });
    formData.append('codigo', codigo);
    formData.append('codigo_pessoa', codigo_pessoa);
    return this.http.post(url, formData, {
      observe: 'events',
      reportProgress: true
    });
  }

  download(url: string) {
    return this.http.get(url, {
      responseType: 'blob' as 'json'
      // reportProgress
      // content-length
    });
  }

  handleFile(res: any, fileName: string) {
    const file = new Blob([res], {
      type: res.type
    });

    // IE
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(file);
      return;
    }

    const blob = window.URL.createObjectURL(file);

    const link = document.createElement('a');
    link.href = blob;
    link.download = fileName;

    // link.click();
    link.dispatchEvent(new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    }));

    setTimeout(() => { // firefox
      window.URL.revokeObjectURL(blob);
      link.remove();
    }, 100);
  }
}
