import {TipoLayout} from "./tipo-layout";
import {CampoCabecalhoLayout} from "./campo-cabecalho-layout";

export class CabecalhoLayout {
    codigo_cabecalho_layout: number;
    codigo_tipo_layout: number;
    titulo: string;
    sigla: string;
    descricao: string;
    created_at: Date;
    deleted_at: Date;
    pode_alterar: boolean = false;
    tipo_layout: TipoLayout = new TipoLayout();
    campos_cabecalho_layout: Array<CampoCabecalhoLayout> = [];
}
