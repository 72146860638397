import { Component, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { AssociadoService } from 'src/app/core/services/associado.service';
import { NotifierService } from 'angular-notifier';
import { Associado } from 'src/app/shared/models/associado';
import { PessoaFiltro } from 'src/app/shared/models/pessoa-filtro';
import { ActivatedRoute, Router } from '@angular/router';
import { AssociadoPagamento } from 'src/app/shared/models/associado-pagamento';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormValidation } from 'src/app/shared/validation/form-validation';
import { FormaPagamentoService } from 'src/app/core/services/forma-pagamento.service';
import { FormaPagamento } from 'src/app/shared/models/forma-pagamento';
import { AssociadoPagamentoTipo } from 'src/app/shared/models/associado-pagamento-tipo';
import { AssociadoPagamentoService } from 'src/app/core/services/associado-pagamento.service';
import { AssociadoPagamentoTipoService } from 'src/app/core/services/associado-pagamento-tipo.service';
import { AssociadoPagamentoAdicional } from 'src/app/shared/models/associado-pagamento-adicional';
import { AssociadoPagamentoAdicionalService } from 'src/app/core/services/associado-pagamento-adicional.service';
import { AssociadoPagamentoAdicionalParcela } from 'src/app/shared/models/associado-pagamento-adicional-parcela';
import { AssociadoPagamentoAdicionalParcelaService } from 'src/app/core/services/associado-pagamento-adicional-parcela.service';
import { PessoaFiltroService } from 'src/app/core/services/pessoa-filtro.service';
import * as moment from 'src/assets/lib/moment.js/moment';

@Component({
  selector: 'app-ficha-financeira',
  templateUrl: './ficha-financeira.component.html',
  styleUrls: ['./ficha-financeira.component.css']
})
export class FichaFinanceiraComponent implements OnInit {

  @ViewChild('contaPagarForm') formValues;
  @ViewChild('devolucaoForm') formDevolucaoValues;
  @ViewChild('pagamentoAdicionalParcelaForm') formValuesAdicionalParcela;

  formPagar: FormGroup;
  formPagamentoAdicional: FormGroup;
  formPagamentoAdicionalParcela: FormGroup;
  formDevolucao: FormGroup;

  associado: Associado;
  breadCrumb;
  pagamentos: AssociadoPagamento;
  codigo_pessoa: any;
  codigo_associado_pagamento: any;
  codigo_conta_pagar: number;
  formasPagamento: FormaPagamento;
  associadoPagamentoTipo: AssociadoPagamentoTipo;
  pagamentosAdicionais: AssociadoPagamentoAdicional;
  submitted: boolean;
  codigo_associado_pagamento_adicional: number;
  erro_cadastro: string;
  erro_cadastro_pagamento_adicional: string;
  nome: string;
  matricula_dpf: string;
  tipo: string;
  isento_mensalidade: any;
  nome_classe: any;
  classe_nome: any;
  valor_plano: number;
  mes_referencia: any;
  ano_referencia: any;
  pago_periodo: any;
  total_periodo: any;
  total_restante_periodo: any;
  plano_nome: any;
  pagamentosAdicionaisParcela: AssociadoPagamentoAdicionalParcela;
  codigo_associado_pagamento_adicional_parcela: any;
  codigo_plano: any;
  carregando: boolean;
  carregando_dados: boolean;
  filtro_ano_inicial: any;
  filtro_ano_final: any;
  filtro_mes_inicial: any;
  filtro_mes_final: any;
  ano: any;
  filtro_tipo: any;
  selected: any;
  startYear: any;
  mes_atual: any;
  meses: any;
  carregando_editar: boolean;
  pagamento_adicional: boolean;
  associadoRecebimentoTipo: AssociadoPagamentoTipo;
  associadoDevolucaoTipo: AssociadoPagamentoTipo;
  total_a_devolver: any;
  valor_devolucao: any;
  data_pagamento_confirmado: any;
  pessoaFiltro: PessoaFiltro;
  codigo_colaborador: string;
  filtro_tipo_salvo: any;
  filtro_mes_inicial_salvo: any;
  filtro_mes_final_salvo: any;
  filtro_ano_inicial_salvo: any;
  filtro_ano_final_salvo: any;

  constructor(private appComponent: AppComponent,
              private formBuilder: FormBuilder,
              private routerParam: ActivatedRoute,
              private router: Router,
              private associadoService: AssociadoService,
              private pessoaFiltroService: PessoaFiltroService,
              private associadoPagamentoService: AssociadoPagamentoService,
              private associadoPagamentoTipoService: AssociadoPagamentoTipoService,
              private associadoPagamentoAdicionalService: AssociadoPagamentoAdicionalService,
              private associadoPagamentoAdicionalParcelaService: AssociadoPagamentoAdicionalParcelaService,
              private formaPagamentoService: FormaPagamentoService,
              private notifierService: NotifierService) {
              this.codigo_pessoa = this.routerParam.snapshot.params.id;
              this.codigo_colaborador = localStorage.getItem('codigo_pessoa');
  }

  ngOnInit() {
    this.breadCrumb = [
      {nome: 'Associados', caminho: 'associado-listar'},
      {nome: 'Ficha Financeira', caminho: '/ficha-financeira'}
    ];
    this.appComponent.setTitulo('Ficha Financeira');
    this.appComponent.setBreadcrumb(this.breadCrumb);

    this.formPagar = this.formBuilder.group({
      valor: [null, Validators.required],
      data_pagamento_confirmado: [null, [, Validators.required, FormValidation.dataValidator]],
      valor_desconto: [null],
      valor_acrescimo: [null],
      valor_recebimento: [null],
      codigo_forma_pagamento: [null, Validators.required],
      observacao: [null]
    });

    this.formPagamentoAdicional = this.formBuilder.group({
      valor: [null, Validators.required],
      data_referencia: [null, Validators.required],
      quantidade_parcelas: [null, Validators.required],
      codigo_associado_pagamento_tipo: [null, Validators.required],
      codigo_forma_pagamento: [null, Validators.required],
      observacao: [null]
    });

    this.formDevolucao = this.formBuilder.group({
      valor: [null, Validators.required],
      data_referencia: [null, Validators.required],
      quantidade_parcelas: [null, Validators.required],
      codigo_associado_pagamento_tipo: [null, Validators.required],
      observacao: [null]
    });


    this.formPagamentoAdicionalParcela = this.formBuilder.group({
      valor: [null, Validators.required],
      data_referencia: [null, Validators.required],
      codigo_forma_pagamento: [null, Validators.required]
    });

    this.obterAssociado();
    this.filtrosSalvos();
    this.listar();
    this.listarPagamentoAdicionalParcela();
    this.obterFormaRecebimento();
    this.listarPagamentoTipo();
    this.listarRecebimentoTipo();
    this.obterMes();
    this.obterAno();
    this.carregando = false;

  }

  obterAssociado(): void {
    this.carregando = true;
    this.associadoService.find(this.codigo_pessoa).subscribe((data: Associado) => {
      this.associado = data;
    });
  }

  filtrosSalvos() {
    this.pessoaFiltroService.list(this.codigo_colaborador, 'ficha-financeira').subscribe((data: PessoaFiltro) => {
      this.pessoaFiltro = data;
      this.filtro_tipo_salvo = data.filtro_tipo;
      this.filtro_mes_inicial_salvo = data.filtro_mes_inicial;
      this.filtro_mes_final_salvo = data.filtro_mes_final;
      this.filtro_ano_inicial_salvo = data.filtro_ano_inicial;
      this.filtro_ano_final_salvo = data.filtro_ano_final;
      console.log(data);
      
    }, error => console.log(error));
  }

  calcularValorPagar() {

    let valor;
    let valor_acrescimo;
    let valor_desconto;
    let valor_final;

    valor = parseFloat(this.formPagar.value.valor);

    if(this.formPagar.value.valor_acrescimo) {
      valor_acrescimo = parseFloat(this.formPagar.value.valor_acrescimo);
    } else {
      valor_acrescimo = parseFloat('0.00');
    }
    if(this.formPagar.value.valor_desconto) {
      valor_desconto = parseFloat(this.formPagar.value.valor_desconto);
    } else {
      valor_desconto = parseFloat('0.00');
    }

    valor_final = valor + valor_acrescimo - valor_desconto;
    this.formPagar.patchValue({valor_recebimento: valor_final});

  }

  cancelar() {
    this.formPagar.reset();
    this.formPagar.patchValue({
      valor: null,
      valor_recebimento: null,
      valor_desconto: null,
      valor_acrescimo: null,
      data_pagamento_confirmado: null,
      codigo_forma_pagamento: null,
    });
  }

  definirCodigo(codigo_associado_pagamento) {
    this.codigo_associado_pagamento = codigo_associado_pagamento;
    // this.formPagar.patchValue({
    //   valor: this.valor_plano
    // });
  }

  obterAno() {
    this.filtro_ano_inicial = new Date().getFullYear();
    this.filtro_ano_final = new Date().getFullYear();
    this.startYear = 2022;
    this.ano = [{"ano": this.startYear}];
    for(let i=this.startYear+1; i <= this.filtro_ano_final+1; i++) {
      this.ano.push({"ano": i});
    }
  }

  obterMes() {
    this.meses = [
                  {"codigo": 1, "nome": "Janeiro"},
                  {"codigo": 2, "nome": 'Fevereiro'},
                  {"codigo": 3, "nome": "Março"},
                  {"codigo": 4, "nome": "Abril"},
                  {"codigo": 5, "nome": "Maio"},
                  {"codigo": 6, "nome": "Junho"},
                  {"codigo": 7, "nome": "Julho"},
                  {"codigo": 8, "nome": "Agosto"},
                  {"codigo": 9, "nome": "Setembro"},
                  {"codigo": 10, "nome": "Outubro"},
                  {"codigo": 11, "nome": "Novembro"},
                  {"codigo": 12, "nome": "Dezembro"},
                 ];
    if(!this.filtro_mes_inicial) {
      this.filtro_mes_inicial = 1;
    }
    if(!this.filtro_mes_final) {
      this.filtro_mes_final = 12;
    }
  }

  definirFiltroMes(mes, inicial) {
    if(inicial) {
      if(mes > this.filtro_mes_final) {
        this.notifierService.notify( 'error', 'O mês inicial não pode ser maior que o mês final.');
      }
      this.filtro_mes_inicial = mes;
    } else {      
      if(mes < this.filtro_mes_inicial) {
        this.notifierService.notify( 'error', 'O mês final não pode ser menor que o mês inicial.');
      }
      this.filtro_mes_final = mes;
    }
  }

  definirFiltroTipo(tipo) {
    this.filtro_tipo = tipo;
  }

  definirFiltroAno(ano, inicial) {
    if(inicial) {
      if(ano > this.filtro_ano_final) {
        this.notifierService.notify( 'error', 'O ano inicial não pode ser maior que o ano final.');
      }
      this.filtro_ano_inicial = ano;
    } else {
      if(ano < this.filtro_ano_final) {
        this.notifierService.notify( 'error', 'O ano final não pode ser menor que o ano inicial.');
      }
      this.filtro_ano_final = ano;
    }
  }

  definirCodigoPagamentoAdicional(codigo_associado_pagamento_adicional) {
    this.codigo_associado_pagamento_adicional = codigo_associado_pagamento_adicional;
  }

  definirCodigoPagamentoAdicionalParcela(codigo_associado_pagamento_adicional_parcela) {
    this.codigo_associado_pagamento_adicional_parcela = codigo_associado_pagamento_adicional_parcela;
  }

  obterFormaRecebimento() {
    this.formaPagamentoService.list().subscribe((data: FormaPagamento) => this.formasPagamento = data, error => console.log(error));
  }

  listarPagamentoTipo() {
    this.associadoPagamentoTipoService.list(false, false).subscribe((data: AssociadoPagamentoTipo) => {
      this.associadoPagamentoTipo = data;
      console.log(this.associadoPagamentoTipo);
      
    }, error => console.log(error));
  }

  listarRecebimentoTipo() {
    this.associadoPagamentoTipoService.list(false, true).subscribe((data: AssociadoPagamentoTipo) => {
      this.associadoDevolucaoTipo = data;
      console.log(this.associadoRecebimentoTipo);
      
    }, error => console.log(error));
  }

  listar(filtro_salvo = null): void {
    // planos ativos
    let erro = false;
    if(this.filtro_mes_final < this.filtro_mes_inicial) {
      this.notifierService.notify( 'error', 'O mês final não pode ser menor que o mês inicial.');
      erro = true;
    }
    if(this.filtro_ano_final < this.filtro_ano_inicial) {
      this.notifierService.notify( 'error', 'O ano final não pode ser menor que o ano inicial.');
      erro = true;
    }
    console.log(erro);
    console.log(this.filtro_ano_final + ' < ' + this.filtro_ano_inicial);
    
    if(!erro) {
      this.pagamentos = null;
      this.carregando = true;
      if(!this.filtro_mes_inicial || this.filtro_mes_inicial == 'todos') {
        this.filtro_mes_inicial = 1;
      }
      if(!this.filtro_mes_final || this.filtro_mes_final == 'todos') {
        this.filtro_mes_final = 12;
      }
      if(!this.filtro_ano_inicial) {
        this.filtro_ano_inicial = new Date().getFullYear();
      }
      if(!this.filtro_ano_final) {
        this.filtro_ano_final = new Date().getFullYear();
      }
      if(filtro_salvo) {
        this.filtro_mes_inicial = this.filtro_mes_inicial_salvo;
        this.filtro_mes_final = this.filtro_mes_final_salvo;
        this.filtro_ano_inicial = this.filtro_ano_inicial_salvo;
        this.filtro_ano_final = this.filtro_ano_final_salvo;
        this.filtro_tipo = this.filtro_tipo_salvo;
      }
      this.associadoPagamentoService.list(this.codigo_pessoa, this.filtro_mes_inicial, this.filtro_mes_final, this.filtro_ano_inicial, this.filtro_ano_final, this.filtro_tipo).subscribe((data: AssociadoPagamento) => {
        this.pagamentos = data;
        this.nome = data[0].nome,
        this.matricula_dpf = data[0].matricula_dpf;
        this.tipo = data[0].tipo;
        this.isento_mensalidade = data[0].isento_mensalidade;
        this.classe_nome = data[0].nome_classe;
        this.plano_nome = data[0].plano_atual.descricao;//data[0].plano.descricao;
        this.codigo_plano = data[0].plano_atual.codigo_plano;
        this.total_periodo = data[0].total_periodo;
        this.pago_periodo = data[0].pago_periodo;
        this.total_a_devolver = data[0].total_a_devolver;
        this.valor_devolucao = data[0].valor_devolucao;
        this.total_restante_periodo = data[0].restante_periodo;
        console.log(this.pagamentos[0].codigo);
        this.carregando = false;
      }, error => console.log(error.error));
      this.carregando = false;
    }
  }

  listarPagamentoAdicionalParcela(): void {
    // planos ativos
    this.associadoPagamentoAdicionalParcelaService.list(false, this.codigo_pessoa).subscribe((data: AssociadoPagamentoAdicionalParcela) => {
      this.pagamentosAdicionaisParcela = data;
    }, error => console.log(error));
  }

  recuperarAssociadoPagamento(codigo) {
    console.log(codigo);
    console.log(this.pagamentos);
    console.log(this.pagamentos[codigo].valor);
    this.mes_referencia = moment(this.pagamentos[codigo].data_referencia).format('MM');
    this.ano_referencia = moment(this.pagamentos[codigo].data_referencia).format('YYYY');
    if(this.pagamentos[codigo].tipo_pagamento == 'adicional' || this.pagamentos[codigo].tipo_pagamento == 'devolucao') {
      this.formPagar.patchValue({
        valor: this.pagamentos[codigo].valor,
        valor_recebimento: this.pagamentos[codigo].valor + this.pagamentos[codigo].acrescimo - this.pagamentos[codigo].desconto,
        valor_desconto: this.pagamentos[codigo].desconto,
        valor_acrescimo: this.pagamentos[codigo].acrescimo,
        codigo_forma_pagamento: this.pagamentos[codigo].codigo_forma_pagamento,
        data_pagamento_confirmado: this.pagamentos[codigo].data_pagamento_confirmado,
      });
      this.pagamento_adicional = true;
      this.data_pagamento_confirmado = this.pagamentos[codigo].data_pagamento_confirmado;
    } else {
      this.formPagar.patchValue({
        valor: this.pagamentos[codigo].plano.valor,
        valor_recebimento: this.pagamentos[codigo].plano.valor + this.pagamentos[codigo].acrescimo - this.pagamentos[codigo].desconto,
        valor_desconto: this.pagamentos[codigo].desconto,
        valor_acrescimo: this.pagamentos[codigo].acrescimo,
        codigo_forma_pagamento: this.pagamentos[codigo].codigo_forma_pagamento,
        data_pagamento_confirmado: this.pagamentos[codigo].data_pagamento_confirmado,
      });
      this.data_pagamento_confirmado = this.pagamentos[codigo].data_pagamento_confirmado;
    }
  }

  obter(codigo_associado_pagamento): void {
    this.carregando_editar = true;
    this.associadoPagamentoService.find(codigo_associado_pagamento).subscribe((data: AssociadoPagamento) => {
      console.log(codigo_associado_pagamento);
      console.log(data);
      this.formPagar.patchValue({
        valor: data.plano.valor,
        valor_recebimento: data.plano.valor,
        valor_desconto: data.desconto,
        valor_acrescimo: data.acrescimo,
        data_pagamento_confirmado: data.data_pagamento_confirmado,
        codigo_forma_pagamento: data.codigo_forma_pagamento,
        observacao: data.observacao
      });
      this.data_pagamento_confirmado = data.data_pagamento_confirmado;
      this.carregando_editar = false;
    }, error => console.log(error));
  }

  obterParcela(codigo): void {
    this.carregando_dados = true;
    this.associadoPagamentoAdicionalParcelaService.find(codigo).subscribe((data: AssociadoPagamentoAdicionalParcela) => {
      this.formPagamentoAdicionalParcela.patchValue({
        valor: data.valor,
        data_referencia: moment(data.data_referencia).format('MM/YYYY')
      });
      this.carregando_dados = false;
    }, error => console.log(error));
  }

  incluirContaPagar(): void {
    this.carregando = true;
    let contaPagar = this.formPagar.value as AssociadoPagamento;
    contaPagar.codigo_associado_pagamento = this.codigo_associado_pagamento;
    contaPagar.codigo_associado = this.codigo_pessoa;
    contaPagar.baixa_manual = 'S';
    if(!contaPagar.data_pagamento_confirmado) {
      contaPagar.data_pagamento_confirmado = moment().format('DD/MM/YYYY');
    }
    //contaPagar.data_referencia = '01/' + this.mes_referencia + '/' + this.ano_referencia;
    if (this.formPagar.valid) {
      this.associadoPagamentoService.save(contaPagar, this.codigo_associado_pagamento).subscribe((data) => {
        this.listar();
        this.listarPagamentoAdicionalParcela();
        $('body').removeClass('modal-open');
        $('body').css('padding-right', '');
        $(".modal-backdrop").remove();
        $('#formPagar').modal('hide');
        this.formPagar.reset();
        this.notifierService.notify( 'success', 'Baixa manual realizada com sucesso.' );
        this.carregando = false;
      }, (error) => {
        this.notifierService.notify( 'error', 'Erro ao realizar a baixa manual.');
      });
    } else {
      this.erro_cadastro = 'Data de pagamento e forma de recebimento são de preenchimento obrigatório.'
      this.notifierService.notify( 'error', 'Preencha os campos obrigatórios. ');
      this.carregando = false;
      console.log(this.formPagar);
    }
  }

  incluirContaPagarParcela(): void {
    this.carregando = true;
    let contaPagar = this.formPagar.value as AssociadoPagamento;
    contaPagar.codigo_associado_pagamento = this.codigo_associado_pagamento;
    contaPagar.codigo_associado = this.codigo_pessoa;
    contaPagar.baixa_manual = 'S';
    if(!contaPagar.data_pagamento_confirmado) {
      contaPagar.data_pagamento_confirmado = moment().format('DD/MM/YYYY');
    }
    //contaPagar.data_referencia = '01/' + this.mes_referencia + '/' + this.ano_referencia;
    if (this.formPagar.valid) {
      this.associadoPagamentoAdicionalParcelaService.save(contaPagar, this.codigo_associado_pagamento).subscribe((data) => {
        this.listar();
        this.listarPagamentoAdicionalParcela();
        $('body').removeClass('modal-open');
        $('body').css('padding-right', '');
        $(".modal-backdrop").remove();
        $('#formPagar').modal('hide');
        this.formPagar.reset();
        this.notifierService.notify( 'success', 'Baixa manual do pagamento adicional realizada com sucesso.' );
        this.carregando = false;
      }, (error) => {
        this.notifierService.notify( 'error', 'Erro ao realizar a baixa manual.');
      });
    } else {
      this.erro_cadastro = 'Data de pagamento e forma de recebimento são de preenchimento obrigatório.'
      this.notifierService.notify( 'error', 'Preencha os campos obrigatórios. ');
      this.carregando = false;
      console.log(this.formPagar);
    }
  }

  incluirPagamentoAdicional(): void {
    this.submitted = true;
    this.carregando = true;
    let pagamentoAdicional = this.formPagamentoAdicional.value as AssociadoPagamentoAdicional;
    pagamentoAdicional.codigo_associado = this.codigo_pessoa;
    pagamentoAdicional.data_referencia = pagamentoAdicional.data_referencia;
    pagamentoAdicional.devolucao = 'N';
    console.log(pagamentoAdicional);
    
    if(pagamentoAdicional.quantidade_parcelas < 1) {
      this.carregando = false;
      this.notifierService.notify( 'error', 'O valor da parcela deve ser maior ou igual a 1.');
    } else {
      if (this.formPagamentoAdicional.valid) {
        this.associadoPagamentoAdicionalService.save(pagamentoAdicional, this.codigo_associado_pagamento_adicional).subscribe((data) => {
          this.listar();
          this.listarPagamentoAdicionalParcela();
          this.carregando = false;
          $('body').removeClass('modal-open');
          $('body').css('padding-right', '');
          $(".modal-backdrop").remove();
          $('#formPagamentoAdicional').modal('hide');
          this.formPagamentoAdicional.reset();
          this.notifierService.notify( 'success', 'Lançamento adicional realizado com sucesso.' );
        }, (error) => {
          this.carregando = false;
          this.notifierService.notify( 'error', 'Erro ao cadastrar parcela. ' + error);
        });
      } else {
        this.erro_cadastro_pagamento_adicional = 'Preencha os campos obrigatórios.'
        this.carregando = false;
        this.notifierService.notify( 'error', 'Preencha os campos obrigatórios. ');
      }
    }
  }

  incluirDevolucaoAdicional(): void {
    this.submitted = true;
    this.carregando = true;
    let pagamentoDevolucao = this.formDevolucao.value as AssociadoPagamentoAdicional;
    pagamentoDevolucao.codigo_associado = this.codigo_pessoa;
    pagamentoDevolucao.data_referencia = pagamentoDevolucao.data_referencia;
    pagamentoDevolucao.devolucao = 'S';
    console.log(pagamentoDevolucao);
    
    if(pagamentoDevolucao.quantidade_parcelas < 1) {
      this.carregando = false;
      this.notifierService.notify( 'error', 'O valor da parcela deve ser maior ou igual a 1.');
    } else {
      if (this.formDevolucao.valid) {
        this.associadoPagamentoAdicionalService.save(pagamentoDevolucao, this.codigo_associado_pagamento_adicional).subscribe((data) => {
          this.listar();
          this.listarPagamentoAdicionalParcela();
          this.carregando = false;
          $('body').removeClass('modal-open');
          $('body').css('padding-right', '');
          $(".modal-backdrop").remove();
          $('#formDevolucao').modal('hide');
          this.formDevolucao.reset();
          this.notifierService.notify( 'success', 'Lançamento adicional realizado com sucesso.' );
        }, (error) => {
          this.carregando = false;
          this.notifierService.notify( 'error', 'Erro ao cadastrar parcela. ' + error);
        });
      } else {
        this.carregando = false;
        this.notifierService.notify( 'error', 'Preencha os campos obrigatórios. ');
      }
    }
  }

  incluirPagamentoAdicionalParcela(): void {
    this.carregando = true;
    this.submitted = true;
    let pagamentoAdicionalParcela = this.formPagamentoAdicionalParcela.value as AssociadoPagamentoAdicionalParcela;
    pagamentoAdicionalParcela.codigo_associado = this.codigo_pessoa;
    pagamentoAdicionalParcela.codigo_associado_pagamento_adicional_parcela = this.codigo_associado_pagamento_adicional_parcela;
    pagamentoAdicionalParcela.data_referencia = pagamentoAdicionalParcela.data_referencia;
    if (this.formPagamentoAdicionalParcela.valid) {
      this.associadoPagamentoAdicionalParcelaService.save(pagamentoAdicionalParcela, this.codigo_associado_pagamento_adicional_parcela).subscribe((data) => {
        this.listar();
        this.listarPagamentoAdicionalParcela();
        this.carregando = false;
        $('body').removeClass('modal-open');
        $('body').css('padding-right', '');
        $(".modal-backdrop").remove();
        $('#formPagamentoAdicionalParcela').modal('hide');
        this.formPagamentoAdicionalParcela.reset();
        this.notifierService.notify('success', 'Parcela alterada com sucesso.');
      }, (error) => {
        this.notifierService.notify('error', error);
      });
    } else {
      this.notifierService.notify('danger', 'Preencha os campos obrigatórios.');
    }
  }

  apagarPagamento(codigo):void {
    this.associadoPagamentoService.delete(codigo).subscribe((data) => {
      this.listar();
      this.notifierService.notify('success', 'Registro DELETADO com sucesso.');
    }, (error) => {
      this.notifierService.notify('danger', 'Não foi possível DELETAR o registro, ocorreu um erro. ' + error );
    });
  }

  apagarPagamentoParcela(codigo):void {
    this.associadoPagamentoAdicionalParcelaService.delete(codigo).subscribe((data) => {
      this.listar();
      this.notifierService.notify('success', 'Registro DELETADO com sucesso.');
    }, (error) => {
      this.notifierService.notify('danger', 'Não foi possível DELETAR o registro, ocorreu um erro. ' + error );
    });
  }

  fecharModal() {
    $('body').removeClass('modal-open');
    $('body').css('padding-right', '');
    $(".modal-backdrop").remove();
    $('#formPagar').modal('hide');
  }

}
