import { Component, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { NotifierService } from 'angular-notifier';
import { DemandaService } from '../../../core/services/demanda.service';
import { Demanda } from '../../../shared/models/demanda';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DemandaTipo } from 'src/app/shared/models/demanda-tipo';
import { DemandaTipoService } from 'src/app/core/services/demanda-tipo.service';

@Component({
  selector: 'app-demandas',
  templateUrl: './demandas.component.html',
  styleUrls: ['./demandas.component.css']
})
export class DemandasComponent implements OnInit {

  @ViewChild('demandaForm') formValues;

  form: FormGroup;

  demandas: Demanda;
  codigo_pessoa;
  breadCrumb;
  demandas_inativas: Demanda;
  codigo_demanda: number;
  codigo_demanda_pai: number;
  filtro_data: string;
  demandas_tipo: DemandaTipo;
  carregando: boolean;

  constructor(private appComponent: AppComponent,
              private formBuilder: FormBuilder,
              private demandaService: DemandaService,
              private demandaTipoService: DemandaTipoService,
              private routerParam: ActivatedRoute,
              private router: Router,
              private notifierService: NotifierService) { }

  ngOnInit() {
    this.breadCrumb = [
      {nome: 'Demandas', caminho: '/demanda-listar'}
    ];
    this.appComponent.setTitulo('Demandas Cadastradas');
    this.appComponent.setBreadcrumb(this.breadCrumb);

    this.form = this.formBuilder.group({
      codigo_demanda_tipo: [null, Validators.required],
      demandante: [null],
      created_at: [null],
    });

    this.listar(false);
    this.obterDemandaTipo();

  }

  obterDemandaTipo() {
    this.demandaTipoService.list(false)
    .subscribe((data: DemandaTipo) => this.demandas_tipo = data,
                error => console.log(error));
  }

  definirCodigo(codigo_demanda) {
    this.codigo_demanda = codigo_demanda;
  }

  definirCodigoPai(codigo_demanda_pai) {
    this.codigo_demanda_pai = codigo_demanda_pai;
    console.log(this.codigo_demanda_pai);
    
  }

  listar(filtro): void {

    let data_pesquisa: string;

    this.demandas = null;
    this.carregando = true;

    const demanda = this.form.value as Demanda;
    if(!filtro) {
      demanda.codigo_demanda_tipo = null;
      demanda.created_at = null;
      demanda.demandante = null;
      data_pesquisa = null;
      this.form.controls.codigo_demanda_tipo.setValue(null);
      this.form.controls.created_at.setValue(null);
      this.form.controls.demandante.setValue(null);
    }

    if(!this.filtro_data) {
      data_pesquisa = null;
    } else {
      data_pesquisa = this.filtro_data;
    }

    // demandas ativas
    this.demandaService.list(false, demanda.demandante, demanda.codigo_demanda_tipo, demanda.created_at).subscribe((data: Demanda) => {
      this.demandas = data;
    }, error => console.log(error));

    // demandas inativas
    this.demandaService.list(true, demanda.demandante, demanda.codigo_demanda_tipo, demanda.created_at).subscribe((data: Demanda) => {
      this.demandas_inativas = data;
    }, error => console.log(error));
  }

  reabrir(): void {
    const demanda = {'codigo_colaborador': parseInt(localStorage.getItem('codigo_pessoa')), 'codigo_demanda_pai': this.codigo_demanda};
    this.demandaService.restore(this.codigo_demanda, demanda).subscribe((data) => {
      this.router.navigate(['demanda-exibir/' + this.codigo_demanda]);
      this.notifierService.notify( 'success', 'Demanda reaberta com sucesso.' );
    }, (error) => {
      this.notifierService.notify( 'error', 'Não foi reabrir a demanda. ' + error );
    });
  }


}
