import { Injectable } from "@angular/core";
import { Observable } from 'rxjs/Observable';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SolicitacaoAssociadoService {

  constructor(private http: HttpClient) {}

  private _UrlAssociado = environment.UrlPrincipal + '/api/solicitacao-associado';

  list() {
    return this.http.get(this._UrlAssociado)
                    .catch(this.handleError);
  }

  find(id) {
    return this.http.get(this._UrlAssociado + "/" + id)
                    .catch(this.handleError);
  }

  save(dados, codigo_pessoa): Observable<any>{   
    let http;
    if(codigo_pessoa || dados.codigo_pessoa){
        http = this.http.put(this._UrlAssociado + "/" + dados.codigo_pessoa, dados);
    } else {
        http = this.http.post(this._UrlAssociado, dados);
    }
    http.map((res) => {
        return res;
    }).catch(this.handleError);
    return http;
  }

  delete(codigo_pessoa) {
    return this.http.delete(this._UrlAssociado + "/" + codigo_pessoa);
  }

  private handleError(error: Response) {
    return error.json();
  }

}