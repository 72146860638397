import { Demanda } from './../../../shared/models/demanda';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppComponent } from '../../../app.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DemandaService } from '../../../core/services/demanda.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { NotifierService } from 'angular-notifier';
import { DemandaTipo } from '../../../shared/models/demanda-tipo';
import { DemandaTipoService } from '../../../core/services/demanda-tipo.service';
import { Associado } from 'src/app/shared/models/associado';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PessoaFisicaService } from 'src/app/core/services/pessoa-fisica.service';

import {Observable, Subject, merge, OperatorFunction} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map} from 'rxjs/operators';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

@Component({
  selector: 'app-demanda-nova',
  templateUrl: './demanda-nova.component.html',
  styleUrls: ['./demanda-nova.component.css']
})
export class DemandaNovaComponent implements OnInit {

  matchingItems: Array<Associado>;

  @ViewChild('demandaForm') formValues;

  form: FormGroup;
  breadCrumb;
  demanda: Demanda;
  demandaTipo: DemandaTipo;
  carregando: boolean;
  submited: boolean;
  associados;
  codigo_demandante: any;
  dados: any;

  states: string[] = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Dakota',
    'North Carolina',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming'
  ];


  keyword = 'nome';

  constructor(private appComponent: AppComponent,
              private formBuilder: FormBuilder,
              private routerParam: ActivatedRoute,
              private router: Router,
              private _sanitizer: DomSanitizer,
              private authenticationService: AuthenticationService,
              private demandaService: DemandaService,
              private pessoaFisicaService: PessoaFisicaService,
              private demandaTipoService: DemandaTipoService,
              private notifierService: NotifierService) {

  }

  ngOnInit() {

    this.breadCrumb = [
      {nome: 'Home', caminho: '/dashboard'},
      {nome: 'Suporte', caminho: '/suporte'},
    ];
    this.appComponent.setTitulo('Abrir Nova Demanda');
    this.appComponent.setBreadcrumb(this.breadCrumb);

    this.form = this.formBuilder.group({
      assunto: [null, Validators.required],
      descricao: [null, Validators.required],
      codigo_demandante: [null],
      codigo_demanda_tipo: [null, Validators.required],
    });

    this.obterDemandaTipo();
    this.recuperarDemandante();

  }

  recuperarDemandante() {
    this.carregando = true;
    this.pessoaFisicaService.list(1)
    .subscribe((data) => { 
      this.associados = data;
      console.log(this.associados);
      
      this.carregando = false;
    }, error => console.log(error));
  }

  definirCodigoDemandante(data: TypeaheadMatch) {
    this.codigo_demandante = data.item.codigo_pessoa;
    console.log(this.codigo_demandante);
    
  }

  obterDemandaTipo() {
    this.demandaTipoService.list(null)
    .subscribe((data: DemandaTipo) => this.demandaTipo = data,
                error => console.log(error));
  }

  onSubmit(): void {
    this.submited = true;
    this.carregando = true;
    const demanda = this.form.value as Demanda;
    if(!this.codigo_demandante) {
      this.notifierService.notify( 'error', 'Selecione um associado. ');
      this.carregando = false;
    } else {
      if (this.form.valid) {
        demanda.codigo_demandante = this.codigo_demandante;
        demanda.codigo_colaborador = parseInt(localStorage.getItem('codigo_pessoa'));
        this.demandaService.save(demanda, null).subscribe((data) => {
          const codigo_demanda = data.codigo_demanda;
          this.notifierService.notify( 'success', 'Demanda cadastrada com sucesso.' );
          this.carregando = false;
          this.router.navigate(['demanda-listar']);
        }, (error) => {
          this.notifierService.notify( 'error', 'Não foi cadastrar uma nova demanda. ' + error );
          this.carregando = false;
        });
      } else {
        console.log(this.form);
        this.notifierService.notify( 'error', 'Existem erros no preenchimento do formulário.');
        this.carregando = false;
      }
    }
  }

}
