import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from 'src/app/shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { AuthGuard } from 'src/app/core/authentication/auth.guard';


import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { BsDropdownModule} from 'ngx-bootstrap/dropdown';

import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { DemandasComponent } from './demandas/demandas.component';
import { DemandaNovaComponent } from './demanda-nova/demanda-nova.component';
import { DemandaExibirComponent } from './demanda-exibir/demanda-exibir.component';

// Import Angular plugin.
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

const appRoutes: Routes = [
  { path: 'demanda-listar', component: DemandasComponent, canActivate: [AuthGuard] },
  { path: 'demanda-novo', component: DemandaNovaComponent, canActivate: [AuthGuard] },
  { path: 'demanda-exibir/:id', component: DemandaExibirComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [
    BrowserModule,
    SharedModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    CKEditorModule,
    NgxMaskModule.forRoot(),
    TypeaheadModule.forRoot(),
    RouterModule.forRoot(appRoutes)
  ],
  declarations: [DemandasComponent, DemandaNovaComponent, DemandaExibirComponent]
})
export class SuporteModule { }
