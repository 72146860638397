import { Injectable } from "@angular/core";
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AssociadoPagamentoService {

  constructor(private http: HttpClient) {}

  private _Url = environment.UrlPrincipal + '/api/associadopagamento';

  list(codigo_associado, mes_inicial = null, mes_final = null, ano_inicial = null, ano_final = null, tipo = null) {
    if(codigo_associado) {
      return this.http.get(this._Url + '?codigo_associado=' + codigo_associado + '&mes_inicial=' + mes_inicial + '&mes_final=' + mes_final + '&ano_inicial=' + ano_inicial + '&ano_final=' + ano_final + '&tipo=' + tipo)
      .catch(this.handleError);
    } else {
      return this.http.get(this._Url)
                      .catch(this.handleError);
    }
  }

  find(id) {
    return this.http.get(this._Url + "/" + id)
                    .catch(this.handleError);
  }

  save(dados, codigo): Observable<any>{
    let http;
    if(codigo || dados.codigo_associado_pagamento){
        http = this.http.put(this._Url + "/" + codigo, dados);
    } else {
        http = this.http.post(this._Url, dados);
    }
    http.map((res) => {
        return res;
    }).catch(this.handleError);
    return http;
  }

  delete(codigo) {
    return this.http.delete(this._Url + "/" + codigo);
  }

  private handleError(error: Response) {
    return error.json();
  }

}
