import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from 'src/app/shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';

import { PerfilComponent } from './perfil.component';
import { AuthGuard } from 'src/app/core/authentication/auth.guard';

const appRoutes: Routes = [
  {path: 'perfil', component: PerfilComponent, canActivate: [AuthGuard]},
];

@NgModule({
  imports: [
    BrowserModule,
    SharedModule,
    FormsModule, 
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    RouterModule.forRoot(appRoutes)
  ],
  declarations: [PerfilComponent]
})
export class PerfilModule { }
