<app-header></app-header>

<div class="row">
  <div class="col-sm-12">
    <div class="panel panel-default panel-table">
    <div class="panel-heading">
        <form novalidate="" class="form-horizontal group-border-dashed">
            <div class="row">
                <div class="col-md-2">
                    <label><b>Nº Patrimônio</b></label>
                    <input id="inputNome" type="text" placeholder="Nº Patrimônio" class="form-control input-xs" (blur)="definirFiltroNumeroPatrimonio($event.target.value)">
                </div>
                <div class="col-md-2">
                    <label><b>Localização</b></label>
                    <select class="form-control input-xs" (change)="definirFiltroLocalizacao($event.target.value)">
                        <option value="">Selecione</option>
                        <option *ngFor="let dado of localizacoes;" [value]="dado.codigo_localizacao">
                        {{dado.nome}}
                        </option>
                    </select>
                </div>
                <div class="col-md-2">
                    <label><b>Categoria</b></label>
                    <select class="select2 form-control input-xs" (change)="definirFiltroCategoria($event.target.value)">
                        <option value="">Selecione</option>
                        <option *ngFor="let dado of categorias" value="{{dado.codigo_categoria}}">
                            {{dado.nome}}
                        </option>
                    </select>
                </div>
                <div class="col-md-3" style="margin-top: 30px">
                    <button class="btn btn-space btn-default" (click)="listar()">Filtrar</button>
                </div>
            </div>
        </form>

        <br />

        <div class="row">
            <div class="col-md-12">
                <button routerLink="/patrimonio-novo" class="btn btn-space btn-success" tabindex="0" aria-controls="table3">Cadastrar Novo</button>
            </div>
        </div>
    </div>
    <div class="panel-body">

        <div class="row">
            <div class="col-md-12">
                <div class="tab-container">
                    <ul class="nav nav-tabs">
                        <li class="active"><a href="#aberta" data-toggle="tab">Ativos</a></li>
                        <li><a href="#encerrada" data-toggle="tab">Baixados</a></li>
                    </ul>
                    <div class="tab-content">
                        <div id="aberta" class="tab-pane active cont">
                            <div class="dataTables_length" id="table3_length">
                                <table id="table2" class="table table-striped table-hover table-fw-widget">
                                    <thead>
                                        <tr>
                                            <th>Patrimônio</th>
                                            <th>Categoria</th>
                                            <th>Descrição</th>
                                            <th>Localização</th>
                                            <th>Data da Compra</th>
                                            <th class="text-center">Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="materiais && materiais.length > 0">
                                        <tr class="odd gradeX" *ngFor="let material of materiais">
                                            <td class="col-md-1">{{material.numero_patrimonio}}</td>
                                            <td class="col-md-2">{{material.categoria.nome}}</td>
                                            <td class="col-md-4">{{material.descricao}}</td>
                                            <td class="col-md-2">{{material.localizacao}}</td>
                                            <td class="col-md-2">{{material.data_compra | date: 'dd/MM/yyyy'}}</td>
                                            <td class="text-center" style="font-size: 22px;">
                                                <a href="patrimonio-exibir/{{material.codigo_material}}" (click)="definirCodigo(material.codigo_material)"><span class="badge badge-primary">Ver</span></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="!materiais">
                                        <tr class="carregando">
                                            <td class="carregando" colspan="7">
                                                <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="materiais && materiais.length == 0">
                                        <tr>
                                            <td class="col-md-12" colspan="7">
                                                Não existem patrimônios ativos.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div id="encerrada" class="tab-pane cont">
                            <div class="dataTables_length" id="table3_length">
                                <table id="table2" class="table table-striped table-hover table-fw-widget">
                                    <thead>
                                        <tr>
                                        <th>Patrimônio</th>
                                        <th>Categoria</th>
                                        <th>Descrição</th>
                                        <th>Localização</th>
                                        <th>Datas</th>
                                        <th class="text-center">Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="materiais_inativos && materiais_inativos.length > 0">
                                        <tr class="odd gradeX" *ngFor="let material of materiais_inativos">
                                        <td class="col-md-1">{{material.numero_patrimonio}}</td>
                                        <td class="col-md-2">{{material.categoria.nome}}</td>
                                        <td class="col-md-4">{{material.descricao}}</td>
                                        <td class="col-md-2">{{material.localizacao}}</td>
                                        <td class="col-md-2"><b>Compra:</b> {{material.data_compra | date: 'dd/MM/yyyy'}}<br /><b>Baixa:</b> {{material.data_baixa | date: 'dd/MM/yyyy'}}</td>
                                        <td class="text-center" style="font-size: 22px;">
                                            <a href="" data-toggle="modal" data-target="#md-footer-restaurar" (click)="definirCodigo(material.codigo_material);obterLocalizacao()"><span class="badge badge-warning">Reativar</span></a>
                                        </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="!materiais_inativos">
                                        <tr class="carregando">
                                            <td class="carregando" colspan="7">
                                                <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="materiais_inativos && materiais_inativos.length == 0">
                                        <tr>
                                            <td class="col-md-12" colspan="7">
                                                Não existem patrimônios inativos.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
  </div>
</div> 

<div id="md-footer-restaurar" tabindex="-1" role="dialog" style="" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
            </div>
            <form novalidate="" class="form-horizontal group-border-dashed">
              <div class="modal-body">
                <div class="form-group xs-mt-10">
                  <div class="col-sm-12">
                      <label>Confirme a localização atual do material <span class="obrigatorio">*</span> </label>
                      <select class="form-control" (change)="definirFiltroLocalizacao($event.target.value)">
                        <option>Selecione</option>
                        <option *ngFor="let dado of localizacoes;" [value]="dado.codigo_localizacao">
                          {{dado.nome}}
                        </option>
                      </select>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                  <button type="button" data-dismiss="modal" class="btn btn-default">Cancelar</button>
                  <button type="button" data-dismiss="modal" class="btn btn-warning" (click)="restaurar()">Confirmar</button>
              </div>
            </form>
        </div>
    </div>
</div>

<app-footer></app-footer>