<app-header></app-header>
<div class="panel panel-default panel-border-color panel-border-color-primary">
  <div class="panel-heading panel-heading-divider">Sincronização Manual</div>
  <div class="panel-body">
    <p>Esta opção sincroniza as informações entre os servidor interno e externo manualmente. </p>
    <div *ngIf="resultado">
        <table id="table2" class="table table-striped table-hover table-fw-widget">
          <thead>
              <tr>
                  <th>Item de Sincronização</th>
                  <th>Resultado</th>
              </tr>
          </thead>
          <tbody>
              <tr class="odd gradeX">
                  <td class="col-md-4">Cadastro de novos associado</td>
                  <td class="col-md-8">
                      <span *ngIf="resultado.cadastro_novo_situacao == 'S'"><span class="label label-success">OK</span>&nbsp;</span>
                      <span *ngIf="resultado.cadastro_novo_situacao == 'N'"><span class="label label-danger">ERRO</span>&nbsp;</span>
                      <span *ngIf="resultado.cadastro_novo_mensagem">&nbsp;{{resultado.cadastro_novo_mensagem}}</span>
                  </td>
              </tr>
              <tr class="odd gradeX">
                <td class="col-md-4">Demandas abertas</td>
                <td class="col-md-8">
                    <span *ngIf="resultado.solicitacao_suporte_situacao == 'S'"><span class="label label-success">OK</span>&nbsp;</span>
                    <span *ngIf="resultado.solicitacao_suporte_situacao == 'N'"><span class="label label-danger">ERRO</span>&nbsp;</span>
                    <span *ngIf="resultado.solicitacao_suporte_mensagem">&nbsp;{{resultado.solicitacao_suporte_mensagem}}</span>
                </td>
              </tr>
              <tr class="odd gradeX">
                <td class="col-md-4">Solicitação de alteração de dados</td>
                <td class="col-md-8">
                    <span *ngIf="resultado.solicitacao_alteracao_situacao == 'S'"><span class="label label-success">OK</span>&nbsp;</span>
                    <span *ngIf="resultado.solicitacao_alteracao_situacao == 'N'"><span class="label label-danger">ERRO</span>&nbsp;</span>
                    <span *ngIf="resultado.solicitacao_alteracao_mensagem">&nbsp;{{resultado.solicitacao_alteracao_mensagem}}</span>
                </td>
              </tr>
              <tr class="odd gradeX">
                <td class="col-md-4">Solicitação de repasse</td>
                <td class="col-md-8">
                    <span *ngIf="resultado.solicitacao_repasse_situacao == 'S'"><span class="label label-success">OK</span>&nbsp;</span>
                    <span *ngIf="resultado.solicitacao_repasse_situacao == 'N'"><span class="label label-danger">ERRO</span>&nbsp;</span>
                    <span *ngIf="resultado.solicitacao_repasse_mensagem">&nbsp;{{resultado.solicitacao_repasse_mensagem}}</span>
                </td>
              </tr>
              <tr class="odd gradeX">
                <td class="col-md-4">Solicitação de recuperação de dados</td>
                <td class="col-md-8">
                    <span *ngIf="resultado.recuperacao_dados_situacao == 'S'"><span class="label label-success">OK</span>&nbsp;</span>
                    <span *ngIf="resultado.recuperacao_dados_situacao == 'N'"><span class="label label-danger">ERRO</span>&nbsp;</span>
                    <span *ngIf="resultado.recuperacao_dados_mensagem">&nbsp;{{resultado.recuperacao_dados_mensagem}}</span>
                </td>
              </tr>
              <tr class="odd gradeX">
                <td class="col-md-4">Envio de informações para recuperação de dados</td>
                <td class="col-md-8">
                    <span *ngIf="resultado.envio_email_recuperacao_dados_situacao == 'S'"><span class="label label-success">OK</span>&nbsp;</span>
                    <span *ngIf="resultado.envio_email_recuperacao_dados_situacao == 'N'"><span class="label label-danger">ERRO</span>&nbsp;</span>
                    <span *ngIf="resultado.envio_email_recuperacao_dados_mensagem">&nbsp;{{resultado.envio_email_recuperacao_dados_mensagem}}</span>
                </td>
              </tr>
              <tr class="odd gradeX">
                <td class="col-md-4">Atualização interno para o externo (Associados)</td>
                <td class="col-md-8">
                    <span *ngIf="resultado.apagar_associado_situacao == 'S'"><span class="label label-success">OK</span>&nbsp;</span>
                    <span *ngIf="resultado.apagar_associado_situacao == 'N'"><span class="label label-danger">ERRO</span>&nbsp;</span>
                    <span *ngIf="resultado.apagar_associado_mensagem">&nbsp;{{resultado.apagar_associado_mensagem}}</span>
                </td>
              </tr>
              <tr class="odd gradeX">
                <td class="col-md-4">Atualização interno para o externo (Demandas)</td>
                <td class="col-md-8">
                    <span *ngIf="resultado.apagar_suporte_situacao == 'S'"><span class="label label-success">OK</span>&nbsp;</span>
                    <span *ngIf="resultado.apagar_suporte_situacao == 'N'"><span class="label label-danger">ERRO</span>&nbsp;</span>
                    <span *ngIf="resultado.apagar_suporte_mensagem">&nbsp;{{resultado.apagar_suporte_mensagem}}</span>
                </td>
              </tr>
              <tr class="odd gradeX">
                <td class="col-md-4">Atualização interno para o externo (Repasse)</td>
                <td class="col-md-8">
                    <span *ngIf="resultado.apagar_externo_repasse_solicitacao_situacao == 'S'"><span class="label label-success">OK</span>&nbsp;</span>
                    <span *ngIf="resultado.apagar_externo_repasse_solicitacao_situacao == 'N'"><span class="label label-danger">ERRO</span>&nbsp;</span>
                    <span *ngIf="resultado.apagar_externo_repasse_solicitacao_mensagem">&nbsp;{{resultado.apagar_externo_repasse_solicitacao_mensagem}}</span>
                </td>
              </tr>
          </tbody>
      </table>
    </div>
    <div *ngIf="resultado && resultado.length > 0">Erro, não foi possível recuperar os dados de sincronização.</div>
    <button type="button" data-toggle="modal" data-target="#md-sincronizar-success" *ngIf="!carregando" class="btn btn-space btn-primary">Sincronizar Banco de Dados</button>
    <div *ngIf="carregando" style="text-align: center;"><img src="/assets/img/carregando_tabela.gif"></div>
  </div>
</div>
<a href="configuracao" class="btn btn-space btn-default">Voltar</a>

<!--Modal Footer-->
<div id="md-sincronizar-success" tabindex="-1" role="dialog" style="" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                  <div class="text-warning"><span class="modal-main-icon mdi mdi-alert-triangle"></span></div>
                  <h3>Tem certeza que deseja SINCRONIZAR MANUALMENTE os bancos de dados?</h3>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default">Cancelar</button>
                <button type="button" data-dismiss="modal" class="btn btn-warning" (click)="sincronizar()">Confirmar</button>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>