import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-dashboard-exibir',
  templateUrl: './dashboard-exibir.component.html',
  styleUrls: ['./dashboard-exibir.component.css']
})
export class DashboardExibirComponent implements OnInit {

  breadCrumb;

  constructor(private appComponent: AppComponent) { 

  }

  ngOnInit() {
    //this.appComponent.setTitulo(false);
    this.appComponent.setBreadcrumb(false);
  }

}
