import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import { environment } from '../../../environments/environment';

import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class PendenciaTipoService {
    private _Url = environment.UrlPrincipal + '/api/pendenciatipo';
    private _urlRestaurar = environment.UrlApi + '/pendencia-tipo-restaurar';

    constructor(private http: HttpClient) {}

    // list() {
    //     return this.http.get(this._Url).catch(this.handleError);
    // }

    list(inativo) {
        if (inativo == false) {
            return this.http
                .get(this._Url + '?inativos=false')
                .catch(this.handleError);
        } else {
            return this.http
                .get(this._Url + '?inativos=true')
                .catch(this.handleError);
        }
    }

    find(codigo) {
        return this.http.get(this._Url + '/' + codigo).catch(this.handleError);
    }

    save(dados, codigo): Observable<any> {
        let http;
        if (codigo) {
            http = this.http.put(this._Url + '/' + codigo, dados);
        } else {
            http = this.http.post(this._Url, dados);
        }
        http.map((res) => {
            return res;
        }).catch(this.handleError);
        return http;
    }

    delete(codigo) {
        return this.http.delete(this._Url + '/' + codigo);
    }

    private handleError(error: Response) {
        return error.json();
    }

    restore(codigo_pendencia_tipo) {
        return this.http.put(this._urlRestaurar + '/' + codigo_pendencia_tipo, null);
    }
}
