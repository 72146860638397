import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { AlterarSenhaComponent } from './alterar-senha.component';
import { AuthService } from 'src/app/core/authentication/auth.service';

const appRoutes: Routes = [
  { path: 'alterar-senha', component: AlterarSenhaComponent, canActivate: [AuthService] }
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule, 
    ReactiveFormsModule,
    RouterModule.forRoot(appRoutes)
  ],
  declarations: [AlterarSenhaComponent]
})
export class AlterarSenhaModule { }
