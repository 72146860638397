import { Injectable } from "@angular/core";
import { Observable } from 'rxjs/Observable';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ColaboradorService {

  codigo_pessoa: string;

  private _Url = environment.UrlPrincipal + '/api/colaborador';

  constructor(private http: HttpClient) { }

  list(codigo_pessoa, codigo_colaborador_atribuido = null) {
    return this.http.get(this._Url + "?codigo_pessoa=" + codigo_pessoa + '&codigo_pessoa_atribuido=' + codigo_colaborador_atribuido)
                    .catch(this.handleError);
  }

  find(id) {
    return this.http.get(this._Url + "/" + id)
                    .catch(this.handleError);
  }

  save(dados, codigo_pessoa): Observable<any>{
    let http;
    if(codigo_pessoa){
        http = this.http.put(this._Url + "/" + codigo_pessoa, dados);
    } else {
        http = this.http.post(this._Url, dados);
    }
    http.map((res) => {
        return res;
    }).catch(this.handleError);
    return http;
  }

  delete(codigo_pessoa) {
    return this.http.delete(this._Url + "/" + codigo_pessoa);
  }

  private handleError(error: Response) {
    return error.json();
  }

}
