<app-header></app-header>

<div class="row wizard-row">
  <div class="col-md-12 fuelux">
    <div class="block-wizard panel panel-default">
      <div id="wizard1" class="wizard wizard-ux">
        <ul class="steps">
          <li data-step="1"><a href="dados-pessoais/{{codigo_pessoa}}">Dados Pessoais</a><span class="chevron"></span></li>
          <li data-step="2"><a href="dados-financeiros/{{codigo_pessoa}}">Dados Financeiros</a><span class="chevron"></span></li>
          <li data-step="3"><a href="dados-administrativos/{{codigo_pessoa}}">Dados Administrativos</a><span class="chevron"></span></li>
          <li data-step="4" class="active"><a href="dados-controle/{{codigo_pessoa}}">Dados de Controle</a><span class="chevron"></span></li>
          <li data-step="5"><a href="dados-complementares/{{codigo_pessoa}}">Dados Complementares</a><span class="chevron"></span></li>
        </ul>
        <div class="step-content">
          <div *ngIf="!carregando" style="text-align: center;"><img src="/assets/img/carregando_tabela.gif"></div>
          <div data-step="1" class="step-pane active" *ngIf="carregando">
            <form #associadoForm="ngForm" [formGroup]="form" data-parsley-namespace="data-parsley-" data-parsley-validate="" novalidate="" class="form-horizontal group-border-dashed">
              <div class="form-group">
                <label class="col-sm-3 control-label">Associado APCF</label>
                <div class="col-sm-6">
                  <span class="label label-primary" *ngIf="associado_apcf == '1'">Afiliado</span><span class="label label-default" *ngIf="associado_apcf == '0'">Desfiliado</span>
                  <br />
                  Esta opção deve ser alterada no <a href="associado-situacao/{{codigo_pessoa}}">controle de situações</a> do associado.
                </div>
              </div>
              <div class="form-group" *ngIf="tipo != 'PE'">
                <label class="col-sm-3 control-label">Associado APCF Sindical</label>
                <div class="col-sm-6">
                  <p style="margin-top: 9px;">
                    <span class="label label-primary" *ngIf="associado_apcf_sindical == '1'">Afiliado</span><span class="label label-default" *ngIf="associado_apcf_sindical == '0'">Desfiliado</span>
                    <br />
                    Esta opção deve ser alterada no <a href="associado-situacao/{{codigo_pessoa}}">controle de situações</a> do associado.
                  </p>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-3 control-label">Isento Mensalidade APCF</label>
                <div class="col-sm-6">
                  <div class="be-radio inline">
                    <input type="radio" id="rad5" formControlName="isento_mensalidade" value="S">
                    <label for="rad5">Sim</label>
                  </div>
                  <div class="be-radio inline">
                    <input type="radio" id="rad6" formControlName="isento_mensalidade" value="N">
                    <label for="rad6">Não</label>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-3 control-label">Isento Mensalidade APCF Sindical</label>
                <div class="col-sm-6">
                  <div class="be-radio inline">
                    <input type="radio" id="rad7" formControlName="isento_mensalidade_apcf_sindical" value="S">
                    <label for="rad7">Sim</label>
                  </div>
                  <div class="be-radio inline">
                    <input type="radio" id="rad8" formControlName="isento_mensalidade_apcf_sindical" value="N">
                    <label for="rad8">Não</label>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-3 control-label">Acesso a Sistemas APCF</label>
                <div class="col-sm-6">
                  <div class="be-radio inline">
                    <input type="radio" id="rad9" formControlName="acesso_sistemas" value="S">
                    <label for="rad9">Sim</label>
                  </div>
                  <div class="be-radio inline">
                    <input type="radio" id="rad10" formControlName="acesso_sistemas" value="N">
                    <label for="rad10">Não</label>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-3 control-label">Acesso a Sistemas APCF Sindical</label>
                <div class="col-sm-6">
                  <div class="be-radio inline">
                    <input type="radio" id="rad11" formControlName="acesso_sistemas_apcf_sindical" value="S">
                    <label for="rad11">Sim</label>
                  </div>
                  <div class="be-radio inline">
                    <input type="radio" id="rad12" formControlName="acesso_sistemas_apcf_sindical" value="N">
                    <label for="rad12">Não</label>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-3 control-label">Habilitado a Votar APCF</label>
                <div class="col-sm-6">
                  <div class="be-radio inline">
                    <input type="radio" id="rad13" formControlName="habilitado_votacao" value="S">
                    <label for="rad13">Sim</label>
                  </div>
                  <div class="be-radio inline">
                    <input type="radio" id="rad14" formControlName="habilitado_votacao" value="N">
                    <label for="rad14">Não</label>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-3 control-label">Habilitado a Votar APCF Sindical</label>
                <div class="col-sm-6">
                  <div class="be-radio inline">
                    <input type="radio" id="rad15" formControlName="habilitado_votacao_sindical" value="S">
                    <label for="rad15">Sim</label>
                  </div>
                  <div class="be-radio inline">
                    <input type="radio" id="rad16" formControlName="habilitado_votacao_sindical" value="N">
                    <label for="rad16">Não</label>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-3 control-label">Inibir envio de mensagens para o Celular</label>
                <div class="col-sm-6">
                  <div class="be-radio inline">
                    <input type="radio" id="rad17" formControlName="inibir_envio_sms" value="S">
                    <label for="rad17">Sim</label>
                  </div>
                  <div class="be-radio inline">
                    <input type="radio" id="rad18" formControlName="inibir_envio_sms" value="N">
                    <label for="rad18">Não</label>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-3 control-label">Inibir envio de mensagens para o E-mail</label>
                <div class="col-sm-6">
                  <div class="be-radio inline">
                    <input type="radio" id="rad19" formControlName="inibir_envio_email" value="S">
                    <label for="rad19">Sim</label>
                  </div>
                  <div class="be-radio inline">
                    <input type="radio" id="rad20" formControlName="inibir_envio_email" value="N">
                    <label for="rad20">Não</label>
                  </div>
                </div>
              </div>
              
              <div class="form-group">
                <div class="col-sm-offset-3 col-sm-9">
                  <a href="dados-administrativos/{{codigo_pessoa}}" class="btn btn-primary btn-space wizard-next">Anterior</a>&nbsp;&nbsp;&nbsp;&nbsp;
                  <a href="dados-complementares/{{codigo_pessoa}}" data-wizard="#wizard1" class="btn btn-primary btn-space wizard-next">Avançar</a>&nbsp;&nbsp;&nbsp;&nbsp;
                  <button data-wizard="#wizard1" class="btn btn-primary btn-space wizard-next" (click)="onSubmit()">Salvar</button>&nbsp;&nbsp;&nbsp;&nbsp;
                  <button data-wizard="#wizard1" class="btn btn-success btn-space wizard-next" (click)="onSubmit(true)">Salvar e Avançar</button>
                  &nbsp; <img *ngIf="carregando_salvar" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>