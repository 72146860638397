import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AppComponent } from 'src/app/app.component';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { ColaboradorService } from 'src/app/core/services/colaborador.service';
import { PessoaPerfilService } from 'src/app/core/services/pessoa-perfil.service';

import { Colaborador } from 'src/app/shared/models/colaborador';
import { PessoaPerfil } from 'src/app/shared/models/pessoa-perfil';
import { ValidarCpf } from 'src/app/shared/validation/cpf-validation';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css']
})
export class PerfilComponent implements OnInit {

  @ViewChild('colaboradorForm') formValues;

  pessoaPerfil: PessoaPerfil;
  colaborador: Colaborador;
  form: FormGroup;
  codigo_pessoa;
  breadCrumb;
  submitted;
  erro_cpf;
  me;

  constructor(private appComponent: AppComponent,
              private formBuilder: FormBuilder,
              private routerParam: ActivatedRoute,
              private notifierService: NotifierService,
              private router: Router,
              private authenticationService: AuthenticationService,
              private pessoaPerfilService: PessoaPerfilService,
              private colaboradorService: ColaboradorService) {

              this.codigo_pessoa = this.routerParam.snapshot.params.id;
              if(!this.codigo_pessoa) {
                this.codigo_pessoa = localStorage.getItem('codigo_pessoa');
              }

  }

  ngOnInit() {
    this.obter();
    this.breadCrumb = [ 
      {nome: "Perfil", caminho: "/perfil"},
    ];
    this.appComponent.setTitulo('Meu Perfil');
    this.appComponent.setBreadcrumb(this.breadCrumb);

    this.form = this.formBuilder.group({
      nome: [null, Validators.required],  
      email: [null, [Validators.required, Validators.email]],
      cpf: [null, Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  definirCodigo(codigo_pessoa) {
    this.codigo_pessoa = codigo_pessoa;
  }

  obter(): void {
      this.colaboradorService.find(this.codigo_pessoa).subscribe((data: Colaborador) => {
        this.form.patchValue({
          nome: data.pessoa.nome,
          email: data.pessoa.email,
          cpf: data.cpf
        });
    });
  }

  onSubmit(): void {
    this.submitted = true;
    let colaborador = this.form.value as Colaborador;
    if(!ValidarCpf.cpf(colaborador.cpf)) {
      this.erro_cpf = true;
    } else if (this.form.valid) {
      colaborador.codigo_pessoa = this.codigo_pessoa;
      this.colaboradorService.save(colaborador, this.codigo_pessoa).subscribe((data) => {
        this.notifierService.notify( 'success', 'Registro ATUALIZADO com sucesso.' );
      }, (error) => {      
        this.notifierService.notify( 'error', error);
      });
    }
  }

}
