import {Injectable} from "@angular/core";
import {Observable} from 'rxjs/Observable';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import {HttpClient} from "@angular/common/http";
import {environment} from '../../../environments/environment';
import {Layout} from "../../shared/models/layout";
import {map} from "rxjs/operators";
import {CampoLayout} from "../../shared/models/campo-layout";

@Injectable({
    providedIn: 'root'
})
export class LayoutService {
    
    private url: string = environment.UrlApi + "/layout";
    
    constructor(private http: HttpClient) {
    }
    
    list(tipo_layout: string = null): Observable<Layout[]> {
        let url: string = this.url + "/index";
        if (tipo_layout) {
            url += "/" + tipo_layout;
        }
        return this.http.get(url)
            .pipe(map((res: any) => {
                return res as Array<Layout>;
            }))
            .catch(this.handleError);
    }
    
    listByTipoEquivalente(tipo_layout: string): Observable<Layout[]> {
        let url: string = this.url + "/list-by-tipo-equivalente/" + tipo_layout;
        return this.http.get(url)
            .pipe(map((res: any) => {
                return res as Array<Layout>;
            }))
            .catch(this.handleError);
    }
    
    find(id) {
        return this.http.get(this.url + "show/" + id)
            .catch(this.handleError);
    }
    
    copy(layout: Layout) {
        return this.http.put(this.url + "/copy/" + layout.codigo_layout, layout)
            .catch(this.handleError);
    }
    
    update(layout: Layout, campos_layout: Array<CampoLayout>) {
        let body:any = {
            titulo: layout.titulo,
            campos: campos_layout,
        }
        if(layout.codigo_cabecalho_layout){
            body.codigo_cabecalho_layout = layout.codigo_cabecalho_layout;
        }
        if(layout.codigo_rodape_layout){
            body.codigo_rodape_layout = layout.codigo_rodape_layout;
        }
        return this.http.put(this.url + "/update/" + layout.codigo_layout, body).catch(this.handleError);
    }
    
    save(layout: Layout): Observable<any> {
        let http;
        if (layout.codigo_layout) {
            http = this.http.put(this.url + "/" + layout.codigo_layout, layout);
        } else {
            http = this.http.post(this.url, layout);
        }
        http.map((res) => {
            return res;
        }).catch(this.handleError);
        return http;
    }
    
    delete(codigo_layout): Observable<any> {
        return this.http.delete(this.url + "/" + codigo_layout);
    }
    
    generateFile(layout): Observable<any> {
        return this.http.get(this.url + "/file/" + layout.codigo_layout);
    }
    
    private handleError(error: Response) {
        return error.json();
    }
    
}
