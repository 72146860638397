import {TipoLayout} from "./tipo-layout";
import {CampoLayout} from "./campo-layout";
import {CabecalhoLayout} from "./cabecalho-layout";
import {RodapeLayout} from "./rodape-layout";

export class Layout {
    codigo_layout: number;
    codigo_tipo_layout: number;
    titulo: string;
    codigo_cabecalho_layout: number;
    codigo_rodape_layout: number;
    created_at: Date;
    deleted_at: Date;
    tipo_layout: TipoLayout = new TipoLayout();
    pode_alterar: boolean = false;
    campos_layout: Array<CampoLayout> = [];
    cabecalho: CabecalhoLayout = new CabecalhoLayout();
    rodape: RodapeLayout = new RodapeLayout();
}
