import { Component, OnInit } from '@angular/core';
import {TipoLayout} from "../../../shared/models/tipo-layout";
import {FormControl, FormGroup} from "@angular/forms";
import {AppComponent} from "../../../app.component";
import {NotifierService} from "angular-notifier";
import {ArquivoService} from "../../../core/services/arquivo.service";
import {Arquivo} from "../../../shared/models/arquivo";
import {forkJoin} from "rxjs";
import {TipoLayoutService} from "../../../core/services/tipo-layout.service";
import {Router} from "@angular/router";
import {environment} from "../../../../environments/environment";
import {isUndefined} from "util";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-consignacao-listar',
  templateUrl: './consignacao-listar.component.html',
  styleUrls: ['./consignacao-listar.component.css']
})
export class ConsignacaoListarComponent implements OnInit {

    public breadCrumb;
    public consignacoes: Array<Arquivo> = [];
    public tipos_layout: Array<TipoLayout> = [];
    public anos: Array<number> = [];
    public form: FormGroup;
    public carregado: boolean = false;
    public caminho: string;
    public mostrar_carregando: boolean = false;
    public codigo_arquivo: number = null;
    public md_consignacao_ref: BsModalRef;

    constructor(private appComponent: AppComponent, private arquivoService: ArquivoService, private tipoLayoutService: TipoLayoutService, private router: Router, private notifierService: NotifierService, private modalService: BsModalService) {

    }

    ngOnInit() {
        this.breadCrumb = [
            {nome: "Configuração", caminho: "/configuracao"},
            {nome: "Consignação", caminho: "/layout/consignacao"}
        ];
        this.appComponent.setTitulo('Arquivos de consignação');
        this.appComponent.setBreadcrumb(this.breadCrumb);

        this.listAnosTiposLayout();
        this.caminho = environment.UrlApi + "/arquivo/file";

        this.form = new FormGroup({
            ano_referencia: new FormControl(new Date().getFullYear(), []),
            tipo_layout: new FormControl("", []),
        });
    }

    list(tipo_layout: string = null, ano: string = null, inicial: boolean = false): void {
        this.carregado = false;
        this.consignacoes = [];
        if((tipo_layout && ano) || inicial) {
            this.mostrar_carregando = true;
            this.arquivoService.list(tipo_layout, parseInt(ano), true).subscribe((consignacoes: Array<Arquivo>) => {
                this.consignacoes = consignacoes;
                this.carregado = true;
                this.mostrar_carregando = false;
            });
        }else{
            this.notifierService.notify('error', 'Informe os campos obrigatórios');
        }
    }

    listAnosTiposLayout(): void{
        this.anos = [];
        this.tipos_layout = [];
        forkJoin([this.arquivoService.listAnos(false), this.tipoLayoutService.list(false)]).subscribe((res) => {
            this.anos = res[0] as Array<number>;
            this.tipos_layout = res[1] as Array<TipoLayout>;
            if(this.tipos_layout.length > 0){
                this.list(this.tipos_layout[0].sigla, null, true);
                this.form.patchValue({
                    'tipo_layout': this.tipos_layout[0].sigla,
                });
            }else{
                this.list(null, null, true);
            }
        });
    }

    downloadFile(codigo_arquivo: number){
        window.open(environment.UrlApi + "/layout/arquivo/file/" + codigo_arquivo, '_blank');
    }

    gerarNovo(): void{
        this.router.navigate(['layout/consignacao/form']);
    }
    
    excluir(codigo_arquivo: number): void{
        this.arquivoService.delete(codigo_arquivo).subscribe((res) => {
            this.notifierService.notify('success', 'Arquivo de consignação excluído com sucesso');
            this.list(this.form.get('tipo_layout').value, this.form.get('ano_referencia').value, true);
        }, (error) => {
            if(typeof error === 'string'){
                this.notifierService.notify('error', error);
            }else if(isUndefined(error.errors)){
                this.notifierService.notify('error', 'Ocorreu um erro ao excluir o arquivo de consignação, tente novamente');
            }else if(error.errors.length > 0){
                error.errors.forEach((error) => {
                    this.notifierService.notify('error', error);
                });
            }
        });
    }
    
    compensar(codigo_arquivo: number): void{
        this.arquivoService.compensar(codigo_arquivo).subscribe((res) => {
            this.notifierService.notify('success', 'Arquivo de consignação compensado com sucesso');
            this.list(this.form.get('tipo_layout').value, this.form.get('ano_referencia').value, true);
        }, (error) => {
            if(typeof error === 'string'){
                this.notifierService.notify('error', error);
            }else if(isUndefined(error.errors)){
                this.notifierService.notify('error', 'Ocorreu um erro ao compensar o arquivo de consignação, tente novamente');
            }else if(error.errors.length > 0){
                error.errors.forEach((error) => {
                    this.notifierService.notify('error', error);
                });
            }
        });
    }
    
    openModalDescompensar(codigo_arquivo, content_modal): void{
        this.codigo_arquivo = codigo_arquivo;
        this.md_consignacao_ref = this.modalService.show(content_modal, {keyboard: false, focus: true});
    }
    
    descompensar(codigo_arquivo: number): void{
        this.arquivoService.descompensar(codigo_arquivo).subscribe((res) => {
            this.notifierService.notify('success', 'Arquivo de consignação descompensado com sucesso');
            this.list(this.form.get('tipo_layout').value, this.form.get('ano_referencia').value, true);
            this.md_consignacao_ref.hide();
        }, (error) => {
            if(typeof error === 'string'){
                this.notifierService.notify('error', error);
            }else if(isUndefined(error.errors)){
                this.notifierService.notify('error', 'Ocorreu um erro ao descompensar o arquivo de consignação, tente novamente');
            }else if(error.errors.length > 0){
                error.errors.forEach((error) => {
                    this.notifierService.notify('error', error);
                });
            }
        });
    }

}
