import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../../../../app.component';
import { NotifierService } from 'angular-notifier';
import { AssociadoSituacaoTipo } from 'src/app/shared/models/associado-situacao-tipo';
import { AssociadoSituacaoTipoService } from 'src/app/core/services/associado-situacao-tipo.service';

@Component({
    selector: 'app-associado-situacao-tipo-listar',
    templateUrl: './associado-situacao-tipo-listar.component.html',
    styleUrls: ['./associado-situacao-tipo-listar.component.css'],
})
export class AssociadoSituacaoTipoListarComponent implements OnInit {
    associados_situacao_tipo: AssociadoSituacaoTipo;
    codigo_associado_situacao_tipo;
    breadCrumb;
    associados_situacao_tipo_inativos: AssociadoSituacaoTipo;
    carregando;

    constructor(
        private appComponent: AppComponent,
        private associadoSituacaoTipoService: AssociadoSituacaoTipoService,
        private notifierService: NotifierService,
    ) {}

    ngOnInit() {
        this.breadCrumb = [
            { nome: 'Configuração', caminho: 'configuracao' },
            {
                nome: 'associados_situacao_tipo',
                caminho: '/associado-situacao-tipo-listar',
            },
        ];
        this.appComponent.setTitulo('Associados Situacao Tipo Cadastradas');
        this.appComponent.setBreadcrumb(this.breadCrumb);

        this.listar();
    }

    definirCodigo(codigo_associado_situacao_tipo) {
        this.codigo_associado_situacao_tipo = codigo_associado_situacao_tipo;
    }

    listar(): void {
        this.associadoSituacaoTipoService.list(false).subscribe(
            (data: AssociadoSituacaoTipo) => {
                this.associados_situacao_tipo = data;
            },
            (error) => console.log(error),
        );

        // demandas inativas
        this.associadoSituacaoTipoService.list(true).subscribe(
            (data_inativo: AssociadoSituacaoTipo) => {
                this.associados_situacao_tipo_inativos = data_inativo;
            },
            (error) => console.log(error),
        );
    }

    apagar(codigo_associado_situacao_tipo): void {
        this.carregando = true;
        this.associadoSituacaoTipoService
            .delete(codigo_associado_situacao_tipo)
            .subscribe(
                (data) => {
                    this.listar();
                    this.carregando = false;
                    this.notifierService.notify(
                        'success',
                        'Registro DELETADO com sucesso.',
                    );
                },
                (error) => {
                    this.notifierService.notify('error', 'Erro: ' + error);
                    this.carregando = false;
                },
            );
    }

    restaurar(codigo): void {
        this.associadoSituacaoTipoService.restore(codigo).subscribe(
            (data) => {
                this.listar();
                this.notifierService.notify(
                    'success',
                    'Registro RESTAURADO com sucesso.',
                );
            },
            (error) => {
                this.notifierService.notify(
                    'error',
                    'Não foi possível REATIVAR o registro. ' + error,
                );
            },
        );
    }
}
