<app-header></app-header>

<div class="row">
  <div class="col-md-4">
    <div class="panel panel-flat">
      <div class="panel-heading">
        <b>Situação</b> <br />
        <span class="label label-success" *ngIf="demanda.demandasituacao?.codigo_demanda_situacao == 1">{{demanda.demandasituacao?.nome}}</span>
        <span class="label label-primary" *ngIf="demanda.demandasituacao?.codigo_demanda_situacao == 2">{{demanda.demandasituacao?.nome}}</span>
        <span class="label label-warning" *ngIf="demanda.demandasituacao?.codigo_demanda_situacao == 3">{{demanda.demandasituacao?.nome}}</span>
        <span class="label" style="background-color: grey; color: #FFF;" *ngIf="demanda.demandasituacao?.codigo_demanda_situacao == 4">{{demanda.demandasituacao?.nome}}</span>
        <span class="label label-danger" *ngIf="demanda.demandasituacao?.codigo_demanda_situacao == 5">{{demanda.demandasituacao?.nome}}</span>
        <span class="label label-default" *ngIf="demanda.demandasituacao?.codigo_demanda_situacao == 6">{{demanda.demandasituacao?.nome}}</span>
      </div>
      <div class="panel-body">
        <div class="row">
          <div class="col-md-4">
            <p><b>Tipo:</b>&nbsp;&nbsp;{{demanda.demandatipo.nome}}</p>
          </div>
          <div class="col-md-2" *ngIf="demanda.codigo_demanda_situacao < 4">
            <span style="cursor: pointer;" data-toggle="modal" data-target="#md-alterar-tipo-demanda" *ngIf="demanda.codigo_colaborador == codigo_pessoa" (click)="definirCodigo(demanda.codigo_demanda)" class="mdi mdi-edit"></span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <!-- <p><b>Prioridade:</b>&nbsp;&nbsp;<span *ngIf="demanda.prioridade">{{demanda.prioridade}}</span><span *ngIf="!demanda.prioridade"> - </span></p> -->
            <p><b>Atribuído a:</b>&nbsp;&nbsp;<span *ngIf="demanda.colaborador?.nome">{{demanda.colaborador?.nome}}</span><span *ngIf="!demanda.colaborador"> - </span></p>
          </div>
        </div>
      </div>
    </div>
    <div class="panel panel-flat">
      <div class="panel-heading">
        <b>Anexos</b>
        &nbsp;&nbsp;&nbsp;
        <button data-toggle="modal" data-target="#md-footer-novo-arquivo" class="btn btn-space btn-default" *ngIf="(demanda.codigo_demanda_situacao < 4) && (demanda.codigo_colaborador == codigo_pessoa)"><i class="icon icon-left mdi mdi-file"></i> Incluir Anexo</button>
      </div>
      <div class="panel-body" *ngIf="anexos && anexos.length == 0">
        <p>Nenhum anexo encontrado</p>
      </div>
      <div class="panel-body" *ngIf="anexos && anexos.length > 0">
        <div *ngFor="let dado of anexos">
          <div class="col-md-10">
            <p style="cursor: pointer;" (click)="baixarDocumento(dado.codigo_demanda_anexo, dado.nome)">{{dado.nome | slice:0:25}}<span *ngIf="dado.nome.length > 25"> ...</span></p>
          </div>
          <div class="col-md-2">
            <span data-toggle="modal" data-target="#md-footer-excluir-arquivo" *ngIf="dado.codigo_pessoa == codigo_pessoa" (click)="definirCodigoArquivoAnexo(dado.codigo_demanda_anexo)" class="mdi mdi-close-circle"></span>
          </div>
        </div>
      </div>
      <div class="panel-body" *ngIf="!anexos">
        <img *ngIf="carregando_anexo" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
      </div>
    </div>
    <button type="button" data-toggle="modal" data-target="#md-footer-finalizar" class="btn btn-space btn-default" *ngIf="demanda.codigo_demanda_situacao < 4 && (demanda.codigo_colaborador == codigo_pessoa)">Finalizar</button>
    <button type="button" data-toggle="modal" data-target="#md-atribuir-demanda" class="btn btn-space btn-warning" *ngIf="demanda.codigo_demanda_situacao < 4">Atribuir</button>
    <button type="button" data-toggle="modal" data-target="#md-footer-capturar" class="btn btn-space btn-primary" *ngIf="demanda.codigo_demanda_situacao < 4 && (!demanda.codigo_colaborador || demanda.codigo_colaborador != codigo_pessoa)">Capturar</button>&nbsp;&nbsp;
    <button type="button" data-toggle="modal" data-target="#md-footer-novo-arquivo" class="btn btn-space btn-primary" *ngIf="demanda.codigo_demanda_situacao < 4 && (demanda.codigo_colaborador == codigo_pessoa)">Anexar</button>&nbsp;&nbsp;    
    <br />
    <br />
    <a href="demanda-listar" class="btn btn-space btn-default">Voltar</a>&nbsp;&nbsp;
  </div>

  <div class="col-md-8">

    <div class="panel panel-contrast">
      <div class="panel-heading panel-heading-contrast">
        <b>Demanda #{{demanda.codigo_demanda}}</b>
        <br/>
        {{demanda.assunto}}
        <span class="panel-subtitle">{{demanda.created_at | date: 'dd/MM/yyyy HH:mm'}}</span>
      </div>
      <div class="panel-body">
        <p><b>Demandante:</b> {{demanda.demandante.nome}}</p>
        <p>{{demanda.descricao}}</p>
      </div>
    </div>

    <div *ngIf="demandaHistorico && demandaHistorico.length > 0">
      <div class="panel panel-contrast" *ngFor="let demanda of demandaHistorico">
        <div class="panel-heading panel-heading-contrast">
          <!-- <b *ngIf="demanda.associado">{{demanda.associado?.nome}}</b> -->
          <b *ngIf="demanda.colaborador">{{demanda.colaborador?.nome}}</b>
          <span class="panel-subtitle">{{demanda.created_at | date: 'dd/MM/yyyy HH:mm'}}</span>
        </div>
        <div class="panel-body">
          <p [innerHTML]="demanda.descricao"></p>
        </div>
      </div>
      <img *ngIf="carregando" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
    </div>

    <form #demandaForm="ngForm" [formGroup]="form" novalidate="" class="form-horizontal group-border-dashed" *ngIf="demanda.codigo_demanda_situacao < 4 && demanda.codigo_colaborador">
      <div class="form-group xs-mt-10">
        <div class="col-sm-12">
          <h4><b>Novo comentário</b></h4>
          <ckeditor [config]="config" formControlName="descricao" [editor]="Editor"></ckeditor>
          <div *ngIf="submitted && form.descricao.errors" class="obrigatorio">
            <div *ngIf="form.descricao.errors">Campo obrigatório</div>
          </div>
        </div>
      </div>
      <div class="form-group">
          <div class="col-sm-7">
            <button type="submit" class="btn btn-space btn-primary" (click)="onSubmit()">Enviar</button>
            &nbsp;&nbsp;
            <button class="btn btn-space btn-primary"  data-toggle="modal" data-target="#md-enviar-atribuir-demanda">Enviar e Atribuir</button>
          </div>
      </div>
    </form>
  </div>
</div>

<!--Modal Situacao Apcf Sindical-->
<div id="md-alterar-tipo-demanda" tabindex="-1" role="dialog" class="modal fade colored-header colored-header-primary">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-header">
              <button type="button" data-dismiss="modal" aria-hidden="true" class="close md-close"><span class="mdi mdi-close"></span></button>
              <h3 class="modal-title">Alterar o tipo da demanda</h3>
          </div>
          <div class="modal-body">
              <form #tipoDemandaForm="ngForm" [formGroup]="formDemanda" novalidate="" class="form-horizontal group-border-dashed">
                  <div class="form-group">
                      <span class="col-sm-2" style="text-align: right;">
                          <label class="control-label">Tipo <span class="obrigatorio">*</span></label>
                      </span>
                      <span class="col-sm-8">
                          <select class="select2 form-control" formControlName="codigo_demanda_tipo">
                            <option *ngFor="let dado of demandaTipo" value="{{dado.codigo_demanda_tipo}}">
                              {{dado.nome}}
                            </option>
                          </select>
                          <app-error-msg [control]="formDemanda.get('codigo_demanda_tipo')" label="Tipo da Demanda"></app-error-msg>
                      </span>
                  </div>
              </form>
          </div>
          <div class="modal-footer">
              <button type="button" data-dismiss="modal" class="btn btn-default md-close">Cancelar</button>
              <button type="button" data-dismiss="modal" class="btn btn-primary md-close" (click)="alterarTipoDemanda()">Confirmar</button>
          </div>
      </div>
  </div>
</div>

<!-- Modal novo arquivo -->
<div id="md-footer-novo-arquivo" tabindex="-1" role="dialog" style="" class="modal fade colored-header colored-header-primary">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-header">
              <button type="button" data-dismiss="modal" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
              <h3 class="modal-title">Envio de Arquivo</h3>
          </div>
          <div class="modal-body">
              
              <form #tratamentoForm="ngForm" [formGroup]="form" novalidate="" class="form-horizontal group-border-dashed">
                  <div class="custom-file">
                      <p>Arquivos permitidos: jpeg | jpg | png | gif | doc | docx | pdf | xls | xlsx</p>
                      <input type="file" class="custom-file-input" id="customFile" (change)="onChange($event)" multiple />
                      <br />
                      <label class="custom-file-label" for="customFile" id="customFileLabel" *ngIf="files"></label>
                    </div>
                  <br />
                  <br />
                  <div class="progress" *ngIf="carregando">
                      <div class="progress-bar" role="progressbar" [style.width]="progress + '%'">
                          {{ progress }}%
                      </div>
                  </div>
                </form>

          </div>
          <div class="modal-footer">
              <button type="button" data-dismiss="modal" class="btn btn-default">Cancelar</button>
              <button type="button" data-dismiss="modal" class="btn btn-primary" (click)="onUpload()">Enviar</button>
          </div>
      </div>
  </div>
</div>

<!--Modal Footer-->
<div id="md-footer-excluir-arquivo" tabindex="-1" role="dialog" style="" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <div class="text-warning"><span class="modal-main-icon mdi mdi-alert-triangle"></span></div>
                <h3>Tem certeza que deseja EXCLUIR este anexo?</h3>
                <p>Esta operação não poderá ser desfeita.</p>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default">Cancelar</button>
                <button type="button" data-dismiss="modal" class="btn btn-warning" (click)="excluirArquivo(codigo_demanda_anexo)">Confirmar</button>
            </div>
        </div>
    </div>
</div>   

<!--Modal Footer-->
<div id="md-footer-finalizar" tabindex="-1" role="dialog" style="" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <div class="text-warning"><span class="modal-main-icon mdi mdi-alert-triangle"></span></div>
                <h3>Tem certeza que deseja FINALIZAR esta demanda?</h3>
                <h4><b>Caso necessite, antes de finalizar a demanda inclua um comentário descrevando o motivo da finalização.</b></h4>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default">Cancelar</button>
                <button type="button" data-dismiss="modal" class="btn btn-warning" (click)="finalizar()">Confirmar</button>
            </div>
        </div>
    </div>
</div>   

<!--Modal Footer-->
<div id="md-footer-capturar" tabindex="-1" role="dialog" style="" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                <h3>Tem certeza que deseja CAPTURAR esta demanda?</h3>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default">Cancelar</button>
                <button type="button" data-dismiss="modal" class="btn btn-primary" (click)="atualizarColaborador(true)">Confirmar</button>
            </div>
        </div>
    </div>
</div>   

<!--Modal Footer-->
<div id="md-atribuir-demanda" tabindex="-1" role="dialog" style="" class="modal fade colored-header colored-header-primary">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-header">
            <button type="button" data-dismiss="modal" aria-hidden="true" class="close md-close"><span class="mdi mdi-close"></span></button>
            <h3 class="modal-title">Atribuir Demanda</h3>
          </div>
          <div class="modal-body">
            <form novalidate="" class="form-horizontal group-border-dashed">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <div class="col-sm-12">
                      <select class="form-control" (change)="definirCodigoColaborador($event.target.value)">
                        <option>Selecione</option>
                        <option *ngFor="let dado of colaboradores" [value]="dado.codigo_pessoa">
                          {{dado.nome}}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
              <button type="button" data-dismiss="modal" class="btn btn-default">Cancelar</button>
              <button type="button" data-dismiss="modal" class="btn btn-primary" (click)="atualizarColaborador(false)">Confirmar</button>
          </div>
      </div>
  </div>
</div>

<!--Modal Footer-->
<div id="md-enviar-atribuir-demanda" tabindex="-1" role="dialog" style="" class="modal fade colored-header colored-header-primary">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-header">
            <button type="button" data-dismiss="modal" aria-hidden="true" class="close md-close"><span class="mdi mdi-close"></span></button>
            <h3 class="modal-title">Atribuir Demanda</h3>
          </div>
          <div class="modal-body">
            <form novalidate="" class="form-horizontal group-border-dashed">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <div class="col-sm-12">
                      <select class="form-control" (change)="definirCodigoColaborador($event.target.value)">
                        <option>Selecione</option>
                        <option *ngFor="let dado of colaboradores" [value]="dado.codigo_pessoa">
                          {{dado.nome}}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
              <button type="button" data-dismiss="modal" class="btn btn-default">Cancelar</button>
              <button type="button" data-dismiss="modal" class="btn btn-primary" (click)="onSubmit()">Confirmar</button>
          </div>
      </div>
  </div>
</div>   

<app-footer></app-footer>