<app-header></app-header>

<div class="row">
    <!--<div class="col-md-6">
        <div class="panel panel-default panel-table">
            <div class="panel-heading">Configurações Gerais</div>
            <div class="panel-body">
                <table class="table">
                    <tbody>
                        <tr>
                            <td>
                                <a href="sincronizar">
                                    <b>Sincronização Manual de Bases de
                                        Dados</b>
                                    <br />
                                    Permite a Sincronização manual entre as
                                    bases de dados do sistema interno e do
                                    sistema externo.
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <a href="certificado">
                                    <b>Geração de Certificados</b>
                                    <br />
                                    Geração de novo par de chaves publico e
                                    privado. Utilizadas para criptografar e
                                    descriptografas a base de dados pública.
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>-->
    <div class="col-md-6">
        <div class="panel panel-default panel-table">
            <div class="panel-heading">Tabelas de Apoio</div>
            <div class="panel-body">
                <table class="table">
                    <tbody>
                        <tr>
                            <td>
                                <a href="plano-listar">
                                    <b>Plano de Pagamento</b>
                                    <br />
                                    Manutenção de planos de pagamento.
                                    Influencia diretamente nos valores mensais
                                    de arrecadação.
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <a href="classe-listar">
                                    <b>Classe</b>
                                    <br />
                                    Manutenção de classes padrões para planos de
                                    pagamento.
                                </a>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <a href="associado-pagamento-tipo-listar">
                                    <b>Associado Pagamento Tipo</b>
                                    <br />
                                    Manutenção de associados pagamento tipo
                                </a>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <a href="associado-situacao-tipo-listar">
                                    <b>Associado Situação Tipo</b>
                                    <br />
                                    Manutenção de associsados situação tipo
                                </a>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <a href="beneficio-tipo-listar">
                                    <b>Beneficio Tipo</b>
                                    <br />
                                    Manutenção de benefício tipo
                                </a>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <a href="demanda-tipo-listar">
                                    <b>Demanda Tipo</b>
                                    <br />
                                    Manutenção de demanda tipo
                                </a>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <a href="pendencia-tipo-listar">
                                    <b>Pendência Tipo</b>
                                    <br />
                                    Manutenção de pendência tipo
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <a href="documento-tipo-listar">
                                    <b>Documento Tipo</b>
                                    <br />
                                    Manutenção de documento tipo
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="panel panel-default panel-table">
            <div class="panel-heading">Layouts e arquivos</div>
            <div class="panel-body">
                <table class="table">
                    <tbody>
                        <tr>
                            <td>
                                <a href="layout/arquivo">
                                    <b>Gera&ccedil;&atilde;o do arquivo</b>
                                    <br />
                                    Gera&ccedil;&atilde;o do arquivo com base no
                                    layout.
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <a href="layout/consignacao">
                                    <b>Upload do arquivo de
                                        consigna&ccedil;&atilde;o</b>
                                    <br />
                                    Upload do arquivo de
                                    consigna&ccedil;&atilde;o aceito.
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <a href="layout">
                                    <b>Altera&ccedil;&atilde;o do layout</b>
                                    <br />
                                    Altera&ccedil;&atilde;o do layout para os
                                    arquivos enviados.
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <a href="layout/cabecalho">
                                    <b>Altera&ccedil;&atilde;o do
                                        cabe&ccedil;alho do layout</b>
                                    <br />
                                    Altera&ccedil;&atilde;o do cabe&ccedil;alho
                                    do layout.
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <a href="layout/rodape">
                                    <b>Altera&ccedil;&atilde;o do
                                        rodap&eacute; do layout</b>
                                    <br />
                                    Altera&ccedil;&atilde;o do rodap&eacute; do
                                    layout.
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<!--<div class="row">
    <div class="col-md-6">
        <div class="panel panel-default panel-table">
            <div class="panel-heading">
                Layouts e arquivos
            </div>
            <div class="panel-body">
                <table class="table">
                    <tbody>
                        <tr>
                            <td>
                                <a href="layout/arquivo">
                                    <b>Gera&ccedil;&atilde;o do arquivo</b>
                                    <br/>
                                    Gera&ccedil;&atilde;o do arquivo com base no layout.
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <a href="layout/consignacao">
                                    <b>Upload do arquivo de consigna&ccedil;&atilde;o</b>
                                    <br/>
                                    Upload do arquivo de consigna&ccedil;&atilde;o aceito.
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <a href="layout">
                                    <b>Altera&ccedil;&atilde;o do layout</b>
                                    <br/>
                                    Altera&ccedil;&atilde;o do layout para os arquivos enviados.
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <a href="layout/cabecalho">
                                    <b>Altera&ccedil;&atilde;o do cabe&ccedil;alho do layout</b>
                                    <br/>
                                    Altera&ccedil;&atilde;o do cabe&ccedil;alho do layout.
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <a href="layout/rodape">
                                    <b>Altera&ccedil;&atilde;o do rodap&eacute; do layout</b>
                                    <br/>
                                    Altera&ccedil;&atilde;o do rodap&eacute; do layout.
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>-->

<app-footer></app-footer>
