import { Injectable } from "@angular/core";
import { Observable } from 'rxjs/Observable';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AssociadoService {

  constructor(private http: HttpClient) {}

  private _UrlAssociado = environment.UrlPrincipal + '/api/associado';
  private _urlRestaurar = environment.UrlPrincipal + '/api/associado-restaurar';
  private _urlPlanoPagamento = environment.UrlPrincipal + '/api/atualizarplanopagamento';

  buscaCep(cep) {
    const _urlCorreio = 'https://api.postmon.com.br/v1/cep/' + cep;
    const _urlCorreioAlternativo = 'http://viacep.com.br/ws/' + cep + '/json/';
    const _urlCorreioLocal = environment.UrlPrincipal + '/api/cep?cep=' + cep;
    let retorno = this.http.get(_urlCorreioLocal)
                    .catch(this.handleError);
    console.log(retorno);
    return retorno;
    
  }

  list(inativo, aprovado = true, contagem = 10, filtro_nome = null, filtro_classe = null, filtro_tipo = null, filtro_unidade_repasse = null) {
    if(inativo == false) {
      if(aprovado == true) {
        return this.http.get(this._UrlAssociado + '?inativo=false&aprovado=true&contagem='+contagem+'&nome='+filtro_nome+'&codigo_classe='+filtro_classe+'&tipo='+filtro_tipo+'&codigo_unidade_repasse='+filtro_unidade_repasse)
        .catch(this.handleError);
      } else {
        return this.http.get(this._UrlAssociado + '?inativo=false&aprovado=false&contagem='+contagem+'&nome='+filtro_nome+'&codigo_classe='+filtro_classe)
        .catch(this.handleError);
      }
    } else {
      return this.http.get(this._UrlAssociado + '?inativo=true&contagem='+contagem)
                      .catch(this.handleError);
    }
  }

  find(id) {
    return this.http.get(this._UrlAssociado + "/" + id)
                    .catch(this.handleError);
  }

  preCadastro(dados): Observable<any>{
    let http;
    http = this.http.post(this._UrlAssociado, dados);
    http.map((res) => {
        return res;
    }).catch(this.handleError);
    return http;
  }

  save(dados, codigo_pessoa): Observable<any>{   
    let http;
    if(!dados.codigo_pessoa) {
      dados.codigo_pessoa = codigo_pessoa;
    }
    if(codigo_pessoa || dados.codigo_pessoa){
        http = this.http.put(this._UrlAssociado + "/" + dados.codigo_pessoa, dados);
    } else {
        http = this.http.post(this._UrlAssociado, dados);
    }
    http.map((res) => {
        return res;
    }).catch(this.handleError);
    return http;
  }

  atualizarPlano(dados, codigo_pessoa) {
    let http;
    if(!dados.codigo_pessoa) {
      dados.codigo_pessoa = codigo_pessoa;
    }
    http =  this.http.put(this._urlPlanoPagamento + '/' + dados.codigo_pessoa, dados);
    http.map((res) => {
      return res;
    }).catch(this.handleError);
    return http;
  }
  
  restore(codigo_pessoa) {
    return this.http.put(this._urlRestaurar + '?codigo_pessoa=' + codigo_pessoa, null);
  }

  delete(codigo_pessoa) {
    return this.http.delete(this._UrlAssociado + "/" + codigo_pessoa);
  }

  private handleError(error: Response) {
    return error.json();
  }

}