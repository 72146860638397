import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {CabecalhoLayout} from "../../shared/models/cabecalho-layout";
import {CampoCabecalhoLayout} from "../../shared/models/campo-cabecalho-layout";


@Injectable({
  providedIn: 'root'
})
export class CabecalhoLayoutService {

  private url: string = environment.UrlApi + "/layout/cabecalho";

  constructor(private http: HttpClient) {}

  list(tipo_layout: string = null) : Observable<CabecalhoLayout[]> {
    let url: string = this.url;
    if(tipo_layout){
      url += "/" + tipo_layout;
    }
    return this.http.get(url)
      .pipe(map((res: any) => {
        let cabecalhos_layout: Array<CabecalhoLayout> = [];
        res.forEach((item: any) => {
          let campos_cabecalho_layout: Array<CampoCabecalhoLayout> = item.campos_cabecalho_layout as Array<CampoCabecalhoLayout>;
          let cabecalho_layout: CabecalhoLayout = item as CabecalhoLayout;
          cabecalho_layout.campos_cabecalho_layout = campos_cabecalho_layout;
          cabecalhos_layout.push(cabecalho_layout);
        });
        return cabecalhos_layout;
      }))
      .catch(this.handleError);
  }

  find(id) {
    return this.http.get(this.url + "/" + id)
      .catch(this.handleError);
  }

  save(cabecalho_layout: CabecalhoLayout): Observable<any>{
    let http;
    if(cabecalho_layout.codigo_cabecalho_layout){
      http = this.http.put(this.url + "/" + cabecalho_layout.codigo_cabecalho_layout, cabecalho_layout);
    } else {
      http = this.http.post(this.url, cabecalho_layout);
    }
    http.map((res) => {
      return res;
    }).catch(this.handleError);
    return http;
  }

  update(cabecalho_layout: CabecalhoLayout, campos_cabecalho_layout: Array<CampoCabecalhoLayout>) {
    return this.http.put(this.url + "/update/" + cabecalho_layout.codigo_cabecalho_layout, {
            titulo: cabecalho_layout.titulo,
            campos: campos_cabecalho_layout,
        }).catch(this.handleError);
  }

  delete(codigo_cabecalho_layout): Observable<any> {
    return this.http.delete(this.url + "/" + codigo_cabecalho_layout);
  }

  copy(cabecalho_layout: CabecalhoLayout) {
    return this.http.put(this.url + "/copy/" + cabecalho_layout.codigo_cabecalho_layout, cabecalho_layout)
      .catch(this.handleError);
  }

  private handleError(error: Response) {
    return error.json();
  }
}
