<app-header></app-header>

<div class="row wizard-row">
  <div class="col-md-12 fuelux">
    <div class="block-wizard panel panel-default">
      <div id="wizard1" class="wizard wizard-ux">
        <ul class="steps">
          <li data-step="1"><a href="dados-pessoais/{{codigo_pessoa}}">Dados Pessoais</a><span class="chevron"></span></li>
          <li data-step="2"><a href="dados-financeiros/{{codigo_pessoa}}">Dados Financeiros</a><span class="chevron"></span></li>
          <li data-step="3"><a href="dados-administrativos/{{codigo_pessoa}}">Dados Administrativos</a><span class="chevron"></span></li>
          <li data-step="4"><a href="dados-controle/{{codigo_pessoa}}">Dados de Controle</a><span class="chevron"></span></li>
          <li data-step="5" class="active"><a href="dados-complementares/{{codigo_pessoa}}">Dados Complementares</a><span class="chevron"></span></li>
        </ul>
        <div class="step-content">
          <div *ngIf="!tipo_associado" style="text-align: center;"><img src="/assets/img/carregando_tabela.gif"></div>
          <div data-step="1" class="step-pane active" *ngIf="tipo_associado">
              <div class="tab-container">
                <ul class="nav nav-tabs">
                  <li class="active"><a href="#correspondencia" data-toggle="tab">Dados de Endereço</a></li>
                  <li><a href="#contato" data-toggle="tab">Dados de Contato</a></li>
                  <li *ngIf="tipo_associado != 'PE'"><a href="#profissionais" data-toggle="tab">Dados Profissionais</a></li>
                  <li><a href="#dependentes" data-toggle="tab">Dependentes</a></li>
                  <li *ngIf="tipo_associado == 'AP'"><a href="#pericia" data-toggle="tab">Perícia Particular</a></li>
                </ul>
                <div class="tab-content">
                  <div id="correspondencia" class="tab-pane active cont" [ngClass]="{ 'active':aba==='correspondencia'||!aba}">
                    <div>
                      <h4><b>Endereço Residencial</b></h4>
                      <form #enderecoForm="ngForm" [formGroup]="form" data-parsley-namespace="data-parsley-" data-parsley-validate="" novalidate="" class="form-horizontal group-border-dashed" autocomplete="off">
                        <div class="form-group" [class.has-error]="(f.cep.touched || submited_contato) && f.cep.invalid == true">
                          <label class="col-sm-3 control-label">CEP <span style="color: red;">*</span></label>
                          <div class="col-sm-2">
                            <input type="text" placeholder="CEP" class="form-control" autocomplete="doNotAutoComplete" (blur)="obterEnderecoCep($event.target.value, false)" [dropSpecialCharacters]="true" mask="00.000-000" formControlName="cep">
                            <app-error-msg [control]="form.get('cep')" label="CEP"></app-error-msg>
                          </div>
                          <div class="col-sm-2">
                            <img *ngIf="carregando_cep" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                          </div>
                        </div>
                        <div class="form-group" [class.has-error]="(f.endereco.touched || submited_endereco) && f.endereco.invalid == true">
                          <label class="col-sm-3 control-label">Endereço <span style="color: red;">*</span></label>
                          <div class="col-sm-6">
                            <input type="text" placeholder="Endereço" autocomplete="doNotAutoComplete" class="form-control" formControlName="endereco">
                            <app-error-msg [control]="form.get('endereco')" label="Endereço"></app-error-msg>
                            <span class="erro" *ngIf="cep_nao_encontrado">{{cep_nao_encontrado}}</span>
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="col-sm-3 control-label">Número</label>
                          <div class="col-sm-2">
                            <input type="number" placeholder="Número" autocomplete="doNotAutoComplete" class="form-control" formControlName="endereco_numero">
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="col-sm-3 control-label">Complemento</label>
                          <div class="col-sm-6">
                            <input type="text" placeholder="Complemento" autocomplete="doNotAutoComplete" class="form-control" formControlName="complemento">
                          </div>
                        </div>
                        <div class="form-group" [class.has-error]="(f.sigla_uf.touched || submited_contato) && f.sigla_uf.invalid == true">
                          <label class="col-sm-3 control-label">UF <span style="color: red;">*</span></label>
                          <div class="col-sm-2">
                            <select class="select2 form-control" formControlName="sigla_uf">
                              <option *ngFor="let dado of Ufs" value="{{dado.sigla_uf}}">
                                {{dado.sigla_uf}}
                              </option>
                            </select>
                            <app-error-msg [control]="form.get('sigla_uf')" label="UF"></app-error-msg>
                          </div>
                        </div>
                        <div class="form-group" [class.has-error]="(f.cidade.touched || submited_contato) && f.cidade.invalid == true">
                          <label class="col-sm-3 control-label">Cidade <span style="color: red;">*</span></label>
                          <div class="col-sm-3">
                            <input type="text" placeholder="Cidade" autocomplete="doNotAutoComplete" class="form-control" formControlName="cidade">
                            <app-error-msg [control]="form.get('cidade')" label="Cidade"></app-error-msg>
                          </div>
                        </div>
                        <div class="form-group" [class.has-error]="(f.bairro.touched || submited_contato) && f.bairro.invalid == true">
                          <label class="col-sm-3 control-label">Bairro <span style="color: red;">*</span></label>
                          <div class="col-sm-3">
                            <input type="text" placeholder="Bairro" autocomplete="doNotAutoComplete" class="form-control" formControlName="bairro">
                            <app-error-msg [control]="form.get('bairro')" label="Bairro"></app-error-msg>
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="col-sm-3 control-label"></label>
                          <div class="col-sm-6">
                            <div class="be-checkbox">
                              <label class="control-label">Utiliza o endereço acima para correspondências</label> &nbsp;&nbsp;
                              <input id="check1" type="checkbox" (click)="definirPreechimento('N')" [checked]="preencher_correspondencia">
                              <label for="check1">Não</label>
                            </div>
                          </div>
                        </div>

                        <div *ngIf="preencher_correspondencia">
                          <h4><b>Endereço de Correspondência</b></h4>
                          <div class="form-group">
                            <label class="col-sm-3 control-label">CEP</label>
                            <div class="col-sm-2">
                              <input type="text" placeholder="CEP" autocomplete="doNotAutoComplete" class="form-control" (blur)="obterEnderecoCep($event.target.value, true)" [dropSpecialCharacters]="true" mask="00.000-000" formControlName="cep_correspondencia">
                            </div>
                            <div class="col-sm-2">
                              <img *ngIf="carregando_cep_correspondencia" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Endereço</label>
                            <div class="col-sm-6">
                              <input type="text" placeholder="Endereço" autocomplete="doNotAutoComplete" class="form-control" formControlName="endereco_correspondencia">
                              <span class="erro" *ngIf="cep_nao_encontrado_correspondencia">{{cep_nao_encontrado_correspondencia}}</span>
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Número</label>
                            <div class="col-sm-2">
                              <input type="number" placeholder="Número" autocomplete="doNotAutoComplete" class="form-control" formControlName="numero_correspondencia">
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Complemento</label>
                            <div class="col-sm-6">
                              <input type="text" placeholder="Complemento" autocomplete="doNotAutoComplete" class="form-control" formControlName="complemento_correspondencia">
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="col-sm-3 control-label">UF</label>
                            <div class="col-sm-2">
                              <select class="select2 form-control" formControlName="sigla_uf_correspondencia">
                                <option *ngFor="let dado of Ufs" value="{{dado.sigla_uf}}">
                                  {{dado.sigla_uf}}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Cidade</label>
                            <div class="col-sm-3">
                              <input type="text" placeholder="Cidade" autocomplete="doNotAutoComplete" class="form-control" formControlName="cidade_correspondencia">
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="col-sm-3 control-label">Bairro</label>
                            <div class="col-sm-3">
                              <input type="text" placeholder="Bairro" autocomplete="doNotAutoComplete" class="form-control" formControlName="bairro_correspondencia">
                            </div>
                          </div>
                        </div>

                        <div class="form-group">
                          <div class="col-sm-offset-3 col-sm-9">
                            <button data-wizard="#wizard1" class="btn btn-primary btn-space wizard-next" (click)="onSubmit()">Salvar</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div id="contato" class="tab-pane cont" [ngClass]="{ 'active':aba==='contato'}">
                    <div *ngIf="!carregando" style="text-align: center;"><img src="/assets/img/carregando_tabela.gif"></div>
                    <div id="contato" class="tab-pane active cont" *ngIf="carregando">
                      <form #contatoForm="ngForm" [formGroup]="formContato" data-parsley-namespace="data-parsley-" data-parsley-validate="" novalidate="" class="form-horizontal group-border-dashed">
                        <div class="form-group">
                          <label class="col-sm-3 control-label">Telefone Fixo</label>
                          <div class="col-sm-3">
                            <input type="text" placeholder="Telefone Fixo" autocomplete="doNotAutoComplete" class="form-control" [dropSpecialCharacters]="true" mask="(00) 0000-0000" formControlName="telefone_fixo">
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="col-sm-3 control-label">Telefone Comercial</label>
                          <div class="col-sm-3">
                            <input type="text" placeholder="Telefone Comercial" autocomplete="doNotAutoComplete" class="form-control" [dropSpecialCharacters]="true" mask="(00) 0000-0000" formControlName="telefone_comercial">
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="col-sm-3 control-label">Telefone para Recados</label>
                          <div class="col-sm-3">
                            <input type="text" placeholder="Telefone para Recados" autocomplete="doNotAutoComplete" class="form-control" [dropSpecialCharacters]="true" mask="(00) 00000-0000" formControlName="telefone_recado">
                          </div>
                          <div class="col-sm-6">
                            <input type="text" placeholder="Nome para Recados" autocomplete="doNotAutoComplete" class="form-control" formControlName="nome_recado">
                          </div>
                        </div>
                        <div class="form-group" [class.has-error]="(fContato.celular_1.touched || submited_contato) && fContato.celular_1.invalid == true">
                          <label class="col-sm-3 control-label">Telefone Celular 1 <span style="color: red;">*</span></label>
                          <div class="col-sm-3">
                            <input type="text" placeholder="Telefone Celular 1" autocomplete="doNotAutoComplete" class="form-control" (blur)="definirSMS1('S')" [dropSpecialCharacters]="true" mask="(00) 00000-0000" formControlName="celular_1">
                            <app-error-msg [control]="formContato.get('celular_1')" label="Telefone Celular 1"></app-error-msg>
                          </div>
                          <div class="col-sm-6" style="margin-top: 6px;">
                            <div class="be-checkbox">
                              <input id="check10" type="checkbox" (click)="definirSMS1('S')" *ngIf="(sms_1 == 'N' || !sms_1)">
                              <input id="check100" type="checkbox" (click)="definirSMS1('N')" *ngIf="(sms_1 == 'S')" checked>
                              <input id="check300" type="checkbox" *ngIf="!celular_1 && !sms_1" disabled>
                              <label for="check10">Receber SMS no Celular 1</label>
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="col-sm-3 control-label">Telefone Celular 2</label>
                          <div class="col-sm-3">
                            <input type="text" placeholder="Telefone Celular 2" autocomplete="doNotAutoComplete" class="form-control" [dropSpecialCharacters]="true" mask="(00) 00000-0000" formControlName="celular_2">
                          </div>
                          <div class="col-sm-6" style="margin-top: 6px;">
                            <div class="be-checkbox">
                              <input id="check20" type="checkbox" (click)="definirSMS2('S')" *ngIf="celular_2 && (sms_2 == 'N' || !sms_2)">
                              <input id="check20" type="checkbox" (click)="definirSMS2('N')" *ngIf="celular_2 && (sms_2 == 'S')" checked>
                              <input id="check10" type="checkbox" *ngIf="!celular_2" disabled>
                              <label for="check20">Receber SMS no Celular 2</label>
                            </div>
                          </div>
                        </div>

                        <div class="form-group" [class.has-error]="fContato.email.invalid == true">
                          <label class="col-sm-3 control-label">E-mail Pessoal</label>
                          <div class="col-sm-6">
                            <input type="text" placeholder="Obrigatório para acessar a sistemas da APCF" autocomplete="doNotAutoComplete" class="form-control" formControlName="email">
                            <p *ngIf="!fContato.email.invalid">* E-mails permitidos: Gmail, Hotmail, Outlook ou Yahoo. <br /> Obrigatório para acessar a sistemas da APCF.</p>
                            <p *ngIf="fContato.email.invalid" style="color: red;">* E-mails permitidos: Gmail, Hotmail, Outlook ou Yahoo. <br /> Obrigatório para acessar a sistemas da APCF.</p>
                          </div>
                          <div class="col-sm-3" style="margin-top: 6px;">
                            <div class="be-checkbox">
                              <input id="check30" type="checkbox" (click)="definirNewsLetter1('S')" *ngIf="(newsletter_1 == 'N')">
                              <input id="check30" type="checkbox" (click)="definirNewsLetter1('N')" *ngIf="!newsletter_1 || (newsletter_1 == 'S')" checked>
                              <label for="check30">Receber Newsletter</label>
                            </div>
                          </div>
                        </div>
                        <div class="form-group" [class.has-error]="fContato.email_secundario.touched && fContato.email_secundario.invalid == true">
                          <label class="col-sm-3 control-label">E-mail Secundário</label>
                          <div class="col-sm-6">
                            <input type="text" placeholder="E-mail Secundário" autocomplete="doNotAutoComplete" class="form-control" (blur)="definirEmailSecundario(true)" formControlName="email_secundario">
                            <p>* E-mails NÃO permitidos: @*.gov.br</p>
                            <app-error-msg [control]="formContato.get('email_secundario')" label="E-mail Secundário"></app-error-msg>
                          </div>
                          <div class="col-sm-3" style="margin-top: 6px;">
                            <div class="be-checkbox">
                              <input id="check40" type="checkbox" (click)="definirNewsLetter2('S')" *ngIf="newsletter_2 == 'N' || !newsletter_2">
                              <input id="check40" type="checkbox" (click)="definirNewsLetter2('N')" *ngIf="newsletter_2 == 'S'" checked>
                              <input id="check40" type="checkbox" *ngIf="!email_secundario" disabled>
                              <label for="check40">Receber Newsletter</label>
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="col-sm-3 control-label">E-mail Institucional</label>
                          <div class="col-sm-6">
                            <input type="text" placeholder="E-mail Institucional" autocomplete="doNotAutoComplete" class="form-control" formControlName="email_institucional">
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="col-sm-offset-3 col-sm-9">
                            <button data-wizard="#wizard1" class="btn btn-primary btn-space wizard-next" (click)="onSubmitContato()">Salvar</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div id="profissionais" class="tab-pane cont" [ngClass]="{ 'active':aba==='profissionais'}">
                    <div *ngIf="!carregando" style="text-align: center;"><img src="/assets/img/carregando_tabela.gif"></div>
                    <div id="profissionais" class="tab-pane active cont" *ngIf="carregando">
                      <form #profissionalForm="ngForm" [formGroup]="formProfissional" data-parsley-namespace="data-parsley-" data-parsley-validate="" novalidate="" class="form-horizontal group-border-dashed">
                        <div class="form-group">
                          <label class="col-sm-3 control-label">Lotação</label>
                          <div class="col-sm-4">
                            <select class="select2 form-control" formControlName="codigo_lotacao">
                              <option *ngFor="let dado of Lotacoes" value="{{dado.codigo_lotacao}}">
                                {{dado.nome}}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="col-sm-3 control-label">Data de Ingresso no Serviço Público Federal </label>
                          <div class="col-sm-2">
                            <input type="text" placeholder="DD/MM/YYYY" class="form-control" [dropSpecialCharacters]="false" mask="00/00/0000" formControlName="data_ingresso_servico_publico">
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="col-sm-3 control-label">Aderiu ao Funpresp</label>
                          <div class="col-sm-6">
                            <div class="be-radio inline">
                              <input type="radio" id="rad10" formControlName="aderiu_funpresp" value="S" [checked]="aderiu_funpresp=='S'">
                              <label for="rad10">Sim</label>
                            </div>
                            <div class="be-radio inline">
                              <input type="radio" id="rad20" formControlName="aderiu_funpresp" value="N" [checked]="aderiu_funpresp=='N'">
                              <label for="rad20">Não</label>
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="col-sm-3 control-label">Egresso</label>
                          <div class="col-sm-6">
                            <div class="row">
                              <!--<input id="check50" type="checkbox" formControlName="egresso_policia_civil" [checked]="egresso_policia_civil">-->
                              <div class="col-md-4">
                                <label style="padding-top:8px;">Polícia Civil</label>
                              </div>
                              <div class="col-md-3">
                                <select class="form-control input-xs" formControlName="egresso_policia_civil">
                                  <option value="S">
                                    Sim
                                  </option>
                                  <option value="N">
                                    Não
                                  </option>
                                </select>
                              </div>
                            </div>
                            <br />
                            <div class="row">
                              <!--<input id="check60" type="checkbox" formControlName="egresso_policia_militar" [checked]="egresso_policia_militar">
                              <label for="check60">Polícia Militar</label>-->
                              <div class="col-md-4">
                                <label style="padding-top:8px;">Polícia Militar</label>
                              </div>
                              <div class="col-md-3">
                                <select class="form-control input-xs" formControlName="egresso_policia_militar">
                                  <option value="S">
                                    Sim
                                  </option>
                                  <option value="N">
                                    Não
                                  </option>
                                </select>
                              </div>
                            </div>
                            <br />
                            <div class="row">
                              <!--<input id="check70" type="checkbox" formControlName="egresso_bombeiro" [checked]="egresso_bombeiro">
                              <label for="check70">Corpo de Bombeiros</label>-->
                              <div class="col-md-4">
                                <label style="padding-top:8px;">Corpo de Bombeiros</label>
                              </div>
                              <div class="col-md-3">
                                <select class="form-control input-xs" formControlName="egresso_bombeiro">
                                  <option value="S">
                                    Sim
                                  </option>
                                  <option value="N">
                                    Não
                                  </option>
                                </select>
                              </div>
                            </div>
                            <br />
                            <div class="row">
                              <!--<input id="check80" type="checkbox" formControlName="egresso_forcas_armadas" [checked]="egresso_forcas_armadas">
                              <label for="check80">Forças Armadas</label>-->
                              <div class="col-md-4">
                                <label style="padding-top:8px;">Forças Armadas</label>
                              </div>
                              <div class="col-md-3">
                                <select class="form-control input-xs" formControlName="egresso_forcas_armadas">
                                  <option value="S">
                                    Sim
                                  </option>
                                  <option value="N">
                                    Não
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="col-sm-3 control-label">Titulação Acadêmica</label>
                          <div class="col-sm-6">
                            <select class="select2 form-control" formControlName="codigo_titulacao_academica">
                              <option *ngFor="let dado of TitulacaoAcademica" value="{{dado.codigo_titulacao_academica}}">
                                {{dado.nome}}
                              </option>
                            </select>
                          </div>
                        </div>

                        <div class="form-group">
                          <label class="col-sm-3 control-label">Formação Acadêmica</label>
                          <div class="col-sm-6">
                            <a data-toggle="modal" data-target="#form-cadastro-formacao-academica" class="btn btn-primary">Incluir formação</a>
                            <table id="table2" class="table table-striped table-hover table-fw-widget">
                              <thead>
                                  <tr>
                                      <th class="col-md-9">Nome</th>
                                      <th class="text-center">Ação</th>
                                  </tr>
                              </thead>
                              <tbody *ngIf="FormacaoAcademicaAssociado && FormacaoAcademicaAssociado.length > 0">
                                <tr class="odd gradeX" *ngFor="let formacaoassociado of FormacaoAcademicaAssociado">
                                  <td>{{formacaoassociado.formacaoacademica.nome}}</td>
                                  <td class="text-center" style="font-size: 25px;">
                                    <a data-toggle="modal" data-target="#form-excluir-cadastro-formacao-academica" (click)="definirCodigoFormacaoAcademicaAssociado(formacaoassociado.codigo_associado_formacao_academica)"><span class="icon mdi mdi-delete"></span></a>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody *ngIf="!FormacaoAcademicaAssociado">
                                  <tr class="carregando">
                                      <td class="carregando" colspan="4">
                                          <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
                                      </td>
                                  </tr>
                              </tbody>
                              <tbody *ngIf="FormacaoAcademicaAssociado && FormacaoAcademicaAssociado.length == 0">
                                  <tr>
                                      <td colspan="4">
                                          Nenhum registro encontrado.
                                      </td>
                                  </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div class="form-group">
                          <label class="col-sm-3 control-label">Área de Atuação</label>
                          <div class="col-sm-6">
                            <a data-toggle="modal" data-target="#form-cadastro-area-atuacao" class="btn btn-primary">Incluir área de atuação</a>
                            <table id="table2" class="table table-striped table-hover table-fw-widget">
                              <thead>
                                  <tr>
                                      <th class="col-md-9">Nome</th>
                                      <th class="text-center">Ação</th>
                                  </tr>
                              </thead>
                              <tbody *ngIf="AreaAtuacaoAssociado && AreaAtuacaoAssociado.length > 0">
                                <tr class="odd gradeX" *ngFor="let dado of AreaAtuacaoAssociado">
                                  <td>{{dado.areaatuacao.nome}}</td>
                                  <td class="text-center" style="font-size: 25px;">
                                    <a data-toggle="modal" data-target="#form-excluir-cadastro-area-atuacao" (click)="definirCodigoAreaAtuacaoAssociado(dado.codigo_associado_area_atuacao)"><span class="icon mdi mdi-delete"></span></a>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody *ngIf="!AreaAtuacaoAssociado">
                                  <tr class="carregando">
                                      <td class="carregando" colspan="4">
                                          <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
                                      </td>
                                  </tr>
                              </tbody>
                              <tbody *ngIf="AreaAtuacaoAssociado && AreaAtuacaoAssociado.length == 0">
                                  <tr>
                                      <td colspan="4">
                                          Nenhum registro encontrado.
                                      </td>
                                  </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div class="form-group">
                          <div class="col-sm-offset-3 col-sm-9">
                            <button data-wizard="#wizard1" class="btn btn-primary btn-space wizard-next" (click)="onSubmitProfissional()">Salvar</button>
                          </div>
                        </div>
                      </form>
                    </div>

                    <div id="form-cadastro-formacao-academica" tabindex="-1" role="dialog" class="modal fade colored-header colored-header-primary">
                      <div class="modal-dialog custom-width">
                        <div class="modal-content">
                          <form #formacaoAcademicaAssociadoForm="ngForm" [formGroup]="formFormacaoAcademica" novalidate="" class="form-horizontal group-border-dashed">
                            <div class="modal-header">
                              <button type="button" data-dismiss="modal" aria-hidden="true" class="close md-close"><span class="mdi mdi-close"></span></button>
                              <h3 class="modal-title">Incluir Formação Academica do Associado.</h3>
                            </div>
                            <div class="modal-body">

                              <div class="form-group">
                                <label class="col-sm-3 control-label">Formação Acadêmica</label>
                                <div class="col-sm-6">
                                  <select class="select2 form-control" formControlName="codigo_formacao_academica">
                                    <option *ngFor="let dado of FormacaoAcademica" value="{{dado.codigo_formacao_academica}}">
                                      {{dado.nome}}
                                    </option>
                                  </select>
                                </div>
                              </div>

                            </div>
                            <div class="modal-footer">
                              <button type="button" data-dismiss="modal" class="btn btn-default md-close">Cancelar</button>
                              <button type="button" data-dismiss="modal" class="btn btn-primary md-close" (click)="incluirFormacaoAcademica()">Confirmar</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div id="form-excluir-cadastro-formacao-academica" tabindex="-1" role="dialog" class="modal fade colored-header colored-header-danger">
                      <div class="modal-dialog custom-width">
                        <div class="modal-content">
                          
                            <div class="modal-header">
                              <button type="button" data-dismiss="modal" aria-hidden="true" class="close md-close"><span class="mdi mdi-close"></span></button>
                              <h3 class="modal-title">Confirmar Exclusão do Registro</h3>
                            </div>
                            <div class="modal-body">

                              <p>Tem certeza que deseja excluir este registro?</p>

                            </div>
                            <div class="modal-footer">
                              <button type="button" data-dismiss="modal" class="btn btn-default md-close">Cancelar</button>
                              <button type="button" data-dismiss="modal" class="btn btn-danger md-close" (click)="apagarFormacaoAcademica(codigo_associado_formacao_academica)">Confirmar</button>
                            </div>
                          
                        </div>
                      </div>
                    </div>

                    <div id="form-cadastro-area-atuacao" tabindex="-1" role="dialog" class="modal fade colored-header colored-header-primary">
                      <div class="modal-dialog custom-width">
                        <div class="modal-content">
                          <form #areaAtuacaoAssociadoForm="ngForm" [formGroup]="formAreaAtuacao" novalidate="" class="form-horizontal group-border-dashed">
                            <div class="modal-header">
                              <button type="button" data-dismiss="modal" aria-hidden="true" class="close md-close"><span class="mdi mdi-close"></span></button>
                              <h3 class="modal-title">Incluir Área de Formação do Associado.</h3>
                            </div>
                            <div class="modal-body">

                              <div class="form-group">
                                <label class="col-sm-3 control-label">Área de Formação</label>
                                <div class="col-sm-6">
                                  <select class="select2 form-control" formControlName="codigo_area_atuacao">
                                    <option *ngFor="let area_atuacao of areaAtuacao" value="{{area_atuacao.codigo_area_atuacao}}">
                                      {{area_atuacao.nome}}
                                    </option>
                                  </select>
                                </div>
                              </div>

                            </div>
                            <div class="modal-footer">
                              <button type="button" data-dismiss="modal" class="btn btn-default md-close">Cancelar</button>
                              <button type="button" data-dismiss="modal" class="btn btn-primary md-close" (click)="incluirAreaAtuacao()">Confirmar</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div id="form-excluir-cadastro-area-atuacao" tabindex="-1" role="dialog" class="modal fade colored-header colored-header-danger">
                      <div class="modal-dialog custom-width">
                        <div class="modal-content">
                          
                            <div class="modal-header">
                              <button type="button" data-dismiss="modal" aria-hidden="true" class="close md-close"><span class="mdi mdi-close"></span></button>
                              <h3 class="modal-title">Confirmar Exclusão do Registro</h3>
                            </div>
                            <div class="modal-body">

                              <p>Tem certeza que deseja excluir este registro?</p>

                            </div>
                            <div class="modal-footer">
                              <button type="button" data-dismiss="modal" class="btn btn-default md-close">Cancelar</button>
                              <button type="button" data-dismiss="modal" class="btn btn-danger md-close" (click)="apagarAreaAtuacao(codigo_associado_area_atuacao)">Confirmar</button>
                            </div>
                          
                        </div>
                      </div>
                    </div>

                  </div>

                  <div id="dependentes" class="tab-pane" [ngClass]="{ 'active':aba==='dependentes'}">
                    <div class="form-group">
                      <div class="col-sm-12">
                        <a data-toggle="modal" data-target="#form-cadastro-dependente" class="btn btn-primary" (click)="limparFormDependente()">Incluir dependente</a>
                        <div class="table-responsive">
                          <table id="table2" class="table table-striped table-hover table-fw-widget">
                            <thead>
                                <tr>
                                    <th class="col-md-4">Nome</th>
                                    <th class="col-md-2">Grau de Parentesco</th>
                                    <th class="col-md-2">Prioridade</th>
                                    <th class="col-md-2">Data de Nascimento</th>
                                    <th class="text-center">Ações</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="dependentesAssociado && dependentesAssociado.length > 0 && !atualizarTabela">
                              <tr class="odd gradeX" *ngFor="let dependente of dependentesAssociado">
                                <td>{{dependente.nome}}</td>
                                <td *ngIf="dependente.grau_parentesco == 'MA'">Mãe</td>
                                <td *ngIf="dependente.grau_parentesco == 'PA'">Pai</td>
                                <td *ngIf="dependente.grau_parentesco == 'FI'">Filho</td>
                                <td *ngIf="dependente.grau_parentesco == 'IR'">Irmão</td>
                                <td *ngIf="dependente.grau_parentesco == 'TI'">Tio</td>
                                <td *ngIf="dependente.grau_parentesco == 'CO'">Cônjuge</td>
                                <td *ngIf="dependente.prioridade != 0">Prioridade: {{dependente.prioridade}}ª</td>
                                <td *ngIf="dependente.prioridade == 0">Padrão</td>
                                <td>{{dependente.data_nascimento | date: 'dd/MM/yyyy'}}</td>
                                <td class="text-center" style="font-size: 25px;">
                                  <a data-toggle="modal" data-target="#form-cadastro-dependente" (click)="obterDependente(dependente.codigo_associado_dependente)"><span class="icon mdi mdi-edit"></span></a>
                                  &nbsp;&nbsp;
                                  <a data-toggle="modal" data-target="#form-excluir-cadastro-dependente" (click)="definirCodigoDependenteAssociado(dependente.codigo_associado_dependente)"><span class="icon mdi mdi-delete"></span></a>
                                </td>
                              </tr>
                            </tbody>
                            <tbody *ngIf="!dependentesAssociado || atualizarTabela">
                                <tr class="carregando">
                                    <td class="carregando" colspan="5">
                                        <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="dependentesAssociado && dependentesAssociado.length == 0">
                                <tr>
                                    <td colspan="5">
                                        Nenhum registro encontrado.
                                    </td>
                                </tr>
                            </tbody>
                          </table>
                        </div>
                        <!--<p>
                          Caso não seja indicada uma ordem de preferência, o auxílio post mortem será concedido na forma do Estatuto Social da APCF, a saber:

                          "Art. 88. (...) §1º Consideram-se dependentes, para os fins previstos neste artigo, em ordem de preferência para pagamento, desde que não indicada pelo associado: I - o cônjuge, do qual o associado não esteja legalmente separado; II - a companheira ou companheiro, desde que comprovado; III - os filhos e enteados, desde que menores de 21 (vinte e um) anos ou maiores inválidos que, comprovadamente, vivam sob a dependência econômica do associado; e IV - os pais que, comprovadamente, vivam sob a dependência econômica do associado. (...)"

                        </p>-->

                      </div>
                    </div>

                    <div id="form-cadastro-dependente" tabindex="-1" role="dialog" class="modal fade colored-header colored-header-primary">
                      <div class="modal-dialog custom-width">
                        <div class="modal-content">
                          <form #dependenteAssociadoForm="ngForm" [formGroup]="formDependente" novalidate="" class="form-horizontal group-border-dashed">
                            <div class="modal-header">
                              <button type="button" data-dismiss="modal" aria-hidden="true" class="close md-close"><span class="mdi mdi-close"></span></button>
                              <h3 class="modal-title">Incluir Dependente do Associado.</h3>
                            </div>
                            <div class="modal-body">

                              <div class="form-group" [class.has-error]="(fDependente.nome.touched || submited) && fDependente.nome.invalid == true">
                                <label class="col-sm-3 control-label">Nome <span style="color: red;">*</span></label>
                                <div class="col-sm-8">
                                  <input type="text" placeholder="Nome" class="form-control" formControlName="nome">
                                  <app-error-msg [control]="formDependente.get('nome')" label="Nome"></app-error-msg>
                                </div>
                              </div>

                              <div class="form-group" [class.has-error]="(fDependente.prioridade.touched || submited) && fDependente.prioridade.invalid == true">
                                <label class="col-sm-3 control-label">Prioridade <span style="color: red;">*</span></label>
                                <div class="col-sm-6">
                                  <select class="select2 form-control" formControlName="prioridade" (change)="definirPrioridadeNova($event.target.value)">
                                    <option value="0" selected="selected">
                                      Padrão
                                    </option>
                                    <option value="1">
                                      Prioridade: 1ª
                                    </option>
                                    <option value="2">
                                      Prioridade: 2ª
                                    </option>
                                    <option value="3">
                                      Prioridade: 3ª
                                    </option>
                                    <option value="4">
                                      Prioridade: 4ª
                                    </option>
                                    <option value="5">
                                      Prioridade: 5ª
                                    </option>
                                    <option value="6">
                                      Prioridade: 6ª
                                    </option>
                                    <option value="7">
                                      Prioridade: 7ª
                                    </option>
                                    <option value="8">
                                      Prioridade: 8ª
                                    </option>
                                    <option value="9">
                                      Prioridade: 9ª
                                    </option>
                                    <option value="10">
                                      Prioridade: 10ª
                                    </option>
                                  </select>
                                  <app-error-msg [control]="formDependente.get('prioridade')" label="Prioridade"></app-error-msg>
                                </div>
                              </div>

                              <div class="form-group" [class.has-error]="(fDependente.grau_parentesco.touched || submited) && fDependente.grau_parentesco.invalid == true">
                                <label class="col-sm-3 control-label">Grau de Parentesco <span style="color: red;">*</span></label>
                                <div class="col-sm-6">
                                  <select class="select2 form-control" formControlName="grau_parentesco">
                                    <option value="MA">
                                      Mãe
                                    </option>
                                    <option value="PA">
                                      Pai
                                    </option>
                                    <option value="IR">
                                      Irmão(ã)
                                    </option>
                                    <option value="TI">
                                      Tio(a)
                                    </option>
                                    <option value="FI">
                                      Filho(a)
                                    </option>
                                    <option value="CO">
                                      Cônjuge
                                    </option>
                                  </select>
                                  <app-error-msg [control]="formDependente.get('grau_parentesco')" label="Grau de Parentesco"></app-error-msg>
                                </div>
                              </div>

                              <div class="form-group" [class.has-error]="(fDependente.data_nascimento.touched || submited) && fDependente.data_nascimento.invalid == true">
                                <label class="col-sm-3 control-label">Data de Nascimento  <span style="color: red;">*</span></label>
                                <div class="col-sm-6">
                                  <input type="text" placeholder="DD/MM/YYYY" class="form-control" [dropSpecialCharacters]="false" mask="00/00/0000" formControlName="data_nascimento">
                                  <app-error-msg [control]="formDependente.get('data_nascimento')" label="Data de Nascimento"></app-error-msg>
                                </div>
                              </div>

                            </div>
                            <div class="modal-footer">
                              <button type="button" data-dismiss="modal" class="btn btn-default md-close">Cancelar</button>
                              <button type="button" class="btn btn-primary md-close" data-toggle="modal" data-target="#form-alterar-prioridade-dependente" *ngIf="codigo_associado_dependente && prioridade_nova && ((prioridade == 0 && prioridade_nova > 0) || (prioridade > 0 && prioridade_nova == 0))">Confirmar</button>
                              <button type="button" class="btn btn-primary md-close" (click)="incluirDependente(true);" *ngIf="codigo_associado_dependente && prioridade_nova && ((prioridade == 0 && prioridade_nova == 0) || (prioridade > 0 && prioridade_nova > 0))">Confirmar</button>
                              <button type="button" class="btn btn-primary md-close" (click)="incluirDependente();" *ngIf="!codigo_associado_dependente || !prioridade_nova">Confirmar</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div id="form-excluir-cadastro-dependente" tabindex="-1" role="dialog" class="modal fade colored-header colored-header-danger">
                      <div class="modal-dialog custom-width">
                        <div class="modal-content">
                          
                            <div class="modal-header">
                              <button type="button" data-dismiss="modal" aria-hidden="true" class="close md-close"><span class="mdi mdi-close"></span></button>
                              <h3 class="modal-title">Confirmar Exclusão do Registro</h3>
                            </div>
                            <div class="modal-body">

                              <p>Tem certeza que deseja excluir este registro?</p>

                            </div>
                            <div class="modal-footer">
                              <button type="button" data-dismiss="modal" class="btn btn-default md-close">Cancelar</button>
                              <button type="button" data-dismiss="modal" class="btn btn-danger md-close" (click)="apagarDependente(codigo_associado_dependente)">Confirmar</button>
                            </div>
                          
                        </div>
                      </div>
                    </div>
                    <div id="form-alterar-prioridade-dependente" tabindex="-1" role="dialog" class="modal fade colored-header colored-header-warning">
                      <div class="modal-dialog custom-width">
                        <div class="modal-content">
                          
                            <div class="modal-header">
                              <button type="button" data-dismiss="modal" aria-hidden="true" class="close md-close"><span class="mdi mdi-close"></span></button>
                              <h3 class="modal-title">Confirmar Alteração de Prioridade</h3>
                            </div>
                            <div class="modal-body">
                              <div class="text-center">
                                <div class="text-warning"><span class="modal-main-icon mdi mdi-alert-triangle"></span></div>
                                <h3>Esta operação irá acarretar na alteração de todos os dependentes.<br />Tem certeza que deseja realizar esta alteração?</h3>
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button type="button" data-dismiss="modal" class="btn btn-default md-close">Cancelar</button>
                              <button type="button" data-dismiss="modal" class="btn btn-warning md-close" (click)="incluirDependente()">Confirmar</button>
                            </div>
                          
                        </div>
                      </div>
                    </div>



                  </div>

                  <div id="pericia" class="tab-pane">
                    <form #periciaForm="ngForm" [formGroup]="formPericia" novalidate="" class="form-horizontal group-border-dashed">
                      <div class="form-group">
                        <div class="col-sm-4">
                          <h4><b>Perícia Particular</b></h4>
                          <div class="be-checkbox">
                            <input id="check100" type="checkbox" formControlName="exerce_pericia_particular" [checked]="exerce_pericia_particular">
                            <label for="check100">Exerce perícia particular/judicial?</label>
                          </div>
                          <div class="be-checkbox">
                            <input id="check110" type="checkbox" formControlName="autoriza_disponibilizar_contato" [checked]="autoriza_disponibilizar_contato">
                            <label for="check110">Autoriza disponibilizar contato?</label>
                          </div>
                          <br />
                          <button data-wizard="#wizard1" class="btn btn-primary btn-space wizard-next" (click)="onSubmitPericia()">Salvar</button>
                        </div>

                        <div class="col-sm-8">
                          <h4><b>Área de Atuação</b></h4>
                          <a data-toggle="modal" data-target="#form-cadastro-area-atuacao-pericia" class="btn btn-primary">Incluir área de atuação</a>
                          <table id="table2" class="table table-striped table-hover table-fw-widget">
                            <thead>
                                <tr>
                                    <th class="col-md-9">Nome</th>
                                    <th class="text-center">Ação</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="areaAtuacaoPericiaParticular && areaAtuacaoPericiaParticular.length > 0">
                              <tr class="odd gradeX" *ngFor="let areatuacaopericia of areaAtuacaoPericiaParticular">
                                <td>{{areatuacaopericia.areaatuacao.nome}}</td>
                                <td class="text-center" style="font-size: 25px;">
                                  <a data-toggle="modal" data-target="#form-excluir-cadastro-area-atuacao-pericia" (click)="definirCodigoAreaAtuacaoPericia(areatuacaopericia.codigo_pericia_particular_area_atuacao)"><span class="icon mdi mdi-delete"></span></a>
                                </td>
                              </tr>
                            </tbody>
                            <tbody *ngIf="!areaAtuacaoPericiaParticular">
                                <tr class="carregando">
                                    <td class="carregando" colspan="4">
                                        <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="areaAtuacaoPericiaParticular && areaAtuacaoPericiaParticular.length == 0">
                                <tr>
                                    <td colspan="4">
                                        Nenhum registro encontrado.
                                    </td>
                                </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </form>

                    <div id="form-cadastro-area-atuacao-pericia" tabindex="-1" role="dialog" class="modal fade colored-header colored-header-primary">
                      <div class="modal-dialog custom-width">
                        <div class="modal-content">
                          <form #areaAtuacaoPericiaParticularForm="ngForm" [formGroup]="formAreaAtuacaoPericiaParticular" novalidate="" class="form-horizontal group-border-dashed">
                            <div class="modal-header">
                              <button type="button" data-dismiss="modal" aria-hidden="true" class="close md-close"><span class="mdi mdi-close"></span></button>
                              <h3 class="modal-title">Incluir Área de Atuação da Perícia Particular</h3>
                            </div>
                            <div class="modal-body">

                              <div class="form-group">
                                <label class="col-sm-3 control-label">Área de Atuação</label>
                                <div class="col-sm-6">
                                  <select class="select2 form-control" formControlName="codigo_area_atuacao">
                                    <option *ngFor="let area_atuacao of areaAtuacao" value="{{area_atuacao.codigo_area_atuacao}}">
                                      {{area_atuacao.nome}}
                                    </option>
                                  </select>
                                </div>
                              </div>

                            </div>
                            <div class="modal-footer">
                              <button type="button" data-dismiss="modal" class="btn btn-default md-close">Cancelar</button>
                              <button type="button" data-dismiss="modal" class="btn btn-primary md-close" (click)="incluirAreaAtuacaoPericiaParticular()">Confirmar</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div id="form-excluir-cadastro-area-atuacao-pericia" tabindex="-1" role="dialog" class="modal fade colored-header colored-header-danger">
                      <div class="modal-dialog custom-width">
                        <div class="modal-content">
                          
                            <div class="modal-header">
                              <button type="button" data-dismiss="modal" aria-hidden="true" class="close md-close"><span class="mdi mdi-close"></span></button>
                              <h3 class="modal-title">Confirmar Exclusão do Registro</h3>
                            </div>
                            <div class="modal-body">

                              <p>Tem certeza que deseja excluir este registro?</p>

                            </div>
                            <div class="modal-footer">
                              <button type="button" data-dismiss="modal" class="btn btn-default md-close">Cancelar</button>
                              <button type="button" data-dismiss="modal" class="btn btn-danger md-close" (click)="apagarAreaAtuacaoPericiaParticular(codigo_pericia_particular_area_atuacao)">Confirmar</button>
                            </div>
                          
                        </div>
                      </div>
                    </div>
              </div>

              

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>