import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardExibirComponent } from './dashboard-exibir/dashboard-exibir.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';

const appRoutes: Routes = [
  {path: 'dashboard', component: DashboardExibirComponent}
];

@NgModule({
  imports: [
    BrowserModule,
    SharedModule,
    RouterModule.forRoot(appRoutes)
  ],
  declarations: [
    DashboardExibirComponent
  ]
})
export class DashboardModule { }
