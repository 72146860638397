import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { NotifierService } from 'angular-notifier';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { PessoaAcessoService } from 'src/app/core/services/pessoa-acesso.service';
import { log } from 'util';

@Component({
  selector: 'app-alterar-senha',
  templateUrl: './alterar-senha.component.html',
  styleUrls: ['./alterar-senha.component.css']
})
export class AlterarSenhaComponent implements OnInit {

  @ViewChild('alterarSenhaForm') formValues;

  form: FormGroup;
  submitted = false;
  nova_senha = false;
  sucesso;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private appComponent: AppComponent,
              private notifierService: NotifierService,
              private authenticationService: AuthenticationService,
              private pessoaAcessoService: PessoaAcessoService) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      senha_atual: [null, [Validators.required, Validators.minLength(6)]],
      nova_senha: [null, [Validators.required, Validators.minLength(6)]],
      nova_senha_confirmacao: [null, [Validators.required, Validators.minLength(6)]]
    });
  }

  get f() { return this.form.controls; }

  limpar() {
    this.sucesso = false;    
  }

  alterarSenha(): void {
    this.submitted = true;

    if(this.f.nova_senha.value != this.f.nova_senha_confirmacao.value) {
      this.nova_senha = true;
      return;
    } else if(this.form.valid) {
      this.authenticationService.alterarSenha({senha:this.f.senha_atual.value, nova_senha: this.f.nova_senha.value}).subscribe((data) => {
        this.formValues.resetForm();
        this.notifierService.notify( 'success', 'Senha ATUALIZADA com sucesso.' );
        this.nova_senha = false;
        this.sucesso = true;
        this.submitted = false;
        this.router.navigate(['dashboard']);
      }, (error) => {
        this.notifierService.notify( 'error', 'Senha atual não confere.');
      });
    }

  }

  logout() {
    this.authenticationService.logout();
    this.appComponent.setTitulo('');
    this.appComponent.setBreadcrumb(false);
    this.router.navigate(['login']);
  }

}
