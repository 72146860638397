import {Injectable} from "@angular/core";
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import { environment } from '../../../environments/environment';

import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class RepasseSolicitacaoService {

  private _Url = environment.UrlPrincipal + '/api/repassesolicitacao';

  constructor(private http: HttpClient) {}

  list(inativos = null) {
    if(inativos) {
      return this.http.get(this._Url + '?inativos=true')
      .catch(this.handleError);  
    } else {
      return this.http.get(this._Url)
                      .catch(this.handleError);
    }
  }

  find(id) {
    return this.http.get(this._Url + "/" + id)
                    .catch(this.handleError);
  }

  save(dados, codigo): Observable<any>{   
    let http;
    if(codigo || dados.codigo_repasse){
        http = this.http.put(this._Url + "/" + codigo, dados);
    } else {
        http = this.http.post(this._Url, dados);
    }
    http.map((res) => {
        return res;
    }).catch(this.handleError);
    return http;
  }

  delete(codigo) {
    return this.http.delete(this._Url + "/" + codigo);
  }

  private handleError(error: Response) {
    return error.json();
  }

}