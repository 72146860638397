<app-header></app-header>

<div class="tab-container">
    <ul class="nav nav-tabs">
        <li class="active"><a href="#ativo" data-toggle="tab">Ficha de Repasses</a></li>
        <li><a href="#pendente" data-toggle="tab">Repasses Aguardando Aprovação</a></li>
        <li><a href="#inativos" data-toggle="tab">Repasses Desaprovados</a></li>
    </ul>
    <div class="tab-content">
        <div id="ativo" class="tab-pane active cont">
            <div class="row">
                <div class="col-sm-12">
                    <div class="panel panel-default panel-table">
                        <div class="panel-body">
                            <div class="row" style="margin-left: 7px; margin-top: 20px;">
                                <div class="col-md-3">
                                    <label><b>Regional</b></label>
                                    <select name="sigla_uf" class="form-control input-xs" (change)="definirFiltroRegional($event.target.value)">
                                        <option value="">Todos</option>
                                        <option *ngFor="let dado of ufs" [value]="dado.sigla_uf" [attr.selected]="dado.sigla_uf==sigla_uf ? true : null">{{dado.sigla_uf}}</option>
                                    </select>
                                </div>
                                <!--<div class="col-md-3">
                                    <label><b>Unidade</b></label>
                                    <select name="codigo_lotacao" class="form-control input-xs" (change)="definirFiltroLotacao($event.target.value)">
                                        <option value="">Todos</option>
                                        <option *ngFor="let dado of lotacoes" [value]="dado.codigo_lotacao" [attr.selected]="dado.codigo_lotacao==filtro_lotacao ? true : null">{{dado.nome}}</option>
                                    </select>
                                </div>-->
                                <div class="col-md-2">
                                    <label><b>Mês</b></label>
                                    <select name="filtro_mes" id="filtro_mes" class="form-control input-xs" (change)="definirFiltroMes($event.target.value)">
                                        <option value="todos">Todos</option>
                                        <option *ngFor="let dado of meses" [value]="dado.codigo" [attr.selected]="dado.codigo==filtro_mes ? true : null">{{dado.nome}}</option>
                                    </select>
                                </div>
                                <div class="col-md-2">
                                    <label><b>Ano</b></label>
                                    <select name="filtro_ano" id="filtro_ano" class="form-control input-xs" (change)="definirFiltroAno($event.target.value)">
                                        <option *ngFor="let dado of ano" [value]="dado.ano" [attr.selected]="dado.ano==fitro_ano ? true : null">{{dado.ano}}</option>
                                    </select>
                                </div>
                                <div class="col-md-3" style="margin-top: 26px">
                                    <button class="btn btn-space btn-default" (click)="listar()">Filtrar</button>
                                </div>
                            </div>
                            <br />
                            <div class="row" style="margin-left: 7px;">
                                <div class="col-md-12">

                                    <div class="dataTables_length" id="table3_length">
                                        <table id="table2" class="table table-striped table-hover table-fw-widget">
                                            <thead>
                                                <tr>
                                                    <!--<th class="text-center">Mês/Ano Ref.</th>-->
                                                    <th class="text-center">Regional</th>
                                                    <th class="text-center">Unidade de Repasse</th>
                                                    <th class="text-center">Valor Indisponível</th>
                                                    <th class="text-center">Valor de Direito</th>
                                                    <th class="text-center">Cota Participação</th>
                                                    <th class="text-center">Valor Repassado</th>
                                                    <th class="text-center">Qtd. Associados</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngIf="repasses && repasses.length > 0">
                                                <tr class="odd gradeX" *ngFor="let dado of repasses; index as i">
                                                    <!--<td class="col-md-2 text-center">{{dado.data_referencia | date:'MM'}} / {{dado.data_referencia | date: 'yyyy'}}</td>-->
                                                    <td class="col-md-1 text-center">{{dado.lotacao.sigla_uf}}</td>
                                                    <td class="col-md-2 text-center">{{dado.lotacao.codigo_lotacao}} - {{dado.lotacao.nome}}</td>
                                                    <td class="col-md-2 text-center"><span *ngIf="!dado.valor_indisponivel">R$ 0.00</span><span *ngIf="!dado.valor_cota_participacao">{{dado.valor_indisponivel | currency:'BRL':true:'.2':'pt'}}</span></td>
                                                    <td class="col-md-2 text-center"><span *ngIf="!dado.valor_direito">R$ 0.00  </span><span *ngIf="dado.valor_direito">{{dado.valor_direito | currency:'BRL':true:'.2':'pt'}}</span></td>
                                                    <td class="col-md-2 text-center"><span *ngIf="!dado.valor_cota_participacao">0.00%</span><span *ngIf="dado.valor_cota_participacao">{{dado.valor_cota_participacao}}%</span></td>
                                                    <td class="col-md-2 text-center">{{dado.valor_repassado | currency:'BRL':true:'.2':'pt'}}</td>
                                                    <td class="col-md-2 text-center"><span *ngIf="!dado.quantidade_associados">0</span><span *ngIf="dado.quantidade_associados">{{dado.quantidade_associados}}</span></td>
                                                </tr>
                                            </tbody>
                                            <tbody *ngIf="!repasses">
                                                <tr class="carregando">
                                                    <td class="carregando" colspan="8">
                                                        <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody *ngIf="repasses && repasses.length == 0">
                                                <tr>
                                                    <td class="col-md-12" colspan="10">
                                                        Nenhum registro encontrado para o filtro selecionado. <br /><br />
                                                        <a *ngIf="mes_atual == filtro_mes" class="btn btn-space btn-primary" data-toggle="modal" data-target="#md-repasse-criar">Criar informações de Repasse</a>
                                                        <a *ngIf="mes_atual != filtro_mes" class="btn btn-space btn-primary" data-toggle="modal" data-target="#md-repasse-mensagem">Criar informações de Repasse</a>
                                                        <img *ngIf="carregando_repasse" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="pendente" class="tab-pane">
            <div class="row">
                <div class="col-sm-12">
                    <div class="panel panel-default panel-table">
                        <div class="panel-body">
                            <div class="row" style="margin-left: 7px;">
                                <div class="col-md-12">

                                    <div class="dataTables_length" id="table3_length">
                                        <table id="table2" class="table table-striped table-hover table-fw-widget">
                                            <thead>
                                                <tr>
                                                    <th class="text-center">Mês/Ano Ref.</th>
                                                    <th class="text-center">Solicitante / Unidade de Repasse</th>
                                                    <th class="text-center">Valor Solicitado</th>
                                                    <th>Observação</th>
                                                    <th>Data</th>
                                                    <th class="text-center">Aprovar?</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngIf="repasses_pendentes && repasses_pendentes.length > 0">
                                                <tr class="odd gradeX" *ngFor="let dado of repasses_pendentes; index as i">
                                                    <td class="col-md-1 text-center">{{dado.data_referencia | date:'MM'}} / {{dado.data_referencia | date: 'yyyy'}}</td>
                                                    <td class="col-md-3 text-center">{{dado.solicitante.nome}} <br /> {{dado.unidade_repasse}}</td>
                                                    <td class="col-md-2 text-center">{{dado.valor_solicitado | currency:'BRL':true:'.2':'pt'}}</td>
                                                    <td class="col-md-2">{{dado.observacao}}</td>
                                                    <td class="col-md-2">{{dado.created_at | date: 'dd/MM/yyyy'}}</td>
                                                    <td class="col-md-2 text-center" style="font-size: 22px;">
                                                        <a data-toggle="modal" data-target="#md-desaprovar" (click)="definirCodigoRepasseSolicitacao(dado.codigo_repasse_solicitacao)"><span style="color: red;" class="icon mdi mdi-block"></span></a>
                                                        &nbsp;&nbsp;
                                                        <a data-toggle="modal" data-target="#md-aprovar" (click)="definirCodigoRepasseSolicitacao(dado.codigo_repasse_solicitacao)"><span class="icon mdi mdi-thumb-up"></span></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody *ngIf="!repasses_pendentes">
                                                <tr class="carregando">
                                                    <td class="carregando" colspan="8">
                                                        <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody *ngIf="repasses_pendentes && repasses_pendentes.length == 0">
                                                <tr>
                                                    <td class="col-md-12" colspan="10">
                                                        Nenhum registro encontrado.
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="inativos" class="tab-pane">
            <div class="row">
                <div class="col-sm-12">
                    <div class="panel panel-default panel-table">
                        <div class="panel-body">
                            <div class="row" style="margin-left: 7px;">
                                <div class="col-md-12">

                                    <div class="dataTables_length" id="table3_length">
                                        <table id="table2" class="table table-striped table-hover table-fw-widget">
                                            <thead>
                                                <tr>
                                                    <th class="text-center">Mês/Ano Ref.</th>
                                                    <th class="text-center">Solicitante</th>
                                                    <th class="text-center">Unidade de Repasse</th>
                                                    <th class="text-center">Valor Solicitado</th>
                                                    <th>Observação</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngIf="repasses_inativos && repasses_inativos.length > 0">
                                                <tr class="odd gradeX" *ngFor="let dado of repasses_inativos; index as i">
                                                    <td class="col-md-2 text-center">{{dado.data_referencia | date:'MM'}} / {{dado.data_referencia | date: 'yyyy'}}</td>
                                                    <td class="col-md-2 text-center">{{dado.solicitante.nome}}</td>
                                                    <td class="col-md-2 text-center">{{dado.unidade_repasse}}</td>
                                                    <td class="col-md-2 text-center">{{dado.valor_solicitado | currency:'BRL':true:'.2':'pt'}}</td>
                                                    <td class="col-md-2">{{dado.observacao}}</td>
                                                </tr>
                                            </tbody>
                                            <tbody *ngIf="!repasses_inativos">
                                                <tr class="carregando">
                                                    <td class="carregando" colspan="8">
                                                        <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody *ngIf="repasses_inativos && repasses_inativos.length == 0">
                                                <tr>
                                                    <td class="col-md-12" colspan="10">
                                                        Nenhum registro encontrado.
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="md-aprovar" tabindex="-1" role="dialog" style="" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <h3>Tem certeza que deseja APROVAR este repasse?</h3>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default">Cancelar</button>
                <button type="button" data-dismiss="modal" class="btn btn-success" (click)="aprovar(codigo_repasse_solicitacao)">Aprovar</button>
            </div>
        </div>
    </div>
</div>

<div id="md-desaprovar" tabindex="-1" role="dialog" style="" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <div class="text-warning"><span class="modal-main-icon mdi mdi-alert-triangle"></span></div>
                    <h3>Tem certeza que deseja DEAPROVAR este repasse?</h3>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default">Cancelar</button>
                <button type="button" data-dismiss="modal" class="btn btn-danger" (click)="desaprovar(codigo_repasse_solicitacao)">Desaprovar</button>
            </div>
        </div>
    </div>
</div>

<div id="md-repasse-criar" tabindex="-1" role="dialog" style="" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <div class="text-warning"><span class="modal-main-icon mdi mdi-alert-triangle"></span></div>
                    <h3>Tem certeza que deseja CRIAR O REPASSE para o período {{filtro_mes}}/{{filtro_ano}}?</h3>
                    <h4><b>Esta operação não poderá ser desfeita.</b></h4>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default">Cancelar</button>
                <button type="button" data-dismiss="modal" class="btn btn-warning" (click)="onSubmit()">Confirmar</button>
            </div>
        </div>
    </div>
</div>

<div id="md-repasse-mensagem" tabindex="-1" role="dialog" style="" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <div class="text-warning"><span class="modal-main-icon mdi mdi-alert-triangle"></span></div>
                    <h3>Não é possível criar informações de repasse fora do mês atual.</h3>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default">Fechar</button>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>