import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../../../../app.component';
import { NotifierService } from 'angular-notifier';
import { AssociadoPagamentoTipo } from 'src/app/shared/models/associado-pagamento-tipo';
import { AssociadoPagamentoTipoService } from 'src/app/core/services/associado-pagamento-tipo.service';

@Component({
    selector: 'app-associado-pagamento-tipo-listar',
    templateUrl: './associado-pagamento-tipo-listar.component.html',
    styleUrls: ['./associado-pagamento-tipo-listar.component.css'],
})
export class AssociadoPagamentoTipoListarComponent implements OnInit {
    associados_pagamento_tipo: AssociadoPagamentoTipo;
    codigo_associado_pagamento_tipo;
    breadCrumb;
    associados_pagamento_tipo_inativos: AssociadoPagamentoTipo;
    carregando;

    constructor(
        private appComponent: AppComponent,
        private associadoPagamentoTipoService: AssociadoPagamentoTipoService,
        private notifierService: NotifierService,
    ) {}

    ngOnInit() {
        this.breadCrumb = [
            { nome: 'Configuração', caminho: 'configuracao' },
            {
                nome: 'associados_pagamento_tipo',
                caminho: '/associado-pagamento-tipo-listar',
            },
        ];
        this.appComponent.setTitulo('Associados Pagamento Tipo Cadastradas');
        this.appComponent.setBreadcrumb(this.breadCrumb);

        this.listar();
    }

    definirCodigo(codigo_associado_pagamento_tipo) {
        this.codigo_associado_pagamento_tipo = codigo_associado_pagamento_tipo;
    }

    listar(): void {
        this.associadoPagamentoTipoService.list(false, true).subscribe(
            (data: AssociadoPagamentoTipo) => {
                this.associados_pagamento_tipo = data;
            },
            (error) => console.log(error),
        );

        // demandas inativas
        this.associadoPagamentoTipoService.list(true, true).subscribe(
            (data_inativo: AssociadoPagamentoTipo) => {
                this.associados_pagamento_tipo_inativos = data_inativo;
            },
            (error) => console.log(error),
        );
    }

    apagar(codigo): void {
        this.carregando = true;
        this.associadoPagamentoTipoService.delete(codigo).subscribe(
            (data) => {
                this.listar();
                this.carregando = false;
                this.notifierService.notify(
                    'success',
                    'Registro DELETADO com sucesso.',
                );
            },
            (error) => {
                this.notifierService.notify('error', error);
                this.carregando = false;
            },
        );
    }

    restaurar(codigo): void {
        this.associadoPagamentoTipoService.restore(codigo).subscribe(
            (data) => {
                this.listar();
                this.notifierService.notify(
                    'success',
                    'Registro RESTAURADO com sucesso.',
                );
            },
            (error) => {
                this.notifierService.notify(
                    'error',
                    'Não foi possível REATIVAR o registro. ' + error,
                );
            },
        );
    }
}
