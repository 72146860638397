import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentoService {

  private _Url = environment.UrlPrincipal + '/api/documento';
  private _UrlRestaurar = environment.UrlPrincipal + '/api/documento-restaurar';

  constructor(private http: HttpClient) {}

  list(inativos, codigo_documento_tipo, ano) {
    if (!codigo_documento_tipo) {
      codigo_documento_tipo = null;
    }
    console.log(ano);
    return this.http.get(this._Url + '?inativos=' + inativos + '&codigo_documento_tipo=' + codigo_documento_tipo + '&ano=' + ano)
                    .catch(this.handleError);
  }

  find(id) {
    return this.http.get(this._Url + '/' + id)
                    .catch(this.handleError);
  }

  save(dados, codigo): Observable<any> {
    let http;
    if (codigo) {
        http = this.http.put(this._Url + '/' + codigo, dados);
    } else {
        http = this.http.post(this._Url, dados);
    }
    http.map((res) => {
        return res;
    }).catch(this.handleError);
    return http;
  }

  restore(codigo) {
    return this.http.put(this._UrlRestaurar + '?codigo_documento=' + codigo, null);
  }

  delete(codigo) {
    return this.http.delete(this._Url + '/' + codigo);
  }

  private handleError(error: Response) {
    return error.json();
  }

}
