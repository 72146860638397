import { Component, OnInit } from '@angular/core';
import {TipoLayout} from "../../../shared/models/tipo-layout";
import {FormControl, FormGroup} from "@angular/forms";
import {AppComponent} from "../../../app.component";
import {NotifierService} from "angular-notifier";
import {ArquivoService} from "../../../core/services/arquivo.service";
import {Arquivo} from "../../../shared/models/arquivo";
import {forkJoin} from "rxjs";
import {TipoLayoutService} from "../../../core/services/tipo-layout.service";
import {Router} from "@angular/router";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-arquivo-listar',
  templateUrl: './arquivo-listar.component.html',
  styleUrls: ['./arquivo-listar.component.css']
})
export class ArquivoListarComponent implements OnInit {

    public breadCrumb;
    public arquivos: Array<Arquivo> = [];
    public tipos_layout: Array<TipoLayout> = [];
    public anos: Array<number> = [];
    public form: FormGroup;
    public carregado: boolean = false;
    public mostrar_carregando: boolean = false;

    constructor(private appComponent: AppComponent,
                private arquivoService: ArquivoService,
                private tipoLayoutService: TipoLayoutService,
                private router: Router,
                private notifierService: NotifierService) {

    }

    ngOnInit() {
        this.breadCrumb = [
            {nome: "Configuração", caminho: "/configuracao"},
            {nome: "Arquivo", caminho: "/layout/arquivo"}
        ];
        this.appComponent.setTitulo('Arquivos gerados');
        this.appComponent.setBreadcrumb(this.breadCrumb);

        this.listAnosTiposLayout();

        this.form = new FormGroup({
            ano_referencia: new FormControl(new Date().getFullYear(), []),
            tipo_layout: new FormControl("", []),
        });
    }

    list(tipo_layout: string = null, ano: string = null, inicial: boolean = false): void {
        this.carregado = false;
        this.arquivos = [];
        if((tipo_layout && ano) || inicial) {
            this.mostrar_carregando = true;
            this.arquivoService.list(tipo_layout, parseInt(ano), false).subscribe((arquivos: Array<Arquivo>) => {
                console.log(arquivos);
                this.arquivos = arquivos;
                this.carregado = true;
                this.mostrar_carregando = false;
            });
        }else{
            this.notifierService.notify('error', 'Informe os campos obrigatórios');
        }
    }

    listAnosTiposLayout(): void{
        this.anos = [];
        this.tipos_layout = [];
        forkJoin([this.arquivoService.listAnos(true), this.tipoLayoutService.list(true)]).subscribe((res) => {
            this.anos = res[0] as Array<number>;
            this.tipos_layout = res[1] as Array<TipoLayout>;
            if(this.tipos_layout.length > 0){
                this.list(this.tipos_layout[0].sigla, null, true);
                this.form.patchValue({
                    "tipo_layout": this.tipos_layout[0].sigla,
                });
            }else{
                this.list(null, null, true);
            }
        });
    }

    gerarNovo(): void{
        this.router.navigate(['layout/arquivo/form']);
    }

    downloadFile(codigo_arquivo: number){
        window.open(environment.UrlApi + "/layout/arquivo/file/" + codigo_arquivo, '_blank');
    }

}
