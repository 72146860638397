<app-header></app-header>

<div class="row">
  <div class="col-sm-12">
    <div class="panel panel-default panel-table">
        <div class="panel-heading"> <b>Lista de Solicitações Aguardando Aprovação de Alteração</b>
        </div>
        <div class="panel-body">

            <div class="dataTables_length" id="table3_length">
                <table id="table2" class="table table-striped table-hover table-fw-widget">
                    <thead>
                        <tr>
                            <th>Nome / E-mail</th>
                            <th>CPF</th>
                            <th>Tipo</th>
                            <th>Data da Solicitação</th>
                            <th class="text-center">Ações</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="associados && associados.length > 0">
                        <tr class="odd gradeX" *ngFor="let associado of associados">
                            <td class="col-md-4">{{associado.pessoa.nome}} <br /> {{associado.pessoa.email}}<br /><span *ngIf="!associado.codigo_unidade_repasse" class="label label-danger">Sem unidade de repasse cadastrada</span></td>
                            <td class="col-md-2">{{associado.pessoa_fisica.cpf | cpf}}</td>                       
                            <td class="col-md-2" *ngIf="associado.associado.tipo == 'PE'">Pensionista</td>
                            <td class="col-md-2" *ngIf="associado.associado.tipo == 'AT'">Ativo</td>
                            <td class="col-md-2" *ngIf="associado.associado.tipo == 'AP'">Aposentado</td>
                            <td class="col-md-2">{{associado.created_at | date: 'dd/MM/yyyy'}}</td>
                            <td class="text-center" style="font-size: 22px;">
                                <a data-toggle="modal" data-target="#md-aprovacao" (click)="definirCodigo(associado.codigo_associado, associado.codigo_solicitacao);obter(associado.codigo_solicitacao)"><span class="icon mdi mdi-eye"></span></a>
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="!associados">
                        <tr class="carregando">
                            <td class="carregando" colspan="5">
                                <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="associados && associados.length == 0">
                        <tr>
                            <td class="col-md-12" colspan="5">
                                Nenhum registro encontrado.
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  </div>
</div>

<!--<div class="row">
    <div class="col-sm-12">
        <a href="associado-listar" class="btn btn-space btn-default">Voltar</a>
    </div>
</div>-->

<!--Modal Footer-->
<div id="md-aprovacao" tabindex="-1" role="dialog" style="" class="modal fade">
    <div style="width: 900px;" class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close modal-close"><span class="mdi mdi-close"></span></button>
                <h3 class="modal-title">Aprovação de Alteração</h3>
            </div>
            <div class="modal-body">

                <!--List Group-->
                <div class="panel panel-default">
                <div class="panel-heading panel-heading-divider">Instruções
                    <span class="panel-subtitle">Marque os itens para aprovação e clique no botão "Confirmar".</span>
                </div>
                <div class="panel-body">
                    <div class="xs-mt-10">
                            <div class="table-responsive">
                                <table id="table2" class="table table-striped table-hover table-fw-widget">
                                    <thead>
                                        <tr>
                                            <th>&nbsp;</th>
                                            <th class="col-md-3">Campo</th>
                                            <th class="col-md-4">Atual</th>
                                            <th class="col-md-4">Solicitação</th>
                                            <th class="text-center">Aprovar?</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="solicitacao">
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_pessoa?.nome && (solicitacao.pessoa.nome !== solicitacao.solicitacao_pessoa?.nome)">
                                            <td><span *ngIf="listaAlteracao.nome" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Nome</b></td>
                                            <td><p class="quebra-linha">{{solicitacao.pessoa.nome}}</p></td>
                                            <td><p class="quebra-linha">{{solicitacao.solicitacao_pessoa?.nome}}</p></td>
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.nome" (click)="desaprovarAlteracao('nome')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.nome" (click)="aprovarAlteracao('nome', solicitacao.solicitacao_pessoa?.nome)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_associado?.nome_guerra && (solicitacao.associado.nome_guerra !== solicitacao.solicitacao_associado?.nome_guerra)">
                                            <td><span *ngIf="listaAlteracao.nome_guerra" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Nome Guerra</b></td>
                                            <td><p class="quebra-linha">{{solicitacao.associado.nome_guerra}}</p></td>
                                            <td><p class="quebra-linha">{{solicitacao.solicitacao_associado?.nome_guerra}}</p></td>
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.nome_guerra" (click)="desaprovarAlteracao('nome_guerra')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.nome_guerra" (click)="aprovarAlteracao('nome_guerra', solicitacao.solicitacao_associado?.nome_guerra)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_associado.data_aposentadoria && (solicitacao.associado.data_aposentadoria !== solicitacao.solicitacao_associado.data_aposentadoria)">
                                            <td><span *ngIf="listaAlteracao.data_aposentadoria" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Data da Aposentadoria</b></td>
                                            <td>{{solicitacao.associado.data_aposentadoria | date: 'dd/MM/yyyy'}}</td>
                                            <td>{{solicitacao.solicitacao_associado.data_aposentadoria | date: 'dd/MM/yyyy'}}</td>                       
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.data_aposentadoria" (click)="desaprovarAlteracao('data_aposentadoria')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.data_aposentadoria" (click)="aprovarAlteracao('data_aposentadoria', solicitacao.solicitacao_associado.data_aposentadoria)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_associado?.codigo_classe && (solicitacao.associado?.codigo_classe !== solicitacao.solicitacao_associado?.codigo_classe)">
                                            <td><span *ngIf="listaAlteracao.codigo_classe" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Classe</b></td>
                                            <td>{{solicitacao.associado?.nome_classe}}</td>
                                            <td>{{solicitacao.solicitacao_associado?.nome_classe}}</td>                       
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.codigo_classe" (click)="desaprovarAlteracao('codigo_classe')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.codigo_classe" (click)="aprovarAlteracao('codigo_classe', solicitacao.solicitacao_associado?.codigo_classe)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_pessoa.email && (solicitacao.pessoa.email !== solicitacao.solicitacao_pessoa.email)">
                                            <td><span *ngIf="listaAlteracao.email" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>E-mail Pessoal</b></td>
                                            <td>{{solicitacao.pessoa.email}}</td>
                                            <td>{{solicitacao.solicitacao_pessoa.email}}</td>                       
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.email" (click)="desaprovarAlteracao('email')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.email" (click)="aprovarAlteracao('email', solicitacao.solicitacao_pessoa.email)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_pessoa.email_newsletter && (solicitacao.pessoa.email_newsletter !== solicitacao.solicitacao_pessoa.email_newsletter)">
                                            <td><span *ngIf="listaAlteracao.email_newsletter" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Receber Newsletter no E-mail Pessoal</b></td>
                                            <td><span *ngIf="solicitacao.pessoa.email_newsletter == 'N'">Não</span><span *ngIf="solicitacao.pessoa.email_newsletter == 'S'">Sim</span></td>
                                            <td><span *ngIf="solicitacao.solicitacao_pessoa.email_newsletter == 'N'">Não</span><span *ngIf="solicitacao.solicitacao_pessoa.email_newsletter == 'S'">Sim</span></td>
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.email_newsletter" (click)="desaprovarAlteracao('email_newsletter')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.email_newsletter" (click)="aprovarAlteracao('email_newsletter', solicitacao.solicitacao_pessoa.email_newsletter)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_pessoa.email_secundario && (solicitacao.pessoa.email_secundario !== solicitacao.solicitacao_pessoa.email_secundario)">
                                            <td><span *ngIf="listaAlteracao.email_secundario" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>E-mail Secundário</b></td>
                                            <td>{{solicitacao.pessoa.email_secundario}}</td>
                                            <td>{{solicitacao.solicitacao_pessoa.email_secundario}}</td>                       
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.email_secundario" (click)="desaprovarAlteracao('email_secundario')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.email_secundario" (click)="aprovarAlteracao('email_secundario', solicitacao.solicitacao_pessoa.email_secundario)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_pessoa.email_secundario_newsletter && (solicitacao.pessoa.email_secundario_newsletter !== solicitacao.solicitacao_pessoa.email_secundario_newsletter)">
                                            <td><span *ngIf="listaAlteracao.email_secundario_newsletter" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Receber Newsletter no E-mail Secundário</b></td>
                                            <td><span *ngIf="solicitacao.pessoa.email_secundario_newsletter == 'N'">Não</span><span *ngIf="solicitacao.pessoa.email_secundario_newsletter == 'S'">Sim</span></td>
                                            <td><span *ngIf="solicitacao.solicitacao_pessoa.email_secundario_newsletter == 'N'">Não</span><span *ngIf="solicitacao.solicitacao_pessoa.email_secundario_newsletter == 'S'">Sim</span></td>
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.email_secundario_newsletter" (click)="desaprovarAlteracao('email_secundario_newsletter')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.email_secundario_newsletter" (click)="aprovarAlteracao('email_secundario_newsletter', solicitacao.solicitacao_pessoa.email_secundario_newsletter)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_pessoa.email_institucional && (solicitacao.pessoa.email_institucional !== solicitacao.solicitacao_pessoa.email_institucional)">
                                            <td><span *ngIf="listaAlteracao.email_institucional" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>E-mail Institucional</b></td>
                                            <td><p class="quebra-linha">{{solicitacao.pessoa.email_institucional}}</p></td>
                                            <td><p class="quebra-linha">{{solicitacao.solicitacao_pessoa.email_institucional}}</p></td>                       
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.email_institucional" (click)="desaprovarAlteracao('email_institucional')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.email_institucional" (click)="aprovarAlteracao('email_institucional', solicitacao.solicitacao_pessoa.email_institucional)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_pessoa_fisica.data_nascimento && (solicitacao.pessoa_fisica.data_nascimento !== solicitacao.solicitacao_pessoa_fisica.data_nascimento)">
                                            <td><span *ngIf="listaAlteracao.data_nascimento" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Data de Nascimento</b></td>
                                            <td>{{solicitacao.pessoa_fisica.data_nascimento | date: 'dd/MM/yyyy'}}</td>
                                            <td>{{solicitacao.solicitacao_pessoa_fisica.data_nascimento | date: 'dd/MM/yyyy'}}</td>
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.data_nascimento" (click)="desaprovarAlteracao('data_nascimento')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.data_nascimento" (click)="aprovarAlteracao('data_nascimento', solicitacao.solicitacao_pessoa_fisica.data_nascimento)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_pessoa_fisica.nome_pai && (solicitacao.pessoa_fisica.nome_pai !== solicitacao.solicitacao_pessoa_fisica.nome_pai)">
                                            <td><span *ngIf="listaAlteracao.nome_pai" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Nome do Pai</b></td>
                                            <td><p class="quebra-linha">{{solicitacao.pessoa_fisica.nome_pai}}</p></td>
                                            <td><p class="quebra-linha">{{solicitacao.solicitacao_pessoa_fisica.nome_pai}}</p></td>                       
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.nome_pai" (click)="desaprovarAlteracao('nome_pai')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.nome_pai" (click)="aprovarAlteracao('nome_pai', solicitacao.solicitacao_pessoa_fisica.nome_pai)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_pessoa_fisica.nome_mae && (solicitacao.pessoa_fisica.nome_mae !== solicitacao.solicitacao_pessoa_fisica.nome_mae)">
                                            <td><span *ngIf="listaAlteracao.nome_mae" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Nome da Mãe</b></td>
                                            <td><p class="quebra-linha">{{solicitacao.pessoa_fisica.nome_mae}}</p></td>
                                            <td><p class="quebra-linha">{{solicitacao.solicitacao_pessoa_fisica.nome_mae}}</p></td>                       
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.nome_mae" (click)="desaprovarAlteracao('nome_mae')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.nome_mae" (click)="aprovarAlteracao('nome_mae', solicitacao.solicitacao_pessoa_fisica.nome_mae)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_pessoa.telefone_fixo && (solicitacao.pessoa.telefone_fixo !== solicitacao.solicitacao_pessoa.telefone_fixo)">
                                            <td><span *ngIf="listaAlteracao.telefone_fixo" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Telefone Fixo</b></td>
                                            <td>{{solicitacao.pessoa.telefone_fixo}}</td>
                                            <td>{{solicitacao.solicitacao_pessoa.telefone_fixo}}</td>                       
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.telefone_fixo" (click)="desaprovarAlteracao('telefone_fixo')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.telefone_fixo" (click)="aprovarAlteracao('telefone_fixo', solicitacao.solicitacao_pessoa.telefone_fixo)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_pessoa.telefone_comercial && (solicitacao.pessoa.telefone_comercial !== solicitacao.solicitacao_pessoa.telefone_comercial)">
                                            <td><span *ngIf="listaAlteracao.telefone_comercial" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Telefone Comercial</b></td>
                                            <td>{{solicitacao.pessoa.telefone_comercial}}</td>
                                            <td>{{solicitacao.solicitacao_pessoa.telefone_comercial}}</td>                       
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.telefone_comercial" (click)="desaprovarAlteracao('telefone_comercial')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.telefone_comercial" (click)="aprovarAlteracao('telefone_comercial', solicitacao.solicitacao_pessoa.telefone_comercial)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_pessoa.telefone_recado && (solicitacao.pessoa.telefone_recado !== solicitacao.solicitacao_pessoa.telefone_recado)">
                                            <td><span *ngIf="listaAlteracao.telefone_recado" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Telefone para Recado</b></td>
                                            <td>{{solicitacao.pessoa.telefone_recado}}</td>
                                            <td>{{solicitacao.solicitacao_pessoa.telefone_recado}}</td>                       
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.telefone_recado" (click)="desaprovarAlteracao('telefone_recado')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.telefone_recado" (click)="aprovarAlteracao('telefone_recado', solicitacao.solicitacao_pessoa.telefone_recado)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_pessoa.nome_recado && (solicitacao.pessoa.nome_recado !== solicitacao.solicitacao_pessoa.nome_recado)">
                                            <td><span *ngIf="listaAlteracao.nome_recado" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Nome para Recado</b></td>
                                            <td><p class="quebra-linha">{{solicitacao.pessoa.nome_recado}}</p></td>
                                            <td><p class="quebra-linha">{{solicitacao.solicitacao_pessoa.nome_recado}}</p></td>
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.nome_recado" (click)="desaprovarAlteracao('nome_recado')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.nome_recado" (click)="aprovarAlteracao('nome_recado', solicitacao.solicitacao_pessoa.nome_recado)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_pessoa.celular_1 && (solicitacao.pessoa.celular_1 !== solicitacao.solicitacao_pessoa.celular_1)">
                                            <td><span *ngIf="listaAlteracao.celular_1" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Celular 1</b></td>
                                            <td>{{solicitacao.pessoa.celular_1}}</td>
                                            <td>{{solicitacao.solicitacao_pessoa.celular_1}}</td>                       
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.celular_1" (click)="desaprovarAlteracao('celular_1')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.celular_1" (click)="aprovarAlteracao('celular_1', solicitacao.solicitacao_pessoa.celular_1)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_pessoa.celular_2 && (solicitacao.pessoa.celular_2 !== solicitacao.solicitacao_pessoa.celular_2)">
                                            <td><span *ngIf="listaAlteracao.celular_2" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Celular 2</b></td>
                                            <td>{{solicitacao.pessoa.celular_2}}</td>
                                            <td>{{solicitacao.solicitacao_pessoa.celular_2}}</td>                       
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.celular_2" (click)="desaprovarAlteracao('celular_2')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.celular_2" (click)="aprovarAlteracao('celular_2', solicitacao.solicitacao_pessoa.celular_2)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_pessoa.celular_1_sms && (solicitacao.pessoa.celular_1_sms !== solicitacao.solicitacao_pessoa.celular_1_sms)">
                                            <td><span *ngIf="listaAlteracao.celular_1_sms" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Receber SMS no Celular 1</b></td>
                                            <td><span *ngIf="solicitacao.pessoa.celular_1_sms == 'N'">Não</span><span *ngIf="solicitacao.pessoa.celular_1_sms == 'S'">Sim</span></td>
                                            <td><span *ngIf="solicitacao.solicitacao_pessoa.celular_1_sms == 'N'">Não</span><span *ngIf="solicitacao.solicitacao_pessoa.celular_1_sms == 'S'">Sim</span></td>
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.celular_1_sms" (click)="desaprovarAlteracao('celular_1_sms')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.celular_1_sms" (click)="aprovarAlteracao('celular_1_sms', solicitacao.solicitacao_pessoa.celular_1_sms)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_pessoa.celular_2_sms && (solicitacao.pessoa.celular_2_sms !== solicitacao.solicitacao_pessoa.celular_2_sms)">
                                            <td><span *ngIf="listaAlteracao.celular_2_sms" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Receber SMS no Celular 2</b></td>
                                            <td><span *ngIf="solicitacao.pessoa.celular_2_sms == 'N'">Não</span><span *ngIf="solicitacao.pessoa.celular_2_sms == 'S'">Sim</span></td>
                                            <td><span *ngIf="solicitacao.solicitacao_pessoa.celular_2_sms == 'N'">Não</span><span *ngIf="solicitacao.solicitacao_pessoa.celular_2_sms == 'S'">Sim</span></td>
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.celular_2_sms" (click)="desaprovarAlteracao('celular_2_sms')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.celular_2_sms" (click)="aprovarAlteracao('celular_2_sms', solicitacao.solicitacao_pessoa.celular_2_sms)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_pessoa_banco?.codigo_banco && (solicitacao.pessoa_banco?.codigo_banco !== solicitacao.solicitacao_pessoa_banco?.codigo_banco)">
                                            <td><span *ngIf="listaAlteracao.codigo_banco" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Banco</b></td>
                                            <td>{{solicitacao.pessoa_banco?.nome_banco}}</td>
                                            <td>{{solicitacao.solicitacao_pessoa_banco?.nome_banco}}</td>                       
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.codigo_banco" (click)="desaprovarAlteracao('codigo_banco')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.codigo_banco" (click)="aprovarAlteracao('codigo_banco', solicitacao.solicitacao_pessoa_banco?.codigo_banco)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_pessoa_banco?.agencia && (solicitacao.pessoa_banco?.agencia !== solicitacao.solicitacao_pessoa_banco?.agencia)">
                                            <td><span *ngIf="listaAlteracao.agencia" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Agência</b></td>
                                            <td>{{solicitacao.pessoa_banco?.agencia}}</td>
                                            <td>{{solicitacao.solicitacao_pessoa_banco?.agencia}}</td>                       
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.agencia" (click)="desaprovarAlteracao('agencia')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.agencia" (click)="aprovarAlteracao('agencia', solicitacao.solicitacao_pessoa_banco?.agencia)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_pessoa_banco?.agencia_dv && (solicitacao.pessoa_banco?.agencia_dv !== solicitacao.solicitacao_pessoa_banco?.agencia_dv)">
                                            <td><span *ngIf="listaAlteracao.agencia_dv" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Agência DV</b></td>
                                            <td>{{solicitacao.pessoa_banco?.agencia_dv}}</td>
                                            <td>{{solicitacao.solicitacao_pessoa_banco?.agencia_dv}}</td>                       
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.agencia_dv" (click)="desaprovarAlteracao('agencia_dv')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.agencia_dv" (click)="aprovarAlteracao('agencia_dv', solicitacao.solicitacao_pessoa_banco?.agencia_dv)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_pessoa_banco?.conta && (solicitacao.pessoa_banco?.conta !== solicitacao.solicitacao_pessoa_banco?.conta)">
                                            <td><span *ngIf="listaAlteracao.conta" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Conta</b></td>
                                            <td>{{solicitacao.pessoa_banco?.conta}}</td>
                                            <td>{{solicitacao.solicitacao_pessoa_banco?.conta}}</td>                       
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.conta" (click)="desaprovarAlteracao('conta')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.conta" (click)="aprovarAlteracao('conta', solicitacao.solicitacao_pessoa_banco?.conta)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_pessoa_banco?.operacao && (solicitacao.pessoa_banco?.operacao !== solicitacao.solicitacao_pessoa_banco?.operacao)">
                                            <td><span *ngIf="listaAlteracao.operacao" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Operação</b></td>
                                            <td>{{solicitacao.pessoa_banco?.operacao}}</td>
                                            <td>{{solicitacao.solicitacao_pessoa_banco?.operacao}}</td>                       
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.operacao" (click)="desaprovarAlteracao('operacao')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.operacao" (click)="aprovarAlteracao('operacao', solicitacao.solicitacao_pessoa_banco?.operacao)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>


                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_pessoa.cep && (solicitacao.pessoa.cep !== solicitacao.solicitacao_pessoa.cep)">
                                            <td><span *ngIf="listaAlteracao.cep" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>CEP</b></td>
                                            <td>{{solicitacao.pessoa.cep}}</td>
                                            <td>{{solicitacao.solicitacao_pessoa.cep}}</td>                       
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.cep" (click)="desaprovarAlteracao('cep')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.cep" (click)="aprovarAlteracao('cep', solicitacao.solicitacao_pessoa.cep)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_pessoa.endereco && (solicitacao.pessoa.endereco !== solicitacao.solicitacao_pessoa.endereco)">
                                            <td><span *ngIf="listaAlteracao.endereco" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Endereço Residêncial</b></td>
                                            <td><p class="quebra-linha">{{solicitacao.pessoa.endereco}}</p></td>
                                            <td>                                                   
                                                <textarea class="col-md-12" rows="5" cols="10" readonly style="resize: none; border: 0">
                                                    {{solicitacao.solicitacao_pessoa.endereco}}
                                                </textarea>
                                            </td>
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.endereco" (click)="desaprovarAlteracao('endereco')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.endereco" (click)="aprovarAlteracao('endereco', solicitacao.solicitacao_pessoa.endereco)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_pessoa.endereco_numero && (solicitacao.pessoa.endereco_numero !== solicitacao.solicitacao_pessoa.endereco_numero)">
                                            <td><span *ngIf="listaAlteracao.endereco_numero" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Número</b></td>
                                            <td>{{solicitacao.pessoa.endereco_numero}}</td>
                                            <td>{{solicitacao.solicitacao_pessoa.endereco_numero}}</td>                       
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.endereco_numero" (click)="desaprovarAlteracao('endereco_numero')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.endereco_numero" (click)="aprovarAlteracao('endereco_numero', solicitacao.solicitacao_pessoa.endereco_numero)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_pessoa.complemento && (solicitacao.pessoa.complemento !== solicitacao.solicitacao_pessoa.complemento)">
                                            <td><span *ngIf="listaAlteracao.complemento" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Complemento</b></td>
                                            <td><p class="quebra-linha">{{solicitacao.pessoa.complemento}}</p></td>
                                            <td>
                                                <textarea class="col-md-12" rows="5" cols="10" readonly style="resize: none; border: 0">
                                                    {{solicitacao.solicitacao_pessoa.complemento}}
                                                </textarea>
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.complemento" (click)="desaprovarAlteracao('complemento')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.complemento" (click)="aprovarAlteracao('complemento', solicitacao.solicitacao_pessoa.complemento)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>

                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_pessoa.sigla_uf && (solicitacao.pessoa.sigla_uf !== solicitacao.solicitacao_pessoa.sigla_uf)">
                                            <td><span *ngIf="listaAlteracao.sigla_uf" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Sigla</b></td>
                                            <td>{{solicitacao.pessoa.sigla_uf}}</td>
                                            <td>{{solicitacao.solicitacao_pessoa.sigla_uf}}</td>                       
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.sigla_uf" (click)="desaprovarAlteracao('sigla_uf')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.sigla_uf" (click)="aprovarAlteracao('sigla_uf', solicitacao.solicitacao_pessoa.sigla_uf)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_pessoa.cidade && (solicitacao.pessoa.cidade !== solicitacao.solicitacao_pessoa.cidade)">
                                            <td><span *ngIf="listaAlteracao.cidade" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Cidade</b></td>
                                            <td><p class="quebra-linha">{{solicitacao.pessoa.cidade}}</p></td>
                                            <td>
                                                <textarea class="col-md-12" rows="5" cols="10" readonly style="resize: none; border: 0">
                                                    {{solicitacao.solicitacao_pessoa.cidade}}
                                                </textarea>
                                            </td>
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.cidade" (click)="desaprovarAlteracao('cidade')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.cidade" (click)="aprovarAlteracao('cidade', solicitacao.solicitacao_pessoa.cidade)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_pessoa.bairro && (solicitacao.pessoa.bairro !== solicitacao.solicitacao_pessoa.bairro)">
                                            <td><span *ngIf="listaAlteracao.bairro" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Bairro</b></td>
                                            <td><p class="quebra-linha">{{solicitacao.pessoa.bairro}}</p></td>
                                            <td>
                                                <textarea class="col-md-12" rows="5" cols="10" readonly style="resize: none; border: 0">
                                                    {{solicitacao.solicitacao_pessoa.bairro}}
                                                </textarea>
                                            </td>
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.bairro" (click)="desaprovarAlteracao('bairro')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.bairro" (click)="aprovarAlteracao('bairro', solicitacao.solicitacao_pessoa.bairro)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>

                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_pessoa_endereco_correspondencia?.cep_correspondencia && (solicitacao.pessoa_endereco_correspondencia?.cep_correspondencia !== solicitacao.solicitacao_pessoa_endereco_correspondencia?.cep_correspondencia)">
                                            <td><span *ngIf="listaAlteracao.cep_correspondencia" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>CEP de Correspondência</b></td>
                                            <td>{{solicitacao.pessoa_endereco_correspondencia?.cep_correspondencia}}</td>
                                            <td>{{solicitacao.solicitacao_pessoa_endereco_correspondencia?.cep_correspondencia}}</td>                       
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.cep_correspondencia" (click)="desaprovarAlteracao('cep_correspondencia')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.cep_correspondencia" (click)="aprovarAlteracao('cep_correspondencia', solicitacao.solicitacao_pessoa_endereco_correspondencia?.cep_correspondencia)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>

                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_pessoa_endereco_correspondencia?.endereco_correspondencia && (solicitacao.pessoa_endereco_correspondencia?.endereco_correspondencia !== solicitacao.solicitacao_pessoa_endereco_correspondencia?.endereco_correspondencia)">
                                            <td><span *ngIf="listaAlteracao.endereco_correspondencia" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Endereço de Correspondência</b></td>
                                            <td><p class="quebra-linha">{{solicitacao.pessoa_endereco_correspondencia?.endereco_correspondencia}}</p></td>
                                            <td>
                                                <textarea class="col-md-12" rows="5" cols="10" readonly style="resize: none; border: 0">
                                                    {{solicitacao.solicitacao_pessoa_endereco_correspondencia?.endereco_correspondencia}}
                                                </textarea>
                                            </td>
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.endereco_correspondencia" (click)="desaprovarAlteracao('endereco_correspondencia')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.endereco_correspondencia" (click)="aprovarAlteracao('endereco_correspondencia', solicitacao.solicitacao_pessoa_endereco_correspondencia?.endereco_correspondencia)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_pessoa_endereco_correspondencia?.numero_correspondencia && (solicitacao.pessoa_endereco_correspondencia?.numero_correspondencia !== solicitacao.solicitacao_pessoa_endereco_correspondencia?.numero_correspondencia)">
                                            <td><span *ngIf="listaAlteracao.numero_correspondencia" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Número de Correspondência</b></td>
                                            <td>{{solicitacao.pessoa_endereco_correspondencia?.numero_correspondencia}}</td>
                                            <td>{{solicitacao.solicitacao_pessoa_endereco_correspondencia?.numero_correspondencia}}</td>                       
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.numero_correspondencia" (click)="desaprovarAlteracao('numero_correspondencia')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.numero_correspondencia" (click)="aprovarAlteracao('numero_correspondencia', solicitacao.solicitacao_pessoa_endereco_correspondencia?.numero_correspondencia)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_pessoa_endereco_correspondencia?.complemento_correspondencia && (solicitacao.pessoa_endereco_correspondencia?.complemento_correspondencia !== solicitacao.solicitacao_pessoa_endereco_correspondencia?.complemento_correspondencia)">
                                            <td><span *ngIf="listaAlteracao.complemento_correspondencia" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Complemento de Correspondência</b></td>
                                            <td><p class="quebra-linha">{{solicitacao.pessoa_endereco_correspondencia?.complemento_correspondencia}}</p></td>
                                            <td>
                                                <textarea class="col-md-12" rows="5" cols="10" readonly style="resize: none; border: 0">
                                                    {{solicitacao.solicitacao_pessoa_endereco_correspondencia?.complemento_correspondencia}}
                                                </textarea>
                                            </td>                       
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.complemento_correspondencia" (click)="desaprovarAlteracao('complemento_correspondencia')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.complemento_correspondencia" (click)="aprovarAlteracao('complemento_correspondencia', solicitacao.solicitacao_pessoa_endereco_correspondencia?.complemento_correspondencia)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_pessoa_endereco_correspondencia?.sigla_uf_correspondencia && (solicitacao.pessoa_endereco_correspondencia?.sigla_uf_correspondencia !== solicitacao.solicitacao_pessoa_endereco_correspondencia?.sigla_uf_correspondencia)">
                                            <td><span *ngIf="listaAlteracao.sigla_uf_correspondencia" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Sigla de Correspondência</b></td>
                                            <td>{{solicitacao.pessoa_endereco_correspondencia?.sigla_uf_correspondencia}}</td>
                                            <td>{{solicitacao.solicitacao_pessoa_endereco_correspondencia?.sigla_uf_correspondencia}}</td>                       
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.sigla_uf_correspondencia" (click)="desaprovarAlteracao('sigla_uf_correspondencia')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.sigla_uf_correspondencia" (click)="aprovarAlteracao('sigla_uf_correspondencia', solicitacao.solicitacao_pessoa_endereco_correspondencia?.sigla_uf_correspondencia)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_pessoa_endereco_correspondencia?.cidade_correspondencia && (solicitacao.pessoa_endereco_correspondencia?.cidade_correspondencia !== solicitacao.solicitacao_pessoa_endereco_correspondencia?.cidade_correspondencia)">
                                            <td><span *ngIf="listaAlteracao.cidade_correspondencia" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Cidade de Correspondência</b></td>
                                            <td><p class="quebra-linha">{{solicitacao.pessoa_endereco_correspondencia?.cidade_correspondencia}}</p></td>
                                            <td>
                                                <textarea class="col-md-12" rows="5" cols="10" readonly style="resize: none; border: 0">
                                                    {{solicitacao.solicitacao_pessoa_endereco_correspondencia?.cidade_correspondencia}}
                                                </textarea>
                                            </td>                       
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.cidade_correspondencia" (click)="desaprovarAlteracao('cidade_correspondencia')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.cidade_correspondencia" (click)="aprovarAlteracao('cidade_correspondencia', solicitacao.solicitacao_pessoa_endereco_correspondencia?.cidade_correspondencia)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_pessoa_endereco_correspondencia?.bairro_correspondencia && (solicitacao.pessoa_endereco_correspondencia?.bairro_correspondencia !== solicitacao.solicitacao_pessoa_endereco_correspondencia?.bairro_correspondencia)">
                                            <td><span *ngIf="listaAlteracao.bairro_correspondencia" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Bairro de Correspondência</b></td>
                                            <td><p class="quebra-linha">{{solicitacao.pessoa_endereco_correspondencia?.bairro_correspondencia}}</p></td>
                                            <td>
                                                <textarea class="col-md-12" rows="5" cols="10" readonly style="resize: none; border: 0">
                                                    {{solicitacao.solicitacao_pessoa_endereco_correspondencia?.bairro_correspondencia}}
                                                </textarea>
                                            </td>
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.bairro_correspondencia" (click)="desaprovarAlteracao('bairro_correspondencia')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.bairro_correspondencia" (click)="aprovarAlteracao('bairro_correspondencia', solicitacao.solicitacao_pessoa_endereco_correspondencia?.bairro_correspondencia)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_associado.codigo_lotacao && (solicitacao.associado.codigo_lotacao !== solicitacao.solicitacao_associado.codigo_lotacao)">
                                            <td><span *ngIf="listaAlteracao.codigo_lotacao" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Lotação</b></td>
                                            <td>{{solicitacao.associado.nome_lotacao}}</td>
                                            <td>{{solicitacao.solicitacao_associado.nome_lotacao}}</td>                       
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.codigo_lotacao" (click)="desaprovarAlteracao('codigo_lotacao')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.codigo_lotacao" (click)="aprovarAlteracao('codigo_lotacao', solicitacao.solicitacao_associado.codigo_lotacao)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_associado.data_ingresso_servico_publico && (solicitacao.associado.data_ingresso_servico_publico !== solicitacao.solicitacao_associado.data_ingresso_servico_publico)">
                                            <td><span *ngIf="listaAlteracao.data_ingresso_servico_publico" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Data de Ingresso no Serviço Público</b></td>
                                            <td>{{solicitacao.associado.data_ingresso_servico_publico | date: 'dd/MM/yyyy'}}</td>
                                            <td>{{solicitacao.solicitacao_associado.data_ingresso_servico_publico | date: 'dd/MM/yyyy'}}</td>
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.data_ingresso_servico_publico" (click)="desaprovarAlteracao('data_ingresso_servico_publico')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.data_ingresso_servico_publico" (click)="aprovarAlteracao('data_ingresso_servico_publico', solicitacao.solicitacao_associado.data_ingresso_servico_publico)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_associado.data_posse_dpf && (solicitacao.associado.data_posse_dpf !== solicitacao.solicitacao_associado.data_posse_dpf)">
                                            <td><span *ngIf="listaAlteracao.data_posse_dpf" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Data de Ingresso no Serviço Público</b></td>
                                            <td>{{solicitacao.associado.data_posse_dpf | date: 'dd/MM/yyyy'}}</td>
                                            <td>{{solicitacao.solicitacao_associado.data_posse_dpf | date: 'dd/MM/yyyy'}}</td>
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.data_posse_dpf" (click)="desaprovarAlteracao('data_posse_dpf')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.data_posse_dpf" (click)="aprovarAlteracao('data_posse_dpf', solicitacao.solicitacao_associado.data_posse_dpf)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_associado.aderiu_funpresp && (solicitacao.associado.aderiu_funpresp != solicitacao.solicitacao_associado.aderiu_funpresp)">
                                            <td><span *ngIf="listaAlteracao.aderiu_funpresp" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Aderiu ao Funpresp</b></td>
                                            <td><span *ngIf="solicitacao.associado.aderiu_funpresp == 'S'">Sim</span><span *ngIf="solicitacao.associado.aderiu_funpresp == 'N'">Não</span></td>
                                            <td><span *ngIf="solicitacao.solicitacao_associado.aderiu_funpresp == 'S'">Sim</span><span *ngIf="solicitacao.solicitacao_associado.aderiu_funpresp == 'N'">Não</span></td>
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.aderiu_funpresp" (click)="desaprovarAlteracao('aderiu_funpresp')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.aderiu_funpresp" (click)="aprovarAlteracao('aderiu_funpresp', solicitacao.solicitacao_associado.aderiu_funpresp)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_associado.egresso_policia_militar && (solicitacao.associado.egresso_policia_militar != solicitacao.solicitacao_associado.egresso_policia_militar)">
                                            <td><span *ngIf="listaAlteracao.egresso_policia_militar" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Egresso Polícia Militar</b></td>
                                            <td><span *ngIf="solicitacao.associado.egresso_policia_militar == 'S'">Sim</span><span *ngIf="solicitacao.associado.egresso_policia_militar == 'N'">Não</span></td>
                                            <td><span *ngIf="solicitacao.solicitacao_associado.egresso_policia_militar == 'S'">Sim</span><span *ngIf="solicitacao.solicitacao_associado.egresso_policia_militar == 'N'">Não</span></td>
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.egresso_policia_militar" (click)="desaprovarAlteracao('egresso_policia_militar')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.egresso_policia_militar" (click)="aprovarAlteracao('egresso_policia_militar', solicitacao.solicitacao_associado.egresso_policia_militar)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_associado.egresso_bombeiro && (solicitacao.associado.egresso_bombeiro != solicitacao.solicitacao_associado.egresso_bombeiro)">
                                            <td><span *ngIf="listaAlteracao.egresso_bombeiro" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Egresso Bombeiro</b></td>
                                            <td><span *ngIf="solicitacao.associado.egresso_bombeiro == 'S'">Sim</span><span *ngIf="solicitacao.associado.egresso_bombeiro == 'N'">Não</span></td>
                                            <td><span *ngIf="solicitacao.solicitacao_associado.egresso_bombeiro == 'S'">Sim</span><span *ngIf="solicitacao.solicitacao_associado.egresso_bombeiro == 'N'">Não</span></td>
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.egresso_bombeiro" (click)="desaprovarAlteracao('egresso_bombeiro')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.egresso_bombeiro" (click)="aprovarAlteracao('egresso_bombeiro', solicitacao.solicitacao_associado.egresso_bombeiro)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_associado.egresso_policia_civil && (solicitacao.associado.egresso_policia_civil != solicitacao.solicitacao_associado.egresso_policia_civil)">
                                            <td><span *ngIf="listaAlteracao.egresso_policia_civil" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Egresso Polícia Civil</b></td>
                                            <td><span *ngIf="solicitacao.associado.egresso_policia_civil == 'S'">Sim</span><span *ngIf="solicitacao.associado.egresso_policia_civil == 'N'">Não</span></td>
                                            <td><span *ngIf="solicitacao.solicitacao_associado.egresso_policia_civil == 'S'">Sim</span><span *ngIf="solicitacao.solicitacao_associado.egresso_policia_civil == 'N'">Não</span></td>
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.egresso_policia_civil" (click)="desaprovarAlteracao('egresso_policia_civil')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.egresso_policia_civil" (click)="aprovarAlteracao('egresso_policia_civil', solicitacao.solicitacao_associado.egresso_policia_civil)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_associado.egresso_forcas_armadas && (solicitacao.associado.egresso_forcas_armadas != solicitacao.solicitacao_associado.egresso_forcas_armadas)">
                                            <td><span *ngIf="listaAlteracao.egresso_forcas_armadas" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Egresso Forças Armadas</b></td>
                                            <td><span *ngIf="solicitacao.associado.egresso_forcas_armadas == 'S'">Sim</span><span *ngIf="solicitacao.associado.egresso_forcas_armadas == 'N'">Não</span></td>
                                            <td><span *ngIf="solicitacao.solicitacao_associado.egresso_forcas_armadas == 'S'">Sim</span><span *ngIf="solicitacao.solicitacao_associado.egresso_forcas_armadas == 'N'">Não</span></td>
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.egresso_forcas_armadas" (click)="desaprovarAlteracao('egresso_forcas_armadas')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.egresso_forcas_armadas" (click)="aprovarAlteracao('egresso_forcas_armadas', solicitacao.solicitacao_associado.egresso_forcas_armadas)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_associado.codigo_titulacao_academica && (solicitacao.associado.codigo_titulacao_academica !== solicitacao.solicitacao_associado.codigo_titulacao_academica)">
                                            <td><span *ngIf="listaAlteracao.codigo_titulacao_academica" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Titulação Acadêmica</b></td>
                                            <td>{{solicitacao.associado.nome_titulacao_academica}}</td>
                                            <td>{{solicitacao.solicitacao_associado.nome_titulacao_academica}}</td>                       
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.codigo_titulacao_academica" (click)="desaprovarAlteracao('codigo_titulacao_academica')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.codigo_titulacao_academica" (click)="aprovarAlteracao('codigo_titulacao_academica', solicitacao.solicitacao_associado.codigo_titulacao_academica)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_associado.autoriza_disponibilizar_contato && (solicitacao.associado.autoriza_disponibilizar_contato !== solicitacao.solicitacao_associado.autoriza_disponibilizar_contato)">
                                            <td><span *ngIf="listaAlteracao.autoriza_disponibilizar_contato" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Autoriza Disponibilizar Contato</b></td>
                                            <td><span *ngIf="solicitacao.associado.autoriza_disponibilizar_contato == 1">Sim</span><span *ngIf="solicitacao.associado.autoriza_disponibilizar_contato == 0">Não</span></td>
                                            <td><span *ngIf="solicitacao.solicitacao_associado.autoriza_disponibilizar_contato == 1">Sim</span><span *ngIf="solicitacao.solicitacao_associado.autoriza_disponibilizar_contato == 0">Não</span></td>
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.autoriza_disponibilizar_contato" (click)="desaprovarAlteracao('autoriza_disponibilizar_contato')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.autoriza_disponibilizar_contato" (click)="aprovarAlteracao('autoriza_disponibilizar_contato', solicitacao.solicitacao_associado.autoriza_disponibilizar_contato)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        <tr class="odd gradeX" *ngIf="solicitacao.solicitacao_associado.exerce_pericia_particular && (solicitacao.associado.exerce_pericia_particular !== solicitacao.solicitacao_associado.exerce_pericia_particular)">
                                            <td><span *ngIf="listaAlteracao.exerce_pericia_particular" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Exerce Perícia Particular</b></td>
                                            <td><span *ngIf="solicitacao.associado.exerce_pericia_particular == 1">Sim</span><span *ngIf="solicitacao.associado.exerce_pericia_particular == 0">Não</span></td>
                                            <td><span *ngIf="solicitacao.solicitacao_associado.exerce_pericia_particular == 1">Sim</span><span *ngIf="solicitacao.solicitacao_associado.exerce_pericia_particular == 0">Não</span></td>
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao.exerce_pericia_particular" (click)="desaprovarAlteracao('exerce_pericia_particular')"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao.exerce_pericia_particular" (click)="aprovarAlteracao('exerce_pericia_particular', solicitacao.solicitacao_associado.exerce_pericia_particular)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>
                                        
                                        <!--<tr class="odd gradeX" *ngFor="let dado of solicitacao_associado_area_atuacao; let contador = index">
                                            <td><span *ngIf="listaAlteracao[dado.codigo_area_atuacao]" style="font-size: 22px; color: green;" class="icon mdi mdi-check-circle"></span></td>
                                            <td><b>Área de Atuação</b></td>
                                            <td>&nbsp;</td>
                                            <td>{{dado.areaatuacao.nome}}</td>
                                            <td class="text-center" style="font-size: 22px;">
                                                <a *ngIf="listaAlteracao[dado.codigo_area_atuacao]" (click)="desaprovarAlteracao(dado.codigo_area_atuacao)"><span  class="icon mdi mdi-check-square"></span></a>
                                                <a *ngIf="!listaAlteracao[dado.codigo_area_atuacao]" (click)="aprovarAlteracao(dado.codigo_area_atuacao, dado.codigo_area_atuacao)"><span class="icon mdi mdi-square-o"></span></a>
                                            </td>
                                        </tr>-->
                                        

                                    </tbody>
                                    <tbody *ngIf="!solicitacao">
                                        <tr class="carregando">
                                            <td class="carregando" colspan="5">
                                                <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-md-2">
                        <button data-toggle="modal" data-target="#md-confirmacao-cancelamento" type="button" class="btn btn-danger">Cancelar todos</button>
                    </div>
                    <div class="col-md-10">
                        <button type="button" data-dismiss="modal" class="btn btn-default">Fechar</button>
                        <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#md-confirmacao">Confirmar</button>&nbsp;&nbsp;
                        <img *ngIf="carregando" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--Modal Footer-->
<div id="md-confirmacao" tabindex="-1" role="dialog" style="" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <div class="text-warning"><span class="modal-main-icon mdi mdi-alert-triangle"></span></div>
                <h3>ATENÇÃO: Os itens que não foram marcados serão automaticamente eliminados e não poderão ser recuperados posteriormente. Confirma a aprovação dos itens marcados?</h3>
                <p>Esta operação não poderá ser desfeita.</p>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default">Cancelar</button>
                <button type="button" data-dismiss="modal" class="btn btn-warning" (click)="confirmar(false)">Confirmar</button>
            </div>
        </div>
    </div>
</div>

<!--Modal Footer-->
<div id="md-confirmacao-cancelamento" tabindex="-1" role="dialog" style="" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <div class="text-danger"><span class="modal-main-icon mdi mdi-alert-triangle"></span></div>
                <h3>Esta opção CANCELA TODAS AS ALTERAÇÕES REALIZADAS NESTA SOLICITAÇÃO. Você confirma esta operação?</h3>
                <p>Esta operação não poderá ser desfeita.</p>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default">Cancelar</button>
                <button type="button" data-dismiss="modal" class="btn btn-danger" (click)="confirmar(true)">Confirmar</button>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>