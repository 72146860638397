<app-header></app-header>

<div class="row wizard-row">
  <div class="col-md-12 fuelux">
    <div class="block-wizard panel panel-default">
      <div id="wizard1" class="wizard wizard-ux">
        <ul class="steps">
          <li data-step="1" class="active">Dados Iniciais<span class="chevron"></span></li>
        </ul>
        <div class="step-content">

          <div data-step="1" class="step-pane active">
            <form #associadoForm="ngForm" [formGroup]="form" id="form" data-parsley-namespace="data-parsley-" data-parsley-validate="" novalidate="" class="form-horizontal group-border-dashed">
              <div class="form-group" [class.has-error]="(f.nome.touched || submited) && f.nome.invalid == true">
                <label class="col-sm-3 control-label">Nome <span style="color: red;" *ngIf="!aprovado || aprovado != 'S'">*</span></label>
                <div class="col-sm-6">
                  <input type="text" placeholder="Nome" class="form-control"  formControlName="nome">
                  <app-error-msg [control]="form.get('nome')" label="Nome"></app-error-msg>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-3 control-label">E-mail </label>
                <div class="col-sm-6">
                  <input type="text" placeholder="E-mail" class="form-control" formControlName="email">
                  <app-error-msg [control]="form.get('email')" label="E-mail"></app-error-msg>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-offset-3 col-sm-9">
                  <button class="btn btn-space btn-default" routerLink="/associado-listar">Cancelar</button>
                  <button data-wizard="#wizard1" class="btn btn-primary btn-space wizard-next" (click)="onSubmit()" [disabled]="carregando_salvar">Cadastrar</button>&nbsp;&nbsp;&nbsp;&nbsp;
                  <img *ngIf="carregando_salvar" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>