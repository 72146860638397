import { FormArray, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import * as moment from 'moment';


export class FormValidation {

  static requiredMinCheckbox(min = 1) {
    const validator = (formArray: FormArray) => {
      const totalChecked = formArray.controls
        .map(v => v.value)
        .reduce((total, current) => current ? total + current : total, 0);
      return totalChecked >= min ? null : { required: true };
    };
    return validator;
  }

  static cpfValidator(control: FormControl) {

      const cpf = control.value;

      if (cpf == null) {
          return null;
      }
      if (cpf.length != 11) {
          return null;
      }
      
      if ((cpf == '00000000000') || (cpf == '11111111111') || (cpf == '22222222222') || (cpf == '33333333333') || (cpf == '44444444444') || (cpf == '55555555555') || (cpf == '66666666666') || (cpf == '77777777777') || (cpf == '88888888888') || (cpf == '99999999999')) {
          return { cpfInvalido : true };
      }
      
      let numero: number = 0;
      let caracter: string = '';
      let numeros: string = '0123456789';
      let j: number = 10;
      let somatorio: number = 0;
      let resto: number = 0;
      let digito1: number = 0;
      let digito2: number = 0;
      let cpfAux: string = '';
      cpfAux = cpf.substring(0, 9);
      for (let i: number = 0; i < 9; i++) {
          caracter = cpfAux.charAt(i);
          if (numeros.search(caracter) == -1) {
              return null;
          }
          numero = Number(caracter);
          somatorio = somatorio + (numero * j);
          j--;
      }
      resto = somatorio % 11;
      digito1 = 11 - resto;
      if (digito1 > 9) {
          digito1 = 0;
      }
      j = 11;
      somatorio = 0;
      cpfAux = cpfAux + digito1;
      for (let i: number = 0; i < 10; i++) {
          caracter = cpfAux.charAt(i);
          numero = Number(caracter);
          somatorio = somatorio + (numero * j);
          j--;
      }
      resto = somatorio % 11;
      digito2 = 11 - resto;
      if (digito2 > 9) {
          digito2 = 0;
      }
      cpfAux = cpfAux + digito2;
      return (cpf != cpfAux) ? { cpfInvalido : true } : null;
  }

  static EmailDpfValidator(control: FormControl) {
    const email = control.value;
    if (email && email !== '') {
      const validaemail = /^[0-9]{8}$/;
      return (email.indexOf(".gov") == -1) ? null : { emailDpfInvalido : true };
    }
    return null;
  }

  static EmailPermitidoValidator(control: FormControl) {
    const email = control.value;
    if (email && email !== '') {
      const validaemail = /^[0-9]{8}$/;
      return (
              (email.indexOf("@gmail.") != -1)
             || (email.indexOf("@hotmail.") != -1) 
             || (email.indexOf("@outlook.") != -1) 
             || (email.indexOf("@yahoo.") != -1)
             || (email.indexOf("@advicetecnologia.") != -1)
             || (email.indexOf("@stagesoftware.") != -1)
             || (email.indexOf("@mohmal.") != -1)
             || (email.indexOf("@uorak.") != -1)
              ) ? null : { emailPermitidoInvalido : true };
    }
    return null;
  }

  static cepValidator(control: FormControl) {
    const cep = control.value;
    if (cep && cep !== '') {
      const validacep = /^[0-9]{8}$/;
      return validacep.test(cep) ? null : { cepInvalido : true };
    }
    return null;
  }

  static pisPasepValidator(control: FormControl) {
    const pis = control.value;
    console.log(pis);
    if (pis && pis !== '' && pis != null) {
      var multiplicadorBase = "3298765432";
      var total = 0;
      var resto = 0;
      var multiplicando = 0;
      var multiplicador = 0;
      var digito = 99;
      
      // Retira a mascara
      if(pis) {
        var numeroPIS = pis.replace(/[^\d]+/g, '');
      } else {
        return { pisPasepInvalido : true }
      }

      if (numeroPIS.length != 11 || 
          numeroPIS === "00000000000" || 
          numeroPIS === "11111111111" || 
          numeroPIS === "22222222222" || 
          numeroPIS === "33333333333" || 
          numeroPIS === "44444444444" || 
          numeroPIS === "55555555555" || 
          numeroPIS === "66666666666" || 
          numeroPIS === "77777777777" || 
          numeroPIS === "88888888888" || 
          numeroPIS === "99999999999") {
          return { pisPasepInvalido : true };
      } else {
          for (var i = 0; i < 10; i++) {
              multiplicando = parseInt( numeroPIS.substring( i, i + 1 ) );
              multiplicador = parseInt( multiplicadorBase.substring( i, i + 1 ) );
              total += multiplicando * multiplicador;
          }

          resto = 11 - total % 11;
          resto = resto === 10 || resto === 11 ? 0 : resto;

          digito = parseInt("" + numeroPIS.charAt(10));
          return (resto === digito) ? null : { pisPasepInvalido : true };
      }
    }
    return null;

  }

  static equalsTo(otherField: string) {
    const validator = (formControl: FormControl) => {
      if (otherField == null) {
        throw new Error('É necessário informar um campo.');
      }

      if (!formControl.root || !(<FormGroup>formControl.root).controls) {
        return null;
      }

      const field = (<FormGroup>formControl.root).get(otherField);

      if (!field) {
        throw new Error('É necessário informar um campo válido.');
      }

      if (field.value !== formControl.value) {
        return { equalsTo : otherField };
      }

      return null;
    };
    return validator;
  }

  static dataValidator(control: FormControl) {
    let result: ValidationErrors = null;
    const dataValidacao = control.value;
    if (dataValidacao == null) {
      return null;
    }
    const isValid = moment(dataValidacao, 'DD/MM/YYYY').isValid();
    if (!isValid) {
        result = { dataInvalida: true };
    }
    return result;
  }

  static dataAtualValidator(control: FormControl) {
    const data_form = control.value;
    if (data_form || data_form !== '') {
      const data_posse = moment(data_form, "DD/MM/YYYY", "pt", true);
      if(!data_posse.isValid()) {
        return { dataInvalida : true };
      } else {
        const now = moment().format("DD/MM/YYYY");
        console.log('NOW: ' + now + ' DATA: ' + data_posse.format("DD/MM/YYYY"));
        
        return moment(data_posse, "DD/MM/YYYY", "pt", true).isBefore(now) ? null : { dataAtualInvalida : true };
      }
    }
    return null;
  }

  static siapePensionistaValidator(control: FormControl) {
    const minlength = control.value;
    if (minlength == null) {
      return null;
    }

    return (minlength.length == 8) ? null : {siapePensionistaInvalido : true};
  }

  static siapeAtivoValidator(control: FormControl) {
    const minlength = control.value;   
    if (minlength == null) {
      return null;
    }

    return (minlength.length == 7) ? null : {siapeAtivoInvalido : true};
  }

  static contratoSerproValidator(control: FormControl) {
    const minlength = control.value;
    if (minlength == null) {
      return null;
    }

    return (minlength.length == 20) ? null : {contratoSerproInvalido : true};
  }

  static getErrorMsg(fieldName: string, validatorName: string, validatorValue?: any) {
    const config = {
      'required': `${fieldName} é obrigatório.`,
      'minlength': `${fieldName} precisa ter no mínimo ${validatorValue.requiredLength} caracteres.`,
      'maxlength': `${fieldName} precisa ter no máximo ${validatorValue.requiredLength} caracteres.`,
      'emailDpfInvalido': 'Não pode ser e-mail institucional.',
      'pisPasepInvalido': 'PIS/PASEP inválido.',
      'cepInvalido': 'CEP inválido.',
      'dataInvalida': 'Data inválida.',
      'dataAtualInvalida': 'Data não pode ser maior que data atual.',
      'cpfInvalido': 'Não é um CPF válido.',
      'siapePensionistaInvalido': 'SIAPE para Pensionista deve ter 8 dígitos.',
      'siapeAtivoInvalido': 'SIAPE para Ativo / Aposentado deve ter 7 dígitos.',
      'contratoSerproInvalido': 'Contrato SERPRO deve ter 20 caracteres.',
      'emailInvalido': 'Email já cadastrado.',
      'emailPermitidoInvalido': 'E-mails permitidos: Gmail, Hotmail, Outlook ou Yahoo.',
      'equalsTo': 'Campos não são iguais',
      'pattern': 'Campo inválido'
    };

    return config[validatorName];
  }
}
