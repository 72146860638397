<app-header></app-header>

<div class="row wizard-row">
  <div class="col-md-12 fuelux">
    <div class="block-wizard panel panel-default">
      <div id="wizard1" class="wizard wizard-ux">
        <ul class="steps" *ngIf="this.aprovado">
          <li data-step="1" class="active"><a href="dados-pessoais/{{codigo_pessoa}}">Dados Pessoais</a><span class="chevron"></span></li>
          <li data-step="2"><a href="dados-financeiros/{{codigo_pessoa}}">Dados Financeiros</a><span class="chevron"></span></li>
          <li data-step="3"><a href="dados-administrativos/{{codigo_pessoa}}">Dados Administrativos</a><span class="chevron"></span></li>
          <li data-step="4"><a href="dados-controle/{{codigo_pessoa}}">Dados de Controle</a><span class="chevron"></span></li>
          <li data-step="5"><a href="dados-complementares/{{codigo_pessoa}}">Dados Complementares</a><span class="chevron"></span></li>
        </ul>
        <ul class="steps" *ngIf="!this.aprovado">
          <li data-step="1" class="active"><a href="dados-pessoais/{{codigo_pessoa}}">Dados Pessoais</a><span class="chevron"></span></li>
          <li data-step="2">Dados Financeiros<span class="chevron"></span></li>
          <li data-step="3">Dados Administrativos<span class="chevron"></span></li>
          <li data-step="4">Dados de Controle<span class="chevron"></span></li>
          <li data-step="5">Dados Complementares<span class="chevron"></span></li>
        </ul>
        <div class="step-content">

          <div *ngIf="carregando" style="text-align: center;"><img src="/assets/img/carregando_tabela.gif"></div>
          <div data-step="1" class="step-pane active" *ngIf="!carregando">
            <form #associadoForm="ngForm" [formGroup]="form" id="form" data-parsley-namespace="data-parsley-" data-parsley-validate="" novalidate="" class="form-horizontal group-border-dashed">
              <div class="form-group" [class.has-error]="(f.tipo.touched || submited) && f.tipo.invalid == true">
                <label class="col-sm-3 control-label">Tipo de Associado <span style="color: red;" *ngIf="!aprovado || aprovado != 'S'">*</span></label>
                <div class="col-sm-6">
                  <div class="be-radio inline" *ngIf="!pessoa_fisica || (pessoa_fisica && tipo_associado != 'PE')">
                    <input type="radio" id="rad6" formControlName="tipo" value="AT" (click)="definirTipoAssociado('AT');">
                    <label for="rad6">Ativo</label>
                  </div>
                  <div class="be-radio inline" *ngIf="!pessoa_fisica || (pessoa_fisica && tipo_associado != 'PE')">
                    <input type="radio" id="rad7" formControlName="tipo" value="AP" (click)="definirTipoAssociado('AP');">
                    <label for="rad7">Aposentado</label>
                  </div>
                  <div class="be-radio inline" *ngIf="!pessoa_fisica || (pessoa_fisica && tipo_associado == 'PE')" >
                    <input type="radio" id="rad8" formControlName="tipo" value="PE" (click)="definirTipoAssociado('PE');" >
                    <label for="rad8">Pensionista</label>
                  </div>
                </div>
              </div>
              <div class="form-group" *ngIf="tipo_associado == 'AP'" [class.has-error]="(f.data_aposentadoria.touched || submited) && f.data_aposentadoria.invalid == true">
                <label class="col-sm-3 control-label">Data da Aposentadoria <span style="color: red;" *ngIf="!aprovado || aprovado != 'S'">*</span></label>
                <div class="col-sm-2">
                  <input type="text" placeholder="DD/MM/YYYY" class="form-control" [dropSpecialCharacters]="false" mask="00/00/0000" formControlName="data_aposentadoria">
                  <app-error-msg [control]="form.get('data_aposentadoria')" label="Data da Posse DPF"></app-error-msg>
                </div>
              </div>
              <div class="form-group" [class.has-error]="(f.siape.touched || submited) && f.siape.invalid == true" *ngIf="tipo_associado != 'PE'">
                <label class="col-sm-3 control-label">
                  SIAPE <span style="color: red;" *ngIf="!aprovado || aprovado != 'S'">*</span>
                </label>
                <div class="col-sm-2">
                  <input type="text" placeholder="SIAPE" class="form-control" [dropSpecialCharacters]="true" mask="0000000" formControlName="siape" >
                  <!-- Servidor = 7 digitos e pensionistas 8 digitos -->
                  <app-error-msg [control]="form.get('siape')" label="SIAPE"></app-error-msg>
                </div>
              </div>
              <div class="form-group" [class.has-error]="(f.siape.touched || submited) && f.siape.invalid == true" *ngIf="tipo_associado == 'PE'">
                <label class="col-sm-3 control-label">
                  SIAPE <span style="color: red;" *ngIf="!aprovado || aprovado != 'S'">*</span>
                </label>
                <div class="col-sm-2">
                  <input type="text" placeholder="SIAPE" class="form-control" [dropSpecialCharacters]="true" mask="00000000" formControlName="siape" >
                  <!-- Servidor = 7 digitos e pensionistas 8 digitos -->
                  <app-error-msg [control]="form.get('siape')" label="SIAPE"></app-error-msg>
                </div>
              </div>
              <div class="form-group" [class.has-error]="(f.matricula_dpf.touched || submited) && f.matricula_dpf.invalid == true" *ngIf="tipo_associado != 'PE'">
                <label class="col-sm-3 control-label">Matrícula PF <span style="color: red;" *ngIf="!aprovado || aprovado != 'S'">*</span></label>
                <div class="col-sm-2">
                  <input type="text" placeholder="Matrícula PF" class="form-control" maxlength="8" formControlName="matricula_dpf" >
                  <app-error-msg [control]="form.get('matricula_dpf')" label="Matrícula PF"></app-error-msg>
                </div>
              </div>
              <div class="form-group" [class.has-error]="(f.data_posse_dpf.touched || submited) && f.data_posse_dpf.invalid == true" *ngIf="tipo_associado != 'PE'">
                <label class="col-sm-3 control-label">Data da Posse PF <span style="color: red;" *ngIf="!aprovado || aprovado != 'S'">*</span></label>
                <div class="col-sm-2">
                  <input type="text" placeholder="DD/MM/YYYY" class="form-control" [dropSpecialCharacters]="false" mask="00/00/0000" formControlName="data_posse_dpf" >
                  <app-error-msg [control]="form.get('data_posse_dpf')" label="Data da Posse DPF"></app-error-msg>
                </div>
              </div>
              <div class="form-group" [class.has-error]="(f.codigo_classe.touched || submited) && f.codigo_classe.invalid == true" *ngIf="tipo_associado != 'PE'">
                <label class="col-sm-3 control-label">Classe <span style="color: red;" *ngIf="!aprovado || aprovado != 'S'">*</span></label>
                <div class="col-sm-2">
                  <select class="select2 form-control" formControlName="codigo_classe">
                    <option *ngFor="let classe of classeAssociado" value="{{classe.codigo_classe}}">
                      {{classe.nome}}
                    </option>
                  </select>
                  <app-error-msg [control]="form.get('codigo_classe')" label="Classe"></app-error-msg>
                </div>
              </div>
              <div class="form-group" [class.has-error]="(f.nome.touched || submited) && f.nome.invalid == true">
                <label class="col-sm-3 control-label">Nome <span style="color: red;" *ngIf="!aprovado || aprovado != 'S'">*</span></label>
                <div class="col-sm-6">
                  <input type="text" placeholder="Nome" class="form-control"  formControlName="nome">
                  <app-error-msg [control]="form.get('nome')" label="Nome"></app-error-msg>
                </div>
              </div>
              <div class="form-group" *ngIf="tipo_associado != 'PE'">
                <label class="col-sm-3 control-label">Nome de Guerra </label>
                <div class="col-sm-6">
                  <input type="text" placeholder="Nome de Guerra" class="form-control" formControlName="nome_guerra">
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-3 control-label">Nome da Mãe</label>
                <div class="col-sm-6">
                  <input type="text" placeholder="Nome da Mãe" class="form-control" formControlName="nome_mae">
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-3 control-label">Nome do Pai</label>
                <div class="col-sm-6">
                  <input type="text" placeholder="Nome do Pai" class="form-control" formControlName="nome_pai">
                </div>
              </div>
              <div class="form-group" *ngIf="tipo_associado != 'PE'">
                  <label class="col-sm-3 control-label">Tipo Sanguineo </label>
                  <div class="col-sm-2">
                    <select class="select2 form-control" formControlName="codigo_tipo_sanguineo">
                      <option *ngFor="let dado of tipoSanguineo" value="{{dado.codigo_tipo_sanguineo}}">
                        {{dado.nome}}
                      </option>
                    </select>
                  </div>
                </div>
              <div class="form-group" [class.has-error]="(f.data_nascimento.touched || submited) && f.data_nascimento.invalid == true">
                <label class="col-sm-3 control-label">Data de Nascimento <span style="color: red;" *ngIf="!aprovado || aprovado != 'S'">*</span></label>
                <div class="col-sm-2">
                  <input type="text" placeholder="DD/MM/YYYY" class="form-control" [dropSpecialCharacters]="false" mask="00/00/0000" formControlName="data_nascimento" >
                  <app-error-msg [control]="form.get('data_nascimento')" label="Data de Nascimento"></app-error-msg>
                </div>
              </div>
              <div class="form-group" [class.has-error]="(f.sexo.touched || submited) && f.sexo.invalid == true">
                <label class="col-sm-3 control-label xs-pt-20">Gênero <span style="color: red;">*</span></label>
                <div class="col-sm-6">
                  <div class="be-radio-icon inline">
                    <input type="radio" id="rad1" formControlName="sexo" value="F">
                    <label for="rad1"><span class="mdi mdi-female"></span></label>
                  </div>
                  <div class="be-radio-icon inline">
                    <input type="radio" id="rad2" formControlName="sexo" value="M">
                    <label for="rad2"><span class="mdi mdi-male-alt"></span></label>
                  </div>
                  <app-error-msg [control]="form.get('sexo')" label="Gênero"></app-error-msg>
                </div>
              </div>
              <div class="form-group" [class.has-error]="(f.cpf.touched || submited) && f.cpf.invalid == true">
                <label class="col-sm-3 control-label">
                  CPF <span style="color: red;" *ngIf="!aprovado || aprovado != 'S'">*</span>
                </label>
                <div class="col-sm-4">
                  <input type="text" placeholder="999.999.999-99" class="form-control" [dropSpecialCharacters]="true" mask="000.000.000-00" formControlName="cpf" >
                  <app-error-msg [control]="form.get('cpf')" label="CPF"></app-error-msg>
                </div>
              </div>
              <div class="form-group" [class.has-error]="(f.cpf_conjuge_falecido.touched || submited) && f.cpf_conjuge_falecido.invalid == true" *ngIf="tipo_associado == 'PE'">
                <label class="col-sm-3 control-label">
                  CPF do Cônjuge <span style="color: red;">*</span>
                </label>
                <div class="col-sm-4">
                  <input type="text" placeholder="999.999.999-99" class="form-control" [dropSpecialCharacters]="true" mask="000.000.000-00" formControlName="cpf_conjuge_falecido">
                  <app-error-msg [control]="form.get('cpf_conjuge_falecido')" label="CPF do Falecido"></app-error-msg>
                </div>
              </div>
              <div class="form-group" [class.has-error]="(f.cpf.touched || submited) && f.rg.invalid == true">
                <label class="col-sm-3 control-label">
                  RG <span style="color: red;" *ngIf="!aprovado || aprovado != 'S'">*</span>
                </label>
                <div class="col-sm-4">
                  <input type="text" placeholder="RG" class="form-control" formControlName="rg" >
                  <app-error-msg [control]="form.get('rg')" label="RG"></app-error-msg>
                </div>
              </div>
              <div class="form-group" [class.has-error]="(f.pis_pasep.touched || submited) && f.pis_pasep.invalid == true" *ngIf="tipo_associado != 'PE'">
                <label class="col-sm-3 control-label">
                  PIS/PASEP 
                </label>
                <div class="col-sm-4">
                  <input type="text" placeholder="PIS/PASEP" class="form-control" [dropSpecialCharacters]="true" mask="000.0000.000-0" formControlName="pis_pasep" >
                  <app-error-msg [control]="form.get('pis_pasep')" label="PIS/PASEP"></app-error-msg>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-offset-3 col-sm-9">
                  <a href="dados-financeiros/{{codigo_pessoa}}" data-wizard="#wizard1" class="btn btn-primary btn-space wizard-next">Avançar</a>&nbsp;&nbsp;&nbsp;&nbsp;
                  <button data-wizard="#wizard1" class="btn btn-primary btn-space wizard-next" (click)="onSubmit()">Salvar</button>&nbsp;&nbsp;&nbsp;&nbsp;
                  <button data-wizard="#wizard1" class="btn btn-success btn-space wizard-next" (click)="onSubmit(true)">Salvar e Avançar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>