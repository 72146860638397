<app-header></app-header>

<div class="row">
    <div class="col-sm-12">
        <div class="panel panel-default panel-table">
            <div class="panel-heading">

            </div>
            <div class="panel-body">

                <div class="dataTables_length" id="table3_length">
                    <!--                    <div class="col-sm-12">-->
                    <!--                        <div class="dt-buttons btn-group">-->
                    <!--                            <div class="tools">-->
                    <!--                                <span class="panel-subtitle">-->
                    <!--                                  <a routerLink="/layout/cabecalho/form" class="btn btn-default buttons-copy buttons-html5" tabindex="0" aria-controls="table3">-->
                    <!--                                    <span>Novo cabe&ccedil;alho</span>-->
                    <!--                                  </a>-->
                    <!--                                </span>-->
                    <!--                            </div>-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                    <ul class="nav nav-tabs" *ngIf="tipos_layout.length > 0">
                        <li role="presentation" id="nav-{{tipo_layout.sigla}}-tab" data-toggle="tab" *ngFor="let tipo_layout of tipos_layout; let i = index" [ngClass]="{'active': i == 0}">
                            <a href="#nav-{{tipo_layout.sigla}}" data-toggle="tab">{{tipo_layout.descricao}}</a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade" *ngFor="let tipo_layout of tipos_layout; let i = index" [ngClass]="{'in active': i == 0}" id="nav-{{tipo_layout.sigla}}" role="tabpanel">
                            <table id="table2" class="table table-striped table-hover table-fw-widget" *ngIf="carregado">
                                <thead>
                                    <tr>
                                        <th>T&iacute;tulo</th>
                                        <th>Data cria&ccedil;&atilde;o</th>
                                        <th>Data exclus&atilde;o</th>
                                        <th class="text-right">A&ccedil;&otilde;es</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="cabecalhos_layout && cabecalhos_layout[tipo_layout.sigla].length > 0">
                                    <tr class="odd gradeX" *ngFor="let cabecalho_layout of cabecalhos_layout[tipo_layout.sigla]">
                                        <td [ngClass]="{'text-bold': !cabecalho_layout.deleted_at}">{{cabecalho_layout.titulo}}</td>
                                        <td [ngClass]="{'text-bold': !cabecalho_layout.deleted_at}">{{cabecalho_layout.created_at | date: 'dd/MM/yyyy hh:mm:ss'}}</td>
                                        <td [ngClass]="{'text-bold': !cabecalho_layout.deleted_at}" class="text-bold">{{cabecalho_layout.deleted_at ? (cabecalho_layout.deleted_at | date: 'dd/MM/yyyy hh:mm:ss') : '-'}}</td>
                                        <td style="text-align: right;">
                                            <button type="button" class="btn btn-space btn-warning" title="Visualizar campos" alt="Visualizar campos" (click)="setCampos(cabecalho_layout, md_cabecalho_detail)">
                                                <i class="glyphicon glyphicon-eye-open"></i>
                                            </button>
                                            <button type="button" class="btn btn-space btn-danger" title="Copiar registro" alt="Copiar registro" (click)="copiar(cabecalho_layout)">
                                                <i class="glyphicon glyphicon-copy"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="!cabecalhos_layout">
                                    <tr class="carregando">
                                        <td class="carregando" colspan="3">
                                            <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="cabecalhos_layout && cabecalhos_layout[tipo_layout.sigla].length == 0">
                                    <tr>
                                        <td class="col-md-12" colspan="3">
                                            Nenhum registro encontrado.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #md_cabecalho_detail tabindex="-1" role="dialog" style="" class="modal fade">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" (click)="modal_cabecalho_detail_ref.hide()" aria-hidden="true" class="close">
                    <span class="mdi mdi-close"></span></button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label class="col-sm-3 control-label">T&iacute;tulo <span class="obrigatorio">*</span></label>
                    <span class="col-sm-9">
                        <input id="titulo" type="text" placeholder="Título" class="form-control" [(ngModel)]="titulo">
                    </span>
                </div>
                <div class="text-center">
                    <table class="table table-striped table-hover table-fw-widget">
                        <thead>
                            <tr>
                                <th class="col-sm-1">Sigla</th>
                                <th class="col-sm-3">Descri&ccedil;&atilde;o</th>
                                <th class="col-sm-1">Qtd<br/>carac.</th>
                                <th class="col-sm-1">Padr&atilde;o</th>
                                <!--              <th class="col-sm-1">Schema</th>-->
                                <!--              <th class="col-sm-2">Tabela</th>-->
                                <!--              <th class="col-sm-2">Campo</th>-->
                                <th class="col-sm-3">SQL</th>
                                <th class="col-sm-3">
                                    <button data-toggle="modal" *ngIf="cabecalho_layout && cabecalho_layout.pode_alterar" (click)="addCampo(md_cabecalho_form)" type="button" class="btn btn-space btn-primary" title="Adicionar campo" alt="Adicionar campo">
                                        <i class="glyphicon glyphicon-plus"></i>
                                    </button>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let campo_cabecalho_layout of campos_cabecalho_layout; let i = index">
                                <td>{{campo_cabecalho_layout.sigla}}</td>
                                <td>{{campo_cabecalho_layout.descricao}}</td>
                                <td>{{campo_cabecalho_layout.quantidade_caracteres}}</td>
                                <td>{{campo_cabecalho_layout.padrao ? campo_cabecalho_layout.padrao : "-"}}</td>
                                <!--              <td>{{campo_cabecalho_layout.schema ? campo_cabecalho_layout.schema : "-"}}</td>-->
                                <!--              <td>{{campo_cabecalho_layout.tabela ? campo_cabecalho_layout.tabela : "-"}}</td>-->
                                <!--              <td>{{campo_cabecalho_layout.campo ? campo_cabecalho_layout.campo : "-"}}</td>-->
                                <td>{{campo_cabecalho_layout.sql ? campo_cabecalho_layout.sql : "-"}}</td>
                                <td style="text-align: right;">
                                    <button type="button" class="btn btn-space btn-primary" title="Subir" alt="Subir" (click)="subir(campo_cabecalho_layout, i)" *ngIf="i > 0">
                                        <i class="glyphicon glyphicon-arrow-up"></i>
                                    </button>
                                    <button type="button" class="btn btn-space btn-light" title="Editar" alt="Editar" (click)="editar(campo_cabecalho_layout, md_cabecalho_form)">
                                        <i class="glyphicon glyphicon-pencil"></i>
                                    </button>
                                    <br/>
                                    <button type="button" class="btn btn-space btn-warning" title="Descer" alt="Descer" (click)="descer(campo_cabecalho_layout, i)" *ngIf="(i + 1) != campos_cabecalho_layout.length">
                                        <i class="glyphicon glyphicon-arrow-down"></i>
                                    </button>
                                    <button type="button" class="btn btn-space btn-danger" title="Excluir" alt="Excluir" (click)="excluir(i)">
                                        <i class="glyphicon glyphicon-remove"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="atualizar(cabecalho_layout, campos_cabecalho_layout)">
                    Salvar
                </button>
                <button type="button" (click)="modal_cabecalho_detail_ref.hide()" class="btn btn-default">Fechar
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #md_cabecalho_form tabindex="-1" role="dialog" style="" class="modal fade">
    <form [formGroup]="form_campos" (ngSubmit)="adicionarCampo()" class="form-horizontal group-border-dashed">
        <div class="modal-header">
            <button type="button" (click)="modal_cabecalho_form_ref.hide()" aria-hidden="true" class="close">
                <span class="mdi mdi-close"></span></button>
        </div>
        <div class="modal-body">
            <div class="text-center">
                <div class="form-group">
                    <label class="col-sm-3 control-label">Sigla <span class="obrigatorio">*</span></label>
                    <span class="col-sm-9">
                        <input id="sigla" type="text" placeholder="Sigla" class="form-control" formControlName="sigla">
                    </span>
                </div>
                <div class="form-group">
                    <label class="col-sm-3 control-label">Descri&ccedil;&atilde;o
                        <span class="obrigatorio">*</span></label>
                        <span class="col-sm-9">
                            <input id="descricao" type="text" placeholder="Descri&ccedil;&atilde;o" class="form-control" formControlName="descricao">
                        </span>
                </div>
                <div class="form-group">
                    <label class="col-sm-3 control-label">Qtd. caracteres <span class="obrigatorio">*</span></label>
                    <span class="col-sm-9">
                        <input id="quantidade_caracteres" step="1" min="1" type="number" placeholder="Qtd de caracteres" class="form-control" formControlName="quantidade_caracteres">
                    </span>
                </div>
                <div class="form-group">
                    <div class="be-checkbox col-sm-9">
                        <input id="check10" type="checkbox" (click)="definirCampoPadrao()" [checked]="existe_padrao">
                        <label for="check10">Existe padr&atilde;o</label>
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-sm-3 control-label">Padr&atilde;o</label>
                    <span class="col-sm-9">
          <input id="padrao" type="text" placeholder="Padr&atilde;o" class="form-control" formControlName="padrao" [readonly]="!existe_padrao">
        </span>
                </div>
                <!--            <div class="form-group">-->
                <!--              <label class="col-sm-3 control-label">Schema</label>-->
                <!--              <span class="col-sm-9">-->
                <!--                  <select #schema_select class="select2 form-control" formControlName="schema" (change)="listarTabelas(schema_select.value); tabela_select.value = ''; campo_select.value = '';">-->
                <!--                    <option value="">Selecione</option>-->
                <!--                    <option *ngFor="let schema of schemas" value="{{schema.nome}}">-->
                <!--                      {{schema.nome}}-->
                <!--                    </option>-->
                <!--                  </select>-->
                <!--                </span>-->
                <!--            </div>-->
                <!--            <div class="form-group">-->
                <!--              <label class="col-sm-3 control-label">Tabela</label>-->
                <!--              <span class="col-sm-9">-->
                <!--                  <select #tabela_select class="select2 form-control" formControlName="tabela" (change)="listarCampos(tabela_select.value, schema_select.value); campo_select.value = '';">-->
                <!--                    <option value="">Selecione</option>-->
                <!--                    <option *ngFor="let tabela of tabelas" [value]="tabela.nome">-->
                <!--                      {{tabela.nome}}-->
                <!--                    </option>-->
                <!--                  </select>-->
                <!--                </span>-->
                <!--            </div>-->
                <!--            <div class="form-group">-->
                <!--              <label class="col-sm-3 control-label">Campo</label>-->
                <!--              <span class="col-sm-9">-->
                <!--                  <select #campo_select class="select2 form-control" formControlName="campo" [disabled]="existe_padrao">-->
                <!--                    <option value="">Selecione</option>-->
                <!--                    <option *ngFor="let campo of campos" [value]="campo.nome">-->
                <!--                      {{campo.nome}}-->
                <!--                    </option>-->
                <!--                  </select>-->
                <!--                </span>-->
                <!--            </div>-->
                <div class="form-group">
                    <label class="col-sm-3 control-label">SQL</label>
                    <span class="col-sm-9">
                        <textarea id="sql" formControlName="sql" placeholder="SQL" class="form-control" rows="3" [readonly]="existe_padrao"></textarea>
                    </span>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary btn-space" (click)="adicionarCampo()">Adicionar
                campo
            </button>
            <button type="button" (click)="modal_cabecalho_form_ref.hide()" class="btn btn-default btn-space">Cancelar
            </button>
        </div>
    </form>
</ng-template>


<app-footer></app-footer>
