<app-header></app-header>

<div class="row">
  <div class="col-sm-12">
    <div class="panel panel-default panel-table">
        <br />
        <div class="row ficha-financeira">
            <div class="col-md-9">
                <span><b>DADOS DO ASSOCIADO</b></span>
                <br />
                <span class="ficha-financeira-span"><b>Associado:</b>&nbsp;&nbsp;{{associado.pessoa.nome}}</span>
                <br />
                <span class="ficha-financeira-span"><b>Matrícula PF:</b>&nbsp;&nbsp;<span *ngIf="associado.matricula_dpf">{{associado.matricula_dpf}}</span><span *ngIf="!associado.matricula_dpf">-</span></span>
                <br />
                <span class="ficha-financeira-span"><b>Tipo de Associado:</b>&nbsp;&nbsp;<span *ngIf="associado.tipo == 'AT'"> Ativo</span><span *ngIf="associado.tipo == 'AP'"> Aposentado</span><span *ngIf="associado.tipo == 'PE'"> Pensionista</span></span>
                <br />
                <span class="ficha-financeira-span"><b>Classe:</b>&nbsp;&nbsp;{{associado.classe.nome}}</span>
                <br />
                <span class="ficha-financeira-span"><b>Plano:</b>&nbsp;&nbsp;<span *ngIf="!associado.plano">Não definido</span><span *ngIf="associado.plano"><span *ngIf="associado.isento_mensalidade == 'N'"> {{associado.plano.nome}}</span><span class="label label-warning" *ngIf="associado.isento_mensalidade == 'S'">Isento</span></span></span>
            </div>
            <div class="col-md-3">
                <span><b>DADOS DO PERÍODO SELECIONADO</b></span>
                <br />
                <div *ngIf="!carregando">
                    <span><b>Meses:</b>&nbsp;&nbsp; {{filtro_mes_inicial}} à {{filtro_mes_final}}</span>
                    <br />
                    <span><b>Ano(s):</b>&nbsp;&nbsp; {{filtro_ano_inicial}} / {{filtro_ano_final}}</span>
                    <br />
                    <br />
                </div>
                <div *ngIf="!carregando && pagamentos && pagamentos.length > 0">
                    <span><b>Total a Pagar:</b>&nbsp;&nbsp; {{total_periodo | currency:'BRL':true:'.2':'pt':'.2':'pt'}}</span>
                    <br />
                    <span><b>Total a Devolver:</b>&nbsp;&nbsp; {{total_a_devolver | currency:'BRL':true:'.2':'pt':'.2':'pt'}}</span>
                    <br />
                    <span><b>Total Pago:</b>&nbsp;&nbsp; {{pago_periodo | currency:'BRL':true:'.2':'pt':'.2':'pt'}}</span>
                    <br />
                    <span><b>Total Devolvido:</b>&nbsp;&nbsp; {{valor_devolucao | currency:'BRL':true:'.2':'pt':'.2':'pt'}}</span>
                    <br />
                    <span><b>Restante a Pagar:</b>&nbsp;&nbsp; {{total_restante_periodo | currency:'BRL':true:'.2':'pt':'.2':'pt'}}</span>
                </div>
                
                <div *ngIf="!pagamentos"><img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...</div>
            </div>
        </div>
        <div class="panel-body">
            <div class="row" style="margin-left: 7px;">
                <div class="col-md-6" style="margin-top: 26px">
                    <button data-toggle="modal" data-target="#formPagamentoAdicional" class="btn btn-space btn-success" tabindex="0" aria-controls="table3">Novo Pagamento Adicional</button>
                    &nbsp;
                    &nbsp;
                    <button data-toggle="modal" data-target="#formDevolucao" class="btn btn-space btn-danger" tabindex="0" aria-controls="table3">Nova Devolução</button>
                </div>
            </div>
            <br />
            <div class="row" style="margin-left: 7px;">
                <div class="col-md-2">
                    <label><b>Tipo</b></label>
                    <select name="filtro_tipo" class="form-control input-xs" (change)="definirFiltroTipo($event.target.value)">
                        <option value="">Todos</option>
                        <option value="mensalidade">Mensalidade</option>
                        <option value="adicional">Pagamento Adicional</option>
                        <option value="devolucao">Devolução</option>
                    </select>
                </div>
                <div class="col-md-2">
                    <label><b>Mês Inicial</b></label>
                    <select name="filtro_mes" id="filtro_mes" class="form-control input-xs" (change)="definirFiltroMes($event.target.value, true)">
                        <option *ngFor="let dado of meses" [value]="dado.codigo" [attr.selected]="dado.codigo==filtro_mes_inicial ? true : null">{{dado.nome}}</option>
                    </select>
                </div>
                <div class="col-md-2">
                    <label><b>Ano Inicial</b></label>
                    <select name="filtro_ano" id="filtro_ano" class="form-control input-xs" (change)="definirFiltroAno($event.target.value, true)">
                        <option *ngFor="let dado of ano" [value]="dado.ano" [attr.selected]="dado.ano==filtro_ano_inicial ? true : null">{{dado.ano}}</option>
                    </select>
                </div>
                <div class="col-md-2">
                    <label><b>Mês Final</b></label>
                    <select name="filtro_mes" id="filtro_mes" class="form-control input-xs" (change)="definirFiltroMes($event.target.value, false)">
                        <option *ngFor="let dado of meses" [value]="dado.codigo" [attr.selected]="dado.codigo==filtro_mes_final ? true : null">{{dado.nome}}</option>
                    </select>
                </div>
                <div class="col-md-2">
                    <label><b>Ano Final</b></label>
                    <select name="filtro_ano" id="filtro_ano" class="form-control input-xs" (change)="definirFiltroAno($event.target.value, false)">
                        <option *ngFor="let dado of ano" [value]="dado.ano" [attr.selected]="dado.ano==filtro_ano_final ? true : null">{{dado.ano}}</option>
                    </select>
                </div>
                <div class="col-md-3" style="margin-top: 26px">
                    <button class="btn btn-space btn-primary" (click)="listar()">Filtrar</button>
                    &nbsp;
                    <button class="btn btn-space btn-default" (click)="listar(true)">Filtro Salvo</button>
                </div>
            </div>
            <br />
            <div class="row" style="margin-left: 7px;">
                <div class="col-md-12">
                    <div class="dataTables_length" id="table3_length">
                        <table id="table2" class="table table-striped table-hover table-fw-widget">
                            <thead>
                                <tr>
                                    <th class="text-center">Tipo</th>
                                    <th class="text-center">Mês/Ano Ref.</th>
                                    <th class="text-center">Valor a Pagar</th>
                                    <th class="text-center">Desconto</th>
                                    <th class="text-center">Acréscimo</th>
                                    <th class="text-center">Valor Efetivado</th>
                                    <th class="text-center">Data Pagamento</th>
                                    <th class="text-center">Tipo Baixa</th>
                                    <th class="text-center">Situação</th>
                                    <th class="text-center">Ações</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="pagamentos && pagamentos.length > 0">
                                <tr class="odd gradeX" *ngFor="let dado of pagamentos; index as i">
                                    <td class="col-md-1 text-center"><span *ngIf="dado.tipo_pagamento == 'devolucao'" class="label label-danger">D</span><span *ngIf="dado.tipo_pagamento == 'adicional'" class="label label-success">A</span><span *ngIf="dado.tipo_pagamento == 'mensalidade'" class="label label-primary">M</span></td>
                                    <td class="col-md-2 text-center">{{dado.mes}} / {{dado.ano}}</td>
                                    <td class="col-md-2 text-center"><span *ngIf="dado.valor">{{dado.valor | currency:'BRL':true:'.2':'pt'}}</span><span *ngIf="!dado.valor">0.00</span></td>
                                    <td class="col-md-1 text-center"><span *ngIf="dado.desconto">{{dado.desconto | currency:'BRL':true:'.2':'pt'}}</span><span *ngIf="!dado.desconto">0.00</span></td>
                                    <td class="col-md-1 text-center"><span *ngIf="dado.acrescimo">{{dado.acrescimo | currency:'BRL':true:'.2':'pt'}}</span><span *ngIf="!dado.acrescimo">0.00</span></td>
                                    <td class="col-md-2 text-center"><span *ngIf="dado.valor_pago">{{dado.valor_pago | currency:'BRL':true:'.2':'pt'}}</span><span *ngIf="!dado.valor_pago">0.00</span></td>
                                    <td class="col-md-2 text-center">
                                        <div *ngIf="dado.data_pagamento_confirmado">
                                            {{dado.data_pagamento_confirmado}}
                                        </div>
                                        <div *ngIf="!dado.data_pagamento_confirmado">
                                            -
                                        </div>
                                    </td>
                                    <td class="col-md-2 text-center">
                                        <div *ngIf="dado.data_pagamento_confirmado">
                                            <span *ngIf="dado.baixa_manual == 'S'" class="label label-default">Manual</span>
                                            <span *ngIf="dado.baixa_manual == 'N'" class="label label-primary">Automática</span>
                                        </div>
                                        <div *ngIf="!dado.data_pagamento_confirmado">
                                            -
                                        </div>
                                    </td>
                                    <td class="col-md-2 text-center" *ngIf="!dado.baixa_manual || dado.baixa_manual == 'N'">
                                        <span *ngIf="!dado.data_pagamento_confirmado" class="label label-default">Não Pago</span>
                                        <span *ngIf="dado.data_pagamento_confirmado && (dado.valor_efetivado_serpro == dado.valor)" class="label label-success">Pago {{dado.valor_pago}}</span>
                                        <span *ngIf="dado.data_pagamento_confirmado && (dado.valor_efetivado_serpro > dado.valor)" class="label label-warning">Pago a maior</span>
                                        <span *ngIf="dado.data_pagamento_confirmado && (dado.valor_efetivado_serpro < dado.valor)" class="label label-danger">Pago a menor</span>
                                    </td>
                                    <td class="col-md-2 text-center" *ngIf="dado.baixa_manual == 'S'">
                                        <span *ngIf="!dado.data_pagamento_confirmado" class="label label-default">Não Pago</span>
                                        <span *ngIf="dado.data_pagamento_confirmado" class="label label-success">Pago</span>
                                    </td>
                                    <td class="text-center" style="font-size: 22px;">
                                        <span *ngIf="!dado.data_pagamento_confirmado">
                                            <a data-toggle="modal" data-target="#formPagar" (click)="recuperarAssociadoPagamento(i);definirCodigo(dado.codigo)"><span class="mdi mdi-square-down"></span></a>
                                        </span>
                                        <span *ngIf="dado.data_pagamento_confirmado && dado.baixa_manual == 'S' && dado.tipo_pagamento == 'mensalidade'">
                                            <a data-toggle="modal" data-target="#formPagar" (click)="obter(dado.codigo);definirCodigo(dado.codigo)"><span class="mdi mdi-edit"></span></a>
                                        </span>
                                        <span *ngIf="dado.data_pagamento_confirmado && dado.baixa_manual == 'S' && dado.tipo_pagamento == 'adicional'">
                                            <a data-toggle="modal" data-target="#formPagar" (click)="recuperarAssociadoPagamento(i);definirCodigo(dado.codigo)"><span class="mdi mdi-edit"></span></a>
                                        </span>
                                        <span *ngIf="dado.data_pagamento_confirmado && dado.baixa_manual == 'N'">
                                            <a data-toggle="modal" data-target="#formSemEdicao"><span class="mdi mdi-edit"></span></a>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="!pagamentos">
                                <tr class="carregando">
                                    <td class="carregando" colspan="8">
                                        <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="pagamentos && pagamentos.length == 0">
                                <tr>
                                    <td class="col-md-12" colspan="10">
                                        Nenhum registro encontrado para o filtro selecionado.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
            <br />
        </div>
    </div>
  </div>
</div>

<div class="row">
    <div class="col-sm-12">
        <a href="associado-listar" class="btn btn-space btn-default">Voltar</a>
    </div>
</div>

<!--Modal Footer-->
<div id="modal-excluir-pagamento" tabindex="-1" role="dialog" style="" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <div class="text-warning"><span class="modal-main-icon mdi mdi-alert-triangle"></span></div>
                <h3>Tem certeza que deseja EXCLUIR este pagamento adicional?</h3>
                <p>Esta operação não poderá ser desfeita.</p>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default">Cancelar</button>
                <button type="button" data-dismiss="modal" class="btn btn-warning" (click)="apagarPagamento(codigo_associado_pagamento)">Confirmar</button>
            </div>
        </div>
    </div>
</div>

<!--Modal Footer-->
<div id="modal-excluir-parcela" tabindex="-1" role="dialog" style="" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <div class="text-warning"><span class="modal-main-icon mdi mdi-alert-triangle"></span></div>
                <h3>Tem certeza que deseja EXCLUIR este pagamento adicional?</h3>
                <p>Esta operação não poderá ser desfeita.</p>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default">Cancelar</button>
                <button type="button" data-dismiss="modal" class="btn btn-warning" (click)="apagarPagamentoParcela(codigo_associado_pagamento)">Confirmar</button>
            </div>
        </div>
    </div>
</div>

<!--Modal Footer-->
<div id="formSemEdicao" tabindex="-1" role="dialog" class="modal fade colored-header colored-header-primary">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close md-close"><span class="mdi mdi-close"></span></button>
                <h3 class="modal-title">Edição não permitida</h3>
            </div>
            <div class="modal-body">
                <p>As edições não são permitidas para baixa foi feita automaticamente.</p>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default md-close" (click)="cancelar()">Fechar</button>
            </div>
        </div>
    </div>
</div>

<!--Modal Footer-->
<div id="formPagar" tabindex="-1" role="dialog" class="modal fade colored-header colored-header-primary">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close md-close"><span class="mdi mdi-close"></span></button>
                <h3 class="modal-title">Baixa Manual</h3>
            </div>
            <div class="modal-body" *ngIf="carregando_editar">
                <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" /> Carregando...
            </div>
            <div class="modal-body" *ngIf="!carregando_editar">
                <form #contaPagarForm="ngForm" [formGroup]="formPagar" novalidate="">
                <div class="row">
                    <div class="col-md-12">
                        <span class="label label-danger">{{erro_cadastro}}</span>
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Data do Pagamento <span class="obrigatorio">*</span></label>
                            <input type="text" placeholder="DD/MM/YYYY" class="form-control" [dropSpecialCharacters]="false" mask="00/00/0000" formControlName="data_pagamento_confirmado">
                        </div>
                        <br />
                        <div class="form-group">
                            <label>Valor <span class="obrigatorio">*</span></label>
                            <input type="text" placeholder="R$" class="form-control" currencyMask formControlName="valor" disabled>
                        </div>
                        <br />
                        <div class="form-group">
                            <label>Forma de Recebimento <span class="obrigatorio">*</span></label>
                            <select class="form-control" formControlName="codigo_forma_pagamento">
                                <option *ngFor="let dado of formasPagamento" value="{{dado.codigo_forma_pagamento}}">
                                    {{dado.nome}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="row no-margin-y">
                            <div class="form-group col-xs-6">
                                <label>Desconto</label>
                                <input type="text" placeholder="R$" class="form-control" currencyMask formControlName="valor_desconto" (blur)="calcularValorPagar()">
                            </div>
                            <div class="form-group col-xs-6">
                                <label>Acréscimo</label>
                                <input type="text" placeholder="R$" class="form-control" currencyMask formControlName="valor_acrescimo" (blur)="calcularValorPagar()">
                            </div>
                        </div>
                        <br />
                        <div class="form-group">
                            <label>Valor do Recebimento</label>
                            <input type="text" placeholder="R$" class="form-control" currencyMask formControlName="valor_recebimento" disabled>
                        </div>
                        <br />
                        <div class="form-group">
                            <label>Observação</label>
                            <textarea placeholder="Observação" class="form-control" formControlName="observacao" cols="5" rows="3"></textarea>
                        </div>
                    </div>
                </div>
                </form>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-md-4 text-left">
                        <button type="button" class="btn btn-danger md-close" *ngIf="data_pagamento_confirmado && pagamento_adicional" data-toggle="modal" data-target="#modal-excluir-parcela" (click)="fecharModal()">Excluir</button>
                        <button type="button" class="btn btn-danger md-close" *ngIf="data_pagamento_confirmado && !pagamento_adicional" data-toggle="modal" data-target="#modal-excluir-pagamento" (click)="fecharModal()">Excluir</button>
                    </div>
                    <div class="col-md-4">
                        &nbsp;
                    </div>
                    <div class="col-md-4">
                        <button type="button" data-dismiss="modal" class="btn btn-default md-close" (click)="cancelar()">Cancelar</button>
                        <button type="button" class="btn btn-primary md-close" (click)="incluirContaPagar()">Cadastrar</button>
                    </div>
                </div>
                &nbsp;&nbsp;
                <img *ngIf="carregando" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            </div>
        </div>
    </div>
</div>

<!--Modal Footer-->
<div id="formPagamentoAdicional" tabindex="-1" role="dialog" class="modal fade colored-header colored-header-primary">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close md-close"><span class="mdi mdi-close"></span></button>
                <h3 class="modal-title">Incluir Pagamento Adicional</h3>
            </div>
            <div class="modal-body">
                <form #pagamentoAdicionalForm="ngForm" [formGroup]="formPagamentoAdicional" novalidate="">
                    <div class="row">
                        <div class="col-md-12">
                            <span class="label label-danger">{{erro_cadastro_pagamento_adicional}}</span>
                        </div>
                    </div>
                    <br />
                    <div class="row">
                        <div class="form-group col-md-6">
                            <label>Mês/Ano de Referência <span class="obrigatorio">*</span></label>
                            <input type="text" placeholder="MM/YYYY" class="form-control" [dropSpecialCharacters]="false" mask="00/0000" formControlName="data_referencia">
                        </div>
                        <div class="form-group col-md-6">
                            <label>Valor <span class="obrigatorio">*</span></label>
                            <input type="text" placeholder="R$" class="form-control" currencyMask formControlName="valor">
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-6">
                            <label>Quantidade de Parcelas <span class="obrigatorio">*</span></label>
                            <input type="number" min="0" class="form-control" formControlName="quantidade_parcelas">
                        </div>
                        <div class="form-group col-md-6">
                            <label>Tipo <span class="obrigatorio">*</span></label>
                            <select class="form-control" formControlName="codigo_associado_pagamento_tipo">
                                <option *ngFor="let dado of associadoPagamentoTipo" value="{{dado.codigo_associado_pagamento_tipo}}">
                                    {{dado.nome}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-6">
                            <label>Forma de Recebimento <span class="obrigatorio">*</span></label>
                            <select class="form-control" formControlName="codigo_forma_pagamento">
                                <option *ngFor="let dado of formasPagamento" value="{{dado.codigo_forma_pagamento}}">
                                    {{dado.nome}}
                                </option>
                            </select>
                        </div>
                        <div class="form-group col-md-6">
                            <label>Observação</label>
                            <textarea cols="8" rows="5" class="form-control" formControlName="observacao"></textarea>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default md-close">Cancelar</button>
                <button type="button" class="btn btn-primary md-close" (click)="incluirPagamentoAdicional()">Cadastrar</button>
                &nbsp;&nbsp;
                <img *ngIf="carregando" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            </div>
        </div>
    </div>
</div>  

<!--Modal Footer-->
<div id="formDevolucao" tabindex="-1" role="dialog" class="modal fade colored-header colored-header-primary">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close md-close"><span class="mdi mdi-close"></span></button>
                <h3 class="modal-title">Incluir Devolução</h3>
            </div>
            <div class="modal-body">
                <form #devolucaoForm="ngForm" [formGroup]="formDevolucao" novalidate="">
                    <div class="row">
                        <div class="form-group col-md-6">
                            <label>Mês/Ano de Referência <span class="obrigatorio">*</span></label>
                            <input type="text" placeholder="MM/YYYY" class="form-control" [dropSpecialCharacters]="false" mask="00/0000" formControlName="data_referencia">
                        </div>
                        <div class="form-group col-md-6">
                            <label>Valor <span class="obrigatorio">*</span></label>
                            <input type="text" placeholder="R$" class="form-control" currencyMask formControlName="valor">
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-6">
                            <label>Quantidade de Parcelas <span class="obrigatorio">*</span></label>
                            <input type="number" min="0" class="form-control" formControlName="quantidade_parcelas">
                        </div>
                        <div class="form-group col-md-6">
                            <label>Tipo <span class="obrigatorio">*</span></label>
                            <select class="form-control" formControlName="codigo_associado_pagamento_tipo">
                                <option *ngFor="let dado of associadoDevolucaoTipo" value="{{dado.codigo_associado_pagamento_tipo}}">
                                    {{dado.nome}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-12">
                            <label>Observação</label>
                            <textarea cols="8" rows="5" class="form-control" formControlName="observacao"></textarea>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default md-close">Cancelar</button>
                <button type="button" class="btn btn-primary md-close" (click)="incluirDevolucaoAdicional()">Cadastrar</button>
                &nbsp;&nbsp;
                <img *ngIf="carregando" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            </div>
        </div>
    </div>
</div>  

<!--Modal Footer-->
<div id="formPagamentoAdicionalParcela" tabindex="-1" role="dialog" class="modal fade colored-header colored-header-primary">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close md-close"><span class="mdi mdi-close"></span></button>
                <h3 class="modal-title">Alterar Pagamento Adicional Parcela</h3>
            </div>
            <div class="modal-body">
                <span *ngIf="carregando_dados"><img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" /> Carregando...</span>
                <br />
                <form #pagamentoAdicionalParcelaForm="ngForm" [formGroup]="formPagamentoAdicionalParcela" novalidate="">
                    <div class="row">
                        <div class="form-group col-md-6">
                            <label>Mês/Ano de Referência <span class="obrigatorio">*</span></label>
                            <input type="text" placeholder="MM/YYYY" class="form-control" [dropSpecialCharacters]="false" mask="00/0000" formControlName="data_referencia">
                        </div>
                        <div class="form-group col-md-6">
                            <label>Valor <span class="obrigatorio">*</span></label>
                            <input type="text" placeholder="R$" class="form-control" currencyMask formControlName="valor">
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default md-close">Cancelar</button>
                <button type="button" class="btn btn-primary md-close" (click)="incluirPagamentoAdicionalParcela()">Atualizar</button> &nbsp;&nbsp;
                <img *ngIf="carregando" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            </div>
        </div>
    </div>
</div>  

<app-footer></app-footer>