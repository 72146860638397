import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {Arquivo} from "../../shared/models/arquivo";
import {Layout} from "../../shared/models/layout";
import {isBoolean} from "util";
import {AssociadoPossivel} from "../../shared/models/associado-possivel";

@Injectable({
  providedIn: 'root'
})
export class ArquivoService {

    private url: string = environment.UrlApi + "/layout/arquivo";

    constructor(private http: HttpClient) {}

    list(tipo_layout: string = null, ano: number = null, aceites: boolean = false) : Observable<Arquivo[]> {
        let url: string = this.url;
        if(!tipo_layout) {
            if (aceites) {
                tipo_layout = "ACALL";
            } else {
                tipo_layout = "NACALL";
            }
        }
        if(!ano){
            ano = new Date().getFullYear();
        }
        url += "/" + tipo_layout + "/" + ano;
        return this.http.get(url)
            .pipe(map((res: any) => {
                let arquivos: Array<Arquivo> = [];
                res.forEach((item: any) => {
                    let layout: Layout = item.layout as Layout;
                    let arquivo: Arquivo = item;
                    arquivo.layout = layout;
                    arquivos.push(arquivo);
                });
                return arquivos;
            }))
            .catch(this.handleError);
    }

    find(id) {
        return this.http.get(this.url + "/" + id)
            .catch(this.handleError);
    }

    listAnos(para_preenchimento: boolean = null) :Observable<number[]>{
        let url: string = this.url + "/list-anos";
        if(isBoolean(para_preenchimento)){
            url += (para_preenchimento) ? "/1" : "/0";
        }
        return this.http.get(url)
            .pipe(map((anos: any) => {
                let anos_ret: Array<number> = [];
                if(anos.length > 0){
                    anos.forEach((ano: any) => {
                        anos_ret.push(ano.ano_referencia);
                    });
                }
                return anos_ret;
            }))
            .catch(this.handleError);
    }

    save(arquivo: Arquivo, arquivo_consignado: any): Observable<any>{
        const form_data: FormData = new FormData();
        form_data.append('sigla_tipo_layout', arquivo.sigla_tipo_layout);
        form_data.append('ano_referencia', arquivo.ano_referencia.toString());
        form_data.append('mes_referencia', arquivo.mes_referencia.toString());
        form_data.append('arquivo_consignacao', arquivo_consignado);

        let params = new HttpParams();
        const options = {params: params, reportProgress: true};
        let url: string = this.url;
        if(arquivo.codigo_layout){
           url += "/" + arquivo.codigo_layout;
        }
        return this.http.post(url, form_data, options).map((res) => {
            return res;
        });
    }

    generateFile(ano_referencia: number, mes_referencia: number, sigla_tipo_layout: string, codigo_layout: number, associados: Array<AssociadoPossivel>): Observable<any>{
        let url = this.url + "/generate/file/" + ano_referencia + "/" + mes_referencia + "/" + sigla_tipo_layout + "/" + codigo_layout;
        return this.http.post(url, {associados: associados}).map((res) => {
            return res;
        });
    }

    listPossiveis(ano: number, mes: number, sigla_tipo_layout: string, codigo_layout: number, codigo_layout_aceite: number, associados_gerados: number = 0, nome_cpf_associado: string = null): Observable<any>{
        let url = this.url + "/list/possiveis/" + ano + "/" + mes + "/" + sigla_tipo_layout + "/" + codigo_layout + "/" + codigo_layout_aceite + "?";
        if(associados_gerados > 0){
            url += "associados_gerados=" + (associados_gerados == 1 ? '1' : '0') + "&";
        }
        if(nome_cpf_associado){
            url += "nome_cpf_associado=" + nome_cpf_associado + "&";
        }
        return this.http.get(url).map((res) => {
            return res;
        });
    }

    delete(codigo_arquivo): Observable<any> {
        return this.http.delete(this.url + "/" + codigo_arquivo);
    }
    
    compensar(codigo_arquivo): Observable<any> {
        return this.http.get(this.url + "/compensar/" + codigo_arquivo).map((res) => {
            return res;
        });
    }
    
    descompensar(codigo_arquivo): Observable<any> {
        return this.http.get(this.url + "/descompensar/" + codigo_arquivo).map((res) => {
            return res;
        });
    }

    private handleError(error: Response) {
        return error.json();
    }
}
