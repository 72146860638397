<app-header></app-header>

<div class="row">
  <div class="col-sm-12">
    <div class="panel panel-default panel-table">
    <div class="panel-heading">
        <div class="">
            <button routerLink="/associado-novo" class="btn btn-space btn-success" tabindex="0" aria-controls="table3">Novo Associado</button>
        </div>
    </div>
    <div class="panel-body">
        <div class="tab-container">
            <ul class="nav nav-tabs">
                <li class="active"><a href="#ativo" data-toggle="tab">Associados</a></li>
                <!--<li><a href="#pendente" data-toggle="tab">Aguardando Aprovação</a></li>-->
                <!--<li><a routerLink="/associado-alteracao-listar">Solicitação de Alterações</a></li>-->
                <li><a href="#inativo" data-toggle="tab">Desfiliados</a></li>
            </ul>
            <div class="tab-content">
                <div id="ativo" class="tab-pane active cont">
                    <!-- Filtros -->
                    <form #formFiltro="ngForm" [formGroup]="filtroForm" novalidate="">
                        <div class="row" style="margin-left: 7px; margin-top: 20px;">
                            <div class="col-md-3">
                                <label><b>Nome do Associado</b></label>
                                <input type="text" placeholder="Nome" class="form-control input-xs" formControlName="nome">
                            </div>
                            <div class="col-md-2">
                                <label><b>Tipo</b></label>
                                <select name="filtro_tipo" class="form-control input-xs" (change)="definirFiltroTipo($event.target.value)">
                                    <option value="">Todos</option>
                                    <option value="AT">Ativos</option>
                                    <option value="AP">Aposentados</option>
                                    <option value="PE">Pensionistas</option>
                                </select>
                            </div>
                            <div class="col-md-4">
                                <label><b>Unidade de Repasse</b></label>
                                <select name="filtro_lotacao" class="select2 form-control input-xs" (change)="definirFiltroLotacao($event.target.value)">
                                    <option value="">Todos</option>
                                    <option value="-1">Sem unidade de repasse</option>
                                    <option *ngFor="let dado of Lotacoes" value="{{dado.codigo_lotacao}}">
                                        {{dado.nome}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-3" style="margin-top: 26px">
                                <button class="btn btn-space btn-default" (click)="listar()">Filtrar</button>
                            </div>
                        </div>
                    </form>
                    <br />
                    <!-- Filtros Fim -->
                    <div class="dataTables_length" id="table3_length">
                        <table id="table2" class="table table-striped table-hover table-fw-widget">
                            <thead>
                                <tr>
                                    <th>Nome / E-mail / CPF</th>
                                    <th>Afiliações</th>
                                    <th>Classe</th>
                                    <th>Unidade de Repasse</th>
                                    <th>Tipo</th>
                                    <th class="text-center">Ações</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="associados && associados.length > 0">
                                <tr class="odd gradeX" *ngFor="let associado of associados">
                                    <td class="col-md-3">{{associado.pessoa.nome}} <br /> {{associado.pessoa_fisica.cpf | cpf}} <br /> {{associado.pessoa.email}}</td>
                                    <td class="col-md-2">
                                        <span *ngIf="associado.associado_apcf == '0'" class="label label-default">Desfiliado APCF</span><span *ngIf="associado.tipo != 'PE'"><br /><span *ngIf="associado.associado_apcf_sindical == '0'" class="label label-default">Desfiliado APCF Sindical</span></span>
                                        <br />
                                        <span *ngIf="associado.associado_apcf == '1'" class="label label-success">AFiliado APCF</span><span *ngIf="associado.tipo != 'PE'"><br /><span *ngIf="associado.associado_apcf_sindical == '1'" class="label label-success">AFiliado APCF Sindical</span></span>
                                    </td>
                                    <td class="col-md-2" *ngIf="associado.tipo == 'PE' || tipo == 'PE'">-</td>
                                    <td class="col-md-2" *ngIf="associado.tipo == 'AT' || tipo == 'AT' || associado.tipo == 'AP' || tipo == 'AP'"><span *ngIf="associado.classe?.nome">{{associado.classe?.nome}}</span><span *ngIf="associado_classe">{{associado_classe}}</span></td>
                                    <td class="col-md-3"><span *ngIf="!associado.codigo_unidade_repasse" class="label label-danger">Sem unidade de repasse cadastrada</span><span *ngIf="associado.codigo_unidade_repasse" class="label label-primary">{{associado.unidade_repasse?.nome}}</span></td>
                                    <td class="col-md-1" *ngIf="associado.tipo == 'PE'">Pensionista</td>
                                    <td class="col-md-1" *ngIf="associado.tipo == 'AT'">Ativo</td>
                                    <td class="col-md-1" *ngIf="associado.tipo == 'AP'">Aposentado</td>
                                    <td class="text-center" style="font-size: 22px;">
                                        <div class="dropdown">
                                        <span class="text-center" id="dLabel" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <a href="#"><div class="icon"><span class="mdi mdi-more-vert"></span></div></a>
                                        </span>
                                        <ul class="dropdown-menu dropdown-menu-left">
                                        <li>
                                            <a href="/dados-pessoais/{{associado.codigo_pessoa}}" (click)="definirAssociado(associado)"><span class="icon mdi mdi-edit"></span>Editar</a>
                                        </li>
                                        <li>
                                            <a href="/ficha-financeira/{{associado.codigo_pessoa}}" (click)="definirAssociado(associado)"><span class="icon mdi mdi-money"></span>Ficha Financeira</a>
                                        </li>
                                        <li>
                                            <a data-toggle="modal" data-target="#formPlano" (click)="obterPlanoAssociado(associado.codigo_pessoa)"><span class="icon mdi mdi-file-text"></span>Plano de Pagamento</a>
                                        </li>
                                        <li>
                                            <a href="/beneficios/{{associado.codigo_pessoa}}" (click)="definirAssociado(associado)"><span class="icon mdi mdi-card-giftcard"></span>Benefícios</a>
                                        </li>
                                        <li>
                                            <a href="/pendencias/{{associado.codigo_pessoa}}" (click)="definirAssociado(associado)"><span class="icon mdi mdi-alert-circle"></span>Pendências</a>
                                        </li>
                                        <li>
                                            <a href="/associado-situacao/{{associado.codigo_pessoa}}" (click)="definirAssociado(associado)"><span class="icon mdi mdi-assignment-o"></span>Situação (Adesão/Baixa)</a>
                                        </li>
                                        <li class="divider"></li>
                                        <li>
                                            <a class="cursor" data-toggle="modal" data-target="#md-footer-success" (click)="definirCodigo(associado.codigo_pessoa)"><span class="icon mdi mdi-delete"></span>Excluir</a>
                                        </li>
                                        </ul>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="!associados">
                                <tr class="carregando">
                                    <td class="carregando" colspan="10">
                                        <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="associados && associados.length == 0">
                                <tr>
                                    <td class="col-md-12" colspan="10">
                                        Nenhum registro encontrado.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <br />
                        <div class="row" *ngIf="associados && associados.length > 0">
                            <div class="col-md-12 text-center" *ngIf="carregando_paginacao">
                                <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
                            </div>
                            <div class="col-md-3 text-center">&nbsp;</div>
                            <div class="col-md-6 text-center">
                                <p>Visualizado {{contagem}} de {{total}}</p>
                                <progress-bar [progress]="percentual" [color]="'#488aff'"></progress-bar>
                                <button data-wizard="#wizard1" class="btn btn-primary btn-space wizard-next" (click)="listar(contagem)">Carregar mais</button>
                            </div>
                            <div class="col-md-3 text-center">&nbsp;</div>
                        </div>
                    </div>
                </div>

                <!--<div id="pendente" class="tab-pane">
                    <h4><b>Aguardando Aprovação de Cadastro</b></h4>
                    <div class="dataTables_length" id="table3_length">
                        <table id="table2" class="table table-striped table-hover table-fw-widget">
                            <thead>
                                <tr>
                                    <th>Nome / E-mail</th>
                                    <th>CPF</th>
                                    <th>Lotação</th>
                                    <th>Tipo</th>
                                    <th class="text-center">Ações</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="aprovacao_pendente && aprovacao_pendente.length > 0">
                                <tr class="odd gradeX" *ngFor="let associado of aprovacao_pendente">
                                    <td class="col-md-3">{{associado.pessoa.nome}} <br /> {{associado.pessoa.email}}</td>
                                    <td class="col-md-2">{{associado.pessoa_fisica.cpf | cpf}}</td>
                                    <td class="col-md-2" *ngIf="associado.tipo == 'PE'">-</td>
                                    <td class="col-md-2" *ngIf="associado.tipo == 'AT' || associado.tipo == 'AP'">{{associado.classe?.nome}}</td>
                                    <td class="col-md-3">{{associado.lotacao?.nome}}</td>
                                    <td class="col-md-1" *ngIf="associado.tipo == 'PE'">Pensionista</td>
                                    <td class="col-md-1" *ngIf="associado.tipo == 'AT'">Ativo</td>
                                    <td class="col-md-1" *ngIf="associado.tipo == 'AP'">Aposentado</td>
                                    <td class="text-center" style="font-size: 22px;">
                                        <div class="dropdown">
                                        <span class="text-center" id="dLabel" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <a href="#"><div class="icon"><span class="mdi mdi-more-vert"></span></div></a>
                                        </span>
                                        <ul class="dropdown-menu dropdown-menu-left">
                                        <li>
                                            <a href="/dados-pessoais/{{associado.codigo_pessoa}}"><span class="icon mdi mdi-edit"></span>Visualizar/Editar</a>
                                        </li>
                                        <li>
                                            <a data-toggle="modal" data-target="#md-aprovar-cadastro" (click)="definirCodigo(associado.codigo_pessoa)"><span class="icon mdi mdi-check-circle" style="color: green;"></span>Aprovar Cadastro</a>
                                        </li>
                                        <li class="divider"></li>
                                        <li>
                                            <a data-toggle="modal" data-target="#md-footer-success" (click)="definirCodigo(associado.codigo_pessoa)"><span class="icon mdi mdi-delete" style="color: red;"></span>Excluir</a>
                                        </li>
                                        </ul>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="!aprovacao_pendente">
                                <tr class="carregando">
                                    <td class="carregando" colspan="5">
                                        <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="aprovacao_pendente && aprovacao_pendente.length == 0">
                                <tr>
                                    <td class="col-md-12" colspan="5">
                                        Nenhum registro encontrado.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>-->

                <div id="inativo" class="tab-pane cont">
                    <h4><b>Associados Desfiliados</b></h4>
                    
                    <div class="dataTables_length" id="table3_length">
                        <table id="table2" class="table table-striped table-hover table-fw-widget">
                            <thead>
                                <tr>
                                    <th>Nome / E-mail / CPF</th>
                                    <th>Afiliações</th>
                                    <th>Classe</th>
                                    <!--<th>Unidade de Repasse</th>-->
                                    <th>Tipo</th>
                                    <th class="text-center">Ações</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="associados_inativos && associados_inativos.length > 0">
                                <tr class="odd gradeX" *ngFor="let associado_inativo of associados_inativos">
                                    <td class="col-md-4">{{associado_inativo.pessoa.nome}} <br /> {{associado_inativo.pessoa_fisica.cpf | cpf}} <br /> {{associado_inativo.pessoa.email}}</td>
                                    <td class="col-md-2">
                                        <span *ngIf="associado_inativo.associado_apcf == '0'" class="label label-default">Desfiliado APCF</span><span *ngIf="associado_inativo.tipo != 'PE'"><br /><span *ngIf="associado_inativo.associado_apcf_sindical == '0'" class="label label-default">Desfiliado APCF Sindical</span></span>
                                        <br />
                                        <span *ngIf="associado_inativo.associado_apcf == '1'" class="label label-success">AFiliado APCF</span><span *ngIf="associado_inativo.tipo != 'PE'"><br /><span *ngIf="associado_inativo.associado_apcf_sindical == '1'" class="label label-success">AFiliado APCF Sindical</span></span>
                                    </td>
                                    <td class="col-md-2" *ngIf="associado_inativo.tipo == 'PE'">-</td>
                                    <td class="col-md-2" *ngIf="associado_inativo.tipo == 'AT' || associado_inativo.tipo == 'AP'">{{associado_inativo.classe?.nome}}</td>
                                    <!--<td class="col-md-3"><span *ngIf="!associado_inativo.codigo_unidade_repasse" class="label label-danger">Sem unidade de repasse cadastrada</span><span *ngIf="associado_inativo.codigo_unidade_repasse && associado.unidade_repasse?.nome" class="label label-primary">{{associado.unidade_repasse?.nome}}</span></td>-->
                                    <td class="col-md-1" *ngIf="associado_inativo.tipo == 'PE'">Pensionista</td>
                                    <td class="col-md-1" *ngIf="associado_inativo.tipo == 'AT'">Ativo</td>
                                    <td class="col-md-1" *ngIf="associado_inativo.tipo == 'AP'">Aposentado</td>
                                    <td class="text-center" style="font-size: 22px;">
                                        <a data-toggle="modal" data-target="#md-footer-restaurar" (click)="definirCodigo(associado_inativo.codigo_pessoa)"><span class="badge badge-warning cursor">Reativar</span></a>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="!associados_inativos">
                                <tr class="carregando">
                                    <td class="carregando" colspan="7">
                                        <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="associados_inativos && associados_inativos.length == 0">
                                <tr>
                                    <td class="col-md-12" colspan="7">
                                        Nenhum registro encontrado.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>

    </div>
  </div>
</div>

<!--Modal Footer-->
<div id="formPlano" tabindex="-1" role="dialog" class="modal fade colored-header colored-header-primary">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close md-close"><span class="mdi mdi-close"></span></button>
                <h3 class="modal-title">Plano do Associado</h3>
            </div>
            <div class="modal-body" *ngIf="carregando">
                <div class="row">
                    <div class="col-md-12">
                        <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
                    </div>
                </div>
            </div>
            <div class="modal-body" *ngIf="!carregando && isento_mensalidade == 'N'">
                <form #formPlano="ngForm" [formGroup]="planoForm" novalidate="">
                    <div class="row">
                        <div class="col-md-12">
                            <!-- <span class="label label-danger">{{erro_cadastro}}</span> -->
                        </div>
                    </div>
                    <br />
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Plano Atual<span class="obrigatorio">*</span></label>
                                <select class="form-control" formControlName="codigo_plano">
                                    <option *ngFor="let dado of planos" value="{{dado.codigo_plano}}">
                                        {{dado.codigo_plano}} - {{dado.descricao}} - R$ {{dado.valor}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-xs-6">
                            <label>Desconto</label>
                            <input type="text" placeholder="R$" class="form-control" currencyMask formControlName="desconto">
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-body" *ngIf="!carregando && isento_mensalidade == 'S'">
                Este associado é Isento de mensalidade. Para alterar esta situação, edite o cadastro do associado na seção Dados de Controle.
            </div>
            <div class="modal-footer" *ngIf="isento_mensalidade == 'N'">
                <button type="button" data-dismiss="modal" class="btn btn-default md-close">Cancelar</button>
                <button type="button" class="btn btn-primary md-close" (click)="incluirPlanoPagamento()">Atualizar</button>
                &nbsp;&nbsp;
                <img *ngIf="submitted" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            </div>
            <div class="modal-footer" *ngIf="isento_mensalidade == 'S'">
                <button type="button" data-dismiss="modal" class="btn btn-default md-close">Fechar</button>
            </div>
        </div>
    </div>
</div>

<!--Modal Footer-->
<div id="md-footer-success" tabindex="-1" role="dialog" style="" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <div class="text-warning"><span class="modal-main-icon mdi mdi-alert-triangle"></span></div>
                <h3>Tem certeza que deseja EXCLUIR este registro?</h3>
                <p>Esta operação não poderá ser desfeita.</p>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default">Cancelar</button>
                <button type="button" data-dismiss="modal" class="btn btn-warning" (click)="apagar(codigo_pessoa)">Confirmar</button>
            </div>
        </div>
    </div>
</div>

<div id="md-aprovar-cadastro" tabindex="-1" role="dialog" style="" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <div class="text-warning"><span class="modal-main-icon mdi mdi-alert-triangle"></span></div>
                <h3>Tem certeza que deseja APROVAR este cadastro?</h3>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default">Cancelar</button>
                <button type="button" data-dismiss="modal" class="btn btn-warning" (click)="aprovar(codigo_pessoa)">Confirmar</button>
            </div>
        </div>
    </div>
</div>

<div id="md-footer-restaurar" tabindex="-1" role="dialog" style="" class="modal fade">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <div class="text-warning"><span class="modal-main-icon mdi mdi-alert-triangle"></span></div>
                    <h3>Tem certeza que deseja RESTAURAR este registro?</h3>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default">Cancelar</button>
                <button type="button" data-dismiss="modal" class="btn btn-warning" (click)="restaurar(codigo_pessoa)">Confirmar</button>
            </div>
        </div>
    </div>
</div>
    

<app-footer></app-footer>