<app-header></app-header>

<div class="row">
  <div class="col-sm-12">
    <div class="panel panel-default panel-table">
    <div class="panel-heading">
        <div class="">
            <button routerLink="/demanda-novo" class="btn btn-space btn-success" tabindex="0" aria-controls="table3">Abrir Nova</button>
        </div>
    </div>
    <div class="panel-body">

        <div class="tab-container">
            <ul class="nav nav-tabs">
                <li class="active"><a href="#aberta" data-toggle="tab">Abertas</a></li>
                <li><a href="#encerrada" data-toggle="tab">Encerradas</a></li>
            </ul>
            <div class="tab-content">

                <form #demandaForm="ngForm" [formGroup]="form" novalidate="" class="form-horizontal group-border-dashed">

                    <div class="form-group xs-mt-5">
                      <div class="col-md-4">
                        <label>Demandante</label>
                        <input type="text" class="form-control" formControlName="demandante">
                      </div>
                      <div class="col-md-3">
                        <label>Tipo de Demanda</label>
                        <select class="form-control" formControlName="codigo_demanda_tipo">
                          <option *ngFor="let dado of demandas_tipo" value="{{dado.codigo_demanda_tipo}}">
                            {{dado.nome}}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-2">
                        <label>Data de abertura</label>
                        <input type="text" placeholder="DD/MM/YYYY" class="form-control" [dropSpecialCharacters]="false" mask="00/00/0000" formControlName="created_at">
                      </div>
                    </div>
                    <div class="form-group xs-mt-5">
                      <div class="col-md-3">
                        <button type="button" data-dismiss="modal" class="btn btn-primary" (click)="listar(true)">Filtrar</button>
                        &nbsp;
                        <button type="button" data-dismiss="modal" class="btn btn-default" (click)="listar(false)">Limpar Filtro</button>
                      </div>
                    </div>

                </form>

                <div id="aberta" class="tab-pane active cont">
                    <div class="dataTables_length" id="table3_length">
                        <table id="table2" class="table table-striped table-hover table-fw-widget">
                            <thead>
                                <tr>
                                    <th>Nº</th>
                                    <th>Demandante</th>
                                    <th>Solicitação</th>
                                    <th>Criado em</th>
                                    <th>Atribuído a</th>
                                    <th>Tipo</th>
                                    <th>Situação</th>
                                    <th class="text-center">Ações</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="demandas && demandas.length > 0">
                                <tr class="odd gradeX" *ngFor="let demanda of demandas">
                                    <td class="col-md-1">{{demanda.numero}}</td>
                                    <td class="col-md-2">{{demanda.demandante.nome}}</td>
                                    <td class="col-md-3">{{demanda.assunto}}</td>
                                    <td class="col-md-2">{{demanda.created_at | date: 'dd/MM/yyyy HH:mm'}}</td>
                                    <td class="col-md-2"><span class="label label-primary" *ngIf="!demanda.colaborador?.nome">Não atribuído</span><span *ngIf="demanda.colaborador?.nome">{{demanda.colaborador?.nome}}</span></td>
                                    <td class="col-md-1">{{demanda.demandatipo.nome}}</td>
                                    <td class="col-md-1">
                                        <span class="label label-success" *ngIf="demanda.demandasituacao.codigo_demanda_situacao == 1">{{demanda.demandasituacao.nome}}</span>
                                        <span class="label label-primary" *ngIf="demanda.demandasituacao.codigo_demanda_situacao == 2">{{demanda.demandasituacao.nome}}</span>
                                        <span class="label label-warning" *ngIf="demanda.demandasituacao.codigo_demanda_situacao == 3">{{demanda.demandasituacao.nome}}</span>
                                        <span class="label" style="background-color: grey; color: #FFF;" *ngIf="demanda.demandasituacao.codigo_demanda_situacao == 4">{{demanda.demandasituacao.nome}}</span>
                                        <span class="label label-danger" *ngIf="demanda.demandasituacao.codigo_demanda_situacao == 5">{{demanda.demandasituacao.nome}}</span>
                                        <span class="label label-default" *ngIf="demanda.demandasituacao.codigo_demanda_situacao == 6">{{demanda.demandasituacao.nome}}</span>
                                    </td>
                                    <td class="text-center" style="font-size: 22px;">
                                        <a href="demanda-exibir/{{demanda.codigo_demanda}}" (click)="definirCodigo(demanda.codigo_demanda);"><span class="badge badge-warning">Ver</span></a>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="!demandas">
                                <tr class="carregando">
                                    <td class="carregando" colspan="7">
                                        <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="demandas && demandas.length == 0">
                                <tr>
                                    <td class="col-md-12" colspan="8">
                                        Não existem demandas em aberto.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div id="encerrada" class="tab-pane cont">
                    <div class="dataTables_length" id="table3_length">
                        <table id="table2" class="table table-striped table-hover table-fw-widget">
                            <thead>
                                <tr>
                                    <th>Nº</th>
                                    <th>Demandante</th>
                                    <th>Solicitação</th>
                                    <th>Atribuído a</th>
                                    <th>Tipo</th>
                                    <th>Encerrada em</th>
                                    <th class="text-center">Ações</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="demandas_inativas && demandas_inativas.length > 0">
                                <tr class="odd gradeX" *ngFor="let demanda of demandas_inativas">
                                    <td class="col-md-1">{{demanda.numero}}</td>
                                    <td class="col-md-2">{{demanda.demandante.nome}}</td>
                                    <td class="col-md-3">{{demanda.assunto}}</td>
                                    <td class="col-md-2">{{demanda.colaborador?.nome}}</td>
                                    <td class="col-md-2">{{demanda.demandatipo.nome}}</td>
                                    <td class="col-md-2">{{demanda.updated_at | date: 'dd/MM/yyyy HH:mm'}}</td>
                                    <td class="text-center" style="font-size: 22px;">
                                        <a href="demanda-exibir/{{demanda.codigo_demanda}}" (click)="definirCodigo(demanda.codigo_demanda)"><span class="badge badge-warning">Ver</span></a>
                                        &nbsp;
                                        <a *ngIf="demanda.codigo_demanda_situacao > 3" style="cursor: pointer;" data-toggle="modal" data-target="#md-reabrir" (click)="definirCodigo(demanda.codigo_demanda);"><span class="badge badge-primary">Reabrir</span></a>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="!demandas_inativas">
                                <tr class="carregando">
                                    <td class="carregando" colspan="7">
                                        <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="demandas_inativas && demandas_inativas.length == 0">
                                <tr>
                                    <td class="col-md-12" colspan="8">
                                        Não existem demandas encerradas.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>

    </div>
  </div>
</div> 

<div id="md-reabrir" tabindex="-1" role="dialog" class="modal fade colored-header colored-header-primary">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" data-dismiss="modal" aria-hidden="true" class="close md-close"><span class="mdi mdi-close"></span></button>
                <h3 class="modal-title">Reabrir demanda</h3>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <div class="text-warning"><span class="modal-main-icon mdi mdi-alert-triangle"></span></div>
                <h3>Tem certeza que deseja REABRIR esta demanda?</h3>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-default">Cancelar</button>
                <button type="button" data-dismiss="modal" class="btn btn-warning" (click)="reabrir()">Confirmar</button>
            </div>
        </div>
    </div>
  </div>

<app-footer></app-footer>