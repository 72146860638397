import {CabecalhoLayout} from "./cabecalho-layout";

export class CampoCabecalhoLayout {
    codigo_campo_cabecalho_layout: number;
    codigo_cabecalho_layout: number;
    descricao: string;
    sigla: string;
    // schema: string;
    // tabela: string;
    // campo: string;
    sql: string;
    quantidade_caracteres: number;
    padrao: string;
    ordem: number;
    created_at: Date;
    deleted_at: Date;
    CabecalhoLayout : CabecalhoLayout = new CabecalhoLayout();
}
