<app-header></app-header>

<div class="panel panel-default panel-border-color panel-border-color-primary">
    <div class="panel-heading panel-heading-divider">
        Cadastro de associado situação tipo
    </div>
    <div class="panel-body">
        <form #associadoSituacaoTipoForm="ngForm" [formGroup]="form" novalidate=""
            class="form-horizontal group-border-dashed">
            <div class="form-group xs-mt-10">
                <label for="inputNome" class="col-sm-3 control-label">
                    Nome <span class="obrigatorio">*</span>
                </label>
                <div class="col-sm-8">
                    <input id="inputDescricao" type="text" placeholder="Nome" class="form-control"
                        formControlName="nome" />
                </div>
            </div>

            <div class="form-group">
                <div class="col-sm-offset-3 col-sm-7">
                    <button class="btn btn-space btn-default" routerLink="/associado-situacao-tipo-listar">
                        Cancelar
                    </button>
                    <a class="btn btn-space btn-primary" (click)="onSubmit()">Confirmar</a>
                </div>
            </div>
        </form>
    </div>
</div>

<app-footer></app-footer>
