<app-header></app-header>

<div class="panel panel-default panel-border-color panel-border-color-primary">
  <div class="panel-heading panel-heading-divider">Abrir Nova Demanda</div>
  <div class="panel-body">
    <form #demandaForm="ngForm" [formGroup]="form" novalidate="" class="form-horizontal group-border-dashed">

      <div class="form-group xs-mt-5">
        <label for="inputEmail" class="col-sm-3 control-label">
          <span class="obrigatorio">*</span> Tipo de Demanda
        </label>
        <div class="col-sm-3">
          <select class="select2 form-control" formControlName="codigo_demanda_tipo">
            <option *ngFor="let dado of demandaTipo" value="{{dado.codigo_demanda_tipo}}">
              {{dado.nome}}
            </option>
          </select>
        </div>
      </div>

      <div class="form-group xs-mt-5">
        <label for="typeahead-basic" class="col-sm-3 control-label">
          Associado
        </label>
        <div class="col-sm-6">
          <!-- <input type="text" class="form-control" (keyup)="recuperarAssociado($event)" id="userIdFirstWay" list="listaAssociado" autocomplete="off" />
          <datalist id="listaAssociado" (change)="definirCodigoAssociado($event.target.value)">
            <option *ngFor="let dado of associados" [value]="dado.nome">{{dado.nome}}</option>
          </datalist> -->
          <!--<input ng2-auto-complete placeholder="Informe o nome da pessoa demandante" class="form-control" loading-text="Carregando..." no-match-found-text="Nenhum registro encontrado" (valueChanged)="definirCodigoDemandante($event)" display-property-name="nome" formControlName="codigo_demandante" [source]="associados" [list-formatter]="autocompleListFormatter" />-->
          <!--<div class="ng-autocomplete">
            <ng-autocomplete [data]="associados" [searchKeyword]="keyword" (selected)='definirCodigoDemandante($event)'
                notFoundText="Nenhum associado encontrado com este nome"
                [itemTemplate]="itemTemplate"
                [notFoundTemplate]="notFoundTemplate">
            </ng-autocomplete>
        
            <ng-template #itemTemplate let-item>
                <a [innerHTML]="item.nome"></a>
            </ng-template>
        
            <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
            </ng-template>
          </div>-->
          <input id="typeahead-basic" type="text" class="form-control" formControlName="codigo_demandante" autocomplete="off" (typeaheadOnSelect)="definirCodigoDemandante($event)" [typeahead]="associados" typeaheadOptionField="nome" />
        </div>
        <div class="col-sm-2">
          <img *ngIf="carregando" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        </div>
      </div>

      <div class="form-group xs-mt-10">
        <label for="inputNome" class="col-sm-3 control-label">
          <span class="obrigatorio">*</span> Assunto
        </label>
        <div class="col-sm-8">
          <input id="inputNome" type="text" placeholder="Assunto" class="form-control" formControlName="assunto" autocomplete="off">
        </div>
      </div>

      <div class="form-group xs-mt-10">
        <label for="inputEmail" class="col-sm-3 control-label">
          <span class="obrigatorio">*</span> Descrição
        </label>
        <div class="col-sm-8">
            <textarea cols="10" rows="8" placeholder="Descrição" class="form-control" formControlName="descricao"></textarea>
        </div>
      </div>

      <div class="form-group">
          <div class="col-sm-offset-3 col-sm-7">
            <button class="btn btn-space btn-default" routerLink="/dashboard">Cancelar</button>
            <button type="submit" class="btn btn-space btn-primary" (click)="onSubmit()">Confirmar</button>
          </div>
      </div>

    </form>
  </div>
</div>

<app-footer></app-footer>
