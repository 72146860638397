import {Injectable} from "@angular/core";
import { Observable } from 'rxjs/Observable';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PessoaFisicaService {

  private _Url = environment.UrlPrincipal + '/api/pessoafisica';

  constructor(private http: HttpClient) {}

  list(codigo_pessoa_tipo = null) {
    let endereco;
    if(codigo_pessoa_tipo) {
      endereco = this._Url + '?codigo_pessoa_tipo=' + codigo_pessoa_tipo;
    } else {
      endereco = this._Url;
    }
    return this.http.get(endereco)
                    .catch(this.handleError);
  }

  find(id) {
    return this.http.get(this._Url + "/" + id)
                    .catch(this.handleError);
  }

  private handleError(error: Response) {
    return error.json();
  }

}