<app-header></app-header>
  <div class="row">
    <div class="col-md-12 text-center">
      <img src="assets/img/logotipo.svg">
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 text-center">
      <h1><b>SGA</b></h1>
      <h2>Sistema de Gestão de Associados</h2>
    </div>
  </div>
<app-footer></app-footer>

