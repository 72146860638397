import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from 'src/app/shared/shared.module';

import { BrowserModule } from '@angular/platform-browser';
import { AuthGuard } from 'src/app/core/authentication/auth.guard';
import { DocumentoListarComponent } from './documento-listar/documento-listar.component';
import { DocumentoEditarComponent } from './documento-editar/documento-editar.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

const appRoutes: Routes = [
  { path: 'documento-listar', component: DocumentoListarComponent, canActivate: [AuthGuard] },
  { path: 'documento-novo', component: DocumentoEditarComponent, canActivate: [AuthGuard] },
  { path: 'documento-editar/:id', component: DocumentoEditarComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [
    BrowserModule,
    SharedModule,
    FormsModule,
    CKEditorModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    RouterModule.forRoot(appRoutes)
  ],
  declarations: [DocumentoListarComponent, DocumentoEditarComponent]
})
export class DocumentosModule { }
