import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../../../../app.component';
import { NotifierService } from 'angular-notifier';
import { DocumentoTipo } from 'src/app/shared/models/documento-tipo';
import { DocumentoTipoService } from 'src/app/core/services/documento-tipo.service';

@Component({
    selector: 'app-documento-tipo-listar',
    templateUrl: './documento-tipo-listar.component.html',
    styleUrls: ['./documento-tipo-listar.component.css'],
})
export class DocumentoTipoListarComponent implements OnInit {
    documentos_tipo: DocumentoTipo;
    codigo_documento_tipo;
    breadCrumb;
    documentos_tipo_inativos: DocumentoTipo;
    carregando;

    constructor(
        private appComponent: AppComponent,
        private documentoTipoService: DocumentoTipoService,
        private notifierService: NotifierService,
    ) {}

    ngOnInit() {
        this.breadCrumb = [
            { nome: 'Configuração', caminho: 'configuracao' },
            { nome: 'documentos_tipo', caminho: '/documento-tipo-listar' },
        ];
        this.appComponent.setTitulo('documentos Tipo Cadastradas');
        this.appComponent.setBreadcrumb(this.breadCrumb);

        this.listar();
    }

    definirCodigo(codigo_documento_tipo) {
        this.codigo_documento_tipo = codigo_documento_tipo;
    }

    listar(): void {
        this.documentoTipoService.list(false).subscribe(
            (data: DocumentoTipo) => {
                this.documentos_tipo = data;
            },
            (error) => console.log(error),
        );

        this.documentoTipoService.list(true).subscribe(
            (data_inativo: DocumentoTipo) => {
                this.documentos_tipo_inativos = data_inativo;
            },
            (error) => console.log(error),
        );
    }

    apagar(codigo_documento_tipo): void {
        this.carregando = true;
        this.documentoTipoService.delete(codigo_documento_tipo).subscribe(
            (data) => {
                this.listar();
                this.carregando = false;
                this.notifierService.notify(
                    'success',
                    'Registro DELETADO com sucesso.',
                );
            },
            (error) => {
                this.notifierService.notify('error', error);
                this.carregando = false;
            },
        );
    }

    restaurar(codigo_documento_tipo): void {
        this.documentoTipoService.restore(codigo_documento_tipo).subscribe(
            (data) => {
                this.listar();
                this.notifierService.notify(
                    'success',
                    'Registro RESTAURADO com sucesso.',
                );
            },
            (error) => {
                this.notifierService.notify(
                    'error',
                    'Não foi possível REATIVAR o registro. ' + error,
                );
            },
        );
    }
}
