<app-header></app-header>

  <div class="panel panel-default panel-border-color panel-border-color-primary">
    <div class="panel-heading panel-heading-divider">Dados Básicos</div>
    <div class="panel-body">
      <form #colaboradorForm="ngForm" [formGroup]="form" novalidate="" class="form-horizontal group-border-dashed">
        <div class="form-group xs-mt-10">
          <label for="inputNome" class="col-sm-3 control-label">
            <span class="obrigatorio">*</span> Nome
            <p>Sem abreviações</p>
          </label>
          <div class="col-sm-9">
            <input id="inputNome" type="text" placeholder="Nome" class="form-control" formControlName="nome">
            <div *ngIf="submitted && f.nome.errors" class="obrigatorio">
              <div *ngIf="f.nome.errors.required">Campo obrigatório</div>
            </div>
          </div>
        </div>
        <div class="form-group xs-mt-10">
          <label for="inputEmail" class="col-sm-3 control-label">
            <span class="obrigatorio">*</span> E-mail
            <p>Informe o e-mail da associação</p>
          </label>
          <div class="col-sm-9">
            <input id="inputEmail" type="email" placeholder="E-mail" class="form-control" formControlName="email">
            <div *ngIf="submitted && f.email.errors" class="obrigatorio">
              <div *ngIf="f.email.errors">Campo obrigatório e deve ser preenchido com um e-mail válido</div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="inputCPF" class="col-sm-3 control-label">
            <span class="obrigatorio">*</span> CPF
            <p>Informe um CPF válido</p>
          </label>
          <div class="col-sm-4">
            <input type="text" placeholder="999.999.999-99" class="form-control" [dropSpecialCharacters]="true" mask="000.000.000-00" formControlName="cpf">
            <div *ngIf="submitted && erro_cpf" class="obrigatorio">
              <div *ngIf="erro_cpf">CPF inválido</div>
              <div *ngIf="f.cpf.errors.required">Campo obrigatório</div>
            </div>
          </div>
        </div>
  
        <div class="form-group">
            <div class="col-sm-offset-3 col-sm-7">
              <button class="btn btn-space btn-default" routerLink="/dashboard">Cancelar</button>
              <button type="submit" class="btn btn-space btn-primary" (click)="onSubmit()">Confirmar</button>
            </div>
        </div>
  
      </form>
    </div>
  </div>

<app-footer></app-footer>
