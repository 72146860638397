import { Component, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { NotifierService } from 'angular-notifier';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Associado } from 'src/app/shared/models/associado';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { AssociadoService } from 'src/app/core/services/associado.service';
import { ClasseService } from 'src/app/core/services/classe.service';
import { Classe } from 'src/app/shared/models/classe';
import { FormValidation } from 'src/app/shared/validation/form-validation';
import { TipoSanguineoService } from 'src/app/core/services/tipo-sanguineo.service';
import { TipoSanguineo } from 'src/app/shared/models/tipo-sanguineo';
import { DatePipe } from '@angular/common'; //import do datePipe

@Component({
  selector: 'app-dados-pessoais',
  templateUrl: './dados-pessoais.component.html',
  styleUrls: ['./dados-pessoais.component.css'],
  providers: [DatePipe] //DatePipe como provider
})
export class DadosPessoaisComponent implements OnInit {

  @ViewChild('associadoForm') formValues;

  form: FormGroup;
  breadCrumb;
  me;
  carregando: boolean = true;
  codigo_pessoa: number;
  classeAssociado: Classe;
  tipoSanguineo;
  tipo_associado: string;
  pessoa_fisica: boolean;
  submited: boolean;
  carregando_salvar: boolean;
  aprovado: string;

  constructor(private appComponent: AppComponent,
              private formBuilder: FormBuilder,
              private routerParam: ActivatedRoute,
              private router: Router,
              private authenticationService: AuthenticationService,
              private datePipe: DatePipe,
              private associadoService: AssociadoService,
              private classeService: ClasseService,
              private tipoSanguineoService: TipoSanguineoService,
              private notifierService: NotifierService) { 
              this.codigo_pessoa = this.routerParam.snapshot.params.id;
  }

  ngOnInit() {

    this.breadCrumb = [ 
      {nome: "Home", caminho: "/dashboard"},
      {nome: "Associados", caminho: "/associado-listar"},
      {nome: "Cadastro", caminho: "/dados-pessoais"},
    ];
    this.appComponent.setTitulo('Cadastro do Associado');
    this.appComponent.setBreadcrumb(this.breadCrumb);

    this.form = this.formBuilder.group({
      siape: [null, [Validators.required, FormValidation.siapeAtivoValidator]],  
      matricula_dpf: [null, Validators.required],  
      data_posse_dpf: [null, FormValidation.dataValidator],
      data_nascimento: [null, [Validators.required, FormValidation.dataValidator]],
      codigo_classe: [null, Validators.required],
      tipo: [null, Validators.required],
      nome: [null, Validators.required],
      nome_guerra: [null],
      nome_pai: [null],
      nome_mae: [null],
      codigo_tipo_sanguineo: [null],
      sexo: [null, Validators.required],
      cpf: [null, [Validators.required, FormValidation.cpfValidator]],
      cpf_conjuge_falecido: [null, FormValidation.cpfValidator],
      rg: [null, Validators.required],
      data_aposentadoria: [null],
      pis_pasep: [null, FormValidation.pisPasepValidator],
    });

    const dataAposentadoriaControl = this.form.get('data_aposentadoria');
    const cpfConjugeFalecidoControl = this.form.get('cpf_conjuge_falecido');
    const matriculaDpfControl = this.form.get('matricula_dpf');
    const codigoClasseControl = this.form.get('codigo_classe');
    const siapeControl = this.form.get('siape');
    const pisPasepControl = this.form.get('pis_pasep');
    this.form.get('tipo').valueChanges
             .subscribe(tipo => {
      if (tipo === 'AP') {
        dataAposentadoriaControl.setValidators([Validators.required, FormValidation.dataValidator]);
        siapeControl.setValidators(null);
      }
      if (tipo === 'AT' || tipo === 'PE') {
        dataAposentadoriaControl.setValidators(null);
        siapeControl.setValidators(null);
        cpfConjugeFalecidoControl.setValidators(null);
        cpfConjugeFalecidoControl.setValidators(null);
        matriculaDpfControl.setValidators(Validators.required);
        codigoClasseControl.setValidators(Validators.required);
      }
      if (tipo === 'PE') {       
        cpfConjugeFalecidoControl.setValidators(Validators.required);
        cpfConjugeFalecidoControl.setValidators(FormValidation.cpfValidator);
        matriculaDpfControl.setValidators(null);
        codigoClasseControl.setValidators(null);
        pisPasepControl.setValidators(null);
        siapeControl.setValidators(FormValidation.siapePensionistaValidator);
      }
      dataAposentadoriaControl.updateValueAndValidity();
      cpfConjugeFalecidoControl.updateValueAndValidity();
      matriculaDpfControl.updateValueAndValidity();
      codigoClasseControl.updateValueAndValidity();
      siapeControl.updateValueAndValidity();
    });

    this.obterClasse();
    this.obterTipoSanguineo();

    if(this.codigo_pessoa) {
      this.obter();
    }

  }

  definirTipoAssociado(tipo_associado) {
    this.tipo_associado = tipo_associado;
    this.form.patchValue(tipo_associado);
  }

  obterTipoSanguineo() {
    this.tipoSanguineoService.list()
    .subscribe((data: TipoSanguineo) => this.tipoSanguineo = data,
                error => console.log(error));             
  }

  obter(): void {
    this.carregando = true;
    this.associadoService.find(this.codigo_pessoa).subscribe((data: Associado) => {
      if(!data.pessoa) {
        this.form.patchValue({
          nome: data.nome,
          email: data.email,
        });
      } else {
        this.form.patchValue({
          nome: data.pessoa.nome,
          email: data.pessoa.email,
          cpf: data.pessoa_fisica.cpf,
          cpf_conjuge_falecido: data.cpf_conjuge_falecido,
          siape: data.siape,
          matricula_dpf: data.matricula_dpf,  
          data_posse_dpf: this.datePipe.transform(data.data_posse_dpf, 'dd/MM/yyyy'),
          data_nascimento: this.datePipe.transform(data.pessoa_fisica.data_nascimento, 'dd/MM/yyyy'),
          data_aposentadoria: this.datePipe.transform(data.data_aposentadoria, 'dd/MM/yyyy'),
          codigo_classe: data.codigo_classe,
          tipo: data.tipo,
          nome_controle: data.nome_controle,
          nome_guerra: data.nome_guerra,
          nome_pai: data.pessoa_fisica.nome_pai,
          nome_mae: data.pessoa_fisica.nome_mae,
          sexo: data.pessoa_fisica.sexo,
          rg: data.pessoa_fisica.rg,
          pis_pasep: data.pessoa_fisica.pis_pasep,
          codigo_tipo_sanguineo: data.codigo_tipo_sanguineo,
        });
      }
      this.tipo_associado = data.tipo;
      this.aprovado = data.aprovado;
      this.carregando = false;
    });
  }

  obterClasse() {
    this.classeService.list(false)
    .subscribe((data: Classe) => this.classeAssociado = data,
                error => console.log(error));
                
  }

  // convenience getter for easy access to form fields
  get f() {    
    return this.form.controls;
  }

  aprovar(codigo_pessoa):void {
    let associado = {'aprovado': 'S', 'codigo_pessoa': this.codigo_pessoa, 'criar_plano': true};
    this.associadoService.save(associado, codigo_pessoa).subscribe((data) => {
      this.notifierService.notify( 'success', 'Cadastro APROVADO com sucesso.' );
      this.router.navigate(['associado-listar']);
    }, (error) => {
      this.notifierService.notify( 'danger', 'Não foi possível APROVAR o cadastro, ocorreu um erro. ' + error );
    });
  }

  onSubmit(avancar): void {
    this.submited = true;
    this.carregando_salvar = true;
    let associado = this.form.value as Associado;    
    if (this.form.valid) {
      associado.codigo_pessoa = this.codigo_pessoa;
      this.associadoService.save(associado, this.codigo_pessoa).subscribe((data) => {
        this.notifierService.notify( 'success', 'Dados pessoais ATUALIZADOS com sucesso.' );
        this.carregando_salvar = false;
        if(avancar) {
          this.router.navigate(['dados-financeiros/' + this.codigo_pessoa]);
        }
      }, (error) => {
        this.carregando_salvar = false;
        this.notifierService.notify( 'error', 'Não foi possível ATUALIZAR dados pessoais, pois ocorreu um erro.' + error );
      });
    } else {
      console.log(this.form.controls);
      this.carregando_salvar = false;
      this.notifierService.notify( 'error', 'Existem erros no preenchimento do formulário.');
    }
  }

}
