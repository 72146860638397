import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Plano } from 'src/app/shared/models/plano';
import { Classe } from 'src/app/shared/models/classe';
import { AppComponent } from 'src/app/app.component';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { PlanoService } from 'src/app/core/services/plano.service';
import { ClasseService } from 'src/app/core/services/classe.service';

@Component({
  selector: 'app-plano-editar',
  templateUrl: './plano-editar.component.html',
  styleUrls: ['./plano-editar.component.css']
})
export class PlanoEditarComponent implements OnInit {

  @ViewChild('planoForm') formValues;

  form: FormGroup;
  breadCrumb;
  plano: Plano;
  classes: Classe;
  carregando: boolean;
  submited: boolean;
  codigo_plano: any;
  codigo_classe: any;
  nome_classe: any;

  constructor(private appComponent: AppComponent,
              private formBuilder: FormBuilder,
              private routerParam: ActivatedRoute,
              private router: Router,
              private authenticationService: AuthenticationService,
              private planoService: PlanoService,
              private classeService: ClasseService,
              private notifierService: NotifierService) {
              this.codigo_plano = this.routerParam.snapshot.params.id;
  }

  ngOnInit() {

    this.carregando = true;
    this.breadCrumb = [
      {nome: 'Configuração', caminho: '/configuracao'},
      {nome: 'Planos', caminho: '/plano-listar'},
    ];
    if(this.codigo_plano) {
      this.appComponent.setTitulo('Alteração de Plano');
    } else {
      this.appComponent.setTitulo('Cadastro de Plano');
    }
    this.appComponent.setBreadcrumb(this.breadCrumb);

    this.form = this.formBuilder.group({
      codigo_classe: [null],
      descricao: [null, Validators.required],
      valor: [null, Validators.required]
    });

    if(this.codigo_plano) {
      this.obter();
    }
    this.obterClasse();

  }

  obterClasse() {
    let sem_plano;
    if(this.codigo_plano) {
      sem_plano = true;
    } else {
      sem_plano = false;
    }
    this.classeService.listSemPlano(sem_plano)
    .subscribe((data: Classe) => this.classes = data,
                error => console.log(error));
  }

  obter(): void {
    this.planoService.find(this.codigo_plano).subscribe((data: Plano) => {
      if(data.codigo_classe) {
        this.codigo_classe = data.codigo_classe;
        this.nome_classe = data.classe.nome;
      }
      this.form.patchValue({
        codigo_classe: data.codigo_classe,
        descricao: data.descricao,
        valor: data.valor
      });
    });
  }

  onSubmit(): void {
    this.submited = true;
    this.carregando = true;
    const plano = this.form.value as Plano;
    plano.padrao = false;
    if (this.form.valid) {
      this.planoService.save(plano, this.codigo_plano).subscribe((data) => {
        this.notifierService.notify( 'success', 'Plano cadastrado com sucesso.' );
        this.carregando = false;
        this.router.navigate(['plano-listar']);
      }, (error) => {
        this.notifierService.notify( 'error', 'Não foi cadastrar um novo plano. ' + error );
        this.carregando = false;
      });
    } else {
      console.log(this.form);
      this.notifierService.notify( 'error', 'Existem erros no preenchimento do formulário.');
      this.carregando = false;
    }
  }

}
