import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AppComponent } from 'src/app/app.component';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { Colaborador } from 'src/app/shared/models/colaborador';
import { ColaboradorService } from 'src/app/core/services/colaborador.service';
import { PessoaPerfilService } from 'src/app/core/services/pessoa-perfil.service';
import { PessoaPerfil } from 'src/app/shared/models/pessoa-perfil';
import { ValidarCpf } from 'src/app/shared/validation/cpf-validation';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-colaborador-editar',
  templateUrl: './colaborador-editar.component.html',
  styleUrls: ['./colaborador-editar.component.css']
})
export class ColaboradorEditarComponent implements OnInit {

  @ViewChild('colaboradorForm') formValues;

  pessoaPerfil: PessoaPerfil;
  colaborador: Colaborador;
  form: FormGroup;
  codigo_pessoa;
  breadCrumb;
  submitted;
  erro_cpf;
  carregando: boolean;

  constructor(private appComponent: AppComponent,
              private formBuilder: FormBuilder,
              private routerParam: ActivatedRoute,
              private notifierService: NotifierService,
              private router: Router,
              private pessoaPerfilService: PessoaPerfilService,
              private colaboradorService: ColaboradorService) {

              this.codigo_pessoa = this.routerParam.snapshot.params.id;

  }

  ngOnInit() {
    if(!this.codigo_pessoa) {
      this.breadCrumb = [ 
        {nome: "Colaboradores", caminho: "/colaborador-listar"},
        {nome: "Novo", caminho: "/colaborador-editar"}
      ];
    } else {
      this.breadCrumb = [ 
        {nome: "Colaboradores", caminho: "/colaborador-listar"},
        {nome: "Editar", caminho: "/colaborador-editar/" + this.codigo_pessoa}
      ];
    }
    if(!this.codigo_pessoa) {
      this.appComponent.setTitulo('Cadastrar Colaborador');
    } else {
      this.appComponent.setTitulo('Editar Colaborador');
    }
    this.appComponent.setBreadcrumb(this.breadCrumb);

    this.listarPerfil();

    if(this.codigo_pessoa) {
      this.obter();
    }

    this.form = this.formBuilder.group({
      nome: [null, Validators.required],  
      email: [null, Validators.required],
      cpf: [null, Validators.required],
      codigo_pessoa_perfil: [null, Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  definirCodigo(codigo_pessoa) {
    this.codigo_pessoa = codigo_pessoa;
  }

  listarPerfil(): void {
    this.pessoaPerfilService.list(1).subscribe((data: PessoaPerfil) => {
      this.pessoaPerfil = data;
    });
  }

  obter(): void {
    this.carregando = true;
      this.colaboradorService.find(this.codigo_pessoa).subscribe((data: Colaborador) => {        
        this.form.patchValue({
          nome: data.pessoa.nome,
          email: data.pessoa.email,
          cpf: data.cpf,
          codigo_pessoa_perfil: data.pessoa.codigo_pessoa_perfil
        });
        console.log(data);
        
        this.carregando = false;
    });
  }

  onSubmit(): void {
    this.submitted = true;
    let colaborador = this.form.value as Colaborador;
    if(!ValidarCpf.cpf(colaborador.cpf)) {
      this.erro_cpf = true;
    } else if (this.form.valid) {
      colaborador.codigo_pessoa = this.codigo_pessoa;
      colaborador.codigo_pessoa_tipo = 2;
      this.colaboradorService.save(colaborador, this.codigo_pessoa).subscribe((data) => {
        this.formValues.resetForm();
        if(!this.codigo_pessoa) {
          this.notifierService.notify( 'success', 'Registro CADASTRADO com sucesso.' );
        } else {
          this.notifierService.notify( 'success', 'Registro ATUALIZADO com sucesso.' );
        }
        this.codigo_pessoa = null;
        this.router.navigate(['colaborador-listar']);
      }, (error) => {      
        this.notifierService.notify( 'error', error);
      });
    }
  }

  private handleError(error: Response) {
    return Observable.throw(error.json() || 'Ops! Ocorreu algum problema técnico que impediu o funcionamento correto do sistema. Entre em contato com suporte');
  }

}
