<app-header></app-header>

<div class="panel panel-default panel-border-color panel-border-color-primary">
    <div class="panel-heading panel-heading-divider">
        <form [formGroup]="form" (ngSubmit)="submitConsulta()" class="form-horizontal group-border-dashed">
            <div class="panel-body">
                <div class="form-group">
                  <span class="col-sm-3" style="text-align: right;">
                    <label class="control-label">Tipo de Layout <span class="obrigatorio">*</span></label>
                  </span>
                    <span class="col-sm-3">
                        <select class="select2 form-control" formControlName="sigla_tipo_layout" required (change)="listarLayouts($event.target.value)">
                            <option value="">Selecione</option>
                            <option *ngFor="let tipo_layout of tipos_layout" [value]="tipo_layout.sigla">
                                {{tipo_layout.descricao}}
                            </option>
                        </select>
                    </span>
                </div>
                <div class="form-group">
                  <span class="col-sm-3" style="text-align: right;">
                    <label class="control-label">Layout <span class="obrigatorio">*</span></label>
                  </span>
                    <span class="col-sm-3">
                        <select class="form-control" id="codigo_layout" required formControlName="codigo_layout">
                            <option value="">Selecione</option>
                            <option value="{{layout.codigo_layout}}" *ngFor="let layout of layouts">{{layout.titulo}}</option>
                        </select>
                    </span>
                    <span class="col-sm-1">
                        <i *ngIf="this.form.get('codigo_layout').disabled" class="mdi mdi-edit text-danger" (click)="this.form.get('codigo_layout').enable()" title="Habilitar edição do campo Layout" style="cursor: pointer;"></i>
                    </span>
                </div>
                <div class="form-group">
                  <span class="col-sm-3" style="text-align: right;">
                    <label class="control-label">Layout de consignação<span class="obrigatorio">*</span></label>
                  </span>
                    <span class="col-sm-3">
                        <select class="form-control" id="codigo_layout_aceite" required formControlName="codigo_layout_aceite">
                            <option value="">Selecione</option>
                            <option value="{{layout.codigo_layout}}" *ngFor="let layout of layouts_aceite">{{layout.titulo}}</option>
                        </select>
                    </span>
                    <span class="col-sm-1">
                        <i *ngIf="this.form.get('codigo_layout_aceite').disabled" class="mdi mdi-edit text-danger" (click)="this.form.get('codigo_layout_aceite').enable()" title="Habilitar edição do campo Layout de consignação" style="cursor: pointer;"></i>
                    </span>
                </div>
                <div class="form-group">
                  <span class="col-sm-3" style="text-align: right;">
                    <label class="control-label">Ano refer&ecirc;ncia <span class="obrigatorio">*</span></label>
                  </span>
                    <span class="col-sm-3">
                        <input type="number" class="form-control" id="ano_referencia" maxlength="4" minlength="2" required formControlName="ano_referencia" />
                    </span>
                </div>
                <div class="form-group">
                  <span class="col-sm-3" style="text-align: right;">
                    <label class="control-label">M&ecirc;s refer&ecirc;ncia <span class="obrigatorio">*</span></label>
                  </span>
                    <span class="col-sm-3">
                        <select class="form-control" id="mes_referencia" required formControlName="mes_referencia">
                            <option value="">Selecione</option>
                            <option value="{{mes.id}}" *ngFor="let mes of meses">{{mes.descricao}}</option>
                        </select>
                    </span>
                </div>
                <div class="form-group">
                  <span class="col-sm-3" style="text-align: right;">
                    <label class="control-label">Já gerados</label>
                  </span>
                    <span class="col-sm-3">
                        <select class="select2 form-control" formControlName="associados_gerados">
                            <option value="">Selecione</option>
                            <option value="1">Sim</option>
                            <option value="2">N&atilde;o</option>
                        </select>
                    </span>
                </div>
                <div class="form-group">
                  <span class="col-sm-3" style="text-align: right;">
                    <label class="control-label">Nome/CPF do associado</label>
                  </span>
                    <span class="col-sm-3">
                        <input type="text" class="form-control" id="nome_cpf_associado" formControlName="nome_cpf_associado" />
                    </span>
                </div>
                <div class="form-group">
                    <div class="col-sm-offset-3 col-sm-7">
                        <button type="submit" class="btn btn-space btn-primary" [disabled]="">Filtrar</button>
                        <button type="button" class="btn btn-space btn-default" (click)="voltar()">Voltar</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="panel-body" *ngIf="carregado">
        <div class="alert alert-danger" *ngIf="cor_alterada">Alerta: clique em 'Filtrar' para atualizar os valores destacados em amarelo</div>
        <div class="tab-navigation">
            <ul role="tablist" class="nav nav-tabs nav-justified">
                <li role="presentation" class="active"><a href="#tab1" aria-controls="tab1" role="tab" data-toggle="tab">Associados com altera&ccedil;&atilde;o</a></li>
                <li role="presentation"><a href="#tab2" aria-controls="tab2" role="tab" data-toggle="tab">Associados sem altera&ccedil;&atilde;o</a></li>
            </ul>
        </div>
        <div class="tab-panel">
            <div class="tab-content">
                <div id="tab1" role="tabpanel" class="tab-pane tab-pagamento active">
                    <div class="dataTables_length" id="table3_length">
                        <form [formGroup]="form_associado" (ngSubmit)="openModalEnvioArquivo(md_envio_arquivo)" class="form-horizontal group-border-dashed">
                            <table id="table2" class="table table-hover table-fw-widget" *ngIf="carregado && associados_pagamento.length > 0">
                                <thead>
                                    <tr>
                                        <th>
                                            <input type="checkbox" (change)="toggle($event)" [checked]="checked_all" class="form-check-input" />
                                        </th>
                                        <th>Nome</th>
                                        <th>UF</th>
                                        <th>CPF</th>
                                        <th>Comando</th>
                                        <th>Valor a<br/>pagar</th>
                                        <th>Pago m&ecirc;s<br/>anterior</th>
                                        <th>Pagamento<br/>preferencial</th>
                                        <th>J&aacute; gerado?</th>
                                        <th>A&ccedil;&atilde;o</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let associado of associados_pagamento; let i = index;" [bgColor]="colors_com_pagamento[i]" formArrayName="itens">
                                        <td [formGroupName]="i">
                                            <input type="checkbox" class="form-check-input" [value]="associado.codigo_pessoa" formControlName="codigo_pessoa" (click)="check(associado.codigo_pessoa, $event)" />
                                        </td>
                                        <td>{{associado.nome}}</td>
                                        <td>{{associado.uf_lotacao}}</td>
                                        <td>{{associado.cpf}}</td>
                                        <td>{{associado.comando_descricao}}</td>
                                        <td>{{associado.valor}}</td>
                                        <td>{{associado.valor_cobrado_mes_anterior}}</td>
                                        <td>
                                            <i class="badge badge-info" title="Mensalidade">M: {{associado.forma_pagamento}}</i>
                                            <span *ngIf="associado.valor_adicional && associado.valor_adicional != 0.00">
                                                <br/><i class="badge badge-warning" title="Pagamento adicional">A: Desconto em Folha</i>
                                            </span>
                                        </td>
                                        <td>{{associado.data_arquivo ? "em: " + associado.data_arquivo : "Não"}}</td>
                                        <td>
                                            <button type="button" (click)="downloadFile(associado.codigo_arquivo)" class="btn btn-warning" *ngIf="associado.codigo_arquivo" title="Download do arquivo gerado">
                                                <span class="icon mdi mdi-download"></span>
                                            </button>
                                            &nbsp;
                                            <a href="/ficha-financeira/{{associado.codigo_pessoa}}" target="_blank" (click)="linkFichaFinanceira(i, true)" class="btn btn-success" title="Ficha financeira">
                                                <span class="icon mdi mdi-money"></span>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="alert alert-warning" *ngIf="carregado && associados_pagamento.length == 0">N&atilde;o existem associados com altera&ccedil;&atilde;o com os filtros informados</div>
                            <div class="alert alert-danger" *ngIf="cor_alterada">Alerta: clique em 'Filtrar' para atualizar os valores destacados em amarelo</div>
                            <div class="form-group">
                                <button type="submit" class="btn btn-space btn-primary">Gerar</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div id="tab2" role="tabpanel" class="tab-pane tab-sem-pagamento">
                    <div class="dataTables_length" id="table4_length">
                        <table id="table3" class="table table-hover table-fw-widget" *ngIf="carregado && associados_sem_pagamento.length > 0">
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>UF</th>
                                    <th>CPF</th>
                                    <th>Valor devido</th>
                                    <th>Pagamento preferencial</th>
                                    <th>A&ccedil;&atilde;o</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let associado of associados_sem_pagamento; let i = index" [bgColor]="colors_sem_pagamento[i]">
                                    <td>{{associado.nome}}</td>
                                    <td>{{associado.uf_lotacao}}</td>
                                    <td>{{associado.cpf}}</td>
                                    <td>{{associado.valor}}</td>
                                    <td>{{associado.forma_pagamento}}</td>
                                    <td>
                                        <a href="/ficha-financeira/{{associado.codigo_pessoa}}" target="_blank" (click)="linkFichaFinanceira(i, false)" class="btn btn-success" title="Ficha financeira">
                                            <span class="icon mdi mdi-money"></span>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="alert alert-warning" *ngIf="carregado && associados_sem_pagamento.length == 0">N&atilde;o existem associados sem altera&ccedil;&atilde;o com os filtros informados</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="carregando" *ngIf="mostrar_carregando">
        <img src="/assets/img/carregando.gif" width="40px">&nbsp;&nbsp;Carregando...
    </div>
</div>

<ng-template #md_envio_arquivo class="modal fade" tabindex="-1" role="dialog" aria-labelledby="md-envio-arquivo-form">
    <form>
        <div class="modal-header">
            <button type="button" (click)="md_envio_arquivo_ref.hide()" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
        </div>
        <div class="modal-body">
            <div class="text-center">
                Deseja realmente gerar o arquivo?
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary btn-space" (click)="save()">Confirmar</button>
            <button type="button" class="btn btn-default btn-space" (click)="md_envio_arquivo_ref.hide()">Cancelar</button>
        </div>
    </form>
</ng-template>
