import {Component, OnInit} from '@angular/core';
import {TipoLayoutService} from "../../../core/services/tipo-layout.service";
import {Router} from "@angular/router";
import {NotifierService} from "angular-notifier";
import {TipoLayout} from "../../../shared/models/tipo-layout";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Arquivo} from "../../../shared/models/arquivo";
import {ArquivoService} from "../../../core/services/arquivo.service";
import {isUndefined} from "util";
import {LayoutService} from "../../../core/services/layout.service";
import {Layout} from "../../../shared/models/layout";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";

@Component({
    selector: 'app-consignacao-form',
    templateUrl: './consignacao-form.component.html',
    styleUrls: ['./consignacao-form.component.css']
})
export class ConsignacaoFormComponent implements OnInit {

    public tipos_layout: Array<TipoLayout> = [];
    public form: FormGroup;
    public meses: Array<{id: number, descricao: string}> = [];
    public file: File;
    public layouts: Array<Layout> = [];
    public modal_msg_erro_ref: BsModalRef;
    public mensagem: string = null;

    constructor(private arquivoService: ArquivoService,
                private tipoLayoutService: TipoLayoutService,
                private router: Router,
                private notifierService: NotifierService,
                private layoutService: LayoutService,
                private modalService: BsModalService) {

    }

    ngOnInit() {
        this.form = new FormGroup({
            sigla_tipo_layout: new FormControl("", [Validators.required]),
            codigo_layout: new FormControl({value: "", disabled: true}, [Validators.required]),
            ano_referencia: new FormControl("", [Validators.required]),
            mes_referencia: new FormControl("", [Validators.required]),
            arquivo_consignacao: new FormControl("", [Validators.required]),
        });
        this.listTiposLayout();
        this.listMeses();
    }

    listMeses(): void{
        this.meses.push({id: 1, descricao: "Janeiro"});
        this.meses.push({id: 2, descricao: "Fevereiro"});
        this.meses.push({id: 3, descricao: "Março"});
        this.meses.push({id: 4, descricao: "Abril"});
        this.meses.push({id: 5, descricao: "Maio"});
        this.meses.push({id: 6, descricao: "Junho"});
        this.meses.push({id: 7, descricao: "Julho"});
        this.meses.push({id: 8, descricao: "Agosto"});
        this.meses.push({id: 9, descricao: "Setembro"});
        this.meses.push({id: 10, descricao: "Outubro"});
        this.meses.push({id: 11, descricao: "Novembro"});
        this.meses.push({id: 12, descricao: "Dezembro"});
    }

    listTiposLayout(): void{
        this.tipos_layout = [];
        this.form.patchValue({
            "codigo_layout": "",
        });
        this.tipoLayoutService.list(false).subscribe((tipos_layout: Array<TipoLayout>) => {
            this.tipos_layout = tipos_layout as Array<TipoLayout>;
        });
    }
    
    listarLayouts(tipo_layout: string): void{
        this.layouts = [];
        this.layoutService.list(tipo_layout).subscribe((layouts: Array<Layout>) => {
            this.layouts = layouts;
            if(layouts.length > 0){
                this.form.patchValue({
                    "codigo_layout": layouts[0].codigo_layout,
                });
            }
        }, () => {
            this.notifierService.notify('error', 'Ocorreu um erro ao carregar os layouts, selecione novamente o tipo de layout');
        });
    }

    voltar(): void {
        this.router.navigate(['layout/consignacao']);
    }

    save(content_modal): void{
        this.mensagem = null;
        if(this.form.valid) {
            let layout_desabilitado: boolean = this.form.get("codigo_layout").disabled;
            if(layout_desabilitado) {
                this.form.get("codigo_layout").enable();
            }
            let arquivo: Arquivo = this.form.value as Arquivo;
            if(layout_desabilitado){
                this.form.get("codigo_layout").disable();
            }
            this.arquivoService.save(arquivo, this.file).subscribe((data: any) => {
                this.notifierService.notify('success', 'Arquivo de consignação cadastrado com sucesso');
                this.router.navigate(['layout/consignacao']);
            }, (error) => {
                if(typeof error === 'string'){
                    this.mensagem = error;
                    this.modal_msg_erro_ref = this.modalService.show(content_modal, {class: 'modal-lg', keyboard: false, focus: true});
                }else if(isUndefined(error.errors)){
                    this.notifierService.notify('error', 'Ocorreu um erro ao cadastrar o arquivo de consignação, tente novamente');
                }else if(error.errors.length > 0){
                    error.errors.forEach((error) => {
                        this.notifierService.notify('error', error);
                    });
                }
            });
        } else {
            this.notifierService.notify('error', "Informe os campos obrigatórios");
        }
    }

    onFileChange(event): void{
        if(event.target.files.length > 0) {
            this.file = event.target.files[0];
        }
    }

}
