<app-header></app-header>

<div class="panel panel-default panel-border-color panel-border-color-primary">
    <div class="panel-heading panel-heading-divider">
        <form [formGroup]="form" (ngSubmit)="save(md_msg_erro)" class="form-horizontal group-border-dashed" >
            <div class="panel-body">
                <div class="form-group">
                  <span class="col-sm-3" style="text-align: right;">
                    <label class="control-label">Tipo de Layout <span class="obrigatorio">*</span></label>
                  </span>
                    <span class="col-sm-3">
                        <select class="select2 form-control" formControlName="sigla_tipo_layout" required (change)="listarLayouts($event.target.value)">
                            <option value="">Selecione</option>
                            <option *ngFor="let tipo_layout of tipos_layout" [value]="tipo_layout.sigla">
                                {{tipo_layout.descricao}}
                            </option>
                        </select>
                    </span>
                </div>
                <div class="form-group">
                  <span class="col-sm-3" style="text-align: right;">
                    <label class="control-label">Layout <span class="obrigatorio">*</span></label>
                  </span>
                    <span class="col-sm-3">
                        <select class="form-control" id="codigo_layout" required formControlName="codigo_layout">
                            <option value="">Selecione</option>
                            <option value="{{layout.codigo_layout}}" *ngFor="let layout of layouts">{{layout.titulo}}</option>
                        </select>
                    </span>
                    <span class="col-sm-1">
                        <i *ngIf="this.form.get('codigo_layout').disabled" class="mdi mdi-edit text-danger" (click)="this.form.get('codigo_layout').enable()" title="Habilitar edição do campo Layout" style="cursor: pointer;"></i>
                    </span>
                </div>
                <div class="form-group">
                  <span class="col-sm-3" style="text-align: right;">
                    <label class="control-label">Ano refer&ecirc;ncia <span class="obrigatorio">*</span></label>
                  </span>
                    <span class="col-sm-3">
                        <input type="number" class="form-control" id="ano_referencia" maxlength="4" minlength="2" required formControlName="ano_referencia" />
                    </span>
                </div>
                <div class="form-group">
                  <span class="col-sm-3" style="text-align: right;">
                    <label class="control-label">M&ecirc;s refer&ecirc;ncia <span class="obrigatorio">*</span></label>
                  </span>
                    <span class="col-sm-3">
                        <select class="form-control" id="mes_referencia" required formControlName="mes_referencia">
                            <option value="">Selecione</option>
                            <option value="{{mes.id}}" *ngFor="let mes of meses">{{mes.descricao}}</option>
                        </select>
                    </span>
                </div>
                <div class="form-group">
                  <span class="col-sm-3" style="text-align: right;">
                    <label class="control-label">Arquivo de consigna&ccedil;&atilde;o <span class="obrigatorio">*</span></label>
                  </span>
                    <span class="col-sm-3">
                        <input type="file" formControlName="arquivo_consignacao" (change)="onFileChange($event)" />
                    </span>
                </div>
                <div class="form-group">
                    <div class="col-sm-offset-3 col-sm-7">
                        <button type="submit" class="btn btn-space btn-primary" [disabled]="">Salvar</button>
                        <button type="button" class="btn btn-space btn-default" (click)="voltar()">Voltar</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<ng-template #md_msg_erro class="modal fade" tabindex="-1" role="dialog" aria-labelledby="md-msg-erro">
    <div class="modal-header">
        <h4 class="modal-title" id="md-msg-erro">Erro</h4>
        <button type="button" (click)="modal_msg_erro_ref.hide()" aria-hidden="true" class="close"><span class="mdi mdi-close"></span></button>
    </div>
    <div class="modal-body">
        <div [innerHTML]="mensagem | html"></div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default btn-space" (click)="modal_msg_erro_ref.hide()">Fechar</button>
    </div>
</ng-template>
